const journeyTypeToJourneyName = type => {
  // todo reuse other code for this, we shouldn't have hardcoded stuff in the middle of nowhere
  switch (type) {
    case "cash":
      return "cashJourney";
    case "vac":
      return "vacJourney";
    case "lld":
      return "lldJourney";
    case "loa":
      return "loaJourney";
    case "finance":
      return "finance";
    default:
      return null;
  }
};

const getEcoBonus = (journeyType, appConfig, businessModel) => {
  const { configuration } = appConfig;

  const ecoBonus = configuration.app.solJourney.ecoBonus;

  const configPath =
    businessModel === "b2b"
      ? configuration.app.solJourney.b2b
      : configuration.app.solJourney;

  const journey = configPath[journeyTypeToJourneyName(journeyType)];

  if (!journey) return ecoBonus;

  return {
    ...ecoBonus,
    enabled:
      journey.ecoBonus?.enabled === undefined
        ? ecoBonus.enabled
        : journey.ecoBonus.enabled,
  };
};

const getEcoBonusFromDeal = (deal, appConfig) =>
  getEcoBonus(deal.financeSimulation.journey, appConfig, deal.businessModel);

module.exports = { getEcoBonusFromDeal, getEcoBonus };
