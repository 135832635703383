import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import FooterItem from "./FooterItem";
import Perks from "../Journey/Perks";
import { color } from "../../shared/utils";

import gtm from "@gtm/core";
import { faqSectionDisplayEvent } from "@gtm/events/shared";

const Container = styled.div`
  padding: 1rem 0;
  background: ${color("black1")};
`;

const FooterSection = () => {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState([]);

  const checkIsSectionOpen = sectionName => openSections.includes(sectionName);

  const onClick = sectionName => {
    if (!checkIsSectionOpen(sectionName)) {
      setOpenSections([sectionName, ...openSections]);
      gtm.fire(faqSectionDisplayEvent(t(sectionName)));
      return;
    }
    setOpenSections(
      openSections.filter(
        openedSectionName => openedSectionName !== sectionName
      )
    );
  };

  const content = t("footer.faq.sections", { returnObjects: true });

  return (
    <>
      <Container data-id={"faq-component"}>
        <FooterItem
          label={"footer.sections.faq.label"}
          description={"footer.sections.faq.content"}
          onClick={onClick}
          isOpen={checkIsSectionOpen("footer.sections.faq.label")}
          content={Object.keys(content)}
          isLast
        />
      </Container>
      <Perks />
    </>
  );
};

export default FooterSection;
