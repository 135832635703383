import React, { useRef } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "@lib/i18n";
import { bool, func, string } from "prop-types";

import Modal from "@components/shared/Modal";
import Heading from "@components/shared/Subheading";
import Typography from "@components/shared/Typography";
import Button from "@components/shared/Button";
import RequestButton from "@components/shared/RequestButton";
import ButtonGroup from "@components/shared/ButtonGroup";

import { color } from "../shared/utils";
import { useResetClientState } from "@hooks/useResetClientState";

const ModalContainer = styled.div`
  background-color: ${color("white")};
  width: 100%;
  max-width: 40rem;
  min-width: 15rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 2rem;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 2rem;
`;

const ClearStorageModal = ({
  title,
  message,
  isOpen,
  onCancel,
  hideCancelButton,
  onBeforeClear,
  onAfterClear,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const resetClientState = useResetClientState();

  const modalFirstElement = useRef(null);
  const modalLastElement = useRef(null);

  const handleClearStore = async () => {
    onBeforeClear?.();
    resetClientState();
    onAfterClear?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      firstElement={modalFirstElement}
      lastElement={modalLastElement}
      disableBackdropClick
      disableEscapePress
      ariaLabel={title ?? t("appInfoHandler.versionChanged")}
    >
      <ModalContainer>
        <StyledHeading>
          {title ?? t("appInfoHandler.versionChanged")}
        </StyledHeading>
        <StyledTypography>
          {message ?? t("appInfoHandler.explanationText")}
        </StyledTypography>
        <ButtonGroup>
          <RequestButton
            ref={modalFirstElement}
            triggerRequestCallback={handleClearStore}
          >
            {t("appInfoHandler.clear")}
          </RequestButton>
          {!hideCancelButton && (
            <Button
              ref={modalLastElement}
              customColor={theme.colors.grey}
              onClick={onCancel}
            >
              {t("appInfoHandler.cancel")}
            </Button>
          )}
        </ButtonGroup>
      </ModalContainer>
    </Modal>
  );
};

ClearStorageModal.propTypes = {
  isOpen: bool.isRequired,
  onCancel: func,
  onBeforeClear: func,
  onAfterClear: func,
  title: string,
  message: string,
  hideCancelButton: bool,
};

export default ClearStorageModal;
