import { useTheme } from "styled-components";
import { useRouter } from "next/router";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { ROUTES } from "@shared/routing/routes";

export const useHeaderBackgroundColor = isPageNotFound => {
  const theme = useTheme();
  const router = useRouter();
  const primaryColor = usePrimaryColor();
  const isTestDrive = router.pathname.includes(ROUTES.TEST_DRIVE_INDEX);

  if (isPageNotFound) {
    return theme.colors.amiOrange;
  }

  if (isTestDrive) {
    return theme.colors.testDriveDefault;
  }

  return primaryColor;
};
