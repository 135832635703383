import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { node, bool } from "prop-types";
import { motion } from "framer-motion";
import { useRouter } from "next/router";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { prop } from "../shared/utils";
import { ROUTES } from "@shared/routing/routes";

const OuterLayer = styled(motion.div)`
  flex: ${({ isFullHeight }) => isFullHeight && "1"};
  display: ${({ isFullHeight }) => isFullHeight && "flex"};
  position: relative;
`;

const InnerLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -20;
  background-color: ${prop("primaryColor")};
`;

const PageTransition = ({ children, noAnimation, isFullHeight }) => {
  const theme = useTheme();

  const primaryColor = usePrimaryColor();
  const router = useRouter();

  useEffect(() => {
    if (router.pathname !== ROUTES.BASKET) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [router.pathname]);

  const complexVariants = {
    initial: { y: 100, opacity: 0 },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.3,
        ease: "easeIn",
      },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.3,
        ease: "easeIn",
        delay: 0.4,
      },
    },
  };

  const noAnimationVariant = {
    initial: { opacity: 1 },
    animate: { opacity: 1 },
    exit: { opacity: 1, transition: { duration: 0, type: "tween" } },
  };

  return (
    <>
      <OuterLayer
        animate="animate"
        initial="initial"
        exit="exit"
        variants={noAnimation ? noAnimationVariant : complexVariants}
        isFullHeight={isFullHeight}
      >
        {children}
      </OuterLayer>
      <InnerLayer
        primaryColor={
          router.pathname.includes(ROUTES.TEST_DRIVE_INDEX)
            ? theme.colors.testDriveDefault
            : primaryColor
        }
      />
    </>
  );
};

PageTransition.propTypes = {
  children: node,
  noAnimation: bool,
  isFullHeight: bool,
};

export default PageTransition;
