const formatNumber = require("number-format.js");

const NON_BREAKING_SPACE = "\u00A0";

const getFormattedNumber = (number, format) => {
  const regex = /#*/;
  const replacer = "0";

  const modifiedFormat = format.replace(regex, match => {
    return match.replace("#", replacer);
  });

  return String(formatNumber(modifiedFormat, number)).replace(
    /\s/g,
    NON_BREAKING_SPACE
  );
};

module.exports = { getFormattedNumber };
