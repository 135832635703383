import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";

import Subheading from "../shared/Subheading";
import Typography from "../shared/Typography";
import CloseIcon from "../shared/Icons/Close";
import Modal from "../shared/Modal";
import PhoneIcon from "@components/shared/Icons/PhoneIcon";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { color } from "@components/shared/utils";

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 0 25% 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${25}%;
`;

const HeadingContactModal = styled(Subheading)`
  font-size: 1.17em;
  font-weight: bolder;
`;

const StyledPhoneIcon = styled(PhoneIcon)``;

const StyledNumberContainer = styled.div`
  display: flex;
`;

const StyledNumber = styled.a`
  padding: 0 10px;
  margin: 0;
  font-weight: bolder;
  font-size: 1.17em;
  color: ${color("black1")};
`;

const StyledTypography = styled(Typography)`
  padding: 1rem 0;
  text-align: center;
`;

const StyledCloseIconContainer = styled.div`
  width: 100%;
`;

const StyledButton = styled.button`
  border: none;
  background-color: unset;
  padding: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  float: right;
`;

const SellerContactModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  const modalFirstElement = useRef(null);
  const modalLastElement = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      firstElement={modalFirstElement}
      lastElement={modalLastElement}
      ariaLabel={t("header.sellerContactModal.heading")}
      onClose={closeModal}
    >
      <ModalContentContainer data-id="seller-contact-modal">
        <StyledCloseIconContainer>
          <StyledButton
            ref={modalFirstElement}
            aria-label={t("basket.back")}
            onClick={closeModal}
          >
            <CloseIcon
              color={primaryColor}
              data-id="seller-contact-close-icon"
              width="1rem"
              height="1rem"
            />
          </StyledButton>
        </StyledCloseIconContainer>
        <HeaderContainer>
          <HeadingContactModal headingTag="h4">
            {t("header.sellerContactModal.heading")}
          </HeadingContactModal>
        </HeaderContainer>
        <StyledTypography>
          <Trans i18nKey={"header.sellerContactModal.text"}></Trans>
        </StyledTypography>
        <StyledNumberContainer>
          <StyledPhoneIcon color={primaryColor} />
          <StyledNumber
            ref={modalLastElement}
            href={`tel:${t("header.sellerContactModal.number")}`}
          >
            {t("header.sellerContactModal.number")}
          </StyledNumber>
        </StyledNumberContainer>
      </ModalContentContainer>
    </Modal>
  );
};

SellerContactModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
};

export default SellerContactModal;
