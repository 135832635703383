import getConfig from "next/config";
import { i18n } from "@lib/i18n";
import produce from "immer";

import { DEAL_TYPE, CTA_SOL_TYPES, JOURNEY_TYPE } from "@shared/constants";
import { getIsEcoBonusEligible } from "@lib/getIsEcoBonusEligible";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getIsLLDJourneySelected } from "@shared/v2/lib/getIsLLDJourneySelected";
import { getIsLOAJourneySelected } from "@shared/v2/lib/getIsLOAJourneySelected";
import { getFormattedFinanceData } from "@shared/v2/lib/getFormattedFinanceData";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";
import { getEnabledVehicleRegistrationMethods } from "@shared/v2/lib/getEnabledVehicleRegistrationMethods";
import { getSolJourneyConfigForJourney } from "@shared/v2/lib/getSolJourneyConfigForJourney";
import { getIsLeasingJourneySelected } from "@shared/v2/lib/getIsLeasingJourneySelected";
import { getIsSCFJourneySelected } from "@shared/v2/lib/getIsSCFJourneySelected";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY, ROOT_URL_PREFIX, FRONTEND_URL } = publicRuntimeConfig;

const getSelectedFinanceJourneyTargetKey = journey => {
  switch (journey) {
    case JOURNEY_TYPE.LOA:
      return "loaPriceBreakdown";

    case JOURNEY_TYPE.VAC:
      return "vacPriceBreakdown";

    case JOURNEY_TYPE.LLD:
      return "lldPriceBreakdown";

    case JOURNEY_TYPE.SCF:
      return "scfPriceBreakdown";

    default:
      throw new Error(
        `Invalid journey - ${journey} - provided to getSelectedJourneyFinancingData`
      );
  }
};

export const buildSummarizeDealFromState = (
  store,
  dealType,
  dealState = null,
  ctaAction,
  updateSource
) => {
  const car = store.appConfig.car;
  const { modelId, idVersion, description, label, modelLabel } = car;
  const salesman = store.deal.salesman ?? null;
  const {
    preconfiguration,
    accessory,
    combinedOptions,
    options,
    service,
  } = store.deal.carConfiguration;

  const isCashJourneySelected = getIsCashJourneySelected(
    store.deal.financeSimulation.journey
  );

  const isLLDJourneySelected = getIsLLDJourneySelected(
    store.deal.financeSimulation.journey
  );

  const isLOAJourneySelected = getIsLOAJourneySelected(
    store.deal.financeSimulation.journey
  );

  const isVACJourneySelected = getIsVACJourneySelected(
    store.deal.financeSimulation.journey
  );

  const isSCFJourneySelected = getIsSCFJourneySelected(
    store.deal.financeSimulation.journey
  );

  const isLeasingJourneySelected = getIsLeasingJourneySelected(
    store.deal.financeSimulation.journey
  );

  const solJourneyConfigForJourney = getSolJourneyConfigForJourney(
    store.deal.financeSimulation.journey,
    store.deal.businessModel,
    store.appConfig.configuration
  );

  return produce(
    {
      car,
      salesman,
      dealType,
      updateSource,
      dealState,
      brandCode: store.appConfig.configuration.brandCode,
      country: COUNTRY,
      language: i18n.language,
      configuration: store.appConfig.configuration,
      assets: store.appConfig.assets,
      customer: store.deal.userProfile,
      financeSimulation: store.deal.financeSimulation,
      deliveryDealer: store.deal.deliveryDealer,
      ctaAction: ctaAction,
      testDrive: {
        slot: store.testDrive.slot,
        types: store.appConfig.testDriveTypes,
        point: store.testDrive.point,
      },
      financeServices: store.appConfig.financing?.financeServices,
      quantity: solJourneyConfigForJourney.multipleVehicles?.enabled
        ? store.deal.quantity
        : 1,
    },
    deal => {
      deal.appBaseUrl = FRONTEND_URL + ROOT_URL_PREFIX;

      deal.customer.name = store.deal.userProfile.userFirstName;
      deal.customer.surname = store.deal.userProfile.userLastName;

      delete deal.customer.userFirstName;
      delete deal.customer.userLastName;

      // This can be removed after next prod release
      if (deal.configuration.b2b.isFallback) {
        delete deal.configuration.b2b;
      }

      if (dealType === DEAL_TYPE.TEST_DRIVE) {
        deal.car = { modelId, idVersion, description, label, modelLabel };
      } else {
        deal.gitId = store.deal.gitId ?? null;

        const enabledRegistrationMethods = getEnabledVehicleRegistrationMethods(
          store.deal.financeSimulation.journey,
          store.deal.businessModel,
          store.appConfig.configuration
        );

        if (enabledRegistrationMethods.length === 1) {
          deal.financeSimulation.vehicleRegistration =
            enabledRegistrationMethods[0];
        }

        if (ctaAction !== CTA_SOL_TYPES.FINAL_ORDER_CONFIRMATION) {
          if (!isCashJourneySelected) {
            const financeTargetKey = getSelectedFinanceJourneyTargetKey(
              store.deal.financeSimulation.journey
            );

            const selectedJourneyFinancingData = store.deal.currentDeal
              .isPriceLocked
              ? store.deal.currentDeal[financeTargetKey]
              : store.deal[financeTargetKey];

            const vehicleSimulationFormattedData = getFormattedFinanceData(
              store.deal[financeTargetKey],
              store.deal.financeSimulation.financeWidgetParameters
            );
            deal.isEcoBonusEnabled = getIsEcoBonusEligible(
              store.appConfig.configuration,
              vehicleSimulationFormattedData?.duration,
              store.deal.financeSimulation.journey,
              store.deal.businessModel
            );

            if (isLOAJourneySelected) {
              deal.loaPriceBreakdown = selectedJourneyFinancingData;
            }

            if (isLLDJourneySelected) {
              deal.lldPriceBreakdown = selectedJourneyFinancingData;
            }

            if (isVACJourneySelected) {
              deal.vacPriceBreakdown = selectedJourneyFinancingData;
            }

            if (isSCFJourneySelected) {
              deal.scfPriceBreakdown = selectedJourneyFinancingData;
            }

            const { legalMention, displayBlocks } =
              vehicleSimulationFormattedData ?? {};

            if (legalMention) {
              deal.financeSimulation.legalMention = legalMention;
            }

            if (displayBlocks) {
              deal.financeSimulation.displayBlocks = displayBlocks;
            }
          } else {
            deal.isEcoBonusEnabled = getIsEcoBonusEligible(
              store.appConfig.configuration,
              null,
              store.deal.financeSimulation.journey,
              store.deal.businessModel
            );
          }
        }

        deal.businessModel = store.deal.businessModel;
        deal.isSavedByUser = store.deal.isSavedByUser;
        deal.isScrappageSelected = store.deal.isScrappageSelected;

        deal.car.preconfiguration = preconfiguration;
        deal.car.options = options.concat(accessory);
        deal.car.combinedOptions = combinedOptions;

        if (isLeasingJourneySelected) {
          deal.car.services = [];
        } else if (isCashJourneySelected) {
          deal.car.services = service;
        }

        delete deal.car.preconfigurations;
      }
    }
  );
};
