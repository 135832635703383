import { ROUTES } from "@shared/routing/routes";
import { BRANDID_PARAMS } from "@shared/constants";

export const getIsRedirectRegistrationBasket = (redirectRoute, query) => {
  const isBasketRedirect = redirectRoute === ROUTES.BASKET;
  const isSuccessStatus = query?.status === BRANDID_PARAMS.SUCCESS;

  const isValidOrigin = [
    BRANDID_PARAMS.ACTIVATE_ACCOUNT,
    BRANDID_PARAMS.CREATE_ACCOUNT,
  ].includes(query?.user_origin);

  return isBasketRedirect && isSuccessStatus && isValidOrigin;
};
