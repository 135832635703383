const getJourneyNameFromJourneyType = type => {
  switch (type) {
    case "cash":
      return "cashJourney";
    case "vac":
      return "vacJourney";
    case "lld":
      return "lldJourney";
    case "loa":
      return "loaJourney";
    case "scf":
      return "scfJourney";
    case "finance":
      return "finance";
    default:
      return null;
  }
};

module.exports = { getJourneyNameFromJourneyType };
