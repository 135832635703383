import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import appConfigReducer from "./reducers/appConfig";
import testDriveReducer from "./reducers/testDrive";
import dealReducer from "./reducers/deal";
import deprecatedReducer from "./reducers/deprecated";
import authReducer from "./reducers/auth";
import errorsReducer from "./reducers/errors";
import requestsReducer from "./reducers/requests";

const getStore = initialState => {
  const rootReducer = combineReducers({
    deprecated: deprecatedReducer,
    auth: authReducer,
    appConfig: appConfigReducer,
    testDrive: testDriveReducer,
    deal: dealReducer,
    errors: errorsReducer,
    requests: requestsReducer,
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );

  return store;
};

export default getStore;
