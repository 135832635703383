import qs from "qs";
import { produce } from "immer";
import { useRouter } from "next/router";

export const useRemoveQueryParameters = () => {
  const router = useRouter();

  return params => {
    const values = params.map(param => router.query[param]);

    const [url, query] = router.asPath.split("?");

    const nextQuery = produce(qs.parse(query), draft => {
      params.forEach(param => {
        delete draft[param];
      });
    });

    const queryConfig = {
      skipNulls: true,
      addQueryPrefix: true,
    };

    router.push(
      router.pathname + qs.stringify(nextQuery, queryConfig),
      url + qs.stringify(nextQuery, queryConfig),
      { shallow: true }
    );

    return values;
  };
};
