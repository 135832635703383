import React from "react";

import BaseIcon from "../BaseIcon";

const ProfileIcon = props => (
  <BaseIcon viewBox="0 0 18 27" fill="none" {...props}>
    <path
      d="M15.75 25V19.183C15.7361 18.3637 15.3994 17.5831 14.8131 17.0107C14.2268 16.4383 13.4384 16.1203 12.619 16.126H3.881C3.06165 16.1203 2.27317 16.4383 1.6869 17.0107C1.10063 17.5831 0.763944 18.3637 0.75 19.183V25M14.5 7C14.5 10.4518 11.7018 13.25 8.25 13.25C4.79822 13.25 2 10.4518 2 7C2 3.54822 4.79822 0.75 8.25 0.75C11.7018 0.75 14.5 3.54822 14.5 7Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </BaseIcon>
);

export default ProfileIcon;
