export const CONFIG_ACTION_TYPES = {
  SET_APP_DATA: "SET_APP_DATA",
  SET_REFERRER: "SET_REFERRER",
  SET_TEST_DRIVE_TYPES: "SET_TEST_DRIVE_TYPES",
};

export const setAppDataAction = data => ({
  type: CONFIG_ACTION_TYPES.SET_APP_DATA,
  data,
});

export const setRefererAction = referrer => ({
  type: CONFIG_ACTION_TYPES.SET_REFERRER,
  data: referrer,
});

export const setTestDriveTypesAction = testDriveTypes => ({
  type: CONFIG_ACTION_TYPES.SET_TEST_DRIVE_TYPES,
  data: testDriveTypes,
});
