const axios = require("axios");

const getRequestCanceller = () => {
  let cancelSource = null;

  return async getRequest => {
    cancelSource?.cancel();
    cancelSource = axios.CancelToken.source();

    try {
      const response = await getRequest(cancelSource);

      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { isRequestCancelled: true };
      }

      throw error;
    }
  };
};

module.exports = { getRequestCanceller };
