const { produce } = require("immer");

const getPriceAlgorithm = require("../../PriceAlgorithm/getPriceAlgorithm");
const squashPreconfigurationOptions = require("../../helper/squashPreconfigurationOptions");
const { getIsB2BSelected } = require("./getIsB2BSelected");
const { getLanguageCode } = require("./getLanguageCode");

const {
  CUSTOMIZE_TYPES,
  FG_DELIVERY_METHODS_PREFIX,
} = require("../../constants");

const {
  getSolJourneyConfigForJourney,
} = require("./getSolJourneyConfigForJourney");

const { COUNTRIES } = require("../../constants");
const { getEcoBonus } = require("./getEcoBonus");
const { getIsSCFJourneySelected } = require("./getIsSCFJourneySelected");
const ApplicationError = require("../error/ApplicationError");
const { getPreselectedDelivery } = require("./getPreselectedDelivery");

const getFiscalProperties = (country, reduxDeal, appConfiguration) => {
  const solJourneyConfig = appConfiguration.configuration.app.solJourney;
  const priceAlgorithm = getPriceAlgorithm(country);

  // Select combinedOptions if available if not, use classic options list
  const combinedOptions = reduxDeal.carConfiguration.combinedOptions;
  const options = reduxDeal.carConfiguration.options;
  const packNetPrice = (combinedOptions && combinedOptions.length
    ? combinedOptions
    : options
  )
    .filter(option => option.type !== CUSTOMIZE_TYPES.ACCESSORY)
    .reduce((acc, cur) => priceAlgorithm.add([acc, cur.netPrice]), 0);

  return produce({}, draft => {
    const {
      financeSimulation: { journey },
      businessModel,
    } = reduxDeal;

    const { scrappage: scrappageConfiguration } = getSolJourneyConfigForJourney(
      journey,
      businessModel,
      appConfiguration.configuration
    );

    const ecoBonus = getEcoBonus(journey, appConfiguration, appConfiguration);
    if (ecoBonus.enabled) {
      draft.ecoBonus = priceAlgorithm.getDiscountAmount(
        [reduxDeal.carConfiguration.netPrice, packNetPrice],
        solJourneyConfig.ecoBonus.governmentDiscountPercentage,
        solJourneyConfig.taxPercentage
      );
    }

    if (
      scrappageConfiguration &&
      scrappageConfiguration.enabled &&
      reduxDeal.isScrappageSelected
    ) {
      draft.scrappageIncentive = priceAlgorithm.getDiscountAmount(
        [reduxDeal.carConfiguration.netPrice, packNetPrice],
        scrappageConfiguration.discountPercentage,
        solJourneyConfig.taxPercentage
      );
    }

    // Add registration fees for UK
    if (country === COUNTRIES.GB) {
      draft.registrationFees = UK_REGISTRATION_FEES;
    }
  });
};

const getAccessories = (deal, appData, isCombinedOptions) => {
  const {
    carConfiguration: { preconfiguration, accessory },
  } = deal;

  const accessoriesTransformed = accessory.map(accessory => ({
    key: accessory.id,
    pricing: {
      amountInclTax: accessory.netPrice,
    },
  }));

  const optionsSquashed = squashPreconfigurationOptions(
    preconfiguration.options,
    appData.car.options,
    preconfiguration.id
  );

  if (isCombinedOptions) return accessoriesTransformed;
  return [...accessoriesTransformed, ...optionsSquashed];
};

const getSantanderContext = (
  country,
  language,
  reduxDeal,
  appData,
  options = {
    isDeliveryIncluded: true,
    withAccessories: false,
    withoutPromoCode: false,
  }
) => {
  const { isDeliveryIncluded, withAccessories, withoutPromoCode } = options;
  const solJourneyConfig = appData.configuration.app.solJourney;
  const journey = reduxDeal.financeSimulation.journey;
  const businessModel = reduxDeal.businessModel;

  const isSCFJourneySelected = getIsSCFJourneySelected(journey);
  const isB2BSelected = getIsB2BSelected(businessModel);

  if (!isSCFJourneySelected) {
    console.error(
      `Trying to get SCF context but selected journey is not SCF: ${journey}`
    );

    return false;
  }

  const journeyConfig = getSolJourneyConfigForJourney(
    reduxDeal.financeSimulation.journey,
    reduxDeal.businessModel,
    reduxDeal.currentDeal?.configuration ?? appData.configuration
  );

  const deliveryData = reduxDeal.userProfile.deliveryData;
  const isDeliverySelected = deliveryData.id;
  const isDeliveryPreselected = getPreselectedDelivery(
    appData,
    journey,
    businessModel
  );

  const { combinedOptions } = reduxDeal.carConfiguration.preconfiguration;

  // Promo Code Discount
  const { promoCode } = reduxDeal.financeSimulation;
  let promoCodeDiscount = 0;

  if (
    !withoutPromoCode &&
    promoCode?.valid &&
    new Date(promoCode?.endDate) > new Date()
  ) {
    promoCodeDiscount = promoCode.discountAmount;
  }

  // Params that are always included in all requests to finance gateway
  const baseParams = {
    context: {
      // TODO Add to reduxDeal object
      languageCode: getLanguageCode(language),
      countryCode: country,
      distributionBrand: appData.configuration.brandCode,
      siteCode: "SME", //journeyConfig.financeGateway.siteCode,
      journeyType: "ACQCN", //journeyConfig.financeGateway.journeyType,
      customer: {
        clientType: "P", //journeyConfig.financeGateway.clientType,
      },
    },
    vehicle: {
      lcdv16Code: reduxDeal.carConfiguration.preconfiguration.lcdvCode,

      pricing: {
        basicPriceInclTax: reduxDeal.carConfiguration.netPrice,
        netPriceInclTax:
          reduxDeal.carConfiguration.netPrice - promoCodeDiscount,
      },
      features: {
        kind: "VP", //journeyConfig.financeGateway.featureKind,
        energy: "05", //journeyConfig.financeGateway.featureEnergy,
      },
      fiscalProperties: getFiscalProperties(country, reduxDeal, appData),
      presentation: {
        longLabelBase64: Buffer.from(
          reduxDeal.carConfiguration.preconfiguration.label
        ).toString("base64"),
      },
      otrCostsIncluded: {
        impostoSobreVeiculos: {
          amountInclTax: 0,
        },
        ecovalor: {
          amountInclTax: 0,
        },
      },
      dealer: {
        distributorActorCode: "001000V",
      },
    },
  };

  return produce(baseParams, draft => {
    if (
      !withAccessories &&
      isDeliverySelected &&
      (isDeliveryIncluded || isDeliveryPreselected)
    ) {
      draft.vehicle.otrCosts = {
        delivery: {
          amountInclTax: reduxDeal.userProfile.deliveryData.netPrice,
        },
      };
    }

    // Adding accessories in vehicle object depending if package is selected or not
    if (combinedOptions && combinedOptions.length) {
      const options =
        reduxDeal.carConfiguration.combinedOptions ||
        getOptions(
          appData.car.options,
          reduxDeal.carConfiguration.preconfiguration.combinedOptions
        );
      draft.vehicle.options = getTransformedOptions(options);
      draft.vehicle.accessories = getAccessories(reduxDeal, appData, true);
    } else {
      draft.vehicle.accessories = getAccessories(reduxDeal, appData);
    }
  });
};

const getOptionAccessories = car => {
  return car.options.filter(({ type }) => type === CUSTOMIZE_TYPES.ACCESSORY);
};

const prefixItems = (items, prefix, attribute = "id") => {
  return items.map(
    produce(item => {
      item[attribute] = `${prefix}${item[attribute]}`;
    })
  );
};

const transformItem = item => {
  return {
    key: item.id,
    pricing: {
      amountInclTax: item.netPrice,
    },
  };
};

const getTransformedAccessories = appConfiguration => {
  const optionalAccessories = getOptionAccessories(appConfiguration.car);

  return optionalAccessories.map(transformItem);
};

const getDeliveryMethods = appConfiguration => {
  const prefixedDeliveryMethods = prefixItems(
    appConfiguration.deliveryMethods,
    FG_DELIVERY_METHODS_PREFIX
  );

  return prefixedDeliveryMethods.map(transformItem);
};

function getOptions(carOptions, options) {
  const matchedOptions = [];
  for (const option of options) {
    const match = carOptions.find(carOption => carOption.id === option);
    if (!match) {
      continue;
    }
    matchedOptions.push(match);
  }
  return matchedOptions;
}

const getTransformedOptions = options =>
  options?.map(item => ({
    code: item.id,
    pricing: {
      netPriceInclTax: item.netPrice,
      basicPriceInclTax: item.netPrice,
    },
  }));

module.exports = {
  getSantanderContext,
};
