const { JOURNEY_TYPE } = require("../constants");
const { getIsLLDJourneyEnabled } = require("./getIsLLDJourneyEnabled");
const { getIsLOAJourneyEnabled } = require("./getIsLOAJourneyEnabled");
const { getIsVACJourneyEnabled } = require("./getIsVACJourneyEnabled");
const { getIsSCFJourneyEnabled } = require("./getIsSCFJourneyEnabled");

const getAvailableJourneys = configuration => {
  const journeys = new Set();

  journeys.add(JOURNEY_TYPE.CASH);

  if (getIsLOAJourneyEnabled(configuration)) {
    journeys.add(JOURNEY_TYPE.LOA);
  }

  if (getIsLLDJourneyEnabled(configuration)) {
    journeys.add(JOURNEY_TYPE.LLD);
  }

  if (getIsVACJourneyEnabled(configuration)) {
    journeys.add(JOURNEY_TYPE.VAC);
  }

  if (getIsSCFJourneyEnabled(configuration)) {
    journeys.add(JOURNEY_TYPE.SCF);
  }

  return Array.from(journeys);
};

module.exports = { getAvailableJourneys };
