import { getIsTargetBusinessModelFinanceEnabled } from "@shared/v2/lib/getIsTargetBusinessModelFinanceEnabled";

import { useConfigurationSelector } from "@redux/reducers/appConfig";

export const useGetIsTargetBusinessModelFinanceEnabled = () => {
  const configuration = useConfigurationSelector();

  return targetBusinessModel =>
    getIsTargetBusinessModelFinanceEnabled(targetBusinessModel, configuration);
};
