import parser from "bbcode-to-react";

const formatText = text => {
  if (text) {
    return parser.toReact(text);
  }
};

const useTextFormatter = () => {
  return formatText;
};

export { useTextFormatter };
