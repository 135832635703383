const DefaultPriceAlgorithm = require("./DefaultPriceAlgorithm");

const {
  getSolJourneyConfigForJourney,
} = require("../v2/lib/getSolJourneyConfigForJourney");
const { getEcoBonus } = require("../v2/lib/getEcoBonus");

class PriceAlgorithmItaly extends DefaultPriceAlgorithm {
  constructor({ roundingPrecision }) {
    super({ roundingPrecision });
  }

  getDiscountAmountForPrice(netPrice, dealLikeObject, appConfig) {
    const { configuration } = appConfig;

    this.validateDealLikeObject(dealLikeObject);

    const { financeSimulation, businessModel } = dealLikeObject;
    const { journey } = financeSimulation;

    const ecoBonus = getEcoBonus(journey, appConfig, businessModel);

    let discountAmount = this.getDiscountAmount(
      [netPrice],
      ecoBonus.enabled ? ecoBonus.governmentDiscountPercentage : 0,
      configuration.app.solJourney.taxPercentage
    );

    if (dealLikeObject.isScrappageSelected) {
      const { scrappage } = getSolJourneyConfigForJourney(
        dealLikeObject.financeSimulation.journey,
        dealLikeObject.businessModel,
        configuration
      );

      discountAmount = this.add([
        discountAmount,
        this.getDiscountAmount(
          [netPrice],
          scrappage.discountPercentage,
          configuration.app.solJourney.taxPercentage
        ),
      ]);
    }

    return discountAmount;
  }

  getPriceAfterDiscount(netPrice, dealLikeObject, appConfig) {
    const { configuration } = appConfig;

    this.validateDealLikeObject(dealLikeObject);

    const discountAmount = this.getDiscountAmountForPrice(
      netPrice,
      dealLikeObject,
      appConfig
    );

    return this.getPrice(
      this.subtract([netPrice, discountAmount]),
      configuration.app.solJourney.taxPercentage
    );
  }

  getDefaultPrice(deal, appConfig) {
    const { configuration } = appConfig;

    const netPrice = this.subtract([
      deal.carConfiguration.preconfiguration?.vehicle.netPrice || 0,
      configuration.promoAmount,
    ]);

    return this.getPriceAfterDiscount(netPrice, deal, appConfig);
  }

  getVehiclePrice(deal, appConfig) {
    const { configuration } = appConfig;

    const defaultPrice = this.getDefaultPrice(deal, appConfig);
    const optionPrices = this.getPackPrice(deal, appConfig);

    const accessoryPrices = deal.carConfiguration.accessory.map(accessory =>
      this.getPrice(
        accessory.netPrice,
        configuration.app.solJourney.taxPercentage
      )
    );

    const servicePrices = deal.carConfiguration.service.map(service =>
      this.getPrice(
        service.netPrice,
        configuration.app.solJourney.taxPercentage
      )
    );

    return this.addPrices(
      [defaultPrice].concat(optionPrices, accessoryPrices, servicePrices)
    );
  }

  /**
   * @param  {} deal
   *
   * Use combinedOptions if available
   * use options if not
   */
  getOptions(deal) {
    const combinedOptions = deal.carConfiguration.combinedOptions;
    if (combinedOptions && combinedOptions.length) return combinedOptions;

    return deal.carConfiguration.options;
  }

  getPackPrice(deal, appConfig) {
    const options = this.getOptions(deal);

    return this.addPrices(
      options.map(option =>
        this.getPriceAfterDiscount(option.netPrice, deal, appConfig)
      )
    );
  }
}

module.exports = PriceAlgorithmItaly;
