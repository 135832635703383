const { produce } = require("immer");

const getBrandIdData = serverConfiguration => {
  const {
    clientId,
    oAuthAuthorizeUrl,
    registrationUrl,
    logoutUrl,
    changeEmailUrl,
    scope,
    responseType,
    url,
    frontendUrl,
    dealApiUrl,
  } = serverConfiguration.app.integrations.brandId;

  return {
    clientId,
    oAuthAuthorizeUrl,
    registrationUrl,
    logoutUrl,
    changeEmailUrl,
    scope,
    responseType,
    url,
    frontendUrl,
    dealApiUrl,
  };
};

const getFilteredAppData = (appData, serverConfiguration) => {
  return produce(appData, draft => {
    delete draft.configuration.mop;
    delete draft.configuration.cashJourney.backdoor;
    delete draft.configuration.admin;

    draft.car.preconfigurations = draft.car.preconfigurations.map(
      preconfiguration => {
        preconfiguration.leadTimes.forEach(leadTime => {
          delete leadTime.accessoryLeadTimeDelay;
          delete leadTime.combinedLeadTimeDelay;
          delete leadTime.optionsLeadTimeDelay;
          delete leadTime.transportToDealerAndPreparationDuration;
        });

        return preconfiguration;
      }
    );

    draft.configuration.app.integrations =
      draft.configuration.app.integrations ?? {};

    draft.configuration.app.integrations.brandId = getBrandIdData(
      serverConfiguration
    );
  });
};

module.exports = { getFilteredAppData };
