const { produce } = require("immer");
const debug = require("debug")("app:squashPreconfigurationOptions");

const ApplicationError = require("../v2/error/ApplicationError");
const { CUSTOMIZE_TYPES, REQUEST_ERROR_CODES } = require("../constants");
const BasePriceAlgorithm = require("../PriceAlgorithm/BasePriceAlgorithm");

const basePriceAlgorithm = new BasePriceAlgorithm({ roundingPrecision: 2 });

module.exports = function squashPreconfigurationOptions(
  preconfigurationOptions,
  options,
  preconfigurationId,
  ignoreMissingOptions = false
) {
  const initialAccessory = {
    key: `${preconfigurationId}-accessory`,
    pricing: {
      amountInclTax: 0,
    },
  };

  // We need to map and reduce options into array of one object with key and
  // pricing object
  const option = preconfigurationOptions.reduce((acc, key) => {
    const option = options.find(({ id }) => id === key);

    if (!option) {
      if (ignoreMissingOptions) {
        return acc;
      }

      throw new Error(
        `Option ${key} was not found in list of all available options in method "squashPreconfigurationOptions".`
      );
    }

    const { netPrice, type } = option;

    // Exclude all options with type 'accessory'
    if (type === CUSTOMIZE_TYPES.ACCESSORY) {
      return acc;
    }

    if (netPrice === undefined) {
      debug(
        `Unknown option in preconfiguration: ${preconfigurationId} for optionId: ${key}.`
      );

      throw new ApplicationError(
        REQUEST_ERROR_CODES.UNKNOWN_PRECONFIGURATION_OPTION,
        422
      );
    }

    return produce(acc, draft => {
      draft.pricing.amountInclTax = basePriceAlgorithm.round(
        basePriceAlgorithm.add([draft.pricing.amountInclTax, netPrice])
      );
    });
  }, initialAccessory);

  if (option.pricing.amountInclTax === 0) {
    return [];
  }

  return [option];
};
