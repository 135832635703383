import React from "react";
import { node, oneOf } from "prop-types";
import styled from "styled-components";

import Typography from "./Typography";
import { fontSize } from "./utils";

const StyledTypography = styled(Typography)`
  font-size: ${fontSize("xl")};
  width: 100%;
`;

const Subheading = ({ children, headingTag = "h2", ...props }) => (
  <StyledTypography forwardedAs={headingTag} {...props}>
    {children}
  </StyledTypography>
);

Subheading.propTypes = {
  children: node.isRequired,
  headingTag: oneOf(["h1", "h2", "h3", "h4", "h5"]),
};

export default Subheading;
