import { SESSION_STORAGE_PREFIX } from "@shared/constants";

export function hasWindow() {
  return typeof window !== "undefined";
}

export function getSessionStorageClient(prefix) {
  function setItem(key, value) {
    hasWindow() && sessionStorage.setItem(`${prefix}.${key}`, value);
  }

  function getItem(key) {
    return hasWindow() ? sessionStorage.getItem(`${prefix}.${key}`) : undefined;
  }

  function removeItem(key) {
    return hasWindow()
      ? sessionStorage.removeItem(`${prefix}.${key}`)
      : undefined;
  }

  return { setItem, getItem, removeItem };
}

export const sessionStorageClient = getSessionStorageClient(
  SESSION_STORAGE_PREFIX
);
