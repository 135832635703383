import getConfig from "next/config";
import { i18n } from "@lib/i18n";

import api from "@api";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

export const sendDocuments = (
  email,
  brandCode,
  cgu,
  cgv,
  cgw,
  cga,
  cg_contrat_service,
  cg_garantie_lld,
  cg_garantie_LOA,
  cg_garantie_VAC
) => {
  const emailSpecification = {
    specification: {
      country: COUNTRY,
      brand: brandCode,
      language: i18n.language,
      email,
    },
    documents: {
      cgu,
      cgv,
      cgw,
      cga,
      cg_contrat_service,
      cg_garantie_lld,
      cg_garantie_LOA,
      cg_garantie_VAC,
    },
  };

  return api.sendDocumentsByEmail(emailSpecification);
};
