import React from "react";
import styled from "styled-components";
import { node, bool, string } from "prop-types";

import { useTextFormatter } from "@hooks/useTextFormatter";

import { sizeType } from "@types";
import { fontSize, prop } from "./utils";

const StyledTypography = styled.p`
  color: ${({ customColor, theme }) => customColor ?? theme.colors.black};
  font-weight: ${({ bold, theme }) =>
    bold ? theme.fontWeights.bold : theme.fontWeights.regular};
  margin: ${({ margin }) => margin ?? 0};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  font-size: ${({ size }) => fontSize(size ?? "default")};
  text-align: ${prop("align")};
`;

const Typography = React.forwardRef(
  ({ children, withFormatter = true, ...props }, ref) => {
    const formatText = useTextFormatter();

    const content =
      typeof children === "string" && withFormatter
        ? formatText(children)
        : children;

    return (
      <StyledTypography {...props} ref={ref}>
        {content}
      </StyledTypography>
    );
  }
);

Typography.displayName = "Typography";

Typography.propTypes = {
  children: node.isRequired,
  customColor: string,
  uppercase: bool,
  bold: bool,
  size: sizeType,
  margin: string,
  align: string,
  withFormatter: bool,
};

export default Typography;
