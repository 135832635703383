import { JOURNEY_TYPE, BUSINESS_MODELS } from "@shared/constants";

export const INITIAL_PRICE = {
  basePrice: null,
  netPrice: null,
  tax: null,
};

const initialFinanceWidget = null;

const initialFinanceSimulation = {
  journey: JOURNEY_TYPE.LLD,
  defaultPrice: {
    cash: INITIAL_PRICE,
  },
  vehiclePrice: {
    cash: INITIAL_PRICE,
  },
  totalPrice: {
    cash: INITIAL_PRICE,
  },
  deliveryPrice: INITIAL_PRICE,
  financeWidgetParameters: initialFinanceWidget,
};

const initialCarConfiguration = {
  preconfiguration: {},
  accessory: [],
  service: [],
  options: [],
  combinedOptions: [],
  netPrice: null,
  basePrice: null,
  tax: null,
  promo: null,
  priceAfterPromo: null,
};

export const initialUserProfile = {
  deliveryData: { type: null },
  deliveryContact: null,
};

export const initialDealState = {
  userProfile: initialUserProfile,
  financeSimulation: initialFinanceSimulation,
  deliveryDealer: {},
  financeAccessoriesIncrease: null,
  financeDeliveryPrices: null,
  carConfiguration: initialCarConfiguration,
  salesman: null,
  isSelectionConfirmed: false,
  isPriceLocked: false,
  currentDeal: null,
  isSavedByUser: false,
  isRefreshingDeal: false,
  lastCta: null,
  gitId: null,
  businessModel: BUSINESS_MODELS.B2C,
  isScrappageSelected: false,
  loaPriceBreakdown: null,
  lldPriceBreakdown: null,
  vacPriceBreakdown: null,
  scfPriceBreakdown: null,
  quantity: 1,
};
