import { ROUTES } from "@shared/routing/routes";
import { CTA_SOL_TYPES } from "@shared/constants";

export const getIsDeliveryPriceIncluded = (
  currentPathname,
  lastCta,
  isDeliveryPreselected = false
) => {
  if (isDeliveryPreselected) {
    return true;
  }

  const isOnConfiguratorPage = currentPathname === ROUTES.CONFIG;
  const validCtaTypes = [
    CTA_SOL_TYPES.OFFER_CREATED,
    CTA_SOL_TYPES.ORDER_CREATED,
    CTA_SOL_TYPES.VEHICLE_ORDER_CONFIRMATION_BY_CUSTOMER,
    CTA_SOL_TYPES.REDIRECT_PAYMENT,
    CTA_SOL_TYPES.FINAL_ORDER_CONFIRMATION,
    CTA_SOL_TYPES.REFRESH_OFFER_DATA,
    CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
    CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED,
    CTA_SOL_TYPES.DELIVERY_POINT_SELECTED,
    CTA_SOL_TYPES.VEHICLE_CONFIRMED,
  ];

  return !isOnConfiguratorPage && validCtaTypes.includes(lastCta);
};
