import React from "react";
import { useTranslation } from "@lib/i18n";
import Layout from "@components/Layout/Layout";
import styled, { useTheme } from "styled-components";
import ContentWrapper from "@components/shared/ContentWrapper";
import { color, fontSize, fontWeight } from "@components/shared/utils";
import Heading from "@components/shared/Heading";
import Typography from "@components/shared/Typography";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { getCarImagePath } from "@shared/v2/lib/getCarImagePath";
import { useDefaultPreconfiguration } from "@hooks/useDefaultPreconfiguration";

const Wrapper = styled(ContentWrapper)`
  flex: 1;
`;

const StyledHeading = styled(Heading)`
  color: ${color("black")};
  font-size: ${fontSize("xxl")};
  font-weight: ${fontWeight("bold")};
  margin: 1.2rem 0;
  text-transform: uppercase;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id, label } = useDefaultPreconfiguration();

  return (
    <Layout isPageNotFound isFullHeight>
      <Wrapper isWholeWidth color={theme.colors.white}>
        <StyledContent>
          <StyledImage
            src={getStaticAssetURL(getCarImagePath(id))}
            alt={label}
            title={label}
          />
          <StyledHeading>{t("seo.404.title")}</StyledHeading>
          <Typography size={"lg"}>{t("seo.404.description")}</Typography>
        </StyledContent>
      </Wrapper>
    </Layout>
  );
};

export default NotFound;
