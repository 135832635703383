import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";

import Subheading from "../shared/Subheading";
import Typography from "../shared/Typography";
import CloseIcon from "../shared/Icons/Close";
import Modal from "../shared/Modal";

import { usePrimaryColor } from "@hooks/usePrimaryColor";

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  padding: 1rem 0;
`;

const StyledButton = styled.button`
  border: none;
  background-color: unset;
  padding: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  float: right;
`;

const StyledLink = styled.a`
  text-decoration: underline;
`;

const SellerInfoModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  const modalFirstElement = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      ariaLabel={t("footer.sellerInformationModal.heading")}
      firstElement={modalFirstElement}
      onClose={closeModal}
    >
      <ModalContentContainer data-id="seller-information-modal">
        <HeaderContainer>
          <Subheading headingTag="h4">
            {t("footer.sellerInformationModal.heading")}
          </Subheading>
          <StyledButton
            ref={modalFirstElement}
            aria-label={t("basket.back")}
            onClick={closeModal}
          >
            <CloseIcon
              color={primaryColor}
              data-id="seller-information-close-icon"
              width="1rem"
              height="1rem"
            />
          </StyledButton>
        </HeaderContainer>
        <StyledTypography>
          <Trans i18nKey={"footer.sellerInformationModal.text"}>
            <StyledLink
              href={t("footer.sellerInformationModal.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ link: t("footer.sellerInformationModal.link") }}
            </StyledLink>
          </Trans>
        </StyledTypography>
      </ModalContentContainer>
    </Modal>
  );
};

SellerInfoModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
};

export default SellerInfoModal;
