import axios from "axios";

export const createAxiosInstance = config => {
  const finalAxiosConfig = {
    ...config,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(config.headers || {}),
    },
  };

  return axios.create(finalAxiosConfig);
};
