import React from "react";

import BaseIcon from "../BaseIcon";

const CloseIcon = props => (
  <BaseIcon viewBox="0 0 208 212" {...props}>
    <g
      stroke="currentColor"
      strokeWidth="20"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M10 11.03341021L197.909605 199.394837M11.15491901 201.168346L196.208183 10" />
    </g>
  </BaseIcon>
);

export default CloseIcon;
