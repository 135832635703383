import combineReducers from "../core/combineReducers";

import { sharedReducerEvents } from "./shared";
import {
  homepageReducerEvents,
  homepageReducerUpdateUrl,
  homepageEcommerceEvents,
} from "./homepage";
import { basketReducerUpdateUrl, basketEcommerceEvents } from "./basket";
import { financeReducerUpdateUrl } from "./finance";
import {
  orderReducerEvents,
  orderReducerUpdateUrl,
  orderEcommerceEvents,
} from "./order";
import { testdriveReducerEvents, testdriveReducerUpdateUrl } from "./testdrive";
import { myaccountReducerEvents, myaccountReducerUpdateUrl } from "./myaccount";

export default combineReducers(
  sharedReducerEvents,
  homepageReducerEvents,
  homepageReducerUpdateUrl,
  homepageEcommerceEvents,
  basketReducerUpdateUrl,
  basketEcommerceEvents,
  orderReducerEvents,
  orderReducerUpdateUrl,
  orderEcommerceEvents,
  testdriveReducerEvents,
  testdriveReducerUpdateUrl,
  myaccountReducerEvents,
  myaccountReducerUpdateUrl,
  financeReducerUpdateUrl
);
