import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { bool, node, func } from "prop-types";

import Typography from "@components/shared/Typography";
import ArrowLeftIcon from "@components/shared/Icons/ArrowLeft";
import UnderlineLoader from "@components/shared/UnderlineLoader";
import { desktop, font, fontWeight } from "@components/shared/utils";
import theme from "styles/theme";

const StyledTypography = styled(Typography)`
  background: none;
  border: none;
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "auto")};
  cursor: pointer;
  opacity: ${({ isLoading }) => (isLoading ? "0.7" : "1")};
  padding: 0;
  text-decoration: ${({ withUnderline, isLoading }) =>
    !isLoading && withUnderline ? "underline" : "none"};
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  text-transform: lowercase;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  position: relative;
  padding: 4px 4px 4px 0;

  &:hover {
    text-decoration: ${({ isLoading }) => isLoading && "none !important"};
  }

  &:focus-visible {
    outline: 2px solid ${theme.colors.brightOrange} !important;
    outline-offset: -2px;
  }

  *:focus:not(:focus-visible) {
    outline: none !important;
  }
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  margin-right: 0.5rem;
  margin-top: -2px;

  ${desktop`
    margin-right: 1rem;
  `}
`;

const TextContainer = styled.div`
  position: relative;
`;

const SimpleButton = forwardRef(
  ({ children, withArrow, startAdornment, onClick, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const onButtonClick = async () => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      const result = onClick?.();

      if (result instanceof Promise) {
        try {
          await result;
        } catch (error) {
          throw new Error(
            `Errors of onClick function of SimpleButton must be handled in the onClick handler so that the promise always resolves successfully`
          );
        }
      }

      setIsLoading(false);
    };

    return (
      <StyledTypography
        ref={ref}
        forwardedAs="button"
        type="button"
        onClick={onButtonClick}
        isLoading={isLoading}
        {...props}
      >
        {withArrow && <StyledArrowLeftIcon size="sm" />}
        {startAdornment}
        <TextContainer>
          {children}
          {isLoading && <UnderlineLoader />}
        </TextContainer>
      </StyledTypography>
    );
  }
);

SimpleButton.displayName = "SimpleButton";

SimpleButton.propTypes = {
  children: node.isRequired,
  onClick: func,
  withArrow: bool,
  startAdornment: node,
  withUnderline: bool,
};

export default SimpleButton;
