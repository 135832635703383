import React from "react";
import { node, string } from "prop-types";
import styled from "styled-components";

import Typography from "./Typography";

const StyledTypography = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const Heading = ({ children, headingTag = "h1", ...props }) => (
  <StyledTypography forwardedAs={headingTag} {...props}>
    {children}
  </StyledTypography>
);

Heading.propTypes = {
  children: node.isRequired,
  headingTag: string,
};

export default Heading;
