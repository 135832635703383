import React from "react";
import NextApp from "next/app";
import { Provider } from "react-redux";
import withRedux from "next-redux-wrapper";
import atob from "atob";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import getConfig from "next/config";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import sentry from "@lib/sentry";
import Head from "next/head";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { AnimatePresence } from "framer-motion";
import { CookiesProvider } from "react-cookie";
import { shape, string, elementType, object, bool } from "prop-types";
import produce from "immer";
import getDebug from "debug";
import { appWithTranslation, i18n } from "@lib/i18n";

import MOPInspectorModal from "@components/MOPInspectorModal/MOPInspectorModal";
import NotFound from "@components/404";
import AppWrapper from "@components/AppWrapper/AppWrapper";

import createStore from "@redux/store";
import theme from "../styles/theme";
import api from "@api";
import RoutingUtils from "@shared/routing/utils";
import ApplicationError from "@shared/v2/error/ApplicationError";

import { getIsB2BUrl } from "@lib/getIsB2BUrl";
import {
  setIsGuestAction,
  setIsTrustedAction,
} from "@redux/actions/authActions";
import { createRequestConfig } from "@api/factory/createRequestConfig";
import { getIsRedirectRegistrationBasket } from "@lib/getIsRedirectRegistrationBasket";
import { getPreconfigurationMandatoryOptions } from "@lib/getPreconfigurationMandatoryOptions";
import { getDefaultPreconfiguration } from "@lib/getDefaultPreconfiguration";
import { ROUTES } from "@shared/routing/routes";
import { GlobalStyle } from "../styles/global";
import { setIsRedirectedFromBrandIdAction } from "@redux/actions/deprecatedActions";
import { parseConfigurationUrl } from "@shared/helper/parseConfigurationUrl";
import { extendPreconfigurationWithImage } from "@lib/extendPreconfigurationWithImage";
import { priceAlgorithm } from "@lib/priceAlgorithm";
import { getFilteredAppData } from "@shared/v2/lib/getFilteredAppData";
import { getSolJourneyConfigForJourney } from "@shared/v2/lib/getSolJourneyConfigForJourney";
import { getPreselectedDelivery } from "@shared/v2/lib/getPreselectedDelivery";
import { universalGetLocalizedPathname } from "@lib/get-initial-props/universalGetLocalizedPathname";
import { getIsB2BSelected } from "@shared/v2/lib/getIsB2BSelected";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { getIsProductionMode } from "@lib/getIsProductionMode";

import {
  setAppDataAction,
  setRefererAction,
} from "@redux/actions/configActions";

import {
  setTestDriveSlotAction,
  setTestDrivePointAction,
  setTestDriveAddressAction,
} from "@redux/actions/testDriveActions";

import {
  IS_CLIENT,
  IS_SERVER,
  REFERER_TYPES,
  CTA_SOL_TYPES,
  QUERY_ACTION_TYPES,
  BUSINESS_MODELS,
  NODE_ENVIRONMENTS,
  JOURNEY_TYPE,
} from "@shared/constants";

import {
  patchCarConfigurationAction,
  setSalesmanAction,
  setDeliveryDataAction,
  updateDeliveryPriceAction,
  setLastCtaAction,
  setDeliveryDealerAction,
  setDeliveryContactAction,
  patchUserProfileAction,
  setJourneyAction,
  setCurrentDealAction,
  setIsSelectionConfirmedAction,
  setIsDealPriceLockedAction,
  setBusinessModelAction,
  calculatePricesAction,
  setFinanceWidgetParametersAction,
  setIsScrappageSelectedAction,
  setLOAPriceBreakdownAction,
  setLLDPriceBreakdownAction,
  setVACPriceBreakdownAction,
  setIsDealSavedByUserAction,
  setVehicleRegistrationDataAction,
  setVehicleQuantityAction,
  setFinanceDeliveryPricesAction,
  setPromoCodeAction,
  setSCFPriceBreakdownAction,
} from "@redux/actions/dealActions";

import "react-toastify/dist/ReactToastify.css";
import "psawidsimulation/main.css";

import LocaleDataPlugin from "dayjs/plugin/localeData";
import LocalizedFormatPlugin from "dayjs/plugin/localizedFormat";

import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import "dayjs/locale/nl-be";
import "dayjs/locale/en-gb";

dayjs.extend(LocaleDataPlugin);
dayjs.extend(LocalizedFormatPlugin);

const { publicRuntimeConfig } = getConfig();

const isProduction = getIsProductionMode();

const debug = getDebug("app:app");

const {
  SENTRY_DSN,
  NODE_ENV,
  RELEASE_VERSION,
  RELEASE_WEB_URL,
  BUSINESS_ENV,
  SENTRY_TEST_MODE,
  CDN_BASE_URL,
} = publicRuntimeConfig;

if (IS_CLIENT) {
  if (NODE_ENV === NODE_ENVIRONMENTS.DEVELOPMENT) {
    whyDidYouRender(React);
  }

  const proxyFactory = () =>
    new Proxy(Function, {
      get() {
        return proxyFactory();
      },
      apply() {
        return proxyFactory();
      },
    });

  window.$ = proxyFactory();
  window.usabilla_live = proxyFactory();

  if (typeof TextEncoder !== "function") {
    require("../utils/polyfillEdge");
  }

  require("../utils/polyfillObjectFromEntries");

  if (!isProduction) {
    window.__unsafeThrowError = (errorMessage = "Testing sentry") => {
      throw new Error(errorMessage);
    };
  }
}

const { captureException } = sentry({
  release: RELEASE_VERSION,
  sentryDsn: SENTRY_DSN,
  isEnabled: !SENTRY_TEST_MODE && NODE_ENV === "production",
  releaseWebUrl: RELEASE_WEB_URL,
  businessEnv: BUSINESS_ENV,
});

class App extends NextApp {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
      isSSRRendered: true,
    };

    this.handleRouteChange = this.handleRouteChange.bind(this);
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      const { options: componentOptions = {} } = Component;
      ctx.componentOptions = componentOptions;

      if (IS_SERVER && ctx.req.url.includes("/_next/")) {
        // TODO Why do some /_next/ request get here ???

        return {
          pageProps: {
            namespacesRequired: ["common"],
          },
        };
      }

      if (IS_SERVER) {
        debug(`SSR is processing url: ${ctx.req.url}`);
      }

      await handleBusinessModel(ctx);

      handleFWState(ctx);
      handlePreviousRedirect(ctx);
      handleShareConfiguration(ctx);

      await handleConfiguration(ctx);

      handleReferer(ctx);

      await handleSalesman(ctx);
      await initReduxState(ctx);

      const pageProps = await Component.getInitialProps?.(ctx);

      if (!pageProps) {
        return {
          pageProps: {
            namespacesRequired: ["common"],
          },
        };
      }

      return { pageProps };
    } catch (error) {
      if (IS_SERVER) {
        const { res } = ctx;

        if (res.statusCode >= 300 && res.statusCode < 400) {
          return {
            pageProps: {
              namespacesRequired: ["common"],
            },
          };
        }

        debug("❌ Following error occurred:\n\n", error);

        captureException(error, ctx);

        res.statusCode = 500;

        return {
          errorStack: error.stack,
          pageProps: {
            namespacesRequired: ["common"],
          },
        };
      }

      return {
        pageProps: {
          namespacesRequired: ["common"],
        },
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  handleRouteChange() {
    this.setState(state => ({ ...state, isSSRRendered: false }));
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  componentDidMount() {
    const { store, router } = this.props;

    if (!isProduction) {
      window.i18n = i18n;

      if (window.Cypress) {
        window.store = store;

        window.cdnBaseUrl = CDN_BASE_URL;
      }

      window.__fetchCurrentMopId = async () => {
        const { deal } = store.getState();
        const { token } = deal.currentDeal ?? {};

        if (!token) {
          console.error(
            `❗️ Couldn't fetch current MOP ID because there is not any current deal token in Redux!`
          );

          return;
        }

        try {
          console.log(`⏳ Fetching MOP ID for deal '${token}'`);

          const { data } = await api.getDeal(token);

          console.log(`✅ MOP ID: '${data.externalId}'`);

          return data.externalId;
        } catch (exception) {
          console.error(`❌ Couldn't fetch deal '${token}':\n`, exception);
        }
      };
    }

    if (!router.pathname.includes([ROUTES.OFFER_SUMMARY])) {
      store.dispatch(calculatePricesAction());
    }

    router.events.on("routeChangeStart", this.handleRouteChange);
  }

  componentWillUnmount() {
    const { router } = this.props;

    router.events.off("routeChangeStart", this.handleRouteChange);
  }

  render() {
    const { props, state } = this;
    const { store } = props;

    const { Component, router, pageProps, pageNotFound, errorStack } = props;
    const reduxState = store.getState();

    const businessModel = reduxState.deal.businessModel;

    const {
      dynamicYield,
      plausible,
    } = reduxState.appConfig.configuration.app.integrations;

    return !isProduction && errorStack ? (
      <pre>{errorStack}</pre>
    ) : (
      <>
        <Head>
          <script type="text/javascript">
            window.dataLayer = window.dataLayer || []
          </script>

          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || []`,
            }}
          />

          <script
            type="text/javascript"
            src={getStaticAssetURL("/scripts/DYContext.js")}
          />

          <script
            type="text/javascript"
            src={getStaticAssetURL("/scripts/DYConsentManager.js")}
          />

          <script
            type="text/javascript"
            src={getStaticAssetURL("/scripts/browserUpdate.js")}
          />

          {dynamicYield.enabled && (
            <>
              <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
              <link rel="preconnect" href="//st-eu.dynamicyield.com" />
              <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
              <link rel="dns-prefetch" href="//cdn-eu.dynamicyield.com" />
              <link rel="dns-prefetch" href="//st-eu.dynamicyield.com" />
              <link rel="dns-prefetch" href="//rcom-eu.dynamicyield.com" />

              <script
                type="text/javascript"
                src={`//cdn-eu.dynamicyield.com/api/${
                  getIsB2BSelected(businessModel)
                    ? dynamicYield.b2b.id
                    : dynamicYield.b2c.id
                }/api_dynamic.js`}
              />

              <script
                type="text/javascript"
                src={`//cdn-eu.dynamicyield.com/api/${
                  getIsB2BSelected(businessModel)
                    ? dynamicYield.b2b.id
                    : dynamicYield.b2c.id
                }/api_static.js`}
              />
            </>
          )}
          {plausible?.enabled && (
            <script
              defer=""
              data-domain={plausible.dataDomain}
              src={plausible.scriptSrc}
            />
          )}
        </Head>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AppWrapper store={store}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <CookiesProvider>
                  <GlobalStyle />
                  <ToastContainer position="top-right" autoClose={8000} />
                  {!isProduction && <MOPInspectorModal />}
                  <AnimatePresence exitBeforeEnter initial={false}>
                    {pageNotFound ? (
                      <NotFound />
                    ) : (
                      <Component
                        key={router.route}
                        router={router}
                        isSSRRendered={state.isSSRRendered}
                        pageProps={pageProps}
                      />
                    )}
                  </AnimatePresence>
                </CookiesProvider>
              </MuiPickersUtilsProvider>
            </AppWrapper>
          </ThemeProvider>
        </Provider>
      </>
    );
  }
}

const handlePreviousRedirect = ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { query, pathname, req } = ctx;
  const { session } = req;

  const routingUtils = new RoutingUtils({
    routes: ctx.req.meta.initialData.routes,
  });

  if (!query.state) {
    return;
  }

  const isRedirectRegistrationBasket = getIsRedirectRegistrationBasket(
    pathname,
    query
  );

  if (isRedirectRegistrationBasket) {
    return;
  }

  const decryptedState = JSON.parse(atob(ctx.query.state));

  if (decryptedState?.location) {
    const localizedBasketUrl = routingUtils.getLocalizedPathname(
      ROUTES.BASKET,
      null,
      session.language
    );

    if (localizedBasketUrl === decryptedState?.location) {
      // We are being redirected to the basket from brand id after login
      ctx.store.dispatch(setIsRedirectedFromBrandIdAction(true));
    }
  }
};

const handleBusinessModel = async ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { req, asPath, pathname } = ctx;
  const { session, flushSession, meta } = req;

  if (pathname !== ROUTES.CONFIG) {
    return;
  }

  const isB2BRoute = getIsB2BUrl(
    asPath,
    meta.initialData.configuration.b2b.urlPrefix
  );

  session.businessModel = isB2BRoute
    ? BUSINESS_MODELS.B2B
    : BUSINESS_MODELS.B2C;

  return flushSession(session);
};

const handleFWState = ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { req } = ctx;

  const query = produce({}, draft => {
    Object.keys(ctx.query).forEach(key => {
      draft[key.replace("amp;", "")] = ctx.query[key];
    });
  });

  const { FWState } = query;
  const { session } = req;

  if (!FWState) {
    return;
  }

  const decryptedFWState = JSON.parse(atob(FWState));

  session.financeWidgetParameters = decryptedFWState;
};

const handleShareConfiguration = ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { query, store } = ctx;

  if (query?.action !== QUERY_ACTION_TYPES.SHARE_CONFIGURATION) {
    return;
  }

  store.dispatch(setLastCtaAction(CTA_SOL_TYPES.VEHICLE_SELECTED));
};

const handleConfiguration = ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { pathname, query, asPath, req } = ctx;
  const { session, meta } = req;
  const { configuration } = meta.initialData;

  const selectedCar = meta.initialData.car;

  if (pathname === ROUTES.CONFIG) {
    const {
      isValidUrl,
      validatedPreconfiguration,
      validatedAccessories,
      validatedServices,
      validJourneyQuery,
    } = parseConfigurationUrl(
      meta.initialData.configuration,
      query.config,
      meta.initialData.car,
      asPath,
      query.journey ?? session.journey
    );

    if (isValidUrl) {
      session.journey = validJourneyQuery;

      session.configuration = {
        preconfiguration: validatedPreconfiguration.id,
        accessories: validatedAccessories.map(accessory => accessory.id),
        service: validatedServices[0]?.id ?? null,
      };

      const {
        scrappage: { enabled: isScrappageEnabled },
      } = getSolJourneyConfigForJourney(
        session.journey,
        session.businessModel,
        configuration
      );

      if (isScrappageEnabled) {
        session.isScrappageSelected = query.isScrappageSelected === "true";
      }

      return ctx.req.flushSession(session);
    }
  }

  if (session.configuration.preconfiguration) {
    const selectedPreconfiguration = selectedCar.preconfigurations.find(
      preconfiguration =>
        preconfiguration.id === session.configuration.preconfiguration
    );

    if (selectedPreconfiguration) {
      const isServiceValid = !selectedCar.services.some(
        service => service === session.configuration.service
      );

      if (!isServiceValid) {
        session.configuration.service = null;
      }

      const validAccessories = session.configuration.accessories.filter(
        accessoryId =>
          selectedPreconfiguration.options.some(
            option => option === accessoryId
          )
      );

      session.configuration.accessories = validAccessories;

      return ctx.req.flushSession(session);
    }
  }

  const defaultPreconfiguration = getDefaultPreconfiguration(
    selectedCar.preconfigurations
  );

  session.configuration.preconfiguration = defaultPreconfiguration.id;
  session.configuration.accessories = [];
  session.configuration.service = null;

  return ctx.req.flushSession(session);
};

const handleSalesman = ctx => {
  if (IS_CLIENT || !ctx.query.salesman) {
    return;
  }

  const { req, query, pathname } = ctx;
  const { session, meta } = req;

  const targetSalesman = query.salesman ?? session.salesman;

  const forbiddenRoutes = [
    ROUTES.BASKET,
    ROUTES.OFFER_SUMMARY,
    ROUTES.ORDER_STATUS,
    ROUTES.FINANCE,
    ROUTES.ORDER_CONFIRMATION,
  ];

  const isForbiddenRoute = forbiddenRoutes.includes(pathname);

  const isBasketUpdateAllowed =
    pathname === ROUTES.BASKET &&
    session.lastCta === CTA_SOL_TYPES.VEHICLE_SELECTED;

  if (!isForbiddenRoute || isBasketUpdateAllowed) {
    session.salesman = targetSalesman;

    const targetAccessories = meta.initialData.car.options
      .filter(option => session.configuration.accessories.includes(option.id))
      .filter(option => !option.salesmanBlacklist.includes(targetSalesman))
      .map(option => option.id);

    session.configuration.accessories = targetAccessories;
  }
};

const handleReferer = ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { query, req } = ctx;
  const { session } = req;

  switch (query.utm_source?.toLowerCase()) {
    case REFERER_TYPES.DARTY:
      session.referer = REFERER_TYPES.DARTY;
      break;

    case REFERER_TYPES.FNAC:
      session.referer = REFERER_TYPES.FNAC;
      break;

    default:
      session.referer = "";
  }
};

const initReduxState = async ctx => {
  if (IS_CLIENT) {
    return;
  }

  const { store, req, pathname } = ctx;
  const { session, sessionId, meta, i18n } = req;

  const guestCheckoutEnabled =
    meta.serverConfiguration.app.guestCheckout?.enabled ?? false;

  const initialData = getFilteredAppData(
    meta.initialData,
    meta.serverConfiguration
  );

  if (guestCheckoutEnabled) {
    store.dispatch(setIsGuestAction(session.brandId.isGuest));
  }

  store.dispatch(setIsTrustedAction(session.brandId.isTrusted));
  store.dispatch(setAppDataAction(initialData));
  store.dispatch(setJourneyAction(session.journey));
  store.dispatch(setIsSelectionConfirmedAction(session.isSelectionConfirmed));
  store.dispatch(setBusinessModelAction(session.businessModel, pathname));
  store.dispatch(setIsScrappageSelectedAction(session.isScrappageSelected));
  store.dispatch(setSalesmanAction(session.salesman));
  store.dispatch(setRefererAction(session.referer));

  store.dispatch(
    setIsDealPriceLockedAction(
      session.isPriceLocked ?? !session.isFinanceChangeAllowedInOfferState
    )
  );

  if (Number.isFinite(session.quantity)) {
    store.dispatch(setVehicleQuantityAction(session.quantity));
  }

  const selectedCar = meta.initialData.car;

  const targetPreconfiguration = extendPreconfigurationWithImage(
    selectedCar.preconfigurations.find(
      preconfiguration =>
        preconfiguration.id === session.configuration.preconfiguration
    )
  );

  store.dispatch(
    patchCarConfigurationAction(
      {
        accessory: session.configuration.accessories.map(accessoryId => {
          return selectedCar.options.find(
            option => option.officialReference === accessoryId
          );
        }),

        service: selectedCar.services.filter(
          service =>
            service.id === session.configuration.service &&
            service.lcdvCode === targetPreconfiguration.lcdvCode
        ),

        preconfiguration: targetPreconfiguration,

        options: getPreconfigurationMandatoryOptions(
          targetPreconfiguration.options,
          selectedCar.options
        ),
        combinedOptions: getPreconfigurationMandatoryOptions(
          targetPreconfiguration.combinedOptions,
          selectedCar.options
        ),
      },
      false
    )
  );

  if (session.financeWidgetParameters) {
    store.dispatch(
      setFinanceWidgetParametersAction(session.financeWidgetParameters)
    );
  }

  if (session.lastCta) {
    store.dispatch(setLastCtaAction(session.lastCta));
  }

  const decryptedState = ctx.query.state && JSON.parse(atob(ctx.query.state));

  const targetDealToken =
    decryptedState?.currentDealToken ?? session.currentDealToken;

  const isTrusted = session.brandId.isTrusted;
  const isGuest = guestCheckoutEnabled && session.brandId.isGuest;

  if (targetDealToken && (isTrusted || isGuest)) {
    try {
      const config = createRequestConfig(sessionId);
      const { data: deal } = await api.getDeal(targetDealToken, config);

      const { extendedCustomerInfo } = deal;

      const transformedUser = produce(extendedCustomerInfo, draft => {
        draft.userFirstName = extendedCustomerInfo.name;
        draft.userLastName = extendedCustomerInfo.surname;

        delete draft.name;
        delete draft.surname;
      });

      if (deal.lldPriceBreakdown) {
        store.dispatch(setLLDPriceBreakdownAction(deal.lldPriceBreakdown));
      }
      if (deal.vacPriceBreakdown) {
        store.dispatch(setVACPriceBreakdownAction(deal.vacPriceBreakdown));
      }

      if (deal.loaPriceBreakdown) {
        store.dispatch(setLOAPriceBreakdownAction(deal.loaPriceBreakdown));
      }

      if (deal.scfPriceBreakdown) {
        store.dispatch(setSCFPriceBreakdownAction(deal.scfPriceBreakdown));
      }

      if (deal.financeSimulation?.vehicleRegistration?.type) {
        store.dispatch(
          setVehicleRegistrationDataAction(
            deal.financeSimulation.vehicleRegistration.type
          )
        );
      }

      if (deal?.financeSimulation?.promoCode) {
        store.dispatch(setPromoCodeAction(deal.financeSimulation.promoCode));
      }

      store.dispatch(patchUserProfileAction(transformedUser));
      store.dispatch(setCurrentDealAction(deal));
      store.dispatch(setIsDealSavedByUserAction(deal.isSavedByUser));
    } catch (error) {
      await ctx.req.purgeSession(sessionId);

      debug(
        `❌ Couldn't set session data for deal token '${targetDealToken}' to Redux store\n\n` +
          `Session object: ${JSON.stringify(session)}\n\n`,
        error
      );

      const configPathname = universalGetLocalizedPathname(ctx, null);

      ctx.res.redirect(configPathname);

      return new ApplicationError(error);
    }
  }

  if (session.testDrive.selectedSlot) {
    store.dispatch(setTestDriveSlotAction(session.testDrive.selectedSlot));
  }

  if (session.testDrive.point) {
    store.dispatch(setTestDrivePointAction(session.testDrive.point));
  }

  if (session.testDrive.address) {
    store.dispatch(setTestDriveAddressAction(session.testDrive.address));
  }

  let deliveryMethodSetFromSession = false;
  if (session.delivery.type) {
    const targetDeliveryMethod = req.meta.initialData.deliveryMethods.find(
      deliveryMethod => deliveryMethod.id === session.delivery.type
    );

    if (targetDeliveryMethod) {
      // restore financeDeliveryPrices
      if (JOURNEY_TYPE.CASH !== session.journey) {
        store.dispatch(
          setFinanceDeliveryPricesAction(session.financeDeliveryPrices)
        );
      }

      store.dispatch(setDeliveryDataAction(targetDeliveryMethod));
      deliveryMethodSetFromSession = true;

      store.dispatch(
        updateDeliveryPriceAction(
          priceAlgorithm.getPrice(
            targetDeliveryMethod.netPrice,
            meta.initialData.configuration.app.solJourney.taxPercentage
          ),
          pathname
        )
      );
    }
  }

  if (session.delivery.point) {
    const { data: deliveryPoints } = await api.getDeliveryPoints(i18n.language);

    const targetDeliveryPoint = deliveryPoints.find(
      deliveryPoint => deliveryPoint.id === session.delivery.point
    );

    if (targetDeliveryPoint) {
      store.dispatch(setDeliveryDealerAction(targetDeliveryPoint));
    }
  }

  if (session.delivery.contact) {
    store.dispatch(setDeliveryContactAction(session.delivery.contact));
  }

  if (!deliveryMethodSetFromSession) {
    const preselectedDelivery = getPreselectedDelivery(
      initialData,
      session.journey,
      session.businessModel
    );

    const deliveryMethods = meta.initialData.deliveryMethods;

    const preselectedDeliveryMethod = deliveryMethods
      .filter(d => !!d.enabled)
      .find(d => d.id === preselectedDelivery);

    if (preselectedDeliveryMethod) {
      store.dispatch(setDeliveryDataAction(preselectedDeliveryMethod));
      store.dispatch(
        updateDeliveryPriceAction(
          priceAlgorithm.getPrice(
            preselectedDeliveryMethod.netPrice,
            meta.initialData.configuration.app.solJourney.taxPercentage
          ),
          pathname
        )
      );
    }
  }

  store.dispatch(calculatePricesAction(pathname));
};

App.propTypes = {
  Component: elementType.isRequired,
  store: object.isRequired,
  router: shape({
    route: string.isRequired,
  }).isRequired,
  pageProps: object,
  pageNotFound: bool,
  errorStack: string,
};

export default withRedux(createStore)(appWithTranslation(App));
