import React from "react";
import { string, func } from "prop-types";
import styled from "styled-components";
import { desktop, fontSize } from "@components/shared/utils";
import CloseIcon from "@components/shared/Icons/Close";

const BannerContainer = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  padding: 1rem 4rem;
  justify-items: center;
  align-items: center;

  margin-bottom: 1rem;

  ${desktop`
    margin-bottom: 0;
  `}
`;

const CrossContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 10px;
`;

const BannerText = styled.span`
  font-size: ${fontSize("sm")};
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
`;

const Banner = ({ text, onClose }) => {
  return (
    <BannerContainer>
      <BannerText>{text}</BannerText>
      <CrossContainer>
        <StyledCloseIcon size={"xs"} onClick={onClose} />
      </CrossContainer>
    </BannerContainer>
  );
};

Banner.propTypes = {
  text: string.isRequired,
  onClose: func.isRequired,
};

export default Banner;
