import produce from "immer";
import { i18n } from "@lib/i18n";

import { SHARED_EVENT_TYPES } from "@gtm/events/shared";
import { getPageName } from "@gtm/core/utils";

import {
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  EVENT_ACTION_DISPLAY,
  EVENT_ACTION_SELECT,
  EVENT_ACTION_REDIRECTION_INTERNAL,
  EVENT_ACTION_REDIRECTION_EXTERNAL,
  EVENT_CATEGORY_CONTENT,
  EVENT_CATEGORY_STEP,
  EVENT_CATEGORY_HEADER,
  EVENT_CATEGORY_FOOTER,
  SITE_TARGET_B2B,
  SITE_TARGET_B2C,
  SHARE_DOCUMENTS,
} from "@gtm/constants";

export const sharedReducerEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case SHARED_EVENT_TYPES.AMI_LOGO_CLICK:
        draft.eventCategory = EVENT_CATEGORY_HEADER;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::homepage`;
        draft.eventLabel = data.label;
        break;

      case SHARED_EVENT_TYPES.ACCOUNT_LOGO_CLICK:
        draft.eventCategory = EVENT_CATEGORY_HEADER;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::Account`;
        draft.eventLabel = "myAccount";
        break;

      case SHARED_EVENT_TYPES.BASKET_LOGO_CLICK:
        draft.eventCategory = EVENT_CATEGORY_HEADER;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::Basket`;
        draft.eventLabel = "header-shoppingCart";
        break;

      case SHARED_EVENT_TYPES.FAQ_SECTION_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::FAQ`;
        draft.eventLabel = data;
        break;

      case SHARED_EVENT_TYPES.TERMS_SERVICE_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Terms of Service`;
        draft.eventLabel = "Terms of Service";
        break;

      case SHARED_EVENT_TYPES.LEGAL_CONDITIONS_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Terms of Sales`;
        draft.eventLabel = "Terms of Sales";
        break;

      case SHARED_EVENT_TYPES.SERVICE_CONDITIONS_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Service Conditions`;
        draft.eventLabel = "Service Conditions";
        break;

      case SHARED_EVENT_TYPES.LEASING_TERMS_OF_SALES_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Delivery Guarantee`;
        draft.eventLabel = "Delivery Guarantee";
        break;

      case SHARED_EVENT_TYPES.SELLER_INFORMATION_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Contact`;
        draft.eventLabel = "Contact";
        break;

      case SHARED_EVENT_TYPES.ACCESSIBILITY_CONDITIONS_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Accessibility`;
        draft.eventLabel = i18n.t("footer.accessibility");
        break;

      case SHARED_EVENT_TYPES.SHARE_INFORMATION_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Share Information`;
        draft.eventLabel = "Share Information";
        break;

      case SHARED_EVENT_TYPES.COOKIES_DISPLAY:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Cookies`;
        draft.eventLabel = "Cookies preference";
        break;

      case SHARED_EVENT_TYPES.CHANGE_DELIVERY:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = EVENT_ACTION_SELECT;
        draft.eventLabel = data;
        break;

      case SHARED_EVENT_TYPES.CHANGE_FUNDING:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::Price switcher`;
        draft.eventAction = `${EVENT_ACTION_SELECT}::Toggle`;
        draft.eventLabel = data;
        break;

      case SHARED_EVENT_TYPES.OPEN_FINANCE_WIDGET:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::Open finance widget`;
        draft.eventAction = "Open::Widget::PSABank";
        draft.eventLabel = `${data.label} ${getPageName(data.route)}`;
        break;

      case SHARED_EVENT_TYPES.SAVE_PROGRESS:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::${data}`;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::Login`;
        draft.eventLabel = i18n.t("footer.saveCarButton");
        break;

      case SHARED_EVENT_TYPES.REVERT_PROGRESS:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::${data}`;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::Edit::Previous`;
        draft.eventLabel = "Modify";
        break;

      case SHARED_EVENT_TYPES.DOWNLOAD_APP:
        draft.eventCategory = `${EVENT_CATEGORY_CONTENT}::LibreService`;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_EXTERNAL}::MobileAppsStoreX`;
        draft.eventLabel = data.type;
        break;

      case SHARED_EVENT_TYPES.REDIRECT_INSURANCE:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_EXTERNAL}::PsaAssurance`;
        draft.eventLabel = data.label;
        break;

      case SHARED_EVENT_TYPES.REDIRECT_UPSELL:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_EXTERNAL}::CitroenLifestyle`;
        draft.eventLabel = data.label;
        break;

      case SHARED_EVENT_TYPES.DISPLAY_MORE_INFO:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::Delivery`;
        draft.eventAction = data.action;
        draft.eventLabel = data.label;
        break;

      case SHARED_EVENT_TYPES.SWITCH_BUSINESS_MODEL:
        draft.eventCategory = EVENT_CATEGORY_HEADER;
        draft.eventAction = `Redirection::Internal::${
          data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C
        }`;
        draft.eventLabel = data.label;
        break;

      case SHARED_EVENT_TYPES.SHARE_DOCUMENTS:
        draft.eventCategory = EVENT_CATEGORY_FOOTER;
        draft.eventAction = `Popin::ShareInformation::${
          data.envoyer ? SHARE_DOCUMENTS.SEND : SHARE_DOCUMENTS.CANCEL
        }`;
        draft.eventLabel = data.label;
        break;

      default:
        return null;
    }
  });
