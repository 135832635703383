const getConfig = require("next/config").default;

const { COUNTRIES } = require("@shared/constants");

const { publicRuntimeConfig } = getConfig();
const { COUNTRY } = publicRuntimeConfig;

const getIsCountry = country => country === COUNTRY;

module.exports = {
  COUNTRIES,
  getIsCountry,
};
