import produce from "immer";

import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";
import { buildPageName, getProducts, getEcommerce } from "../core/utils";

import {
  HOMEPAGE_EVENT_TYPES,
  HOMEPAGE_UPDATE_URL_TYPES,
  HOMEPAGE_ECOMMERCE_EVENT_TYPES,
} from "@gtm/events/homepage";

import {
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT,
  EVENT_CATEGORY_CONTENT,
  EVENT_ACTION_SELECT,
  EVENT_ACTION_DISPLAY,
  EVENT_ACTION_CLOSE,
  EVENT_ADD_TO_CART,
  EVENT_ADD_TO_CART_ACTION,
  EVENT_CATEGORY_ECOMMERCE,
  PAGE_CATEGORY_CONFIGURATOR,
  MAIN_STEP_CONFIGURATION,
  SITE_TARGET_B2B,
  SITE_TARGET_B2C,
} from "@gtm/constants";

export const homepageReducerEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case HOMEPAGE_EVENT_TYPES.CHANGE_CONFIGURATION:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_SELECT}::Color`;
        draft.eventLabel = data;
        break;

      case HOMEPAGE_EVENT_TYPES.OPEN_CUSTOMIZE_SECTION:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = EVENT_ACTION_DISPLAY;
        draft.eventLabel = data;
        break;

      case HOMEPAGE_EVENT_TYPES.OPEN_CUSTOMIZE_DETAIL:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::${data.action}`;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.CHANGE_SELECTION:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${data.action}::Selection`;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.OPEN_TECHNICAL_DETAILS:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Technical Details`;
        draft.eventLabel = i18n.t("technicalDetails.techList");
        break;

      case HOMEPAGE_EVENT_TYPES.MODEL_360_CLICK:
        draft.eventAction = `${
          data.goingToOpen ? EVENT_ACTION_DISPLAY : EVENT_ACTION_CLOSE
        }::360view`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.CHANGE_MODEL_VIEW_CLICK:
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::360view::${data.action}`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.TOGGLE_SCRAPPAGE_MODAL:
        draft.eventAction = `${data.displayClose}::Popin::ScrapDiscount`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.CHANGE_SCRAPPAGE_SELECT:
        draft.eventAction = `Select::ScrapDiscount::${data.isScrappage}`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.COMPARE_EQUIPMENTS_CLICK:
        draft.eventAction = `Redirection::External::ListeEquipements`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.ECOLOGICAL_BONUS_CLICK:
        draft.eventAction = `${data.displayClose}::Popin::BonusEcologique`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.LINK_ECO_BONUS_CLICK:
        draft.eventAction = `Redirection::External::BonusEcologique`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      case HOMEPAGE_EVENT_TYPES.LINK_TEST_DRIVE_CLICK:
        draft.eventAction = `Redirection::External::DiscoveryJourney`;
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventLabel = data.label;
        break;

      default:
        return null;
    }
  });

export const homepageReducerUpdateUrl = ({ type, data }) =>
  produce(DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case HOMEPAGE_UPDATE_URL_TYPES.CONFIGURATOR:
        draft.virtualPageURL = "/buy/configuration";
        draft.pageCategory = PAGE_CATEGORY_CONFIGURATOR;
        draft.mainStepIndicator = 1;
        draft.mainStepName = MAIN_STEP_CONFIGURATION;
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.price && data.variant) {
          draft.ecommerce = getEcommerce(
            (data.currency = "EUR"),
            data.price,
            data.variant,
            data.affiliation,
            data.isB2BSelected
          );
        }

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });

export const homepageEcommerceEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case HOMEPAGE_ECOMMERCE_EVENT_TYPES.ECOMMERCE_CONFIGURATOR:
        draft.event = EVENT_CATEGORY_ECOMMERCE;
        draft.ecommerce = getEcommerce(
          (data.currency = "EUR"),
          data.price,
          data.variant,
          data.affiliation,
          data.isB2BSelected
        );
        break;

      case HOMEPAGE_ECOMMERCE_EVENT_TYPES.ADD_TO_CART:
        draft.event = EVENT_ADD_TO_CART;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_ADD_TO_CART_ACTION;
        draft.ecommerce = {
          currencyCode: "EUR",
          add: {
            products: getProducts(
              data.price,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      default:
        return null;
    }
  });
