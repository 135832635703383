import produce from "immer";
import { useSelector } from "react-redux";

import { DEPRECATED_ACTION_TYPES } from "../actions/deprecatedActions";

const deprecated = produce((state = initialState, { data, type }) => {
  switch (type) {
    case DEPRECATED_ACTION_TYPES.SET_IS_REDIRECTED_FROM_BRAND_ID:
      state.isRedirectedFromBrandId = data;
      break;

    case DEPRECATED_ACTION_TYPES.SET_LAST_CHANGED_AT:
      state.lastChangedAt = data;
      break;

    default:
      return state;
  }
});

const initialState = {
  isRedirectedFromBrandId: false,
  lastChangedAt: null,
};

export const useIsRedirectedFromBrandIdSelector = () =>
  useSelector(({ deprecated }) => deprecated.isRedirectedFromBrandId);

export const useLastChangedAtSelector = () =>
  useSelector(({ deprecated }) => deprecated.lastChangedAt);

export default deprecated;
