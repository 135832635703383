import React from "react";
import styled from "styled-components";

import { string, arrayOf, object } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";

const StyledLink = styled.a`
  text-decoration: underline;
`;

const StyledList = styled.ul`
  margin: 1rem 0;
`;

const ContentQuestion = styled.span`
  display: block;
  font-weight: bold;
`;

const ContentAnswer = styled(Typography)`
  margin: 0;
  padding-bottom: 1rem;
`;

const QuestionList = ({ questions, title, section }) => {
  const { t } = useTranslation();

  return (
    <StyledList data-id={`question-${title}-content`}>
      {questions.map(({ links }, questionIndex) => {
        const baseKey = `footer.faq.sections.${section}.questions.${questionIndex}`;
        const question = `${baseKey}.question`;
        const answer = `${baseKey}.answer`;

        if (!t(question) && !t(answer)) {
          return null;
        }

        return (
          <li key={questionIndex}>
            <ContentQuestion>
              <Trans i18nKey={question} />
            </ContentQuestion>
            <ContentAnswer>
              <Trans i18nKey={answer}>
                {Array.isArray(links) &&
                  links.filter(Boolean).map((_, linkIndex) => {
                    const link = t(`${baseKey}.links.${linkIndex}`);

                    if (!link) {
                      return;
                    }

                    return (
                      <StyledLink
                        key={linkIndex}
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ link }}
                      </StyledLink>
                    );
                  })}
              </Trans>
            </ContentAnswer>
          </li>
        );
      })}
    </StyledList>
  );
};

QuestionList.propTypes = {
  questions: arrayOf(object),
  title: string,
  section: string,
};

export default QuestionList;
