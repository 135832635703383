import React from "react";
import { bool } from "prop-types";
import { motion } from "framer-motion";

import BaseIcon from "../BaseIcon";

const CheckmarkIcon = props => (
  <BaseIcon viewBox="0 0 26 26" {...props}>
    <motion.path
      d="M6 14l4.5 4.5L21 8"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      initial={{ pathLength: props.checked ? 1 : 0 }}
      animate={{ pathLength: props.checked ? 1 : 0 }}
      transition={{ type: "tween", duration: 0.5 }}
      exit={{ pathLength: 1 }}
    />
  </BaseIcon>
);

CheckmarkIcon.propTypes = {
  checked: bool,
};

export default CheckmarkIcon;
