import React, { useState, useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Subheading from "@components/shared/Subheading";
import Typography from "@components/shared/Typography";
import CloseIcon from "@components/shared/Icons/Close";
import Button from "@components/shared/Button";
import Modal from "@components/shared/Modal";
import Checkbox from "@components/shared/Checkbox";
import TextField from "@components/shared/TextField";
import ButtonGroup from "@components/shared/ButtonGroup";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { sendDocuments } from "@lib/sendDocuments";
import { toast } from "@lib/reactToastify";
import { getIsCountry, COUNTRIES } from "@lib/getIsCountry";
import { shareDocumentsModalEvent } from "@gtm/events/shared";
import { useBrandCodeSelector } from "@redux/reducers/appConfig";

import gtm from "@gtm/core";

import {
  useIsLOAJourneyEnabledSelector,
  useIsLLDJourneyEnabledSelector,
  useIsVACJourneyEnabledSelector,
} from "@redux/reducers/appConfig";

const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  align-self: self-start;
`;

const StyledButton = styled.button`
  width: 16px;
  height: 16px;
  border: none;
  background-color: unset;
  padding: 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const DocumentListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
`;

const StyledTextField = styled(TextField)`
  margin-top: 0;
  min-width: 15rem;
`;

const EmailWrapper = styled.div`
  align-self: self-start;
  margin-top: 1rem;
`;

const ShareInfoModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const brandCode = useBrandCodeSelector();
  const isLOAJourneyEnabled = useIsLOAJourneyEnabledSelector();
  const isLLDJourneyEnabled = useIsLLDJourneyEnabledSelector();
  const isVACJourneyEnabled = useIsVACJourneyEnabledSelector();

  const [isCheckedTermsOfService, setIsCheckedTermsOfService] = useState(false);
  const [isCheckedTermsOfSales, setIsCheckedTermsOfSales] = useState(false);
  const [isCheckedWarranty, setIsCheckedWarranty] = useState(false);
  const [isCheckedAssistance, setIsCheckedAssistance] = useState(false);
  const [isCheckedService, setIsCheckedService] = useState(false);
  const [isCheckedGuarantee, setIsCheckedGuarantee] = useState(false);
  const [isCheckedLOAConditions, setIsCheckedLOAConditions] = useState(false);
  const [isCheckedVACConditions, setIsCheckedVACConditions] = useState(false);
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);

  const modalFirstElement = useRef(null);
  const modalLastElement = useRef(null);

  const cancelButtonText = t("general.cancel");
  const sendButtonText = t("general.send");
  const isDocumentSelected =
    isCheckedTermsOfService ||
    isCheckedTermsOfSales ||
    isCheckedService ||
    isCheckedGuarantee ||
    isCheckedLOAConditions ||
    isCheckedVACConditions ||
    isCheckedWarranty ||
    isCheckedAssistance;

  const shareDocuments = async e => {
    e.preventDefault();

    if (isSending) {
      return;
    }

    setIsSending(true);

    try {
      await sendDocuments(
        email,
        brandCode,
        +isCheckedTermsOfService,
        +isCheckedTermsOfSales,
        +isCheckedWarranty,
        +isCheckedAssistance,
        +isCheckedService,
        +isCheckedGuarantee,
        +isCheckedLOAConditions,
        +isCheckedVACConditions
      );

      toast.success(t("basket.shareConfigurationModal.notification"));

      gtm.fire(
        shareDocumentsModalEvent({
          label: sendButtonText,
          envoyer: true,
        })
      );

      closeModal();
    } catch {
      toast.error(t("basket.shareConfigurationModal.error"));
    }

    setIsSending(false);
  };

  const onCancelButtonClick = () => {
    gtm.fire(
      shareDocumentsModalEvent({
        label: cancelButtonText,
        envoyer: false,
      })
    );

    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      firstElement={modalFirstElement}
      lastElement={modalLastElement}
      ariaLabel={t("footer.shareInformationModal.heading")}
      onClose={closeModal}
    >
      <StyledContentForm
        data-id="share-information-modal"
        onSubmit={shareDocuments}
      >
        <HeaderContainer>
          <Subheading headingTag="h4">
            {t("footer.shareInformationModal.heading")}
          </Subheading>
          <StyledButton
            ref={modalFirstElement}
            aria-label={t("basket.back")}
            onClick={closeModal}
          >
            <StyledCloseIcon
              color={primaryColor}
              width="16px"
              height="16px"
              data-id="seller-information-close-icon"
            />
          </StyledButton>
        </HeaderContainer>
        <StyledTypography>
          {t("footer.shareInformationModal.text")}
        </StyledTypography>
        <DocumentListWrapper>
          {!getIsCountry(COUNTRIES.GB) && (
            <Checkbox
              checked={isCheckedTermsOfService}
              onChange={() =>
                setIsCheckedTermsOfService(!isCheckedTermsOfService)
              }
              label={t("footer.termsOfService")}
            />
          )}

          {t("footer.termsOfSales") && t("footer.termsOfSales").length > 0 && (
            <Checkbox
              checked={isCheckedTermsOfSales}
              onChange={() => setIsCheckedTermsOfSales(!isCheckedTermsOfSales)}
              label={t("footer.termsOfSales")}
            />
          )}
          <Checkbox
            checked={isCheckedService}
            onChange={() => setIsCheckedService(!isCheckedService)}
            label={t("footer.serviceConditions")}
          />
          {isLLDJourneyEnabled &&
            t("footer.deliveryGuaranteeConditions") &&
            t("footer.deliveryGuaranteeConditions").length > 0 && (
              <Checkbox
                checked={isCheckedGuarantee}
                onChange={() => setIsCheckedGuarantee(!isCheckedGuarantee)}
                label={t("footer.deliveryGuaranteeConditions")}
              />
            )}
          {isLOAJourneyEnabled &&
            t("footer.loaConditions") &&
            t("footer.loaConditions").length > 0 && (
              <Checkbox
                checked={isCheckedLOAConditions}
                onChange={() =>
                  setIsCheckedLOAConditions(!isCheckedLOAConditions)
                }
                label={t("footer.loaConditions")}
              />
            )}
          {isVACJourneyEnabled &&
            t("footer.vacConditions") &&
            t("footer.vacConditions").length > 0 && (
              <Checkbox
                checked={isCheckedVACConditions}
                onChange={() =>
                  setIsCheckedVACConditions(!isCheckedVACConditions)
                }
                label={t("footer.vacConditions")}
              />
            )}
          {t("footer.termsOfWarranty") &&
            t("footer.termsOfWarranty").length > 0 && (
              <Checkbox
                checked={isCheckedWarranty}
                onChange={() => setIsCheckedWarranty(!isCheckedWarranty)}
                label={t("footer.termsOfWarranty")}
              />
            )}
          {t("footer.termsOfAssistance") &&
            t("footer.termsOfAssistance").length > 0 && (
              <Checkbox
                checked={isCheckedAssistance}
                onChange={() => setIsCheckedAssistance(!isCheckedAssistance)}
                label={t("footer.termsOfAssistance")}
              />
            )}
        </DocumentListWrapper>
        <EmailWrapper>
          <StyledTextField
            required
            label={t("footer.shareInformationModal.email")}
            placeholder={t("footer.shareInformationModal.email")}
            type="email"
            data-id="personal-details-name"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </EmailWrapper>
        <ButtonGroup>
          <Button
            inverted
            color={primaryColor}
            data-id="footer-share-send-btn"
            disabled={!isDocumentSelected}
            type="submit"
          >
            {sendButtonText}
          </Button>
          <Button
            ref={modalLastElement}
            withBorder
            onClick={onCancelButtonClick}
            data-id="footer-share-docs-cancel-btn"
            type="reset"
          >
            {cancelButtonText}
          </Button>
        </ButtonGroup>
      </StyledContentForm>
    </Modal>
  );
};

ShareInfoModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
};

export default ShareInfoModal;
