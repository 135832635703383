import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@lib/i18n";

import { color, tablet, font } from "../../shared/utils";
import Typography from "../../shared/Typography";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";

const Container = styled.div`
  max-width: ${({ theme }) => theme.contentWidth};
  width: 100%;
  margin: 0 auto;
  background: ${color("white")};
  padding: 1rem 2rem;

  ${tablet`
    padding: 1rem 0.8rem;
  `}
`;

const Advantages = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${tablet`
    flex-direction: row;
  `}
`;

const Advantage = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  text-transform: lowercase;
  flex: 1;

  ${tablet`
    padding: 1rem 0;
    flex-direction: column;
  `}
`;

const AdvantageText = styled(Typography)`
  display: block;
  width: 70%;

  ${tablet`
    margin-top: 0;
    text-align: center;
    padding: 1rem 0;
    width: 90%;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 5rem;
`;

const AdvantageImage = styled.img`
  max-width: 3rem;
  max-height: 3rem;

  ${tablet`
    max-width: initial;
    max-height: initial;
`}
`;

const StyledTypography = styled(Typography)`
  font-family: ${font("citroen")};
`;

const Perks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledTypography
        uppercase
        data-id={`footer-section-buy`}
        margin="1rem 0"
        bold
      >
        <Trans i18nKey={"footer.sections.buy.title"} />
      </StyledTypography>
      <Advantages data-id="footer-journey-advantages">
        <Advantage>
          <ImageContainer>
            <AdvantageImage
              src={getStaticAssetURL("/icons/footer_achat_en_ligne.png")}
              alt={t("features.onlineProcess")}
              title={t("features.onlineProcess")}
            />
          </ImageContainer>
          <AdvantageText>
            <Trans i18nKey={"features.onlineProcess"} />
          </AdvantageText>
        </Advantage>
        <Advantage>
          <ImageContainer>
            <AdvantageImage
              src={getStaticAssetURL("/icons/footer_flexible.png")}
              alt={t("features.instantFinancing")}
              title={t("features.instantFinancing")}
            />
          </ImageContainer>
          <AdvantageText>
            <Trans i18nKey={"features.instantFinancing"} />
          </AdvantageText>
        </Advantage>
        <Advantage>
          <ImageContainer>
            <AdvantageImage
              src={getStaticAssetURL("/icons/footer_changer_avis.png")}
              alt={t("features.trialPeriod")}
              title={t("features.trialPeriod")}
            />
          </ImageContainer>
          <AdvantageText>
            <Trans i18nKey={"features.trialPeriod"} />
          </AdvantageText>
        </Advantage>
        <Advantage>
          <ImageContainer>
            <AdvantageImage
              src={getStaticAssetURL("/icons/footer_chat.png")}
              alt={t("features.customerSupport")}
              title={t("features.customerSupport")}
            />
          </ImageContainer>
          <AdvantageText>
            <Trans i18nKey={"features.customerSupport"} />
          </AdvantageText>
        </Advantage>
        <Advantage>
          <ImageContainer>
            <AdvantageImage
              src={getStaticAssetURL("/icons/footer_localisation.png")}
              alt={t("features.network")}
              title={t("features.network")}
            />
          </ImageContainer>
          <AdvantageText>
            <Trans i18nKey={"features.network"} />
          </AdvantageText>
        </Advantage>
      </Advantages>
    </Container>
  );
};

export default Perks;
