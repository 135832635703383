import React from "react";
import styled from "styled-components";
import { string, node, oneOf } from "prop-types";

import { prop } from "./utils";

const StyledSvg = styled.svg`
  fill: ${({ fill }) => fill || "currentColor"};
  min-height: ${prop("height")};
  min-width: ${prop("width")};
`;

const SIZES = {
  xs: "8px",
  sm: "12px",
  default: "16px",
  md: "24px",
  lg: "36px",
};

const BaseIcon = ({ size = "md", className, children, ...props }) => (
  <StyledSvg
    height={SIZES[size]}
    width={SIZES[size]}
    preserveAspectRatio="xMidYMid meet"
    className={className}
    {...props}
  >
    {children}
  </StyledSvg>
);

BaseIcon.propTypes = {
  children: node.isRequired,
  className: string,
  size: oneOf(Object.keys(SIZES)),
};

export default BaseIcon;
