import getConfig from "next/config";

import { IS_SERVER } from "@shared/constants";
import { i18n } from "@lib/i18n";
import RoutingUtils from "@shared/routing/utils";

const { publicRuntimeConfig } = getConfig();
const { ROOT_URL_PREFIX } = publicRuntimeConfig;

export const universalGetLocalizedPathname = (ctx, route, params = null) => {
  const { req, store } = ctx;
  const { appConfig } = store.getState();

  const routingUtils = new RoutingUtils({
    routes: appConfig.routes,
  });

  return (
    ROOT_URL_PREFIX +
    routingUtils.getLocalizedPathname(
      route,
      params,
      IS_SERVER ? req.session.language : i18n.language
    )
  );
};
