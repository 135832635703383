import React, { useState } from "react";
import styled from "styled-components";

import { color } from "@components/shared/utils";

import { useVACPriceBreakdownSelector } from "@redux/reducers/deal";
import { useIsCashJourneySelected } from "@hooks/useIsCashJourneySelected";
import Typography from "@components/shared/Typography";
import FinanceTerms from "@components/shared/FinanceTerms";

const Container = styled.div`
  padding: 2rem;
  color: ${color("black")};
  background: ${color("lightGrey")};
  text-align: left;
  height: ${({ show }) => (show ? "auto" : "0")};

  padding-top: ${({ show }) => (show ? "2rem" : "0")};
  padding-bottom: ${({ show }) => (show ? "2rem" : "0")};
  padding-left: 2rem;
  padding-right: 2rem;
  transition: all 0.2s;
`;

const Link = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const HorizontalBreak = styled.hr`
  width: 100%;
  margin: 0;
`;

const FinancialPanel = () => {
  const [isFinanceTermsOpen, setIsFinanceTermsOpen] = useState(false);

  const isCashJourneySelected = useIsCashJourneySelected();
  const vacPriceBreakdown = useVACPriceBreakdownSelector();

  const teaser = vacPriceBreakdown?.vehicleSimulation?.result.teaser;
  const lines = isCashJourneySelected
    ? []
    : teaser?.textConditions?.regularPaymentConditions || [];

  const noLines = !lines || lines.length === 0;

  return (
    <>
      <Container show={!noLines}>
        {lines.map((l, i) => (
          <Typography size="xs" key={`line-${i}`}>
            * {l.text}{" "}
            <Link onClick={() => setIsFinanceTermsOpen(true)}>
              {l.detailOpenText}
            </Link>
          </Typography>
        ))}
      </Container>
      {!noLines && <HorizontalBreak />}
      <FinanceTerms
        isOpen={isFinanceTermsOpen}
        onClose={() => setIsFinanceTermsOpen(false)}
      />
    </>
  );
};

FinancialPanel.propTypes = {};

export default FinancialPanel;
