import { produce } from "immer";
import getConfig from "next/config";
import getDebug from "debug";

import AxiosLoggerService from "@shared/service/AxiosLoggerService";
import { createDealApi } from "@api/factory/createDealApi";
import { createAxiosInstance } from "@api/factory/createAxiosInstance";

import {
  CLIENT_COUNTRY_HEADER_KEY,
  CLIENT_BRAND_HEADER_KEY,
  IS_SERVER,
} from "@shared/constants";

const { publicRuntimeConfig } = getConfig();

const { DEAL_API_URL, COUNTRY, BRAND } = publicRuntimeConfig;

const axiosTimeoutPeriod = 15000;

const axiosConfigBase = {
  timeout: axiosTimeoutPeriod,
  withCredentials: true,
  headers: {
    [CLIENT_COUNTRY_HEADER_KEY]: COUNTRY,
    [CLIENT_BRAND_HEADER_KEY]: BRAND,
  },
};

const getClientAxiosInstance = () =>
  createDealApi(
    createAxiosInstance(
      produce(axiosConfigBase, draft => {
        draft.baseURL = DEAL_API_URL;
      })
    )
  );

const getServerAxiosInstance = () => {
  const Sentry = require("@sentry/node");

  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

  const {
    INTERNAL_DEAL_API_URL,
    SSR_API_X_FORWARDED_PROTO,
  } = serverRuntimeConfig;

  const { COUNTRY } = publicRuntimeConfig;

  const axiosConfig = produce(axiosConfigBase, draft => {
    draft.baseURL = INTERNAL_DEAL_API_URL;

    draft.headers = draft.headers || {};

    draft.headers[CLIENT_COUNTRY_HEADER_KEY] = COUNTRY;
    draft.headers[CLIENT_BRAND_HEADER_KEY] = BRAND;

    if (SSR_API_X_FORWARDED_PROTO) {
      draft.headers["x-forwarded-proto"] = SSR_API_X_FORWARDED_PROTO;
    }
  });

  const axiosInstance = createAxiosInstance(axiosConfig);
  const axiosDebug = getDebug("axios:lead");
  const axiosLoggerService = AxiosLoggerService(Sentry);

  axiosLoggerService.addLogger(axiosInstance, axiosDebug);

  return createDealApi(axiosInstance);
};

export default IS_SERVER ? getServerAxiosInstance() : getClientAxiosInstance();
