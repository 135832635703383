import { BUSINESS_MODELS } from "@shared/constants";

export const getIsDeliveryMethodAvailable = (
  currentBusinessModel,
  deliveryData
) => {
  if (!deliveryData || !deliveryData.id) {
    return true;
  }

  const targetKey =
    currentBusinessModel === BUSINESS_MODELS.B2B ? "b2B" : "b2C";

  return deliveryData[targetKey] === true;
};
