export const ORDER_EVENT_TYPES = {
  UNCHECK_CONSENT: "UNCHECK_CONSENT",
  CHECK_CONSENT: "CHECK_CONSENT",
  REDIRECT_TO_PAYMENT: "REDIRECT_TO_PAYMENT",
  REDIRECT_TO_CITROEN_LIFESTYLE: "REDIRECT_TO_CITROEN_LIFESTYLE",
  PLAY_VIDEO: "PLAY_VIDEO",
  ORDER_TRACKING_CLICK: "ORDER_TRACKING_CLICK",
};

export const ORDER_UPDATE_URL_TYPES = {
  ORDER_CONFIRMATION: "ORDER_CONFIRMATION",
};

export const ORDER_ECOMMERCE_EVENT_TYPES = {
  CHECKOUT_ORDER_REVIEW: "CHECKOUT_ORDER_REVIEW",
  CHECKOUT_PAYMENT_SUCCESS: "CHECKOUT_PAYMENT_SUCCESS",
  ECOMMERCE_PURCHASE: "ECOMMERCE_PURCHASE",
};

export const uncheckConsentBoxEvent = label => ({
  type: ORDER_EVENT_TYPES.UNCHECK_CONSENT,
  data: label,
});

export const checkConsentBoxEvent = label => ({
  type: ORDER_EVENT_TYPES.CHECK_CONSENT,
  data: label,
});

export const redirectToPaymentEvent = data => ({
  type: ORDER_EVENT_TYPES.REDIRECT_TO_PAYMENT,
  data,
});

export const redirectToCitroenLifestyleEvent = () => ({
  type: ORDER_EVENT_TYPES.REDIRECT_TO_CITROEN_LIFESTYLE,
});

export const playVideoEvent = () => ({
  type: ORDER_EVENT_TYPES.PLAY_VIDEO,
});

export const orderConfirmationUpdateUrlEvent = data => ({
  type: ORDER_UPDATE_URL_TYPES.ORDER_CONFIRMATION,
  data,
});

export const orderReviewEcommerceEvent = data => ({
  type: ORDER_ECOMMERCE_EVENT_TYPES.CHECKOUT_ORDER_REVIEW,
  data,
});

export const paymentSuccessEcommerceEvent = data => ({
  type: ORDER_ECOMMERCE_EVENT_TYPES.CHECKOUT_PAYMENT_SUCCESS,
  data,
});

export const confirmationPurchaseEcommerceEvent = data => ({
  type: ORDER_ECOMMERCE_EVENT_TYPES.ECOMMERCE_PURCHASE,
  data,
});

export const orderTrackingClickEvent = () => ({
  type: ORDER_EVENT_TYPES.ORDER_TRACKING_CLICK,
});
