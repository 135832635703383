export const HOMEPAGE_EVENT_TYPES = {
  CHANGE_CONFIGURATION: "CHANGE_CONFIGURATION",
  OPEN_CUSTOMIZE_SECTION: "OPEN_CUSTOMIZE_SECTION",
  OPEN_CUSTOMIZE_DETAIL: "OPEN_CUSTOMIZE_DETAIL",
  CHANGE_SELECTION: "CHANGE_SELECTION",
  OPEN_TECHNICAL_DETAILS: "OPEN_TECHNICAL_DETAILS",
  MODEL_360_CLICK: "MODEL_360_CLICK",
  CHANGE_MODEL_VIEW_CLICK: "CHANGE_MODEL_VIEW_CLICK",
  TOGGLE_SCRAPPAGE_MODAL: "TOGGLE_SCRAPPAGE_MODAL",
  CHANGE_SCRAPPAGE_SELECT: "CHANGE_SCRAPPAGE_SELECT",
  COMPARE_EQUIPMENTS_CLICK: "COMPARE_EQUIPMENTS_CLICK",
  ECOLOGICAL_BONUS_CLICK: "ECOLOGICAL_BONUS_CLICK",
  LINK_ECO_BONUS_CLICK: "LINK_ECO_BONUS_CLICK",
  LINK_TEST_DRIVE_CLICK: "LINK_TEST_DRIVE_CLICK",
};

export const HOMEPAGE_UPDATE_URL_TYPES = {
  CONFIGURATOR: "CONFIGURATOR",
};

export const HOMEPAGE_ECOMMERCE_EVENT_TYPES = {
  ECOMMERCE_CONFIGURATOR: "ECOMMERCE_CONFIGURATOR",
  ADD_TO_CART: "ADD_TO_CART",
};

export const changeConfigurationEvent = configurationColor => ({
  type: HOMEPAGE_EVENT_TYPES.CHANGE_CONFIGURATION,
  data: configurationColor,
});

export const openCustomizeSectionEvent = customizeSectionLabel => ({
  type: HOMEPAGE_EVENT_TYPES.OPEN_CUSTOMIZE_SECTION,
  data: customizeSectionLabel,
});

export const openCustomizeDetailEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.OPEN_CUSTOMIZE_DETAIL,
  data,
});

export const changeSelectionEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.CHANGE_SELECTION,
  data,
});

export const displayTechnicalDetailsEvent = () => ({
  type: HOMEPAGE_EVENT_TYPES.OPEN_TECHNICAL_DETAILS,
});

export const homepageConfiguratorUpdateUrlEvent = data => ({
  type: HOMEPAGE_UPDATE_URL_TYPES.CONFIGURATOR,
  data,
});

export const homepageConfiguratorEcommerceEvent = data => ({
  type: HOMEPAGE_ECOMMERCE_EVENT_TYPES.ECOMMERCE_CONFIGURATOR,
  data,
});

export const homepageAddToCartEcommerceEvent = data => ({
  type: HOMEPAGE_ECOMMERCE_EVENT_TYPES.ADD_TO_CART,
  data,
});

export const openThreeSixtyEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.MODEL_360_CLICK,
  data,
});

export const changeModelViewEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.CHANGE_MODEL_VIEW_CLICK,
  data,
});

export const toggleScrappageModalEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.TOGGLE_SCRAPPAGE_MODAL,
  data,
});

export const scrappageSelectChangeEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.CHANGE_SCRAPPAGE_SELECT,
  data,
});

export const compareEquipmentsClickEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.COMPARE_EQUIPMENTS_CLICK,
  data,
});

export const ecologicalBonusClickEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.ECOLOGICAL_BONUS_CLICK,
  data,
});

export const linkEcologicalBonusClickEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.LINK_ECO_BONUS_CLICK,
  data,
});

export const linkTestDriveClickEvent = data => ({
  type: HOMEPAGE_EVENT_TYPES.LINK_TEST_DRIVE_CLICK,
  data,
});
