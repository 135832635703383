const { getIsB2BSelected } = require("./getIsB2BSelected");

const getIsTargetBusinessModelFinanceEnabled = (
  targetBusinessModel,
  configuration
) => {
  const isB2BSelected = getIsB2BSelected(targetBusinessModel);

  const {
    lldJourney,
    loaJourney,
    b2b,
    vacJourney,
    scfJourney,
  } = configuration.app.solJourney;

  if (!isB2BSelected) {
    return (
      loaJourney?.enabled ||
      lldJourney?.enabled ||
      vacJourney?.enabled ||
      scfJourney?.enabled
    );
  }

  return (
    b2b.loaJourney?.enabled ||
    b2b.lldJourney?.enabled ||
    b2b.vacJourney?.enabled ||
    b2b.scfJourney?.enabled
  );
};

module.exports = { getIsTargetBusinessModelFinanceEnabled };
