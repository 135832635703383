export const BASKET_UPDATE_URL_TYPES = {
  BASKET_SUMMARY: "BASKET_SUMMARY",
  BASKET_DELIVERY: "BASKET_DELIVERY",
  BASKET_DELIVERY_LOCATION: "BASKET_DELIVERY_LOCATION",
  BASKET_DEALER_LIST: "BASKET_DEALER_LIST",
  BASKET_DELIVERY_ADDRESS: "BASKET_DELIVERY_ADDRESS",
  BASKET_PERSONAL_DETAIL: "BASKET_PERSONAL_DETAIL",
  BASKET_REGISTRATION: "BASKET_REGISTRATION",
  BASKET_ORDER_REVIEW: "BASKET_ORDER_REVIEW",
};

export const BASKET_ECOMMERCE_EVENT_TYPES = {
  CHECKOUT_DELIVERY: "CHECKOUT_DELIVERY",
  CHECKOUT_DELIVERY_CONTINUE: "CHECKOUT_DELIVERY_CONTINUE",
  CHECKOUT_PERSONAL_DETAILS: "CHECKOUT_PERSONAL_DETAILS",
  CHECKOUT_PERSONAL_CONTINUE: "CHECKOUT_PERSONAL_CONTINUE",
  CHECKOUT_REGISTRATION_CHANGE: "CHECKOUT_REGISTRATION_CHANGE",
  CHECKOUT_REGISTRATION_CONFIRM_REJECT: "CHECKOUT_REGISTRATION_CONFIRM_REJECT",
  SHARE_CONFIG_EMAIL_CLICK: "SHARE_CONFIG_EMAIL_CLICK",
  TOGGLE_SHARE_EMAIL: "TOGGLE_SHARE_EMAIL",
};

export const basketSummaryUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_SUMMARY,
  data,
});

export const basketDeliveryUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY,
  data,
});

export const basketDeliveryLocationUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY_LOCATION,
  data,
});

export const basketDealerListUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_DEALER_LIST,
  data,
});

export const basketDeliveryAddressUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY_ADDRESS,
  data,
});

export const basketPersonalDetailsUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_PERSONAL_DETAIL,
  data,
});

export const basketVehicleRegistrationUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_REGISTRATION,
  data,
});

export const basketOrderReviewUpdateUrlEvent = data => ({
  type: BASKET_UPDATE_URL_TYPES.BASKET_ORDER_REVIEW,
  data,
});

export const basketDeliveryEcommerceEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_DELIVERY,
  data,
});

export const basketDeliveryContinueEcommerceEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_DELIVERY_CONTINUE,
  data,
});

export const basketPersonalDetailsEcommerceEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_PERSONAL_DETAILS,
  data,
});

export const basketPersonalContinueEcommerceEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_PERSONAL_CONTINUE,
  data,
});

export const basketRegistrationChangeEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_REGISTRATION_CHANGE,
  data,
});

export const basketRegistrationConfirmRejectEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_REGISTRATION_CONFIRM_REJECT,
  data,
});

export const shareConfigurationEmailEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.SHARE_CONFIG_EMAIL_CLICK,
  data,
});

export const toggleShareEmailModalEvent = data => ({
  type: BASKET_ECOMMERCE_EVENT_TYPES.TOGGLE_SHARE_EMAIL,
  data,
});
