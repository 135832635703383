const { string } = require("prop-types");
const { DEAL_TYPES_STATUSES } = require("../constants");

const dealStatusAllowsPromoCodeChanges = dealStatus => {
  return ![
    DEAL_TYPES_STATUSES.RESERVATION_COMPLETED,
    DEAL_TYPES_STATUSES.VEHICLE_ORDERED,
    DEAL_TYPES_STATUSES.REFUND_REQUESTED,
    DEAL_TYPES_STATUSES.REFUND_COMPLETED,
    DEAL_TYPES_STATUSES.PAYMENT_ACCEPTED,
    DEAL_TYPES_STATUSES.PAYMENT_PENDING,
    DEAL_TYPES_STATUSES.PAYMENT_FAILED,
    DEAL_TYPES_STATUSES.FINAL_ORDER_CONFIRMATION,
  ].includes(dealStatus);
};

dealStatusAllowsPromoCodeChanges.propTypes = {
  dealStatus: string.isRequired,
};

module.exports = { dealStatusAllowsPromoCodeChanges };
