import React, { Component } from "react";
import styled from "styled-components";
import { func, node, bool } from "prop-types";
import { useTranslation, withTranslation } from "@lib/i18n";

import ClearStorageModal from "../ClearStorageModal/ClearStorageModal";

const DefaultFallbackContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const DefaultFallbackText = styled.h1`
  margin: 0;
`;

const DefaultFallback = () => {
  const { t } = useTranslation();

  return (
    <DefaultFallbackContainer>
      <DefaultFallbackText>
        {t("errors.currentlyUnavailable")}
      </DefaultFallbackText>
    </DefaultFallbackContainer>
  );
};

// @see https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component {
  static propTypes = {
    children: node.isRequired,
    t: func.isRequired,
    fallback: node,
    withModal: bool,
    fallbackFunction: func,
  };

  static defaultProps = {
    fallback: <DefaultFallback />,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isModalOpen: false,
    };
  }

  componentDidCatch() {
    this.setState({ isModalOpen: true });
  }

  componentDidMount() {
    if (this.state.hasError && this.props.fallbackFunction) {
      this.props.fallbackFunction();
    }
  }

  render() {
    const { hasError, isModalOpen } = this.state;
    const { fallback, children, t, withModal = true } = this.props;

    if (hasError) {
      return (
        <>
          {withModal && (
            <ClearStorageModal
              hideCancelButton
              isOpen={isModalOpen}
              title={t("errorBoundary.modalTitle")}
              message={t("errorBoundary.clearStorageMessage")}
            />
          )}
          {fallback}
        </>
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
