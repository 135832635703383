import { useAssetsSettingsSelector } from "@redux/reducers/appConfig";

export const useGetAssetPath = language => {
  const assetsSettings = useAssetsSettingsSelector();

  return assetId =>
    assetsSettings.find(
      asset => asset.id === assetId && asset.language === language
    )?.path ?? null;
};
