import { css } from "styled-components";
import theme from "./theme";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";

const fonts = css`
  @font-face {
    src: url(${getStaticAssetURL("/fonts/CitroenTypeRegular.woff2")})
        format("woff2"),
      url(${getStaticAssetURL("/fonts/CitroenTypeRegular.woff")}) format("woff");
    font-family: CitroenType;
    font-weight: ${theme.fontWeights.regular};
  }

  @font-face {
    src: url(${getStaticAssetURL("/fonts/CitroenTypeBold.woff2")})
        format("woff2"),
      url(${getStaticAssetURL("/fonts/CitroenTypeBold.woff")}) format("woff");
    font-family: CitroenType;
    font-weight: ${theme.fontWeights.bold};
  }

  @font-face {
    src: url(${getStaticAssetURL("/fonts/CitroenTypeBlack.woff2")})
        format("woff2"),
      url(${getStaticAssetURL("/fonts/CitroenTypeBlack.woff")}) format("woff");
    font-family: CitroenType;
    font-weight: ${theme.fontWeights.bolder};
  }
`;

export default fonts;
