import cookie from "cookie";

import { KOA_SESSION_COOKIE_ID } from "@shared/constants";

export const createRequestConfig = (sessionId, config = {}) => {
  if (sessionId) {
    config.headers = config.headers ?? {};

    config.headers["Cookie"] = cookie.serialize(
      KOA_SESSION_COOKIE_ID,
      sessionId
    );
  }

  return config;
};
