import { useTranslation } from "@lib/i18n";
import { useRouter } from "next/router";

import { getMetaData } from "./getMetaData";

export const useMetaData = (
  preconfigurationName,
  isGetPageNameByPathname,
  isPageNotFound
) => {
  const { t } = useTranslation();
  const router = useRouter();

  return getMetaData(
    t,
    router.pathname,
    preconfigurationName,
    isGetPageNameByPathname,
    isPageNotFound
  );
};
