import { createGlobalStyle } from "styled-components";

import fonts from "./fonts";
import theme from "./theme";
import { desktop, font } from "@components/shared/utils";

export const GlobalStyle = createGlobalStyle`
  ${fonts}

  html {
    scroll-behavior: smooth;
    height: 100%;
    font-size: 14px;

    ${desktop`
      font-size: 16px;
    `}
  }

  body {
    margin: 0;
    height: 100%;
    letter-spacing: 0.5px;
    font-family: ${font("ubuntu")};
  }

  #__next {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  address {
    font-style: normal;
  }

  // ----REACT DATES----
  .DateInput_input__focused {
    border-bottom: 2px solid red;
    font-size: 1rem;
  }

  .DateInput_input {
    font-size: 1rem;
  }

  .CalendarDay__selected {
    background: red;
    border: red;
    color: white;
  }

  .CalendarDay__selected:hover {
    background: white;
    color: red;
    border: red;
  }
  // ----REACT DATES----

  // ----FINANCIAL WIDGET----
  .Fipsa-modal {
    z-index: 1000;
   }
  // ----FINANCIAL WIDGET----

  //----REACT MODAL------
  .ReactModal__Overlay{
    z-index:50;
  }
  //----REACT MODAL------

  // ----ACCESSIBILITY----
  *:focus-visible {
    outline: 3px solid ${theme.colors.brightOrange} !important;
    outline-offset: 3px;
  }

  *:focus:not(:focus-visible) {
    outline: none !important;
  }

  //---DY CAMPAIGN---
  .dy-wysiwyg{
    line-height:normal;
    direction:ltr;
    align-text:left;
    margin:0;
    padding:0;
    background-color:transparent
  }

  //---PRICE DETAILS---
  div[data-id="price-switcher-price-container"] * {
    color: white !important;
  }
`;
