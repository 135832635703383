import { generateConfigurationUrl } from "@shared/helper/generateConfigurationURL";

export const getConfigurationPathnameFromRedux = (redux, language) => {
  const { deal, appConfig } = redux;

  const appConfiguration = appConfig.configuration;

  const {
    preconfiguration: selectedPreconfiguration,
    accessory: selectedAccessories,
    service: selectedServices,
  } = deal.carConfiguration;

  return generateConfigurationUrl({
    selectedJourney: deal.financeSimulation.journey,
    selectedBusinessModel: deal.businessModel,
    selectedLanguage: language,
    selectedPreconfiguration,
    selectedAccessories,
    selectedServices,
    appConfiguration,
  });
};
