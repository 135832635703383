const getParsedLegalMention = legalMention => {
  if (!legalMention) {
    return [];
  }

  return [...legalMention]
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(block => block.text);
};

module.exports = { getParsedLegalMention };
