const { JOURNEY_TYPE } = require("../../constants");
const { getIsB2BSelected } = require("./getIsB2BSelected");

const getSolJourneyConfigForJourney = (
  journey,
  businessModel,
  configuration
) => {
  const { solJourney: solJourneyConfiguration } = configuration.app;
  const isB2BSelected = getIsB2BSelected(businessModel);

  const getSolJourneyConfigForPaymentJourney = (journey, parentObject) => {
    switch (journey) {
      case JOURNEY_TYPE.CASH:
        return parentObject.cashJourney;

      case JOURNEY_TYPE.LLD:
        return parentObject.lldJourney;

      case JOURNEY_TYPE.LOA:
        return parentObject.loaJourney;

      case JOURNEY_TYPE.VAC:
        return parentObject.vacJourney;

      case JOURNEY_TYPE.SCF:
        return parentObject.scfJourney;

      default:
        throw new Error(
          `Invalid journey - ${journey} - provided to getSolJourneyConfigForJourney`
        );
    }
  };

  if (isB2BSelected) {
    return getSolJourneyConfigForPaymentJourney(
      journey,
      solJourneyConfiguration.b2b
    );
  }

  return getSolJourneyConfigForPaymentJourney(journey, solJourneyConfiguration);
};

module.exports = { getSolJourneyConfigForJourney };
