import { toast as reactToast } from "react-toastify";

import { getIsProductionMode } from "@lib/getIsProductionMode";

const isProduction = getIsProductionMode();

export const toast = {
  ...reactToast,
  error: (message, options, errorLog) => {
    if (errorLog && !isProduction) {
      console.error(errorLog);
    }
    return reactToast.error(message, options);
  },
};
