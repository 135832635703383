import { CUSTOMIZE_TYPES } from "@shared/constants";

export const getPreconfigurationMandatoryOptions = (
  preconfigurationOptions,
  options
) => {
  if (!preconfigurationOptions) {
    return [];
  }

  return preconfigurationOptions
    .map(optionId =>
      options.find(
        ({ id, type }) => id === optionId && type !== CUSTOMIZE_TYPES.ACCESSORY
      )
    )
    .filter(Boolean);
};
