import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { getIsRedirectRegistrationBasket } from "@lib/getIsRedirectRegistrationBasket";
import { useResetClientState } from "@hooks/useResetClientState";
import { BRANDID_PARAMS } from "@shared/constants";
import { logoutUserAction } from "@redux/actions/authActions";

export default function useHandleDealRelatedTriggers() {
  const router = useRouter();
  const dispatch = useDispatch();

  const resetClientState = useResetClientState();

  useEffect(() => {
    const handlingDeal = async () => {
      if (getIsRedirectRegistrationBasket(router.route, router.query)) {
        return;
      }

      if (router.query?.user_origin === BRANDID_PARAMS.DISCONNECTION) {
        if (router.query?.status === BRANDID_PARAMS.SUCCESS) {
          try {
            await dispatch(logoutUserAction());
          } catch (error) {
            console.warn("Failed to clear the session.");
          }
        }

        if (router.query?.status === BRANDID_PARAMS.ERROR) {
          await resetClientState();

          console.warn("Brand ID logout failed, falling back state reset.");
        }
      }
    };

    handlingDeal();
  }, []);
}
