import { produce } from "immer";

export const TEST_DRIVE_ACTION_TYPES = {
  SET_TEST_DRIVE_POINT: "SET_TEST_DRIVE_POINT",
  SET_TEST_DRIVE_ADDRESS: "SET_TEST_DRIVE_ADDRESS",
  SET_TEST_DRIVE_SLOT: "SET_TEST_DRIVE_SLOT",
};

export const setTestDrivePointAction = point => ({
  type: TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_POINT,
  data: point,
});

export const setTestDriveSlotAction = slot => ({
  type: TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_SLOT,
  data: slot,
});

export const setTestDrivePointAddressAction = targetAddress => async (
  dispatch,
  getState
) => {
  const { testDrive } = getState();

  const updatedPoint = produce(testDrive.point, draft => {
    draft.address1 = targetAddress.address1;
    draft.city = targetAddress.city;
    draft.zipcode = targetAddress.zipcode;
  });

  dispatch(setTestDrivePointAction(updatedPoint));
};

export const setTestDriveAddressAction = address => ({
  type: TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_ADDRESS,
  data: address,
});
