import { IS_CLIENT } from "@shared/constants";

let widgetConfig = {
  apiKey: null,
};

const initGoogleMapsWidget = config => {
  widgetConfig = config;
};

const promiseMap = {};

const fetchScript = src => {
  if (promiseMap[src]) {
    return promiseMap[src];
  }

  promiseMap[src] = new Promise((resolve, reject) => {
    const script = document.createElement("script");

    script.onload = resolve;
    script.onerror = reject;
    script.src = src;

    window.document.head.appendChild(script);
  });

  return promiseMap[src];
};

const getLazyLoader = (getInstance, getPromise) => {
  let promise = null;

  if (!widgetConfig.apiKey) {
    throw new Error(
      "GoogleMaps widget must be initialized before using lazyLoad[Something] method"
    );
  }

  return async () => {
    const instance = getInstance();

    if (instance) {
      return instance;
    }

    if (promise) {
      return promise;
    }

    promise = getPromise();

    return promise;
  };
};

const lazyLoadMarkerClusterer = () => {
  if (!widgetConfig.apiKey) {
    throw new Error(
      `initGoogleMapsWidget must be called before calling lazyLoadMarkerClusterer`
    );
  }

  return getLazyLoader(
    () => IS_CLIENT && window.MarkerClusterer,
    () =>
      fetchScript(
        `https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js`
      ).then(() => window.MarkerClusterer)
  )();
};

const lazyLoadGoogleMaps = language => {
  if (!widgetConfig.apiKey) {
    throw new Error(
      `initGoogleMapsWidget must be called before calling lazyLoadGoogleMaps`
    );
  }

  return getLazyLoader(
    () => IS_CLIENT && window.google?.maps,
    () =>
      fetchScript(
        `https://maps.googleapis.com/maps/api/js?key=${
          widgetConfig.apiKey
        }&v=3.exp&libraries=places,geometry${
          language ? `&language=${language}` : ""
        }`
      ).then(() => {
        return window.google.maps;
      })
  )();
};

module.exports = {
  initGoogleMapsWidget,
  lazyLoadGoogleMaps,
  lazyLoadMarkerClusterer,
};
