import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const { CDN_BASE_URL, COMMIT_HASH } = publicRuntimeConfig;

export const getStaticAssetURL = (url, options) => {
  const { useCdn = true, withVersion = true } = options ?? {};

  if (!url.startsWith("/")) {
    throw new Error(
      `Bad URL format provided. Expected relativePath (i.e. "/asset"), but got "${url}"`
    );
  }

  let targetUrl = useCdn
    ? `${CDN_BASE_URL}${url}`
    : `${publicRuntimeConfig.ROOT_URL_PREFIX}${url}`;

  if (withVersion) {
    targetUrl += `?version=${COMMIT_HASH}`;
  }

  return targetUrl;
};
