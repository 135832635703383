const { BUSINESS_MODELS } = require("../../constants");

const getMovesProgram = (journeyType, appConfig, businessModel) => {
  const { configuration } = appConfig;

  const movesProgram =
    businessModel === BUSINESS_MODELS.B2B
      ? configuration.app.solJourney.b2b.movesProgram
      : configuration.app.solJourney.movesProgram;

  return movesProgram;
};

module.exports = { getMovesProgram };
