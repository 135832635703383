import produce from "immer";

import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";

import { buildPageName } from "../core/utils";

import {
  MYACCOUNT_EVENT_TYPES,
  MYACCOUNT_UPDATE_URL_TYPES,
} from "@gtm/events/myaccount";

import {
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  EVENT_ACTION_DISPLAY,
  EVENT_CATEGORY_CONTENT,
  EVENT_CATEGORY_STEP,
  EVENT_ACTION_MORE_INFO,
  EVENT_ACTION_LESS_INFO,
  EVENT_ACTION_REDIRECTION_INTERNAL,
  BASIC_UPDATE_URL_DATA_LAYER_OBJECT,
  SITE_TARGET_B2B,
  SITE_TARGET_B2C,
} from "@gtm/constants";

export const myaccountReducerEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case MYACCOUNT_EVENT_TYPES.SHOW_MORE_INFO:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::${data}`;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::${EVENT_ACTION_MORE_INFO}`;
        draft.eventLabel = i18n.t("myOrder.showSummary");
        break;

      case MYACCOUNT_EVENT_TYPES.SHOW_LESS_INFO:
        draft.eventCategory = `${EVENT_CATEGORY_STEP}::${data}`;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::${EVENT_ACTION_LESS_INFO}`;
        draft.eventLabel = i18n.t("myOrder.hideSummary");
        break;

      case MYACCOUNT_EVENT_TYPES.DEAL_CONTINUE:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = EVENT_ACTION_REDIRECTION_INTERNAL;
        draft.eventLabel = i18n.t("myDeal.continueWithThisCar");
        break;

      default:
        return null;
    }
  });

export const myaccountReducerUpdateUrl = ({ type, data }) =>
  produce(BASIC_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case MYACCOUNT_UPDATE_URL_TYPES.MY_ACCOUNT_LOGIN:
        draft.virtualPageURL = "/my-account/login";
        draft.affiliation = data.affiliation;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.EMPTY_BASKET:
        draft.virtualPageURL = "/my-account/basket-empty";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_DASHBOARD:
        draft.virtualPageURL = "/my-account/dashboard";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_MYORDER:
        draft.virtualPageURL = "/my-account/my-order";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_SAVED_DEALS:
        draft.virtualPageURL = "/my-account/saved-deals";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_PERSONAL_DETAIL:
        draft.virtualPageURL = "/my-account/personal-details";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_O2C:
        draft.virtualPageURL = "/my-account/myAmi";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_F2M:
        draft.virtualPageURL = "/my-account/f2m";
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.mainStepIndicator = "";
        draft.mainStepName = "";
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: false,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });
