const LOCALE_IDENTIFIER = Symbol("lang");

const ROUTES = {
  INDEX: "/",
  CONFIG: "/[...config]",
  BASKET: "/basket",
  OFFER_SUMMARY: "/order/summary",
  ORDER_STATUS: "/order/payment",
  ORDER_CONFIRMATION: "/order/confirmation",
  ORDER_BANKTRANSFER_SUCCESSFUL: "/order/banktransfer-successful",
  ORDER_BANKTRANSFER_DECLINED: "/order/banktransfer-declined",
  FINANCE: "/finance",
  FINANCE_REQUESTED: "/finance-requested",

  MY_ACCOUNT_LOGIN: "/login",
  MY_ACCOUNT_INDEX: "/myaccount",
  MY_ACCOUNT_DEALS: "/myaccount/mydeals",
  MY_ACCOUNT_ORDERS: "/myaccount/myorders",
  MY_ACCOUNT_ORDER_TRACKING: "/myaccount/ordertracking",
  MY_ACCOUNT_DETAILS: "/myaccount/mydetails",
  MY_ACCOUNT_FREE2MOVE: "/myaccount/myfree2move",
  MY_ACCOUNT_O2C: "/myaccount/myo2c",

  TEST_DRIVE_INDEX: "/test-drive",
  TEST_DRIVE_HOME: "/test-drive/home-delivery",

  TEST_DRIVE_DETAIL_ENSTORE: "/test-drive/popupstore",
  TEST_DRIVE_DETAIL_LA_MAISON: "/test-drive/chezcitroen",
  TEST_DRIVE_DETAIL_FNAC_DARTY: "/test-drive/fnac-darty",
  TEST_DRIVE_DETAIL_MEDIAMARKT: "/test-drive/mediamarkt",
  TEST_DRIVE_DETAIL_LOCATION: "/test-drive/free2move",

  ADMIN_IMPORTS: "/admin/imports",

  UNAVAILABLE: "/unavailable",
};

const ROUTES_TO_CONFIGURATION_KEYS_MAP = {
  [ROUTES.BASKET]: "BASKET",
  [ROUTES.OFFER_SUMMARY]: "OFFER_SUMMARY",
  [ROUTES.ORDER_STATUS]: "ORDER_STATUS",
  [ROUTES.ORDER_CONFIRMATION]: "ORDER_CONFIRMATION",
  [ROUTES.ORDER_BANKTRANSFER_SUCCESSFUL]: "ORDER_BANKTRANSFER_SUCCESSFUL",
  [ROUTES.ORDER_BANKTRANSFER_DECLINED]: "ORDER_BANKTRANSFER_DECLINED",
  [ROUTES.FINANCE_REQUESTED]: "FINANCE_REQUESTED",

  [ROUTES.MY_ACCOUNT_LOGIN]: "LOGIN",
  [ROUTES.MY_ACCOUNT_INDEX]: "MY_ACCOUNT_INDEX",
  [ROUTES.MY_ACCOUNT_DEALS]: "MY_ACCOUNT_DEALS",
  [ROUTES.MY_ACCOUNT_ORDERS]: "MY_ACCOUNT_ORDERS",
  [ROUTES.MY_ACCOUNT_ORDER_TRACKING]: "MY_ACCOUNT_ORDER_TRACKING",
  [ROUTES.MY_ACCOUNT_DETAILS]: "MY_ACCOUNT_DETAILS",
  [ROUTES.MY_ACCOUNT_FREE2MOVE]: "MY_ACCOUNT_FREE2MOVE",
  [ROUTES.MY_ACCOUNT_O2C]: "MY_ACCOUNT_O2C",

  [ROUTES.TEST_DRIVE_INDEX]: "TEST_DRIVE_INDEX",
  [ROUTES.TEST_DRIVE_HOME]: "TEST_DRIVE_HOME",
  [ROUTES.TEST_DRIVE_DETAIL_LOCATION]: "TEST_DRIVE_DETAIL_LOCATION",
  [ROUTES.TEST_DRIVE_DETAIL_ENSTORE]: "TEST_DRIVE_DETAIL_ENSTORE",
  [ROUTES.TEST_DRIVE_DETAIL_LA_MAISON]: "TEST_DRIVE_DETAIL_LA_MAISON",
  [ROUTES.TEST_DRIVE_DETAIL_FNAC_DARTY]: "TEST_DRIVE_DETAIL_FNAC_DARTY",
  [ROUTES.TEST_DRIVE_DETAIL_MEDIAMARKT]: "TEST_DRIVE_DETAIL_MEDIAMARKT",

  [ROUTES.FINANCE]: "FINANCE",
  [ROUTES.ADMIN_IMPORTS]: "ADMIN_IMPORTS",
  [ROUTES.UNAVAILABLE]: "UNAVAILABLE",
};

module.exports = {
  LOCALE_IDENTIFIER,
  ROUTES,
  ROUTES_TO_CONFIGURATION_KEYS_MAP,
};
