const AxiosLoggerService = require("axios-debug-log");

class AxiosError extends Error {}

module.exports = sentry => {
  AxiosLoggerService({
    request(debug, request) {
      const { method, baseURL, url } = request;

      debug(`ℹ  Request: ${method.toUpperCase()} ${baseURL}${url}`);
    },

    response(debug, response) {
      const { request, config } = response;

      const responseStatus = response ? response.status : "UNKNOWN STATUS";
      const responseData = response
        ? JSON.stringify(response.data)
        : "< -- NO DATA -- >";

      debug(
        `✅ ${request.method} ${config.baseURL}${config.url} ${responseStatus}\n\n` +
          `→ Request data: ${JSON.stringify(config.data)}\n` +
          `← Response data: ${responseData}`
      );
    },

    error(debug, error) {
      const { request, config, response } = error;

      if (!config) {
        debug("❌ An error occurred!\n\n", error);

        return;
      }

      const { axiosLoggerConfig = {} } = config;

      const URL = config && config.baseURL + config.url;

      debug(
        `❌ ${request.method ? request.method : "-"} ${URL} ${
          response ? response.status : "UNKNOWN STATUS"
        }\n` +
          `→ Request data: ${JSON.stringify(config.data)}\n` +
          `← Response data: ${
            response ? JSON.stringify(response.data) : "< -- NO DATA -->"
          }`
      );

      const debugNamespace = debug.namespace;

      let shouldCaptureException = true;
      if (
        axiosLoggerConfig &&
        axiosLoggerConfig.shouldLogToSentry instanceof Function
      ) {
        shouldCaptureException = axiosLoggerConfig.shouldLogToSentry(error);
      }

      if (sentry && shouldCaptureException) {
        sentry.configureScope(function(scope) {
          scope.setExtra("method", request && request.method);
          scope.setExtra("url", URL);
          scope.setExtra("response_status", response && response.status);
          scope.setExtra("request_data", JSON.stringify(config && config.data));
          scope.setExtra(
            "response_data",
            response && JSON.stringify(response.data)
          );
          sentry.captureException(
            new AxiosError(`AxiosError of ${debugNamespace}`)
          );
        });
      }
    },
  });

  return AxiosLoggerService;
};
