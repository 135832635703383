export const DEPRECATED_ACTION_TYPES = {
  SET_IS_REDIRECTED_FROM_BRAND_ID: "SET_IS_REDIRECTED_FROM_BRAND_ID",
  SET_LAST_CHANGED_AT: "SET_LAST_CHANGED_AT",
  SET_PREVIOUS_REDIRECT: "SET_PREVIOUS_REDIRECT",
  POST_DEAL_SUCCESS: "POST_DEAL_SUCCESS",
  POST_DEAL_PENDING: "POST_DEAL_PENDING",
  POST_DEAL_FAILURE: "POST_DEAL_FAILURE",
};

export const setIsRedirectedFromBrandIdAction = isRedirectedFromBrandId => ({
  type: DEPRECATED_ACTION_TYPES.SET_IS_REDIRECTED_FROM_BRAND_ID,
  data: isRedirectedFromBrandId,
});

export const setLastChangedAtAction = timestamp => ({
  type: DEPRECATED_ACTION_TYPES.SET_LAST_CHANGED_AT,
  data: timestamp,
});
