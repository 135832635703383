export const TESTDRIVE_EVENT_TYPES = {
  TESTDRIVE_LINK: "TESTDRIVE_LINK",
  GEOLOCALISATION: "GEOLOCALISATION",
  DISPLAY_FILTER: "DISPLAY_FILTER",
  FILTER_MAP: "FILTER_MAP",
  MORE_RESULTS: "MORE_RESULTS",
  MORE_INFO: "MORE_INFO",
  FINAL_STEP_REDIRECT: "FINAL_STEP_REDIRECT",
};

export const TESTDRIVE_UPDATE_URL_TYPES = {
  TESTDRIVE_MAP: "TESTDRIVE_MAP",
  TESTDRIVE_SEARCH_RESULTS: "TESTDRIVE_SEARCH_RESULTS",
  TESTDRIVE_FNAC_DARTY: "TESTDRIVE_FNAC_DARTY",
  TESTDRIVE_MEDIAMARKT: "TESTDROVE_MEDIAMARKT",
  TESTDRIVE_DATE: "TESTDRIVE_DATE",
  TESTDRIVE_DETAILS: "TESTDRIVE_DETAILS",
  TESTDRIVE_DETAILS_VERIFICATION: "TESTDRIVE_DETAILS_VERIFICATION",
  TESTDRIVE_CONFIRMATION: "TESTDRIVE_CONFIRMATION",
  TESTDRIVE_SELF_SERVICE: "TESTDRIVE_SELF_SERVICE",
  TESTDRIVE_SELF_SERVICE_REDIRECT: "TESTDRIVE_SELF_SERVICE_REDIRECT",
  TESTDRIVE_DESSAI_CLICKED: "TESTDRIVE_DESSAI_CLICKED",
  TESTDRIVE_FNAC_CLICKED: "TESTDRIVE_FNAC_CLICKED",
};

export const testDriveLinkEvent = () => ({
  type: TESTDRIVE_EVENT_TYPES.TESTDRIVE_LINK,
});

export const geolocalisationEvent = () => ({
  type: TESTDRIVE_EVENT_TYPES.GEOLOCALISATION,
});

export const displayMapFilterEvent = () => ({
  type: TESTDRIVE_EVENT_TYPES.DISPLAY_FILTER,
});

export const filterMapTypeEvent = mapTypeLabel => ({
  type: TESTDRIVE_EVENT_TYPES.FILTER_MAP,
  data: mapTypeLabel,
});

export const testDriveMoreResultsEvent = () => ({
  type: TESTDRIVE_EVENT_TYPES.MORE_RESULTS,
});

export const testDriveMoreInfoEvent = data => ({
  type: TESTDRIVE_EVENT_TYPES.MORE_INFO,
  data,
});

export const finalTestDriveRedirectEvent = () => ({
  type: TESTDRIVE_EVENT_TYPES.FINAL_STEP_REDIRECT,
});

export const testDriveMapUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_MAP,
  data,
});

export const testDriveSearchResultsUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SEARCH_RESULTS,
  data,
});

export const testDriveFnacDartyUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_FNAC_DARTY,
  data,
});

export const testDriveMediaMarktUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_MEDIAMARKT,
  data,
});

export const testDriveDateUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DATE,
  data,
});

export const testDriveDetailsUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DETAILS,
  data,
});

export const testDriveClickedResultDessaiUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DESSAI_CLICKED,
  data,
});

export const testDriveClickedResultFnacUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_FNAC_CLICKED,
  data,
});

export const testDriveDetailsVerificationUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DETAILS_VERIFICATION,
  data,
});

export const testDriveConfirmationUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_CONFIRMATION,
  data,
});

export const testDriveFree2MoveUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SELF_SERVICE,
  data,
});

export const testDriveSelfServiceRedirectionUpdateUrlEvent = data => ({
  type: TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SELF_SERVICE_REDIRECT,
  data,
});
