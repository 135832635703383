import React, { useState, forwardRef, useEffect } from "react";
import styled from "styled-components";
import { node, string, bool } from "prop-types";

import VisibilityIcon from "./Icons/Visibility";
import VisibilityOffIcon from "./Icons/VisibilityOff";

import { color, font, fontSize } from "./utils";

const StyledLabel = styled.label`
  display: block;
  cursor: text;
  margin-top: 2rem;
  width: ${({ fullWidth }) => fullWidth && "100%"};
`;

const StyledInputContainer = styled.div`
  align-items: center;
  border: 2px solid ${color("grey")};
  background: ${({ disabled }) => (disabled ? color("grey") : color("white"))};
  border-radius: 5px;
  display: flex;
  font-size: 1rem;
  letter-spacing: 0.25px;
  margin-top: 0.5rem;
`;

const StyledInput = styled.input`
  border: none;
  font-size: ${fontSize("sm")};
  font-family: ${font("ubuntu")};
  outline: none;
  flex: 1;
  background: ${({ disabled }) => (disabled ? color("grey") : "none")};
  padding: 0.5rem;
`;

const AdornmentLeft = styled.div`
  padding-right: 0.5rem;
`;

const AdornmentRight = styled.div`
  padding-left: 0.5rem;
`;

const PasswordContainer = styled.div`
  cursor: pointer;
`;

const TextField = forwardRef(
  (
    {
      type = "text",
      label,
      className,
      required,
      prefix,
      disabled = false,
      suffix,
      maxLength,
      fullWidth = false,
      name,
      errorHelperMessage,
      placeholder,
      ...props
    },
    ref
  ) => {
    const [isVisible, setVisible] = useState(false);
    const isPassword = type === "password";

    useEffect(() => {
      const inputElement = document.querySelector(`[name=${name}]`);

      if (!inputElement) {
        return;
      }

      inputElement.addEventListener("input", () => {
        inputElement.setCustomValidity("");

        if (!inputElement.validity.valid && errorHelperMessage) {
          inputElement.setCustomValidity(errorHelperMessage);
        }
      });
    }, []);

    return (
      <StyledLabel
        aria-label={!label || label === "" ? placeholder : label}
        className={className}
        fullWidth={fullWidth}
      >
        {label}
        {required && "*"}
        <StyledInputContainer disabled={disabled}>
          {prefix && <AdornmentLeft>{prefix}</AdornmentLeft>}
          <StyledInput
            type={isPassword && isVisible ? "text" : type}
            required={required}
            ref={ref}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            placeholder={placeholder}
            {...props}
          />
          {suffix && <AdornmentRight>{suffix}</AdornmentRight>}
          {isPassword && (
            <PasswordContainer onClick={() => setVisible(!isVisible)}>
              {isVisible ? (
                <VisibilityIcon size="md" />
              ) : (
                <VisibilityOffIcon size="md" />
              )}
            </PasswordContainer>
          )}
        </StyledInputContainer>
      </StyledLabel>
    );
  }
);

TextField.displayName = "TextField";

TextField.propTypes = {
  type: string,
  label: node,
  className: string,
  required: bool,
  multiline: bool,
  prefix: node,
  suffix: node,
  disabled: bool,
  maxLength: string,
  fullWidth: bool,
  name: string,
  errorHelperMessage: string,
  placeholder: string,
};

export default TextField;
