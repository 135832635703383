const getConfig = require("next/config").default;

const { BUSINESS_ENVIRONMENTS } = require("@shared/constants");

const getIsProductionMode = () => {
  const { publicRuntimeConfig } = getConfig();
  const { BUSINESS_ENV } = publicRuntimeConfig;

  return BUSINESS_ENV === BUSINESS_ENVIRONMENTS.PRODUCTION;
};

module.exports = { getIsProductionMode };
