import api from "@api";
import { DEAL_ACTION_TYPES, resetDealAction } from "@redux/actions/dealActions";

export const AUTH_ACTION_TYPES = {
  SET_IS_TRUSTED: "SET_IS_TRUSTED",
  SET_IS_GUEST: "SET_IS_GUEST",
};

export const setIsTrustedAction = isTrusted => ({
  type: AUTH_ACTION_TYPES.SET_IS_TRUSTED,
  data: isTrusted,
});

export const setIsGuestAction = isGuest => ({
  type: AUTH_ACTION_TYPES.SET_IS_GUEST,
  data: isGuest,
});

export const logoutUserAction = () => async dispatch => {
  dispatch({
    type: DEAL_ACTION_TYPES.LOGOUT_USER,
  });

  await api.logoutSession();

  dispatch(resetDealAction(true));
  dispatch(setIsTrustedAction(false));
};
