import React from "react";

import BaseIcon from "../BaseIcon";

const ArrowDownIcon = props => (
  <BaseIcon viewBox="0 0 30.724 30.725" {...props}>
    <path d="M29.992 10.182l-14.63 14.63-14.63-14.63c-.977-.976-.977-2.559 0-3.535.976-.977 2.559-.977 3.535 0L15.361 17.74 26.457 6.646c.977-.977 2.56-.977 3.536 0 .975.976.975 2.559-.001 3.536z" />
  </BaseIcon>
);

export default ArrowDownIcon;
