export const ERRORS_ACTION_TYPES = {
  SET_BASKET_ERRORS: "SET_BASKET_ERRORS",
  REMOVE_BASKET_ERROR: "REMOVE_BASKET_ERROR",
  SET_FINANCE_WIDGET_ERRORS: "SET_FINANCE_WIDGET_ERRORS",
  SET_UNAVAILABLE_ITEMS: "SET_UNAVAILABLE_ITEMS",
};

export const setBasketErrorCodesAction = errorCodes => ({
  type: ERRORS_ACTION_TYPES.SET_BASKET_ERRORS,
  data: errorCodes,
});

export const removeBasketErrorAction = errorCode => ({
  type: ERRORS_ACTION_TYPES.REMOVE_BASKET_ERROR,
  data: errorCode,
});

export const setFinanceWidgetErrorCodesAction = errorCodes => ({
  type: ERRORS_ACTION_TYPES.SET_FINANCE_WIDGET_ERRORS,
  data: errorCodes,
});

export const resetFinanceWidgetErrorCodesAction = () => dispatch => {
  dispatch(setFinanceWidgetErrorCodesAction([]));
};

export const setUnavailableItemsAction = unavailableItems => ({
  type: ERRORS_ACTION_TYPES.SET_UNAVAILABLE_ITEMS,
  data: unavailableItems,
});
