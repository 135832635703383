const { string } = require("prop-types");
const { ROUTES } = require("../routing/routes");

const isIndexOrConfigPage = pathname => {
  return [ROUTES.INDEX, ROUTES.CONFIG].includes(pathname);
};

isIndexOrConfigPage.propTypes = {
  pathname: string.isRequired,
};

module.exports = { isIndexOrConfigPage };
