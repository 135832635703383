import produce from "immer";
import { useSelector } from "react-redux";

import { REQUESTS_ACTION_TYPES } from "../actions/requestsActions";

const requests = produce((state = initialState, { type }) => {
  switch (type) {
    case REQUESTS_ACTION_TYPES.POST_DEAL_SUCCESS:
      state.updateSteps.isPending = false;
      state.updateSteps.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.POST_DEAL_PENDING:
      state.updateSteps.isPending = true;
      state.updateSteps.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.POST_DEAL_FAILURE:
      state.updateSteps.isPending = false;
      state.updateSteps.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SESSION_UPDATE_PENDING:
      state.sessionPatch.isPending = true;
      state.sessionPatch.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SESSION_UPDATE_FAILURE:
      state.sessionPatch.isPending = false;
      state.sessionPatch.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SESSION_UPDATE_SUCCESS:
      state.sessionPatch.isPendingPending = false;
      state.sessionPatch.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_PENDING:
      state.lldPriceBreakdown.isPending = true;
      state.lldPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_ERROR:
      state.lldPriceBreakdown.isPending = false;
      state.lldPriceBreakdown.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_SUCCESS:
      state.lldPriceBreakdown.isPending = false;
      state.lldPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_PENDING:
      state.loaPriceBreakdown.isPending = true;
      state.loaPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_ERROR:
      state.loaPriceBreakdown.isPending = false;
      state.loaPriceBreakdown.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_SUCCESS:
      state.loaPriceBreakdown.isPending = false;
      state.loaPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_PENDING:
      state.vacPriceBreakdown.isPending = true;
      state.vacPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_ERROR:
      state.vacPriceBreakdown.isPending = false;
      state.vacPriceBreakdown.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_SUCCESS:
      state.vacPriceBreakdown.isPending = false;
      state.vacPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_PENDING:
      state.scfPriceBreakdown.isPending = true;
      state.scfPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_ERROR:
      state.scfPriceBreakdown.isPending = false;
      state.scfPriceBreakdown.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_SUCCESS:
      state.scfPriceBreakdown.isPending = false;
      state.scfPriceBreakdown.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_FINANCING_REQUEST_PENDING:
      state.scfFinancing.isPending = true;
      state.scfFinancing.isError = false;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_FINANCING_REQUEST_ERROR:
      state.scfFinancing.isPending = false;
      state.scfFinancing.isError = true;
      break;

    case REQUESTS_ACTION_TYPES.SET_IS_SCF_FINANCING_REQUEST_SUCCESS:
      state.scfFinancing.isPending = false;
      state.scfFinancing.isError = false;
      break;

    default:
      return state;
  }
});

const initialState = {
  updateSteps: {
    isPending: false,
    isError: false,
  },
  sessionPatch: {
    isPending: false,
    isError: false,
  },
  lldPriceBreakdown: {
    isPending: false,
    isError: false,
  },
  loaPriceBreakdown: {
    isPending: false,
    isError: false,
  },
  vacPriceBreakdown: {
    isPending: false,
    isError: false,
  },
  scfPriceBreakdown: {
    isPending: false,
    isError: false,
  },
  scfFinancing: {
    isPending: false,
    isError: false,
  },
};

export const useUpdateStepsRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.updateSteps);

export const useIsSessionUpdatePendingSelector = () =>
  useSelector(({ requests }) => requests.sessionPatch.isPending);

export const useIsSessionUpdateErrorSelector = () =>
  useSelector(({ requests }) => requests.sessionPatch.isError);

export const useIsSessionUpdateSessionSelector = () =>
  useSelector(({ requests }) => requests.sessionPatch);

export const useLLDPriceBreakdownRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.lldPriceBreakdown);

export const useIsLLDPriceBreakdownRequestPendingSelector = () =>
  useSelector(({ requests }) => requests.lldPriceBreakdown.isPending);

export const useIsLLDPriceBreakdownRequestErrorSelector = () =>
  useSelector(({ requests }) => requests.lldPriceBreakdown.isError);

export const useLOAPriceBreakdownRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.loaPriceBreakdown);

export const useIsLOAPriceBreakdownRequestPendingSelector = () =>
  useSelector(({ requests }) => requests.loaPriceBreakdown.isPending);

export const useIsLOAPriceBreakdownRequestErrorSelector = () =>
  useSelector(({ requests }) => requests.loaPriceBreakdown.isError);

export const useVACPriceBreakdownRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.vacPriceBreakdown);

export const useIsVACPriceBreakdownRequestPendingSelector = () =>
  useSelector(({ requests }) => requests.vacPriceBreakdown.isPending);

export const useIsVACPriceBreakdownRequestErrorSelector = () =>
  useSelector(({ requests }) => requests.vacPriceBreakdown.isError);

export const useSCFPriceBreakdownRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.scfPriceBreakdown);

export const useIsSCFPriceBreakdownRequestPendingSelector = () =>
  useSelector(({ requests }) => requests.scfPriceBreakdown.isPending);

export const useIsSCFPriceBreakdownRequestErrorSelector = () =>
  useSelector(({ requests }) => requests.scfPriceBreakdown.isError);

export const useSCFFinancingRequestStatusSelector = () =>
  useSelector(({ requests }) => requests.scfFinancing);

export const useIsSCFFinancingRequestPendingSelector = () =>
  useSelector(({ requests }) => requests.scfFinancing.isPending);

export const useIsSCFFinancingRequestErrorSelector = () =>
  useSelector(({ requests }) => requests.scfFinancing.isError);

export default requests;
