const getIsB2BEnabledForAnyJourney = configuration => {
  const solB2BConfiguration = configuration.app.solJourney.b2b;

  return (
    solB2BConfiguration.lldJourney?.enabled ||
    solB2BConfiguration.loaJourney?.enabled ||
    solB2BConfiguration.cashJourney?.enabled ||
    solB2BConfiguration.vacJourney?.enabled ||
    solB2BConfiguration.scfJourney?.enabled
  );
};

module.exports = { getIsB2BEnabledForAnyJourney };
