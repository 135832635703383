import produce from "immer";

import { buildPageName, getProducts } from "../core/utils";
import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";

import {
  BASKET_UPDATE_URL_TYPES,
  BASKET_ECOMMERCE_EVENT_TYPES,
} from "@gtm/events/basket";
import {
  DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT,
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  PAGE_CATEGORY_CHECKOUT,
  MAIN_STEP_PERSONAL,
  EVENT_CHECKOUT,
  EVENT_CHECKOUT_OPTION,
  EVENT_CHECKOUT_OPTION_ACTION,
  EVENT_CATEGORY_ECOMMERCE,
  EVENT_CATEGORY_CONTENT,
  EVENT_CHECKOUT_ACTION,
  SITE_TARGET_B2B,
  SITE_TARGET_B2C,
} from "@gtm/constants";

export const basketReducerUpdateUrl = ({ type, data }) =>
  produce(DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case BASKET_UPDATE_URL_TYPES.BASKET_SUMMARY:
        draft.virtualPageURL = "/buy/basket-summary";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "2";
        draft.mainStepName = "basket summary";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY:
        draft.virtualPageURL = "/buy/delivery-method";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "3";
        draft.mainStepName = "delivery method";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY_LOCATION:
        draft.virtualPageURL = `/buy/delivery-location/${data.deliveryType}`;
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "4";
        draft.mainStepName = "delivery location";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_DEALER_LIST:
        draft.virtualPageURL = "/buy/delivery-location/shop/dealer-list";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "4bis";
        draft.mainStepName = "find dealer";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_DELIVERY_ADDRESS:
        draft.virtualPageURL = `/buy/delivery-location/${data.deliveryType}/recap`;
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "5";
        draft.mainStepName = "delivery address";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_PERSONAL_DETAIL:
        draft.virtualPageURL = "/buy/personal-details";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "6";
        draft.mainStepName = MAIN_STEP_PERSONAL;
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_REGISTRATION:
        draft.virtualPageURL = "/buy/registration";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "7";
        draft.mainStepName = "registration";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case BASKET_UPDATE_URL_TYPES.BASKET_ORDER_REVIEW:
        draft.virtualPageURL = "/buy/order-review";
        draft.pageCategory = PAGE_CATEGORY_CHECKOUT;
        draft.mainStepIndicator = "8";
        draft.mainStepName = "order review";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });

export const basketEcommerceEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_DELIVERY:
        draft.event = EVENT_CHECKOUT;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_ACTION;
        draft.ecommerce = {
          checkout: {
            actionField: {
              step: 1,
              option: "Delivery",
            },
            products: getProducts(
              data.price,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_DELIVERY_CONTINUE:
        draft.event = EVENT_CHECKOUT_OPTION;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_OPTION_ACTION;
        draft.ecommerce = {
          checkout_option: {
            actionField: {
              step: 1,
              option: `${data.deliveryType}::${
                data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C
              }`,
            },
          },
        };
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_PERSONAL_DETAILS:
        draft.event = EVENT_CHECKOUT;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_ACTION;
        draft.ecommerce = {
          checkout: {
            actionField: {
              step: 2,
              option: "Personal Details",
            },
            products: getProducts(
              data.price,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_PERSONAL_CONTINUE:
        draft.event = EVENT_CHECKOUT_OPTION;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_OPTION_ACTION;
        draft.ecommerce = {
          checkout_option: {
            actionField: {
              step: 3,
              option: `${data.personalOption}::${
                data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C
              }`,
            },
          },
        };
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_REGISTRATION_CHANGE:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = "Select::Immatriculation";
        draft.eventLabel = data.label;
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.CHECKOUT_REGISTRATION_CONFIRM_REJECT:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = "Popin::ImmatriculationAlone";
        draft.eventLabel = data.label;
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.SHARE_CONFIG_EMAIL_CLICK:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${data.actionBase}::Popin::ShareByMail`;
        draft.eventLabel = data.label;
        break;

      case BASKET_ECOMMERCE_EVENT_TYPES.TOGGLE_SHARE_EMAIL:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `Select:ShareByMail::${data.envoyerAnnuler}`;
        draft.eventLabel = data.label;
        break;

      default:
        return null;
    }
  });
