const { JOURNEY_TYPE, BUSINESS_MODELS } = require("../constants");

const { getAvailableJourneys } = require("./getAvailableJourneys");
const { getLanguageCode } = require("../v2/lib/getLanguageCode");

const {
  getIsTargetBusinessModelFinanceEnabled,
} = require("../v2/lib/getIsTargetBusinessModelFinanceEnabled");

const VALID_QUERY_TYPES = {
  ACCESSORY: "accessory",
  SERVICE: "service",
};

const SEARCH_MODE = {
  ACCESSORIES: "accesories",
  SERVICES: "services",
};

const parseConfigurationUrl = (
  appConfiguration,
  config,
  carObject,
  asPath,
  journey
) => {
  const filteredConfig = config.filter(
    configPart =>
      configPart !== appConfiguration.b2b.urlPrefix &&
      !appConfiguration.localisation.allowedLanguages
        .map(getLanguageCode)
        .includes(configPart)
  );

  const { preconfigurations, options, services } = carObject;
  const [carConfigQueryParam, ...configFields] = filteredConfig;
  const validatedAccessories = [];
  const validatedServices = [];
  let validJourneyQuery;

  const validatedPreconfiguration = preconfigurations.find(
    ({ slug }) => slug === carConfigQueryParam
  );

  const isValidPreconfigurationFromUrl = !!validatedPreconfiguration;

  let isValidUrl = true;

  const countrySpecificJourneys = getAvailableJourneys(appConfiguration);

  const isValidJourney = countrySpecificJourneys.includes(journey);

  const accessories = options.filter(
    option => option.type && option.type === VALID_QUERY_TYPES.ACCESSORY
  );

  const selectedBusinessModel = asPath.includes(
    `/${appConfiguration.b2b.urlPrefix}`
  )
    ? BUSINESS_MODELS.B2B
    : BUSINESS_MODELS.B2C;

  const isTargetBusinessModelFinanceEnabled = getIsTargetBusinessModelFinanceEnabled(
    selectedBusinessModel,
    appConfiguration
  );

  if (isValidPreconfigurationFromUrl && isValidJourney) {
    validJourneyQuery = isTargetBusinessModelFinanceEnabled
      ? journey
      : JOURNEY_TYPE.CASH;

    if (configFields.length > 0) {
      let mode = SEARCH_MODE.ACCESSORIES;

      for (let i = 0; i < configFields.length; ) {
        const slug = configFields[i];

        if (mode === SEARCH_MODE.ACCESSORIES) {
          const accessory = accessories.find(
            accessory => encodeURIComponent(accessory.slug) === slug
          );

          if (accessory && validatedPreconfiguration.options.includes(accessory.id)) {
            validatedAccessories.push(accessory);
          } else {
            mode = SEARCH_MODE.SERVICES;

            continue;
          }
        } else if (mode === SEARCH_MODE.SERVICES) {
          const service = services.find(
            service => encodeURIComponent(service.slug) === slug
          );

          if (service) {
            validatedServices.push(service);
          } else {
            isValidUrl = false;

            break;
          }
        }

        i++;
      }

      let validatedFields = validatedAccessories.concat(validatedServices);

      isValidUrl = validatedFields.length === configFields.length;
    }
  } else {
    isValidUrl = false;
  }

  return {
    isValidUrl,
    isValidPreconfigurationFromUrl,
    validatedAccessories,
    validatedServices,
    validatedPreconfiguration,
    validJourneyQuery,
    selectedBusinessModel,
  };
};

module.exports = { parseConfigurationUrl };
