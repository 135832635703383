export const scrollToRef = (ref, offsetTop = 0) => {
  const refPoint = ref?.current;

  if (!refPoint) {
    return;
  }

  window.scrollBy(0, refPoint.getBoundingClientRect().top - offsetTop);
};

export const scrollInFullView = ref => {
  const refPoint = ref?.current;
  const elementBottomEdge = refPoint.getBoundingClientRect().bottom;

  if (!refPoint) {
    return;
  }

  if (elementBottomEdge > window.innerHeight)
    window.scrollBy(
      0,
      refPoint.getBoundingClientRect().bottom - window.innerHeight
    );
};
