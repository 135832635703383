import React, { useState } from "react";
import styled from "styled-components";
import produce from "immer";
import qs from "qs";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { string } from "prop-types";
import { useTranslation } from "@lib/i18n";
import { useDispatch } from "react-redux";

import ProfileIcon from "@components/shared/Icons/Profile";
import ShoppingCartIcon from "@components/shared/Icons/ShoppingCart";
import PhoneIcon from "@components/shared/Icons/PhoneIcon";
import ItemSelector from "./ItemSelector";
import Logo from "@components/Logo/Logo";
import SimpleButton from "@components/shared/SimpleButton";
import SellerContactModal from "@components/Header/SellerContactModal";

import gtm from "@gtm/core";
import api from "@api";
import { toast } from "@lib/reactToastify";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { getFinanceVariant } from "@lib/getFinanceVariant";
import { BUSINESS_MODELS, JOURNEY_TYPE, DEAL_STATES } from "@shared/constants";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { ROUTES } from "@shared/routing/routes";
import { useConfigurationPathname } from "@hooks/useConfigurationPathname";
import { useRoutingUtils } from "@hooks/useRoutingUtils";
import { withTabNavigation } from "@hoc/withTabNavigation";
import {
  homepageConfiguratorUpdateUrlEvent,
  changeConfigurationEvent,
} from "@gtm/events/homepage";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { setAppDataAction } from "@redux/actions/configActions";
import { useGetIsTargetBusinessModelFinanceEnabled } from "@hooks/useGetIsTargetBusinessModelFinanceEnabled";
import { FIXED_ELEMENTS_ATTRIBUTE_NAME } from "@hooks/useScrollToView";
import { useDetectDevice } from "@hooks/useDetectDevice";
import { getIsB2BEnabledForAnyJourney } from "@shared/v2/lib/getIsB2BEnabledForAnyJourney";
import { useIsContactPhoneNumberEnabledSelector } from "@redux/reducers/appConfig";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";

import {
  useCarConfigurationSelector,
  useBusinessModelSelector,
  useDealSelector,
  useActiveCarPreconfigurationSelector,
} from "@redux/reducers/deal";

import {
  useReferrerSelector,
  useIsBusinessModelSwitcherEnabledSelector,
  useLanguageSettingsSelector,
  useConfigurationSelector,
} from "@redux/reducers/appConfig";

import {
  color,
  tablet,
  smallMobile,
  largeMobile,
  desktop,
  fontWeight,
  prop,
} from "../shared/utils";

import {
  createNewDealFromExistingAction,
  updateIsSelectionConfirmedAction,
  setBusinessModelAction,
  setJourneyAction,
  patchCarConfigurationAction,
  refreshDealWithAppConfigAction,
  updateCarPreconfigurationAction,
  setVehicleQuantityAction,
} from "@redux/actions/dealActions";

import {
  amiLogoClickEvent,
  accountLogoClickEvent,
  basketLogoClickEvent,
  switchBusinessModelEvent,
  displaySellerInformationEvent,
} from "@gtm/events/shared";

const { publicRuntimeConfig } = getConfig();
const { ROOT_URL_PREFIX } = publicRuntimeConfig;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 7.5rem;
`;

const SelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${color("black1")};
  height: 1.5rem;
  padding: 0 0.2rem;

  ${tablet`
    padding: 0 0.8rem;
  `}
`;

const BusinessModelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavigationContainer = styled.div`
  display: flex;
  height: 6rem;
  padding: 0 1rem;
  justify-items: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  ${tablet`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    padding: 0 2rem;
  `}
`;

const LinkWrapper = styled.div`
  justify-self: ${({ justifySelf }) => justifySelf || "center"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${color("white")};
  transition: 0.1s linear color, 0.1s linear background;
  margin-left: ${prop("marginLeft")};
`;

const StyledLinkWrapper = styled(LinkWrapper)`
  border-right: 1px solid ${color("white")};
  order: -1;
  padding-right: 1.2rem;

  ${tablet`
    border-right: none;
    order: initial;
    padding-right: 0;
  `}
`;

const CitroenLogoWrapper = withTabNavigation(styled.div`
  width: 2rem;

  ${smallMobile`
    width: 2.5rem;
  `};
`);

const CitroenLogoImage = styled.img`
  width: 100%;
  cursor: pointer;

  ${tablet`
    width: 140%;
  `}
`;

const NavigationList = styled.ul`
  display: flex;
  list-style: none;
`;

const NavigationItem = styled.li`
  color: ${color("white")};
  transition: 0.1s linear color;

  span {
    display: none;
  }

  ${largeMobile`
    padding-left: 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  `};

  ${tablet`
    padding-left: 0;

    span {
      display: initial;
    }
  `}
`;

const StyledAmiLogo = styled(Logo)`
  margin: 0 0.5rem;

  ${tablet`
    margin: 0;
  `}
`;

const StyledAmiLogoWrapper = withTabNavigation(`div`);

const CheckContainer = styled.div`
  margin-right: 0.5rem;

  ${desktop`
    margin-right: 1rem;
  `}
`;

const StyledProfileIcon = styled(ProfileIcon)`
  height: 24px;
  width: 24px;

  ${largeMobile`
    height: 36px;
    width: 36px;
  `}
`;

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  height: 24px;
  width: 24px;
  margin-right: 1rem;

  ${largeMobile`
    height: 36px;
    width: 36px;
  `}
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  height: 24px;
  width: 24px;

  ${largeMobile`
    height: 36px;
    width: 36px;
    margin-right: 1rem;
  `}
`;

const StyledSimpleButton = styled(SimpleButton)`
  color: ${color("white")};
  font-weight: ${fontWeight("regular")};
  letter-spacing: 1px;
  margin-left: 0.85rem;

  &:hover {
    color: ${color("black1")};
  }
`;

const Header = ({ className, dealState }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const routingUtils = useRoutingUtils();
  const isUserTrusted = useIsUserTrustedSelector();
  const router = useRouter();
  const configurationPathname = useConfigurationPathname();
  const { financeSimulation } = useDealSelector();
  const pageVariant = getFinanceVariant(financeSimulation.journey);
  const affiliation = useReferrerSelector();
  const { label } = useActiveCarPreconfigurationSelector();
  const isBusinessModelSwitcherEnabled = useIsBusinessModelSwitcherEnabledSelector();
  const configuration = useConfigurationSelector();
  const isB2BEnabled = getIsB2BEnabledForAnyJourney(configuration);
  const currentBusinessModel = useBusinessModelSelector();
  const isB2BSelected = useIsB2BSelected();
  const { allowedLanguages } = useLanguageSettingsSelector();
  const getIsTargetBusinessModelFinanceEnabled = useGetIsTargetBusinessModelFinanceEnabled();
  const isContactPhoneNumberHeaderEnabled = useIsContactPhoneNumberEnabledSelector();
  const {
    id: activePreconfigurationId,
  } = useActiveCarPreconfigurationSelector();

  const isConfigurationPage = router.pathname.includes(ROUTES.CONFIG);

  const { service: selectedServices } = useCarConfigurationSelector();

  const { isDesktop, isMobile } = useDetectDevice();

  const containerAttributes = {
    className,
    [FIXED_ELEMENTS_ATTRIBUTE_NAME]: isDesktop,
  };

  const baseBusinessModels = [
    { value: BUSINESS_MODELS.B2C, title: t("header.b2cTitle") },
  ];

  const availableBusinessModels = produce(baseBusinessModels, draft => {
    if (isBusinessModelSwitcherEnabled && isB2BEnabled) {
      draft.push({
        value: BUSINESS_MODELS.B2B,
        title: t("header.b2bTitle"),
      });
    }
  });

  const isMultipleLanguages = allowedLanguages.length > 1;

  const availableLanguages =
    isMultipleLanguages &&
    allowedLanguages.map(language => {
      return {
        value: language,
        title: t(`header.languages.${language}`),
      };
    });

  const onPreconfigurationChange = preconfiguration => {
    if (preconfiguration.id === activePreconfigurationId) {
      return;
    }

    dispatch(updateCarPreconfigurationAction(preconfiguration));

    dispatch(setLastChangedAtAction(Date.now()));

    gtm.fire(changeConfigurationEvent(preconfiguration.label));
  };

  const changeBusinessModel = async targetBusinessModel => {
    if (currentBusinessModel === targetBusinessModel) {
      return;
    }
    const { data } = await api.getAppData();
    const { car } = data;
    const { preconfigurations } = car;

    const isTargetBusinessModelB2B =
      targetBusinessModel === BUSINESS_MODELS.B2B;

    const singlePreconfiguration =
      preconfigurations.find(config =>
        isTargetBusinessModelB2B ? config.b2bDefault : config.default
      ) || preconfigurations[0];

    onPreconfigurationChange(singlePreconfiguration);

    const nextServices = selectedServices.filter(service =>
      isTargetBusinessModelB2B ? service.b2B : service.b2C
    );

    dispatch(patchCarConfigurationAction({ service: nextServices }));

    let session = {
      businessModel: targetBusinessModel,
      configuration: {
        service: nextServices[0]?.id ?? null,
      },
      quantity: 1,
    };

    dispatch(setVehicleQuantityAction(1));

    if (!getIsTargetBusinessModelFinanceEnabled(targetBusinessModel)) {
      dispatch(setJourneyAction(JOURNEY_TYPE.CASH));

      session.journey = JOURNEY_TYPE.CASH;
    }

    dispatch(setBusinessModelAction(targetBusinessModel));

    await api.updateSession(session);

    if (isUserTrusted) {
      await dispatch(createNewDealFromExistingAction());
    }

    gtm.fire(
      switchBusinessModelEvent({
        isB2BSelected: isTargetBusinessModelB2B,
        label: isTargetBusinessModelB2B
          ? t("header.b2bTitle")
          : t("header.b2cTitle"),
      })
    );

    gtm.fire(
      homepageConfiguratorUpdateUrlEvent({
        pageVariant,
        pageTitle: router.asPath,
        affiliation,
        isB2BSelected: isTargetBusinessModelB2B,
        price: financeSimulation.totalPrice.cash.netPrice,
        variant: label,
      })
    );
  };

  const onAmiLogoClick = () => {
    gtm.fire(
      amiLogoClickEvent({
        label: isB2BSelected ? "home ami pro" : "home ami",
      })
    );

    router.push(ROUTES.CONFIG, configurationPathname);
  };

  const onMyProfileIconClick = () => {
    // prevent reload when on login page
    if (router.pathname === ROUTES.MY_ACCOUNT_LOGIN) {
      return;
    }

    gtm.fire(accountLogoClickEvent());

    const targetRoute = isUserTrusted
      ? ROUTES.MY_ACCOUNT_INDEX
      : ROUTES.MY_ACCOUNT_LOGIN;

    const localizedPathname = routingUtils.getLocalizedPathname(
      targetRoute,
      null,
      i18n.language
    );

    return router.push(targetRoute, localizedPathname);
  };

  const [isSellerInfoOpen, setIsSellerInfoOpen] = useState(false);

  const onSellerInformationClick = () => {
    if (isMobile) {
      window.location.href = `tel:${t("header.sellerContactModal.number")}`;
      return;
    }
    setIsSellerInfoOpen(true);
    gtm.fire(displaySellerInformationEvent());
  };

  const onShoppingCartIconClick = async () => {
    if (dealState === DEAL_STATES.OFFER) {
      const localizedOfferSummaryPathname = routingUtils.getLocalizedPathname(
        ROUTES.OFFER_SUMMARY,
        null,
        i18n.language
      );

      return router.push(ROUTES.OFFER_SUMMARY, localizedOfferSummaryPathname);
    }

    if (
      dealState === DEAL_STATES.ORDER &&
      router.pathname !== ROUTES.ORDER_CONFIRMATION
    ) {
      await dispatch(createNewDealFromExistingAction());
    }

    if (router.pathname === ROUTES.ORDER_CONFIRMATION) {
      await dispatch(updateIsSelectionConfirmedAction(false));
    }

    gtm.fire(basketLogoClickEvent());

    const localizedBasketPathname = routingUtils.getLocalizedPathname(
      ROUTES.BASKET,
      null,
      i18n.language
    );

    return router.push(ROUTES.BASKET, localizedBasketPathname);
  };

  const redirectToTranslatedUrl = () => {
    // TODO config page gets handled by applyConfigurationToUrl; consider refactoring to have both event-driven
    if (router.pathname === ROUTES.CONFIG) {
      return;
    }

    const query = qs.stringify(router.query, { addQueryPrefix: true });

    const params = routingUtils.getParamsFromPathname(
      router.asPath.replace(ROOT_URL_PREFIX, "").replace(/\?.+$/, "")
    );

    const nextAsPath = routingUtils.getLocalizedPathname(
      router.pathname,
      params,
      i18n.language
    );

    router.replace(router.pathname + query, nextAsPath, {
      shallow: true,
    });
  };

  const changeLocale = async nextLanguage => {
    await Promise.all([
      i18n.changeLanguage(nextLanguage),
      api.updateSession({
        language: nextLanguage,
      }),
    ]);

    try {
      const { data: appData } = await api.getAppData();

      dispatch(setAppDataAction(appData));
      dispatch(refreshDealWithAppConfigAction());

      redirectToTranslatedUrl();
    } catch (error) {
      toast.error(
        t("notify.apiDefaultError"),
        {
          toastId: "brandid-registration-redirect-error",
        },
        error
      );
    }
  };

  const citroenLogoWithLabel = getStaticAssetURL("/header/citroen_Logo.svg");
  const citroenLogoWithoutLabel = getStaticAssetURL(
    "/header/citroen_Logo_no_label.svg"
  );

  const citroenLogoUrl = isMobile
    ? citroenLogoWithoutLabel
    : citroenLogoWithLabel;

  return (
    <HeaderContainer {...containerAttributes}>
      <SelectorContainer>
        <BusinessModelContainer data-id="business-model-container">
          <ItemSelector
            value={currentBusinessModel}
            items={availableBusinessModels}
            onClickItem={changeBusinessModel}
            isDisabled={!isConfigurationPage}
          />
          {isMultipleLanguages && (
            <ItemSelector
              value={i18n.language}
              items={availableLanguages}
              onClickItem={changeLocale}
            />
          )}
        </BusinessModelContainer>
      </SelectorContainer>

      <NavigationContainer>
        <LinkWrapper justifySelf="flex-start">
          <StyledAmiLogoWrapper aria-label="AMI" onClick={onAmiLogoClick}>
            <StyledAmiLogo />
          </StyledAmiLogoWrapper>
        </LinkWrapper>

        <StyledLinkWrapper>
          <CitroenLogoWrapper onClick={onAmiLogoClick}>
            <CitroenLogoImage
              data-id="img-brand-logo"
              src={citroenLogoUrl}
              alt="Citroen"
              title="Citroen"
            />
          </CitroenLogoWrapper>
        </StyledLinkWrapper>

        <SellerContactModal
          isOpen={isSellerInfoOpen}
          closeModal={() => setIsSellerInfoOpen(false)}
        />

        <LinkWrapper justifySelf="flex-end" marginLeft="auto">
          <nav>
            <NavigationList>
              {isContactPhoneNumberHeaderEnabled && (
                <NavigationItem data-id="header-contactNumber">
                  <StyledSimpleButton
                    onClick={onSellerInformationClick}
                    startAdornment={<StyledPhoneIcon />}
                    aria-label={t("header.sellerContactModal.number")}
                  >
                    {!isMobile && t("header.sellerContactModal.number")}
                  </StyledSimpleButton>
                </NavigationItem>
              )}
              <NavigationItem data-id="header-myAccount">
                <StyledSimpleButton
                  onClick={onMyProfileIconClick}
                  startAdornment={
                    <>
                      <StyledProfileIcon />
                      {isUserTrusted && <CheckContainer>✔</CheckContainer>}
                    </>
                  }
                  aria-label={t("header.myAccount")}
                >
                  {!isMobile ? t("header.myAccount") : ""}
                </StyledSimpleButton>
              </NavigationItem>
              <NavigationItem data-id="header-shoppingCart">
                <StyledSimpleButton
                  onClick={onShoppingCartIconClick}
                  startAdornment={<StyledShoppingCartIcon />}
                  aria-label={t("header.shoppingCart")}
                >
                  {!isMobile ? t("header.shoppingCart") : ""}
                </StyledSimpleButton>
              </NavigationItem>
            </NavigationList>
          </nav>
        </LinkWrapper>
      </NavigationContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  className: string,
  dealState: string,
};

export default Header;
