const { produce } = require("immer");

const { JOURNEY_TYPE } = require("@shared/constants");

const getFormattedFinanceData = (
  financeData,
  financeWidgetParameters,
  isDeliveryIncluded = false
) => {
  const simulationResult = financeData?.vehicleSimulation?.result;

  if (!simulationResult) {
    return null;
  }

  const baseFinanceData = {
    regularPayment: simulationResult.teaser.data.regularPayment,
    duration: simulationResult.teaser.data.duration,
    annualMileage: simulationResult.teaser.data.annualMileage,
    areServicesIncluded: Boolean(financeWidgetParameters?.services?.length),
    legalMention: simulationResult.globalText.legalMention,
    displayBlocks: simulationResult.displayBlocks,
    financialProductLabel:
      simulationResult.globalText.financialProductLabel.text,
    isDeliveryIncluded,
  };

  return produce(baseFinanceData, draft => {
    if (
      simulationResult.teaser.data.financialType?.toLowerCase() ===
      JOURNEY_TYPE.VAC
    ) {
      draft.deposit = simulationResult.teaser.data.deposit ?? null;

      return;
    }

    draft.firstRental = simulationResult.teaser.data?.firstRental ?? null;
    draft.deposit =
      simulationResult.teaser.data.firstRental?.amountInclTax ?? null;
  });
};

module.exports = { getFormattedFinanceData };
