import React, { useCallback, useState, useRef } from "react";
import styled from "styled-components";
import { bool, func, any, string } from "prop-types";
import cn from "classnames";

import Modal from "@components/shared/Modal";
import Heading from "@components/shared/Subheading";
import Button from "@components/shared/Button";
import ButtonGroup from "@components/shared/ButtonGroup";
import { useIsScrolledToBottom } from "../../hooks/useIsScrolledToBottom";
import { tablet } from "./utils";

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100dvh - 130px);
  overflow: hidden;

  ${tablet`
    max-height: 70vh;
    padding: 0 1rem;
  `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 2rem;
  font-weight: bold;
`;

const SCROLLBAR_BORDER_RADIUS = "4px";

/**
 * @prop {boolean} isScrollable
 */
const StyledContainer = styled.div.attrs(({ isScrollable }) => ({
  className: cn({ scrollable: isScrollable }),
}))`
  overflow-y: auto;
  position: relative;

  &.scrollable {
    padding-right: 8px;
  }

  &::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: ${SCROLLBAR_BORDER_RADIUS};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey1};
    border-radius: ${SCROLLBAR_BORDER_RADIUS};
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  flex-direction: column;
`;

const Popin = ({
  isOpen,
  onClose,
  onConfirm,
  confirmTitle,
  children,
  title,
  overlayColor = "transparentBlack",
  preventConfirmBeforeScroll = false,
  ...props
}) => {
  const [containerNode, setContainerNode] = useState(null);
  const initContainerNode = useCallback(node => setContainerNode(node), [
    setContainerNode,
  ]);

  const modalLastElement = useRef(null);

  const isScrolledToBottom = useIsScrolledToBottom(containerNode, {
    disabled: !preventConfirmBeforeScroll,
  });

  return (
    <Modal
      isOpen={isOpen}
      ariaLabel={title ?? ""}
      onClose={onClose}
      {...(modalLastElement ? { lastElement: modalLastElement } : {})}
      overlayColor={overlayColor}
      {...props}
    >
      <StyledModalContainer>
        {title && <StyledHeading>{title}</StyledHeading>}
        <StyledContainer
          isScrollable={
            containerNode &&
            containerNode.scrollHeight > containerNode.clientHeight
          }
          ref={initContainerNode}
        >
          {children}
        </StyledContainer>
      </StyledModalContainer>
      {onConfirm && confirmTitle && (
        <StyledButtonGroup>
          <Button
            ref={modalLastElement}
            disabled={preventConfirmBeforeScroll && !isScrolledToBottom}
            onClick={onConfirm}
            data-id="home-ami-pop-in-confirmation-button"
            withBorder
          >
            {confirmTitle}
          </Button>
        </StyledButtonGroup>
      )}
    </Modal>
  );
};

Popin.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func,
  children: any.isRequired,
  confirmTitle: string,
  title: string.isRequired,
  overlayColor: string,
  preventConfirmBeforeScroll: bool,
};

export default Popin;
