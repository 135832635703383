const {
  getJourneyNameFromJourneyType,
} = require("@shared/v2/lib/getJourneyNameFromJourneyType");
const { getIsB2BSelected } = require("./getIsB2BSelected");

const getPreselectedDelivery = (appConfig, selectedJourney, businessModel) => {
  const journey = getJourneyNameFromJourneyType(selectedJourney);

  const config = appConfig.configuration.app.solJourney;

  const journeyConfig = getIsB2BSelected(businessModel)
    ? config.b2b[journey]
    : config[journey];

  if (journeyConfig) {
    return journeyConfig.preselectedDelivery;
  }

  return null;
};

module.exports = { getPreselectedDelivery };
