import { isMobile, isTablet } from "react-device-detect";

import gtm from "@gtm/core";
import { NEW_CARS } from "@gtm/constants";
import {
  HOMEPAGE_ECOMMERCE_EVENT_TYPES,
  HOMEPAGE_EVENT_TYPES,
} from "@gtm/events/homepage";
import { ROUTES } from "@shared/routing/routes";
import { TEST_DRIVE_TYPE_KEY } from "@shared/constants";
import { CTA_SOL_TYPES, FINANCE_FILE_STATUS } from "@shared/constants";

import {
  DESKTOP,
  TABLET,
  MOBILE,
  SITE_FAMILY,
  SITE_OWNER,
  SITE_TARGET_B2C,
  SITE_TYPE_LEVEL_1,
  SITE_TYPE_LEVEL_2,
  EVENT_ACTION_DETAIL,
  VEHICLE_MODEL_BODY_LABEL,
  VEHICLE_MODEL_BODY,
  BRAND_NAME,
  GTM_TESTDRIVE_TYPE,
  PAGES,
  FINANCE_STATUS_TYPES,
} from "@gtm/constants";
import {
  basketSummaryUpdateUrlEvent,
  basketDeliveryUpdateUrlEvent,
  basketPersonalDetailsUpdateUrlEvent,
  basketVehicleRegistrationUpdateUrlEvent,
  basketPersonalDetailsEcommerceEvent,
  basketPersonalContinueEcommerceEvent,
} from "gtm/events/basket";

export const isObject = o =>
  Object.prototype.toString.call(o) === "[object Object]";

export const buildPageName = data => {
  const {
    siteTypeLevel2 = SITE_TYPE_LEVEL_2,
    pageTitle,
    showVehicleModel = true,
    businessModel = SITE_TARGET_B2C,
  } = data;
  const pageName = `${SITE_TYPE_LEVEL_1}/${siteTypeLevel2}/${SITE_OWNER}/${businessModel}/${SITE_FAMILY}`;
  const vehicleModel = showVehicleModel ? `/${VEHICLE_MODEL_BODY}` : "";

  const getDeviceType = () => {
    if (isMobile) {
      return MOBILE;
    }

    if (isTablet) {
      return TABLET;
    }

    return DESKTOP;
  };

  return `${pageName}//${getDeviceType()}${vehicleModel}${pageTitle}`;
};

export const getProducts = (price, variant, withQuantity, isB2BSelected) => [
  {
    name: VEHICLE_MODEL_BODY_LABEL,
    id: VEHICLE_MODEL_BODY,
    price: price,
    brand: BRAND_NAME,
    category: isB2BSelected ? NEW_CARS.B2B : NEW_CARS.B2C,
    variant: variant,
    ...(withQuantity && { quantity: 1 }),
  },
];

export const getEcommerce = (
  currency,
  price,
  variant,
  affiliation,
  isB2BSelected
) => ({
  currencyCode: currency,
  detail: {
    actionField: {
      affiliation,
      action: EVENT_ACTION_DETAIL,
      list: "configurator",
    },
    products: getProducts(price, variant, false, isB2BSelected),
  },
});

export const getTestDriveType = testDriveType => {
  const { MAISON, ENSTORE } = GTM_TESTDRIVE_TYPE;
  const gtmTestDriveType =
    testDriveType === TEST_DRIVE_TYPE_KEY.LAMAISON ? MAISON : ENSTORE;

  return gtmTestDriveType;
};

export const generateGitId = () => {
  let gitId = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let index = 0; index < 20; index++) {
    gitId += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return gitId;
};

export const getGTMPathnameStep = (pathname, lastCta) => {
  const { CONFIG, BASKET } = ROUTES;
  const {
    VEHICLE_SELECTED,
    VEHICLE_CONFIRMED,
    DELIVERY_POINT_SELECTED,
    PERSONAL_DETAILS_CONFIRMED,
  } = CTA_SOL_TYPES;

  switch (pathname) {
    case CONFIG:
      return "basket";
    case BASKET:
      switch (lastCta) {
        case VEHICLE_SELECTED:
          return "basket";
        case VEHICLE_CONFIRMED:
          return "delivery";
        case DELIVERY_POINT_SELECTED:
          return "personal details";
        case PERSONAL_DETAILS_CONFIRMED:
          return "registration";
        default:
          return "basket";
      }

    default:
      return "basket";
  }
};

export const getPageName = currentRoute => {
  switch (currentRoute) {
    case ROUTES.CONFIG:
      return PAGES.HOME;

    case ROUTES.BASKET:
      return PAGES.BASKET;

    case ROUTES.OFFER_SUMMARY:
      return PAGES.ORDER_SUMMARY;

    default:
      return "Unknown page";
  }
};

export const getFinanceStatusUrl = status => {
  switch (status) {
    case FINANCE_FILE_STATUS.ACCEPTED:
      return FINANCE_STATUS_TYPES.FINAL_APPROVAL;

    case FINANCE_FILE_STATUS.PENDING:
      return FINANCE_STATUS_TYPES.PENDING;

    default:
      console.error("Unknown finance status");
      return;
  }
};

const getPersonalDetailsEvents = (
  pageVariant,
  pageTitle,
  affiliation,
  totalPrice,
  selectedConfiguration,
  isUserTrusted,
  isB2BSelected,
  isSSRRendered
) => {
  gtm.fire(
    basketPersonalDetailsUpdateUrlEvent({
      pageVariant,
      pageTitle,
      affiliation,
      isB2BSelected,
      isSSRRendered,
    })
  );

  gtm.fire(
    basketPersonalDetailsEcommerceEvent({
      price: totalPrice.cash.netPrice,
      variant: selectedConfiguration.preconfiguration.label,
      isB2BSelected,
    })
  );

  if (isUserTrusted) {
    gtm.fire(
      basketPersonalContinueEcommerceEvent({
        personalOption: "Login",
        isB2BSelected,
      })
    );
  }
};

export const getBasketUpdateUrl = (
  lastCta,
  pageVariant,
  currency,
  totalPrice,
  selectedConfiguration,
  pageTitle,
  affiliation,
  isUserTrusted,
  isB2BSelected,
  isSSRRendered
) => {
  switch (lastCta) {
    case CTA_SOL_TYPES.VEHICLE_SELECTED:
      return gtm.fire(
        basketSummaryUpdateUrlEvent({
          pageVariant,
          currency,
          pageTitle,
          affiliation,
          isB2BSelected,
          isSSRRendered,
        })
      );
    case CTA_SOL_TYPES.VEHICLE_CONFIRMED:
      return gtm.fire(
        basketDeliveryUpdateUrlEvent({
          pageVariant,
          pageTitle,
          affiliation,
          isB2BSelected,
          isSSRRendered,
        })
      );
    case CTA_SOL_TYPES.DELIVERY_POINT_SELECTED:
      return getPersonalDetailsEvents(
        pageVariant,
        pageTitle,
        affiliation,
        totalPrice,
        selectedConfiguration,
        isUserTrusted,
        isB2BSelected,
        isSSRRendered
      );
    case CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED:
      return gtm.fire(
        basketVehicleRegistrationUpdateUrlEvent({
          pageVariant,
          pageTitle,
          affiliation,
          isB2BSelected,
          isSSRRendered,
        })
      );
    default:
      return null;
  }
};

export const updateDynamicYieldRecommendationContext = event => {
  window.DY = window.DY || {};
  const contextData = ["1CO2Q1"];

  switch (event.type) {
    case HOMEPAGE_ECOMMERCE_EVENT_TYPES.ECOMMERCE_CONFIGURATOR: {
      if (event.data && event.data.variant) {
        contextData.push(event.data.variant);
      }
      window.DY.recommendationContext = { type: "PRODUCT", data: contextData };
      break;
    }
    case HOMEPAGE_EVENT_TYPES.CHANGE_CONFIGURATION: {
      if (event.data) {
        contextData.push(event.data);
      }
      window.DY.recommendationContext = { type: "PRODUCT", data: contextData };
      break;
    }
    default:
      break;
  }
};
