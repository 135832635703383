import { useIsOfferSummaryPage } from "@hooks/useIsOfferSummaryPage";

import {
  useCurrentDealSelector,
  usePrimaryColorSelector,
} from "@redux/reducers/deal";

export const usePrimaryColor = () => {
  const currentDeal = useCurrentDealSelector();
  const isOfferSummaryPage = useIsOfferSummaryPage();
  const primaryColor = usePrimaryColorSelector();

  return isOfferSummaryPage
    ? `#${currentDeal.extendedVehicleInfo.preconfiguration.colorHexa}`
    : primaryColor;
};
