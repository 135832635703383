const ALL_KEY = "all";

const IMPORT_SOURCE_TYPES = {
  LOCAL: "local",
  SFTP: "sftp",
};

const BUSINESS_ENVIRONMENTS = {
  LOCAL: "local",
  DEVELOPMENT: "development",
  QA: "qa",
  PREPROD: "preproduction",
  PRODUCTION: "production",
};

const BUSINESS_ENVIRONMENTS_API_LAYER_MAPPING = {
  [BUSINESS_ENVIRONMENTS.LOCAL]: "qa",
  [BUSINESS_ENVIRONMENTS.DEVELOPMENT]: "qa",
  [BUSINESS_ENVIRONMENTS.QA]: "qa",
  [BUSINESS_ENVIRONMENTS.PREPROD]: "preprod",
  [BUSINESS_ENVIRONMENTS.PRODUCTION]: "prod",
};

const NODE_ENVIRONMENTS = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

const AVAILABLE_JOURNEY = ["cashJourney", "loaJourney", "lldJourney"];

const BRANDS = {
  CITROEN: "AC",
  OPEL: "OV",
};

const COUNTRIES = {
  FR: "FR",
  IT: "IT",
  ES: "ES",
  BE: "BE",
  PT: "PT",
  GB: "GB",
  LU: "LU",
};

const COUNTRY_CODES = {
  FR: "+33",
  IT: "+39",
  ES: "+34",
  BE: "+32",
  PT: "+351",
  GB: "+44",
  LU: "+352",
};

const LOCALES = {
  FR: "fr",
  IT: "it",
  ES: "es",
  FR_BE: "fr-BE",
  NL_BE: "nl-BE",
  PT: "pt",
  EN: "en",
  LU: "fr-LU",
};

const MOP_CULTURE_TO_LOCALE = {
  "fr-FR": LOCALES.FR,
  "it-IT": LOCALES.IT,
  "es-ES": LOCALES.ES,
  "pt-PT": LOCALES.PT,
  "fr-BE": LOCALES.FR_BE,
  "nl-BE": LOCALES.NL_BE,
  "en-GB": LOCALES.EN,
  "fr-LU": LOCALES.LU,
};

const DAYJS_LOCALES_MAP = {
  [LOCALES.FR]: "fr",
  [LOCALES.IT]: "it",
  [LOCALES.ES]: "es",
  [LOCALES.FR_BE]: "fr",
  [LOCALES.NL_BE]: "nl-be",
  [LOCALES.PT]: "pt",
  [LOCALES.EN]: "en-gb",
  [LOCALES.LU]: "fr",
};

const DAYJS_LOCALES_WEEKDAY_TRANSLATIONS = {
  [LOCALES.PT]: ["D", "2ª", "3ª", "4ª", "5ª", "6ª", "S"],
};

const DEV_COOKIE_DOMAIN = "o2c.local";

const CUSTOMIZE_TYPES = {
  ACCESSORY: "accessory",
  DESCRIPTION: "description",
  PACK: "pack",
  PERSONALIZATION: "personalization",
  SERVICE: "service",
};

const MOP_CUSTOMIZE_TYPES = {
  ACCESSORY: "Accessory",
  PERSONALIZATION: "Personalization",
  PACK: "pack",
};

const MOP_GOVERNMENT_CONTRIBUTION_CODES = {
  ECOBONUS: "ecologicBonus",
};

const MOP_FINANCIAL_TYPE = {
  LOA: "LOA",
  LLD: "LLD",
  VAC: "VAC",
};

const CTA_SOL_TYPES = {
  VEHICLE_SELECTED: "VEHICLE_SELECTED",
  VEHICLE_CONFIRMED: "VEHICLE_CONFIRMED",
  DELIVERY_POINT_SELECTED: "DELIVERY_POINT_SELECTED",
  PERSONAL_DETAILS_CONFIRMED: "PERSONAL_DETAILS_CONFIRMED",
  OFFER_CREATED: "OFFER_CREATED",
  VEHICLE_ORDER_CONFIRMATION_BY_CUSTOMER:
    "VEHICLE_ORDER_CONFIRMATION_BY_CUSTOMER",
  REDIRECT_PAYMENT: "REDIRECT_PAYMENT",
  FINAL_ORDER_CONFIRMATION: "FINAL_ORDER_CONFIRMATION",
  REFRESH_OFFER_DATA: "REFRESH_OFFER_DATA",
  CONFIGURATION_SAVED_BY_USER: "CONFIGURATION_SAVED_BY_USER",
  ORDER_CREATED: "ORDER_CREATED",
  ORDER_CANCELED: "ORDER_CANCELED",
};

const CTA_SOL_TYPES_ORDER = {
  [CTA_SOL_TYPES.VEHICLE_SELECTED]: 1,
  [CTA_SOL_TYPES.VEHICLE_CONFIRMED]: 2,
  [CTA_SOL_TYPES.DELIVERY_POINT_SELECTED]: 3,
  [CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED]: 4,
  [CTA_SOL_TYPES.OFFER_CREATED]: 5,
  [CTA_SOL_TYPES.VEHICLE_ORDER_CONFIRMATION_BY_CUSTOMER]: 6,
  [CTA_SOL_TYPES.REDIRECT_PAYMENT]: 7,
  [CTA_SOL_TYPES.FINAL_ORDER_CONFIRMATION]: 8,
  [CTA_SOL_TYPES.REFRESH_OFFER_DATA]: 99,
  [CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER]: 99,
};

const CTA_TD_TYPES = {
  LOCATION_SELECTED: "LOCATION_SELECTED",
  DATE_SELECTED: "DATE_SELECTED",
  CUSTOMER_COMPLETED: "CUSTOMER_COMPLETED_TEST_DRIVE",
  RESERVATION_COMPLETED: "RESERVATION_COMPLETED",
};

const JOURNEY_TYPE = {
  CASH: "cash",
  LOA: "loa",
  LLD: "finance",
  VAC: "vac",
  SCF: "scf",
};

const DEFAULT_JOURNEY_TYPE = JOURNEY_TYPE.LLD;

const FINANCE_JOURNEYS = Object.values(JOURNEY_TYPE).filter(
  j => j !== JOURNEY_TYPE.CASH
);

const FINANCE_BANK_STATUS = {
  CONFIRMED: "confirmed",
  TEMPORARY_APPROVAL: "temporary_approval",
  FINAL_APPROVAL: "final_approval",
  PENDING: "pending",
  REFUSED: "refused",
  CANCELED: "canceled",
};

const FINANCE_FILE_STATUS = {
  ACCEPTED: "4",
  PENDING: "3",
  REFUSED: "5",
  CANCELED: "6",
};

const CACHE_NAMESPACES = {
  CONFIGURATION: "cache:{country}:configuration",
  SERVER_CONFIGURATION: "cache:{country}:serverConfiguration",
  SITEMAP: "cache:{country}:sitemap",
  APP_DATA: "cache:{country}:{language}:appData",
  INIT_APP_CONFIG: "cache:{country}:initAppConfig",
  VEHICLE: "cache:{country}:vehicle",
  FINANCE_GATEWAY_FINANCE_DETAIL: "cache:financeGateway:financeDetail:{hash}",
  FINANCE_GATEWAY_VEHICLE_SIMULATION:
    "cache:financeGateway:vehicleSimulation:{hash}",
  FINANCE_GATEWAY_ACCESORIES_INCREASE:
    "cache:financeGateway:accessoriesIncrease:{hash}",

  FINANCE_GATEWAY: "finance-gateway",
  FINANCE_GATEWAY_ACCESSORIES_INCREASE: "finance-gateway:accessories-increase",
  FINANCE_GATEWAY_VEHICLE_SIMULATION_DETAILS:
    "finance-gateway:vehicle-simulation-details",
  FINANCE_GATEWAY_ELASTIC_DATA: "finance-gateway:elastic-data",

  // TODO SCF: should the santander service be using the cache service?
  SANTANDER_SIMULATION: "cache:santander:simulation:{hash}",

  FRONTEND_INITIAL_DATA: "frontend-initial-data",
  FILES: "files",
  TEST_DRIVE_INITIAL_DATA: "test-drive-initial-data",
  TEST_DRIVE_LOCATIONS: "test-drive-locations",
  HOME_TEST_DRIVE_LOCATIONS: "home-test-drive-locations",
  TEST_DRIVE_TYPES: "test-drive-types",
  TEST_DRIVE_VEHICLES: "test-drive-vehicles",
  DIMENSIONS: "dimensions:{host}",
  ROUTES: "routes:{country}:{brand}",
};

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

const KOA_SESSION_REDIS_PREFIX = "koa:sess:";
const KOA_SESSION_COOKIE_ID = "o2c.sid";
const KOA_SESSION_CSRF_COOKIE_ID = "o2c.csrf";
const KOA_BANNER_COOKIE_ID = "o2c.banner";
const KOA_LANGUAGE_COOKIE_ID = "o2c.language";
const KOA_PARTIAL_SESSION_REDIS_PREFIX = "koa:partialSess:";
const KOA_USER_PROFILE_SESSION_REDIS_PREFIX = "koa:userProfile:";
const KOA_PARTIAL_SESSION_REDIS_TTL = ONE_DAY_IN_SECONDS * 30;
const KOA_USER_PROFILE_SESSION_REDIS_TTL = ONE_DAY_IN_SECONDS;

const DEAL_TYPE = {
  DEAL: "deal",
  TEST_DRIVE: "testDrive",
};

const UPDATE_SOURCE = {
  CONFIGURATOR_SAVE_MY_CAR: "CONFIGURATOR_SAVE_MY_CAR",
};

const DEAL_ORIGIN = {
  NOT_YET_DETERMINED: "not_yet_determined",
  TEST_DRIVE: "test_drive",
  SELLING_ONLINE: "selling_online",
};

const MOUNTING_TYPES = {
  DIY: "DIY",
  BY_GEFCO: "By Gefco",
};

const CLIENT_COUNTRY_HEADER_KEY = "x-client-country";
const CLIENT_LANGUAGE_HEADER_KEY = "x-client-language";
const CLIENT_BRAND_HEADER_KEY = "x-client-brand";

const TEST_DRIVE_TYPE_ID = {
  F2M: "TDT01",
  FNAC_DARTY: "TDT02",
  STORE: "TDT03",
  LAMAISON: "TDT04",
  HOME: "TEST_DRIVE_HOME",
  SUPER: "TDT05",
  MEDIAMARKT: "TDT06",
};

const LP_DEMOOZ_URL = "https://lp.demooz.com/citroen/";

const TEST_DRIVE_HOME_LABEL = "Essai à domicile";
const TEST_DRIVE_HOME_MAIL_NAME = "Essai à votre domicile";

const LAST_STEP_CODES = {
  JOURNEY_CREATED: 500,
  TEST_DRIVE: 500,
  DELIVERY_METHOD_LOCATION_VALIDATION: 1210,
  USER_DETAILS_RECAP_PAGE_DISLAY: 1215,
  VEHICLE_REGISTRATION_NEW_SECTION_DISPLAY: 1230,
  ORDER_SUMMARY_PAGE_DISPLAY: 1300,
  EPAYMENT_REDIRECT: 1400,
  EPAYMENT_DECLINED: 1500,
  EPAYMENT_ACCEPTED: 2000,
  EPAYMENT_ASYNCHRONOUS_ACCEPTED: 2100,
  FINANCE_CHECKOUT: 1410,
  FINANCE_DECLINED: 1510,
  FINANCE_TEMPORARY_APPROVAL: 1450,
  FINANCE_DEFINITIVE_APPROVAL: 2010,
  CONFIRMATION_PAGE: 2200,
};

const MOP_ORDER_STATUS_CODES = {
  IN_PROGRESS: "IN_PROGRESS",
  DISTRIBUTION_SI_ORDER_TODO: "DISTRIBUTION_SI_ORDER_TODO",
  CANCELLED_BEFORE_DISTRIBUTION_SI: "CANCELLED_BEFORE_DISTRIBUTION_SI",
  CANCELLED_AFTER_DISTRIBUTION_SI: "CANCELLED_AFTER_DISTRIBUTION_SI",
  CANCELLED_BEFORE_PAYMENT: "CANCELLED_BEFORE_PAYMENT",
  CANCELLED_AFTER_PAYMENT: "CANCELLED_AFTER_PAYMENT",
  PAYMENT_OK: "PAYMENT_OK",
};

const MOP_MAIL_STATUS_CODES = {
  O2C_VN_FULL_BANKTRANSFER_DONE: "O2C_VN_FULL_BANKTRANSFER_DONE",
};

const O2C_MAIL_PROVIDER = {
  NEOLANE: "neolane",
};

const O2C_MAIL_TEMPLATE_NAMES = {
  TD_CONFIRMATION_TO_CUSTOMER: "O2CTestDrive",
  TD_CONFIRMATION_TO_LOCATION: "O2CTestDriveLocation",
  CASH_ORDER_CONFIRMATION: "O2CCashOrder",
  B2B_ORDER_CONFIRMATION: "O2CB2BCashOrder",
  CASH_DEPOSIT_REFUSAL: "O2CDepositRefusal",
  FINANCE_WITH_DEPOSIT_PAYMENT: "O2CPaymentDeposit",
  FINANCE_ORDER_CONFIRMATION: "O2CLLDFinalOrderConfirmation",
  B2B_FINANCE_ORDER_CONFIRMATION: "O2CB2BLLDFinalOrderConfirmation",
  B2B_LOA_FINANCE_ORDER_CONFIRMATION: "O2CB2BFinalOrderConfirmation",
  SHARE_TERMS_DOCUMENTS: "O2CTermsAndConditions",
  SHARE_CONFIGURATION: "O2CShareBasket",
  BALANCE_OR_DOWN_PAYMENT_CONFIRMATION: "O2CSAVirTotalArrive",
};

const O2C_MAIL_CTX = {
  ORDER_NUMBER: "order_number",
  ORDER_ID: "order_id",
};

const DEAL_STATES = {
  OFFER: "offer",
  DEAL: "deal",
  ORDER: "order",
};

const FINANCE_PRESCORE_GREEN = "vert";

const BUSINESS_MODELS = {
  B2B: "b2b",
  B2C: "b2c",
};

const BUSINESS_MODELS_TARGET_KEYS = {
  B2B: "b2B",
  B2C: "b2C",
};

const PERSONAL_DETAIL_PROPERTY = {
  PERSONAL_DETAIL: "personalDetail",
  COMPANY_DETAIL: "companyDetail",
  DELIVERY_ADDRESS: "deliveryAddress",
  REGISTRATION_ADDRESS: "registrationAddress",
  BILLING_ADDRESS: "billingAddress",
};

const DELIVERY_METHOD = {
  HOME: "home",
  DELIVERY_POINT: "delivery-points",
  SALESMAN_POINT: "salesman-delivery-points",
};

const GTM_TYPES = {
  SHOP: "shop",
};

const TEST_DRIVE_TYPE_KEY = {
  F2M: "F2M",
  FNAC_DARTY: "FNAC_DARTY",
  STORE: "Store",
  LAMAISON: "LaMaison",
  HOME: "Domicile",
  SUPER: "Super_Fan",
  MEDIAMARKT: "MediaMarkt",
};

const TEST_DRIVE_TYPES = [
  {
    key: TEST_DRIVE_TYPE_KEY.STORE,
    id: TEST_DRIVE_TYPE_ID.STORE,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.F2M,
    id: TEST_DRIVE_TYPE_ID.F2M,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.FNAC_DARTY,
    id: TEST_DRIVE_TYPE_ID.FNAC_DARTY,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.LAMAISON,
    id: TEST_DRIVE_TYPE_ID.LAMAISON,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.HOME,
    id: TEST_DRIVE_TYPE_ID.HOME,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.SUPER,
    id: TEST_DRIVE_TYPE_ID.SUPER,
  },
  {
    key: TEST_DRIVE_TYPE_KEY.MEDIAMARKT,
    id: TEST_DRIVE_TYPE_ID.MEDIAMARKT,
  },
];

const TEST_DRIVE_POINT_STORE_TYPE = {
  DARTY: "Darty",
  FNAC: "Fnac",
  HOME: "HOME",
  F2M: "F2M",
  SUPER: "Super",
  MEDIAMARKT: "Mediamarkt",
};

const QUERY_ACTION_TYPES = {
  SHARE_CONFIGURATION: "shareConfiguration",
};

const PAYMENT_STATUS = {
  ACCEPTED: "accepted",
  COMPLETED: "completed",
  DECLINED: "declined",
  FAILED: "failed",
  CANCELLED: "cancelled",
  PENDING: "pending",
  REJECTED: "rejected",
};

const PAYMENT_CALLBACK_STATUS = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
  FAILED: "failed",
  CANCELLED: "cancelled",
  PENDING: "pending",
  REJECTED: "rejected",
};

//handles animation glitch at Delivery.js
const COLLAPSIBLE_ANIM_TIMER = {
  //in miliseconds
  resetToScrollTimeout: 350,
  //in SECONDS
  collapseDuration: 0.35,
};

const DEAL_TYPES_STATUSES = {
  // chronological order
  JOURNEY_CREATED: "journey_created",
  CAR_COMPLETED: "car_completed",
  CUSTOMER_COMPLETED: "customer_completed",
  DEALER_COMPLETED: "dealer_completed",
  LAST_ATTEMPT: "last_attempt",
  RESERVATION_COMPLETED: "reservation_completed",
  VEHICLE_ORDERED: "vehicle_ordered",
  REFUND_REQUESTED: "refund_requested",
  REFUND_COMPLETED: "refund_completed",
  PAYMENT_ACCEPTED: "payment_accepted",
  PAYMENT_PENDING: "payment_pending",
  PAYMENT_FAILED: "payment_failed",
  FINAL_ORDER_CONFIRMATION: "final_order_confirmation",
};

const ORDER_STATUS = {
  RECEIVED: "vehicle_ordered",
  PAYMENT: "payment",
  CONFIRMED: "final_order_confirmation",
  CAR_READY: "car_completed",
  // we don't know yet what exactly belong to this status
  CAR_HANDED_OVER: "dealer_completed",
  CANCELED: "order_canceled",
};

const PROGRESS_BAR_STAGES = [
  {
    title: "progressBar.isSummaryOpen",
    lastCta: [CTA_SOL_TYPES.VEHICLE_SELECTED],
  },
  {
    title: "progressBar.isDeliveryOpen",
    lastCta: [
      CTA_SOL_TYPES.VEHICLE_CONFIRMED,
      DEAL_TYPES_STATUSES.DEALER_COMPLETED,
    ],
  },
  {
    title: "progressBar.isPersonalInformationOpen",
    lastCta: [CTA_SOL_TYPES.DELIVERY_POINT_SELECTED],
  },
  {
    title: "progressBar.isVehicleRegistrationOpen",
    lastCta: [CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED],
  },
  {
    title: "progressBar.recap",
    lastCta: [
      CTA_SOL_TYPES.OFFER_CREATED,
      CTA_SOL_TYPES.REDIRECT_PAYMENT,
      CTA_SOL_TYPES.REFRESH_OFFER_DATA,
    ],
  },
];

const REFERER_TYPES = {
  DARTY: "darty",
  FNAC: "fnac",
};

const REQUEST_ERROR_CODES = {
  // Commons
  INVALID_TOKEN: "INVALID_TOKEN",
  NO_PERMISSIONS_TO_ACCESS_RESOURCE: "NO_PERMISSIONS_TO_ACCESS_RESOURCE",
  UNAUTHORIZED: "UNAUTHORIZED",
  FORBIDDEN: "FORBIDDEN",
  NOT_FOUND: "NOT_FOUND",
  CONFLICT: "CONFLICT",
  NOT_ALLOWED_VALUE_USER_ORIGIN: "NOT_ALLOWED_VALUE_USER_ORIGIN",

  // Frontend specific
  FINANCE_WIDGET_RESET: "FINANCE_WIDGET_RESET",
  PRECONFIGURATION_UNAVAILABLE: "PRECONFIGURATION_UNAVAILABLE",
  OPTIONS_UNAVAILABLE: "OPTIONS_UNAVAILABLE",
  CASH_PRICE_CHANGED: "CASH_PRICE_CHANGED",
  FINANCE_PRICE_CHANGED: "FINANCE_PRICE_CHANGED",
  LEAD_TIME_CHANGED: "LEAD_TIME_CHANGED",
  DELIVERY_METHOD_UNAVAILABLE: "DELIVERY_METHOD_UNAVAILABLE",

  // Testing
  TESTING_DISABLED_ON_PROD: "TESTING_DISABLED_ON_PROD",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  INVALID_QUERY_PARAMS_PROVIDED: "INVALID_QUERY_PARAMS_PROVIDED",

  // Leads
  LEAD_IS_IN_RESPECTIVE_STATE_ALREADY: "LEAD_IS_IN_RESPECTIVE_STATE_ALREADY",
  DELIVERY_POINT_UNAVAILABLE: "DELIVERY_POINT_IS_UNAVAILABLE",
  INVALID_REQUEST_QUERY: "INVALID_REQUEST_QUERY",
  FAILED_TO_REDIRECT_TO_ORDER_COMPLETE: "FAILED_TO_REDIRECT_TO_ORDER_COMPLETE",
  LEAD_SERVICE_ERROR: "LEAD_SERVICE_ERROR",

  // CSVs
  FAILED_TO_LIST_CSV_FILES_FOR_COUNTRY: "FAILED_TO_LIST_CSV_FILES_FOR_COUNTRY",
  FAILED_TO_GET_STAGED_CSV_FILES_FOR_COUNTRY:
    "FAILED_TO_GET_STAGED_CSV_FILES_FOR_COUNTRY",
  FAILED_TO_GET_CSV_FROM_SERVER: "FAILED_TO_GET_CSV_FROM_SERVER",
  FAILED_TO_GET_CSV_BY_FOLDER: "FAILED_TO_GET_CSV_BY_FOLDER",
  FAILED_TO_GET_UPLOAD_HISTORY: "FAILED_TO_GET_UPLOAD_HISTORY",
  FAILED_TO_GET_UPLOAD_FILES: "FAILED_TO_GET_UPLOAD_FILES",
  FAILED_TO_DELETE_FILE: "FAILED_TO_DELETE_FILE",
  FAILED_TO_RUN_IMPORTS_FOR_COUNTRY: "FAILED_TO_RUN_IMPORTS_FOR_COUNTRY",
  INVALID_X_SECRET_HEADER: "INVALID_X_SECRET_HEADER",
  INVALID_CSRF_TOKEN: "INVALID_CSRF_TOKEN",

  // SFTP
  FAILED_TO_CONNECT_TO_SFTP: "FAILED_TO_CONNECT_TO_SFTP",

  // Free2Move
  FAILED_TO_GET_FREE2MOVE_VEHICLES: "FAILED_TO_GET_FREE2MOVE_VEHICLES",
  FAILED_TO_GET_FREE2MOVE_VEHICLE: "FAILED_TO_GET_FREE2MOVE_VEHICLE",

  // FORMS API
  FAILED_TO_CREATE_FORMS_LEAD: "FAILED_TO_CREATE_FORMS_LEAD",

  // Lead state machine
  INVALID_DEAL_STATE_TRANSITION: "INVALID_DEAL_STATE_TRANSITION",

  // Brand ID
  FAILED_TO_POST_BRAND_ID_ACCESS_TOKEN: "FAILED_TO_POST_BRAND_ID_ACCESS_TOKEN",
  FAILED_TO_GET_BRAND_ID_TOKEN: "FAILED_TO_GET_BRAND_ID_TOKEN",
  MISSING_BRANDID_TOKEN: "MISSING_BRANDID_TOKEN",
  FAILED_TO_GET_BRAND_ID_ACCESS_TOKEN: "FAILED_TO_GET_BRAND_ID_ACCESS_TOKEN",
  GET_ACCESS_TOKEN_SERVER_RETURNED_NO_DATA:
    "GET_ACCESS_TOKEN_SERVER_RETURNED_NO_DATA",
  GET_ACCESS_TOKEN_RETURN_CODE_NOT_OK: "GET_ACCESS_TOKEN_RETURN_CODE_NOT_OK",
  FAILED_TO_GET_BRAND_ID_CUSTOMER_DATA: "FAILED_TO_GET_BRAND_ID_CUSTOMER_DATA",
  BRAND_ID_ERROR_LANDING_PAGE: "BRAND_ID_ERROR_LANDING_PAGE",
  FAILED_TO_GET_BRAND_ID_SESSION: "FAILED_TO_GET_BRAND_ID_SESSION",
  FAILED_TO_VALIDATE_BRAND_ID_TOKEN: "FAILED_TO_VALIDATE_BRAND_ID_TOKEN",
  NO_LEAD_TOKEN_IN_SESSION: "NO_LEAD_TOKEN_IN_SESSION",
  FAILED_TO_UPDATE_BRAND_ID_DATA: "FAILED_TO_UPDATE_BRAND_ID_DATA",

  // E-Payment
  LEAD_CONTAINS_INVALID_JWT_TOKEN: "LEAD_CONTAINS_INVALID_JWT_TOKEN",
  UNKNOWN_PAYMENT_CALLBACK_STATUS: "UNKNOWN_PAYMENT_CALLBACK_STATUS",
  EPAYMENT_ORDER_ALREADY_PAID: "EPAYMENT_ORDER_ALREADY_PAID",
  FAILED_TO_RETRIEVE_ORDER_ID: "FAILED_TO_RETRIEVE_ORDER_ID",
  EPAYMENT_PAYMENT_CALLBACK_FAILED: "EPAYMENT_PAYMENT_CALLBACK_FAILED",
  BANK_TRANSFER_PAYMENT_NOT_ALLOWED: "BANK_TRANSFER_PAYMENT_NOT_ALLOWED",
  LEAD_IS_NOT_B2B: "LEAD_IS_NOT_B2B",
  LEAD_IS_ALREADY_ORDER: "LEAD_IS_ALREADY_ORDER",
  FAILED_TO_RETRIEVE_EPAYMENT_STATE: "FAILED_TO_RETRIEVE_EPAYMENT_STATE",
  FAILED_TO_PREPARE_EPAYMENT_ARGUMENTS: "FAILED_TO_PREPARE_EPAYMENT_ARGUMENTS",
  FAILED_TO_GET_EPAYMENT_PARAMETERS: "FAILED_TO_GET_EPAYMENT_PARAMETERS",
  EPAYMENT_REDIRECTION_URL_IS_TOO_LONG: "EPAYMENT_REDIRECTION_URL_IS_TOO_LONG",
  FAILED_TO_CREATE_EPAYMENT_SOAP_CLIENT:
    "FAILED_TO_CREATE_EPAYMENT_SOAP_CLIENT",
  FAILED_TO_GET_REMAINING_AMOUNT_FOR_JOURNEY:
    "FAILED_TO_GET_REMAINING_AMOUNT_FOR_JOURNEY",

  // E-shop order ID
  DELIVERY_METHOD_NOT_INCLUDED: "DELIVERY_METHOD_NOT_INCLUDED",

  // MOP
  FAILED_TO_GET_MOP: "FAILED_TO_GET_MOP",
  FAILED_TO_CREATE_MOP: "FAILED_TO_CREATE_MOP",
  FAILED_TO_UPDATE_MOP: "FAILED_TO_UPDATE_MOP",
  FAILED_TO_ADD_MOP_PAYMENT: "FAILED_TO_ADD_MOP_PAYMENT",
  FAILED_TO_INITIALISE_ADMIN_FILES: "FAILED_TO_INITIALISE_ADMIN_FILES",
  FAILED_TO_UPDATE_ADMIN_FILES: "FAILED_TO_UPDATE_ADMIN_FILES",
  MOP_UPDATE_FAILED: "MOP_UPDATE_FAILED",
  MOP_NOT_FOUND: "MOP_NOT_FOUND",
  MOP_CONNECTION_ERROR: "MOP_CONNECTION_ERROR",

  // Mail
  FAILED_TO_SEND_EMAIL: "FAILED_TO_SEND_EMAIL",
  FAILED_TO_SEND_EMAIL_WITH_DOCUMENTS: "FAILED_TO_SEND_EMAIL_WITH_DOCUMENTS",

  // Validation service
  OBJECT_NOT_FOUND: "OBJECT_NOT_FOUND",
  INVALID_CTA_ACTION: "INVALID_CTA_ACTION_ERROR",
  DELIVERY_METHOD_IS_REQUIRED: "DELIVERY_METHOD_IS_REQUIRED",
  DELIVERY_METHOD_NOT_FOUND: "DELIVERY_METHOD_NOT_FOUND",
  DELIVERY_METHOD_NOT_ENABLED: "DELIVERY_METHOD_NOT_ENABLED",
  DELIVERY_METHOD_MISMATCH: "DELIVERY_METHOD_MISMATCH",
  INVALID_DATA_RECEIVED: "INVALID_DATA_RECEIVED",
  INVALID_CASH_PRICE_ERROR: "INVALID_CASH_PRICE_ERROR",
  INVALID_FINANCE_PRICE_ERROR: "INVALID_FINANCE_PRICE_ERROR",
  INVALID_PRECONFIGURATION_ERROR: "INVALID_PRECONFIGURATION_ERROR",
  INVALID_OPTION_ERROR: "INVALID_OPTION_ERROR",
  INVALID_LEAD_TIME_ERROR: "INVALID_LEAD_TIME_ERROR",
  CASH_PRICE_MISMATCH_ERROR: "CASH_PRICE_MISMATCH_ERROR",

  // Session
  FAILED_TO_PURGE_SESSION: "FAILED_TO_PURGE_SESSION",
  SESSION_NOT_FOUND: "SESSION_NOT_FOUND",
  FAILED_TO_GET_SESSION: "FAILED_TO_GET_SESSION",

  // SVG
  FAILED_TO_GET_LOGO: "FAILED_TO_GET_LOGO",
  FAILED_TO_GENERATE_SVG_LOGO: "FAILED_TO_GENERATE_SVG_LOGO",

  // helpers
  UNKNOWN_PRECONFIGURATION_OPTION: "UNKNOWN_PRECONFIGURATION_OPTION",

  // Parkopoly
  FAILED_TO_CREATE_PARKOPOLY_RESERVATION:
    "FAILED_TO_CREATE_PARKOPOLY_RESERVATION",
  FAILED_TO_GET_PARKOPOLY_AVAILABLE_SLOTS:
    "FAILED_TO_GET_PARKOPOLY_AVAILABLE_SLOTS",
  FAILED_TO_GET_PARKOPOLY_HOME_TD_AVAILABILITY:
    "FAILED_TO_GET_PARKOPOLY_HOME_TD_AVAILABILITY",
  SELECTED_PARKOPOLY_TIME_IS_NOT_AVAILABLE:
    "SELECTED_PARKOPOLY_TIME_IS_NOT_AVAILABLE",

  // Database
  FAILED_TO_GET_OPPORTUNITY_SOURCE: "FAILED_TO_GET_OPPORTUNITY_SOURCE",
  FAILED_TO_GET_OPPORTUNITY: "FAILED_TO_GET_OPPORTUNITY",
  FAILED_TO_GET_OPPORTUNITIES_BY_CUSTOMER_ID:
    "FAILED_TO_GET_OPPORTUNITIES_BY_CUSTOMER_ID",
  FAILED_TO_GET_OPPORTUNITIES_BY_ACCOUNT_ID:
    "FAILED_TO_GET_OPPORTUNITIES_BY_ACCOUNT_ID",
  FAILED_TO_GET_TOP_LEAD_IDS_FOR_CRON_CHECK:
    "FAILED_TO_GET_TOP_LEAD_IDS_FOR_CRON_CHECK",
  FAILED_TO_GET_LEADS_BY_LIST_OF_IDS: "FAILED_TO_GET_LEADS_BY_LIST_OF_IDS",
  FAILED_TO_GET_LEAD_BY_MOP_ID: "FAILED_TO_GET_LEAD_BY_MOP_ID",
  FAILED_TO_GET_LEAD_BY_TRANSACTION_ORDER_ID:
    "FAILED_TO_GET_LEAD_BY_TRANSACTION_ORDER_ID",
  FAILED_TO_UPDATE_OPPORTUNITY_SOURCE: "FAILED_TO_UPDATE_OPPORTUNITY_SOURCE",
  FAILED_TO_GET_LEAD_PROGRESS_STATUS: "FAILED_TO_GET_LEAD_PROGRESS_STATUS",
  FAILED_TO_CREATE_OPPORTUNITY: "FAILED_TO_CREATE_OPPORTUNITY",
  FAILED_TO_CREATE_CUSTOMER: "FAILED_TO_CREATE_CUSTOMER",
  FAILED_TO_CREATE_OPPORTUNITY_SOURCE: "FAILED_TO_CREATE_OPPORTUNITY_SOURCE",
  FAILED_TO_UPDATE_LEAD_WITH_CUSTOMER: "FAILED_TO_UPDATE_LEAD_WITH_CUSTOMER",
  FAILED_TO_SET_OPPORTUNITY_STATUSES: "FAILED_TO_SET_OPPORTUNITY_STATUSES",
  FAILED_TO_ADD_OPPORTUNITY_ACL: "FAILED_TO_ADD_OPPORTUNITY_ACL",
  FAILED_TO_DELETE_OPPORTUNITIES_BY_ACCOUNT_ID:
    "FAILED_TO_DELETE_OPPORTUNITIES_BY_ACCOUNT_ID",
  FAILED_TO_GET_LIST_OF_UNFINISHED_PAYMENTS:
    "FAILED_TO_GET_LIST_OF_UNFINISHED_PAYMENTS",

  // Elastic
  FAILED_TO_GET_SERVER_CONFIGURATION: "FAILED_TO_GET_SERVER_CONFIGURATION",
  FAILED_TO_GET_CONFIGURATION: "FAILED_TO_GET_CONFIGURATION",
  FAILED_TO_GET_VEHICLE: "FAILED_TO_GET_VEHICLE",
  FAILED_TO_GET_TEST_DRIVE_TYPES: "FAILED_TO_GET_TEST_DRIVE_TYPES",
  FAILED_TO_GET_TEST_DRIVE_EXCLUSION_DATES:
    "FAILED_TO_GET_TEST_DRIVE_EXCLUSION_DATES",
  FAILED_TO_GET_UPSELL_ITEMS: "FAILED_TO_GET_UPSELL_ITEMS",
  FAILED_TO_GET_TEST_DRIVE_LOCATIONS: "FAILED_TO_GET_TEST_DRIVE_LOCATIONS",
  FAILED_TO_GET_HOME_TEST_DRIVE_LOCATIONS:
    "FAILED_TO_GET_HOME_TEST_DRIVE_LOCATIONS",
  FAILED_TO_GET_DELIVERY_METHODS: "FAILED_TO_GET_DELIVERY_METHODS",
  FAILED_TO_GET_DELIVERY_POINTS: "FAILED_TO_GET_DELIVERY_POINTS",
  FAILED_TO_GET_TEST_DRIVE_LOCATION_BY_SLUG:
    "FAILED_TO_GET_TEST_DRIVE_LOCATION_BY_SLUG",
  FAILED_TO_DELETE_DUPLICATE_INDEX: "FAILED_TO_DELETE_DUPLICATE_INDEX",
  FAILED_TO_DUPLICATE_INDEX: "FAILED_TO_DUPLICATE_INDEX",
  FAILED_TO_RECOVER_INDEX: "FAILED_TO_RECOVER_INDEX",
  FAILED_TO_CREATE_ELASTIC_BACKUP: "FAILED_TO_CREATE_ELASTIC_BACKUP",
  FAILED_TO_REMOVE_ELASTIC_BACKUP: "FAILED_TO_REMOVE_ELASTIC_BACKUP",
  FAILED_TO_RECOVER_ELASTIC_DATA: "FAILED_TO_RECOVER_ELASTIC_DATA",
  FAILED_TO_GET_ASSETS: "FAILED_TO_GET_ASSETS",
  FAILED_TO_GET_DIMENSIONS: "FAILED_TO_GET_DIMENSIONS",
  FAILED_TO_GET_ROUTES: "FAILED_TO_GET_ROUTES",
  FAILED_TO_GET_DISTRIBUTION_HARBOURS: "FAILED_TO_GET_DISTRIBUTION_HARBOURS",

  // ESB
  FAILED_TO_FETCH_BANK_REDIRECT: "FAILED_TO_FETCH_BANK_REDIRECT",
  FAILED_TO_INITIALIZE_FINANCING_OFFER: "FAILED_TO_INITIALIZE_FINANCING_OFFER",

  // Finance Gateway
  FAILED_TO_FETCH_VEHICLE_SIMULATION: "FAILED_TO_FETCH_VEHICLE_SIMULATION",
  FAILED_TO_FETCH_FINANCE_DETAILS: "FAILED_TO_FETCH_FINANCE_DETAILS",
  FAILED_TO_FETCH_ACCESSORIES_INCREASE: "FAILED_TO_FETCH_ACCESSORIES_INCREASE",

  // Santander
  FAILED_TO_FETCH_SANTANDER_TOKEN: "FAILED_TO_FETCH_SANTANDER_TOKEN",
  FAILED_TO_FETCH_SANTANDER_SIMULATION: "FAILED_TO_FETCH_SANTANDER_SIMULATION",
  FAILED_TO_FETCH_SANTANDER_FINANCE: "FAILED_TO_FETCH_SANTANDER_FINANCE",

  // Customer@
  FAILED_TO_CALL_CUSTOMER_AT_API: "FAILED_TO_CALL_CUSTOMER_AT_API",
};

const UPDATE_STEPS_ERROR_CODES = {
  ECONNABORTED: "ECONNABORTED",
};

const DEAL_STEP_TIMELINE = {
  VEHICLE_CONFIGURED: "carConfigured",
  DELIVERY_METHOD: "deliveryMethod",
  OFFER_CREATED: "offerCreated",
  ORDER_PLACED: "orderPlaced",
};

const STEP_INDEXES = {
  ORDER_SUMMARY: 1,
  PAYMENT: 2,
  DELIVERY: 3,
  PERSONAL_DETAILS: 4,
  VEHICLE_REGISTRATION: 5,
  CONSUMER_RIGHTS: 6,
};

const MY_DEAL_STEP_INDEXES = {
  FINANCE_DETAILS: 2,
  DELIVERY_DETAILS: 3,
  PERSONAL_DETAILS: 4,
  VEHICLE_REGISTRATION: 5,
};

const BRANDID_RESPONSES = {
  OK: "OK",
  BRANDID_TOKEN_EXPIRED: "BRANDID_TOKEN_EXPIRED",
};

const BRANDID_REGISTRATION_TYPES = {
  DELIVERY_POINT: 1,
  HOME_DELIVERY: 2,
  B2B_LOGIN_PAGE: 3,
  B2B_DELIVERY_POINT: 4,
  B2B_HOME_DELIVERY: 5,
};

const BRANDID_PARAMS = {
  ACTIVATE_ACCOUNT: "activate-account",
  CREATE_ACCOUNT: "create-account",
  SUCCESS: "success",
  ERROR: "error",
  DISCONNECTION: "disconnection",
  USER_DENIED: "user_denied",
  CANCEL: "cancel",
  SUBSCRIBE: "subscribe",
};

const CIVILITY_TYPES = {
  MR: "MR",
  MISS: "MISS",
  MRS: "MRS",
};

const VEHICLE_REGISTRATION_METHOD = {
  CITROEN: "Citroen",
  MYSELF: "Myself",
};

const PRECONFIGURATION_CAR_IMAGES = {
  P001: "P001_ami_orange",
  P002: "P002_ami_blue",
  P003: "P003_ami_khaki",
  P004: "P004_ami_grey",
  P005: "P005_ami_pop",
  P006: "P006_ami_new_pop",
  P007: "P007_ami",
  P008: "P008_ami_cargo",
  P009: "P009_ami_buggy",
  P010: "P010_ami_buggy",
  P011: "P011_ami_tonic",
  P012: "P012_ami_new_pop",
  P101: "P101_ami",
  P102: "P102_ami_orange",
  P103: "P103_ami_blue",
  P104: "P104_ami_grey",
  P105: "P105_ami_peps",
  P106: "P106_ami_tonic",
};

const PRECONFIGURATION_CAR_DEFAULT_IMAGE = PRECONFIGURATION_CAR_IMAGES.P002;

const ACCESSORY_IMAGES = {
  DEFAULT: 1676030380, // Legacy fallback (?)
  "1696914080": "1696914080", // Cargo Accessory
};

const ACCESSORY_DEFAULT_IMAGE = ACCESSORY_IMAGES.DEFAULT;

const MOBILE_APP_TYPES = {
  APP_STORE: "App Store",
  GOOGLE_PLAY: "Google play",
};

const REFERRER_TYPES = {
  DARTY: "darty.com",
  FNAC: "fnac.com",
};

const RESERVATION_STATUSES = {
  JOURNEY_CREATED: "journey_created",
  CAR_COMPLETED: "car_completed",
  CUSTOMER_COMPLETED: "customer_completed",
  DEALER_COMPLETED: "dealer_completed",
  LAST_ATTEMPT: "last_attempt",
  RESERVATION_COMPLETED: "reservation_completed",
  VEHICLE_ORDERED: "vehicle_ordered",
  REFUND_REQUESTED: "refund_requested",
  REFUND_COMPLETED: "refund_completed",
  PAYMENT_ACCEPTED: "payment_accepted",
  PAYMENT_PENDING: "payment_pending",
  PAYMENT_FAILED: "payment_failed",
  FINAL_ORDER_CONFIRMATION: "final_order_confirmation",
  ORDER_CANCELED: "order_canceled",
  ORDER_REFUSED: "order_refused",
  INVALID: "invalid",
};

const OFFLINE_PAYMENT = {
  type: {
    bankTransfer: "BANKTRANSFER",
    bankDeposit: "BANKDEPOSIT",
    cardImprint: "PREAUTHORIZATION",
  },
  status: {
    authorised: "AUTHORISED",
    declined: "DECLINED",
    reference: "REFERENCE",
  },
  psaPayId: "BANK_TRANSFER",
  transaction: {
    deposit: "DEPOSIT",
  },
};

// APP-20856 requires UK to have BANK_PAYMENT not BANK_TRANSFER
const OFFLINE_PAYMENT_UK = {
  type: {
    bankTransfer: "BANKPAYMENT",
    bankDeposit: "BANKDEPOSIT",
    cardImprint: "PREAUTHORIZATION",
  },
  status: {
    authorised: "AUTHORISED",
    declined: "DECLINED",
    reference: "REFERENCE",
  },
  psaPayId: "BANK_PAYMENT",
  transaction: {
    deposit: "DEPOSIT",
  },
};

const BANK_TRANSFER_PAYMENT = {
  bankTransfer: "banktransfer",
  balance: "BALANCE",
  down: "DOWN",
  notPaidStatuses: [
    "REFERENCE",
    "AUTHENTICATION FAILED",
    "BLOCKED",
    "DENIED",
    "AUTHORIZED AND PENDING",
    "REFUSED",
    "EXPIRED",
    "CANCELLED",
  ],
};

const MOP_CONSTANTS = {
  extraServices: {
    code: "DEL",
    homeDeliveryLabel: "Home Delivery",
    deliveryPointsLabel: "Delivery",
  },
  costs: {
    registration: {
      code: "regcost",
      label: "registrationCost",
    },
  },
  promotions: {
    cashDiscount: {
      code: "cashDiscount",
      label: "Cash Discount",
    },
  },
  emissions: [
    {
      key: "co2Combined",
      unit: "g/km",
      value: 0,
    },
    {
      key: "co2Class",
      value: "A",
    },
  ],
};

const CAR_GALLERY_360_ICON = [
  "P007",
  "P001",
  "P002",
  "P004",
  "P005",
  "P006",
  "P011",
  "P101",
  "P102",
  "P103",
  "P104",
  "P105",
];

const RESERVATION_STATUS_ORDER = {
  journey_created: 0,
  car_completed: 1,
  customer_completed: 2,
  dealer_completed: 3,
  last_attempt: 4,
  reservation_completed: 5,
  final_order_confirmation: 5,
  refund_requested: 6,
  refund_completed: 7,
  payment_accepted: 0,
  payment_pending: 0,
  payment_failed: 0,
};

const TEST_DRIVE_STATUS = {
  JOURNEY_CREATED: "journey_created",
  LOCATION_SELECTED: "location_selected",
  DATE_SELECTED: "date_selected",
  CUSTOMER_COMPLETED: "customer_completed",
  RESERVATION_COMPLETED: "reservation_completed",
};

const MTOC_COLOR_MAPPING = {
  grey: "NH-797M",
  yellow: "Y-79M",
  white: "NH-883P",
  blue: "B-625M",
  black: "NH-731P",
};

const MOP_DELIVERY_TYPE = {
  HOME: "HOME",
  DEALER: "DEALER",
};

// Delivery type corresponding to selected delivery method
// home = User selected home delivery
// delivery-points = User selected delivery point from the map
const DELIVERY_TYPE = {
  HOME: "home",
  DELIVERY_POINTS: "delivery-points",
  SALESMAN_DELIVERY_POINTS: "salesman-delivery-points",
};

const DELIVERY_TYPE_TEXT = {
  HOME: "Livraison à domicile",
  DELIVERY_POINTS: "Livraison en point retrait",
};

const EMPTY_DELIVERY_METHOD = {
  id: null,
  label: null,
  type: null,
  basePrice: null,
  netPrice: null,
  tax: null,
  country: null,
  language: null,
};

const PAYMENT_AUTHORISED = "AUTHORISED";

const MAIL_STATUSES = {
  SENT: "sent",
  FAILURE_TO_SEND: "failure_to_send",
};

const PARKOPOLY_FIELDS = {
  PLATENUMBER: "Arenseigner",
  TYPE: "VC",
  MISSION_TYPE: "PICKUP",
  BOOKING_CODE_ID: "58e27aa0e4b0c27d4893d016",
  BOOKING_CODE_ID_HOME_TD: "5ef236c24b5c0c77358698bf",
  DATE_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSZ",
};

const PAYMENT_DESCRIPTION_TIMEOUT = "Timeout";

const EPAYMENT_PROCESSING_STATES = {
  OK: "9",
  KO: "91",
  REFUNDED: "8",
  NOT_PAID: "0",
};

const EPAYMENT_OPERATIONS = {
  AUTH: "AUTHORIZATION",
  SALE: "SALE",
};

const EPAYMENT_RESPONSE_TO_NOTIFICATION = {
  success: {
    "ns1:returnCode": "1",
    "ns1:returnLabel": "Success",
  },
};

const EPAYMENT_HISTORY_MESSAGES = {
  NEW_FORM: "Redirecting user to a newly generated e-payment form.",
  ALREADY_EXIST_NOT_TIMED_OUT:
    "E-payment form already exists and its not timed out yet, redirecting user there.",
  ALREADY_EXIST_IS_TIMED_OUT:
    "E-payment form already exists but its already timed out, redirecting user to a newly generated e-payment form.",
  ALREADY_ACCEPTED:
    "The payment is already accepted, no need to check the payment status, redirecting user to the Payment Accepted page.",
  ALREADY_DECLINED:
    "The payment is already declined, no need to check the payment status, redirecting user to the Payment Declined page.",
  ALREADY_DECLINED_NEW_FORM:
    "The payment is already declined, no need to check the payment status, redirecting user to a newly generated e-payment form.",
  ALREADY_DECLINED_RETRY:
    "E-payment was declined, user wants to try again. Redirecting user to a newly generated e-payment form.",
  ALREADY_REJECTED:
    "The payment is already rejected, no need to check the payment status, redirecting user to the Payment Exception page.",
  ALREADY_EXCEPTION_RETRY:
    "E-payment has already exception status,  user wants to try again. Redirecting user to a newly generated e-payment form.",
  FORM_EXIST_ALREADY_ACCEPTED:
    "E-payment form exists, but the payment is already accepted, triggering actions and redirecting user to the Payment Accepted page.",
  FORM_EXIST_ALREADY_DECLINED:
    "E-payment form exists, but the payment is already declined, triggering actions and redirecting user to the Payment Declined page.",
  FORM_EXIST_DIFFERENT_STATUS:
    "E-payment form already exists but the status does NOT equal to ACCEPTED, CANCELED, or NOT_PAID so the user is redirecting to the homepage.",
  RECEIVED_ACCEPTED_STATUS:
    "Received accepted status from E-payment, actions will be triggered accordingly.",
  RECEIVED_DECLINED_STATUS:
    "Received declined status from E-payment, actions will be triggered accordingly.",
  RECEIVED_CANCELED_STATUS:
    "Received canceled status from E-payment, it will be handled as a declined payment and actions will be triggered accordingly.",
  RECEIVED_EXCEPTION_STATUS:
    "Received exception status from E-payment, redirecting user to the Payment Rejected page.",
  RECEIVED_NOT_FOUND_STATUS:
    "Received exception status from E-payment, which was not found.",
  NEW_FORM_DUE_TO_EXCEPTION:
    "Redirecting user to a newly generated e-payment form due to exception which was before.",
};

const FINANCE_RESULT = {
  INVALID_PRESCORE_FINANCE: "INVALID_PRESCORE",
  REDIRECT_TO_FRONTEND: "REDIRECT_TO_FRONTEND",
};

const UNAVAILABLE_ERRORS = {
  FINANCE_CHECKOUT: "finance-checkout",
};

const AVAILABLE_FILES = {
  SERVER_CONFIGURATION: "serverConfiguration.csv",
  TEST_DRIVE_TYPES: "testDriveTypes.csv",
  TEST_DRIVE_LOCATIONS: "testDriveLocations.csv",
  TEST_DRIVE_EXCLUSION_DATES: "testDriveExclusionDates.csv",
  HOME_TEST_DRIVE_LOCATIONS: "homeTestDriveLocations.csv",
  CONFIGURATION: "configuration.csv",
  DELIVERY_METHODS: "deliveryMethods.csv",
  DELIVERY_POINTS: "deliveryPoints.csv",
  SERVICES: "services.csv",
  PRECONFIGURATIONS: "preconfigurations.csv",
  OPTIONS: "options.csv",
  LEAD_TIMES: "leadTime.csv",
  UPSELL_ITEMS: "upsellItems.csv",
  ASSETS: "assets.csv",
  DIMENSIONS: "dimensions.csv",
  ROUTES: "routes.csv",
  VEHICLES: "vehicles.csv",
  DISTRIBUTION_HARBOURS: "distributionHarbours.csv",
};

const FILES_BLACKLIST = [AVAILABLE_FILES.SERVER_CONFIGURATION];

const ALLOWED_ADMIN_REFERERS = [
  "https://o2c-it-dev.summit-automotive.solutions",
  "https://o2c-fr-dev.summit-automotive.solutions",
  "https://o2c-it-qa.summit-automotive.solutions",
  "https://o2c-fr-qa.summit-automotive.solutions",
  "https://o2c-it-preprod.summit-automotive.solutions",
  "https://o2c-fr-preprod.summit-automotive.solutions",
  "https://admin-ami-store.citroen.fr",
  "https://admin-ami-store.citroen.it",
];

const CSV_FILE_WHITELIST = Object.values(AVAILABLE_FILES).filter(item => {
  return !FILES_BLACKLIST.includes(item);
});

const SFTP_AVAILABLE_FOLDERS = {
  CURRENT: "current",
  HISTORY: "history",
  STAGED: "staged",
};

const SECRET_QUERY_PARAMETER = "admin-secret";

const IS_CLIENT = typeof window !== "undefined";

const IS_SERVER = typeof window === "undefined";

const DATE_FORMATS = {
  TO_DAY: "YYYY-MM-DD",
  TO_DAY_CONCISE: "YYYYMMDD",
  TO_MINUTE: "YYYY-MM-DDTHH:mm",
  TO_SECOND: "YYYY-MM-DDTHH:mm:ss",
  TO_SECOND_SLASH: "YYYY/MM/DD HH:mm:ss",
  TO_SECOND_OFFSET: "YYYY-MM-DDTHH:mm:ssZZ",
  TO_MS: "YYYY-MM-DDTHH:mm:00+0000",
};

const USER_FIELDS_NAMES = {
  COMPANY_INFORMATION: {
    COMPANY_NAME: "companyName",
    SIRET_NUMBER: "siretNumber",
    TVA_NUMBER: "TVANumber",
  },
  PERSONAL_INFORMATION: {
    CIVILITY: "civility",
    USER_FIRST_NAME: "userFirstName",
    USER_LAST_NAME: "userLastName",
    PHONE: "phone",
    TIN: "tin",
    EMAIL: "email",
    NAME: "name",
    SURNAME: "surname",
  },
  ADDRESS: {
    PRIMARY_ADDRESS: "address",
    ADDITIONAL_ADDRESS: "additionalAddress",
    CITY: "city",
    PROVINCE: "province",
    POST_CODE: "postCode",
    COUNTRY: "country",
  },
  GUEST_CHECKOUT_ADDRESS: {
    PRIMARY_ADDRESS: "Address1",
    ADDITIONAL_ADDRESS: "AdditionalAddress",
    CITY: "City",
    PROVINCE: "Province",
    POST_CODE: "PostCode",
    POSTAL_CODE: "PostalCode",
    COUNTRY: "Country",
  },
};

const USER_FIELDS = {
  USR_FIRST_NAME: "USR_FIRST_NAME",
  USR_LAST_NAME: "USR_LAST_NAME",
  USR_PHONE_NUMBER: "USR_PHONE_NUMBER",
  USR_BILLING_ADDRESS_CITY: "USR_BILLING_ADDRESS_CITY",
  USR_BILLING_ADDRESS_COUNTRY: "USR_BILLING_ADDRESS_COUNTRY",
  USR_BILLING_ADDRESS_POSTAL_CODE: "USR_BILLING_ADDRESS_POSTAL_CODE",
  USR_BILLING_ADDRESS_STREET: "USR_BILLING_ADDRESS_STREET",
  USR_BILLING_ADDRESS_EXTRA_INFO: "USR_BILLING_ADDRESS_EXTRA_INFO",
  USR_CIVILITY: "USR_CIVILITY",
  USR_DELIVERY_ADDRESS_CITY: "USR_DELIVERY_ADDRESS_CITY",
  USR_DELIVERY_ADDRESS_COUNTRY: "USR_DELIVERY_ADDRESS_COUNTRY",
  USR_DELIVERY_ADDRESS_POSTAL_CODE: "USR_DELIVERY_ADDRESS_POSTAL_CODE",
  USR_DELIVERY_ADDRESS_STREET: "USR_DELIVERY_ADDRESS_STREET",
  USR_DELIVERY_ADDRESS_EXTRA_INFO: "USR_DELIVERY_ADDRESS_EXTRA_INFO",
  USR_PREFERED_LANGUAGE: "USR_PREFERED_LANGUAGE",
  USR_REGISTRATION_ADDRESS_CITY: "USR_REGISTRATION_ADDRESS_CITY",
  USR_REGISTRATION_ADDRESS_COUNTRY: "USR_REGISTRATION_ADDRESS_COUNTRY",
  USR_REGISTRATION_ADDRESS_POSTAL_CODE: "USR_REGISTRATION_ADDRESS_POSTAL_CODE",
  USR_REGISTRATION_ADDRESS_STREET: "USR_REGISTRATION_ADDRESS_STREET",
  USR_REGISTRATION_ADDRESS_EXTRA_INFO: "USR_REGISTRATION_ADDRESS_EXTRA_INFO",
  USR_POSTADDR_COUNTRY: "USR_POSTADDR_COUNTRY",
  USR_POSTADDR_GEO_POINT: "USR_POSTADDR_GEO_POINT",
  USR_POSTADDR_POSTCODE: "USR_POSTADDR_POSTCODE",
  USR_POSTADDR_TOWN: "USR_POSTADDR_TOWN",
  USR_POSTADDR_IS_DEFAULT: "USR_POSTADDR_IS_DEFAULT",
  USR_EMAIL: "USR_EMAIL",
  USR_ACCOUNTID: "USR_ACCOUNTID",
};

const FG_DELIVERY_METHODS_PREFIX = "netTransportFee-";

const GOVERNMENT_CONTRIBUTION = {
  CODE: {
    ECO_BONUS: "ecologicBonus",
    SCRAPPAGE: "scrappage",
  },
};

const PDF_DOCUMENT_TYPES = {
  TERMS_OF_SERVICE: "termsOfService",
  TERMS_OF_WARRANTY: "termsOfWarranty",
  TERMS_OF_ASSISTANCE: "termsOfAssistance",
  TERMS_OF_SALES_CASH: "termsOfSalesCash",
  AMI_CARE_SERVICE: "amiCareService",
  ACCESSIBILITY_DECLARATION: "accessibility",
  TECHNICAL_CHARACTERISTICS: "technicalCharacteristics",
  COMPARISON_OF_PRECONFIGURATIONS: "comparisonOfPreconfiguration",
  TERMS_OF_SALES_LLD: "termsOfSalesLld",
  TERMS_OF_SALES_LOA: "termsOfSalesLoa",
  TERMS_OF_SALES_VAC: "termsOfSalesVac",
  IMPORTANT_INFORMATION: "importantInformation",
  MAINTENANCE_PLAN: "maintenancePlan",
};

const BANK_PROVIDERS = {
  HIPAY: "hipay",
  BANCONTACT: "bancontact",
};

const DEFAULT_TITLE = "MR";

const PROMO_CODE_JOURNEY_TYPES = {
  ALL: "ALL",
  CASH: "CASH",
  FINANCE: "FINANCE",
};

const PROMO_CODE_DISCOUNT_TYPES = {
  ABSOLUTE: "absolute",
  PERCENTAGE: "percentage_catalog",
};

const PROMO_CODE_STATUS = {
  ACTIVE: "ACTIVE",
};

const SESSION_STORAGE_PREFIX = "o2c";
const SESSION_STORAGE_KEYS = {
  PROMOCODE: "promoCode",
};

const CSV_FILE_ERROR_CODES = {
  FILE_NOT_WHITELISTED: 400001,
  NO_SCHEMA_FOUND: 400002,
  INVALID_CSV_CONTENT: 400003,
  FILE_NOT_CSV: 400004,
  ERROR_READING_FILE: 400005,
};

/**
 * Max age of opportunity to be picked up by finance cronjob, value in number of months
 */
const MAX_OPPORTUNITY_AGE = 12;

// Obtained from BE dev
const MYFILES_DOCUMENT_STATUS = {
  NOT_UPLOADED: null,
  PENDING: "UPLOADED",
  WIP: "WIP",
  VALIDATED: "VALIDATED",
  REJECTED: "REJECTED",
};

const MYFILES_CATEGORY_NAMES = {
  MAIN_DOCUMENTS: "MAIN",
};

const MYFILES_CATEGORY_NAME_TRANSLATION = {
  [MYFILES_CATEGORY_NAMES.MAIN_DOCUMENTS]: "mainDocuments",
};

const MYFILES_DOCUMENT_TYPES = {
  CO_OWNER_IDENTITY_CARD: "CO_OWNER_IDENTITY_CARD",
  DELIVERY_DOCUMENT: "DELIVERY_DOCUMENT",
  DRIVING_LICENSE: "DRIVING_LICENSE",
  IDENTITY_CARD: "IDENTITY_CARD",
  OFFICIAL_REGISTRATION_FORM: "OFFICIAL_REGISTRATION_FORM",
  ORDER_FORM: "ORDER_FORM",
  PROOF_OF_ADDRESS: "PROOF_OF_ADDRESS",
  PROVISIONAL_REGISTRATION_DOCUMENT: "PROVISIONAL_REGISTRATION_DOCUMENT",
  QUOTE: "QUOTE",
  REGISTRATION_MANDATE: "REGISTRATION_MANDATE",
};

const MYFILES_DOCUMENT_TYPE_TRANSLATION = {
  [MYFILES_DOCUMENT_TYPES.CO_OWNER_IDENTITY_CARD]: "coOwnerIdentityCard",
  [MYFILES_DOCUMENT_TYPES.DELIVERY_DOCUMENT]: "deliveryDocument",
  [MYFILES_DOCUMENT_TYPES.DRIVING_LICENSE]: "drivingLicense",
  [MYFILES_DOCUMENT_TYPES.IDENTITY_CARD]: "identityCard",
  [MYFILES_DOCUMENT_TYPES.OFFICIAL_REGISTRATION_FORM]:
    "officialRegistrationForm",
  [MYFILES_DOCUMENT_TYPES.ORDER_FORM]: "orderForm",
  [MYFILES_DOCUMENT_TYPES.PROOF_OF_ADDRESS]: "proofOfAddress",
  [MYFILES_DOCUMENT_TYPES.PROVISIONAL_REGISTRATION_DOCUMENT]:
    "provisionalRegistrationDocument",
  [MYFILES_DOCUMENT_TYPES.QUOTE]: "quote",
  [MYFILES_DOCUMENT_TYPES.REGISTRATION_MANDATE]: "registrationMandate",
};

const MYFILES_ALLOWED_FILE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const MYFILES_LOCAL_UPLOAD_PREFIX = "local_";

const MYFILES_MAX_FILE_SIZE = 5; // size in MB

const MYFILES_HINT_TYPES = {
  NONE: null,
  FILE: "file",
  LINK: "link",
  TEXT: "text",
};

const NEW_CLUSTER_ENVS = [
  BUSINESS_ENVIRONMENTS.LOCAL,
  BUSINESS_ENVIRONMENTS.QA,
  BUSINESS_ENVIRONMENTS.PREPROD,
];

// Global E-mail checking regex, that supports google "+" modifiers
const EMAIL_REGEX = /^(?!\.)+(?!.*\.{2})([\w.+-](?!\+{2}))+@([\w-]+\.)+[\w-]+$/;

// Customer@ constants
const CUSTOMER_AT_STATUS_CODES_CUSTOMER_ACTIVATED = ["SIGNED_SUBSCRIBER"];

const CUSTOMER_AT_STATUS_CODES_CUSTOMER_FOUND = [
  ...CUSTOMER_AT_STATUS_CODES_CUSTOMER_ACTIVATED,
  "NOT_ACTIVATED",
  "NOT_INITIALIZED",
];

const CUSTOMER_AT_CONSENT_FIELDS = {
  CONSENT_STLA_DISCLAIMER: "CONSENT_STLA_DISCLAIMER",
  CONSENT_STLA_MARKET: "CONSENT_STLA_MARKET",
  CONSENT_STLA_PROFILING: "CONSENT_STLA_PROFILING",
  CONSENT_STLA_3RD_PART_MARKET: "CONSENT_STLA_3RD_PART_MARKET",
  CONSENT_STLA_3RD_PART_PROFILING: "CONSENT_STLA_3RD_PART_PROFILING",
  PRIVACY_VERSION_CLAUSE: "PRIVACY_VERSION_CLAUSE",
  PRIVACY_DOC_TYPE: "PRIVACY_DOC_TYPE",
  PRIVACY_DOC_NUMBER: "PRIVACY_DOC_NUMBER",
  PRIVACY_COLLECT_DATE: "PRIVACY_COLLECT_DATE",
  PRIVACY_COUNTRY: "PRIVACY_COUNTRY",
  PRIVACY_CONSUMER: "PRIVACY_CONSUMER",
};

const GUEST_CHECKOUT_CONSENTS = [
  "marketing",
  "profiling",
  "thirdPartiesMarketing",
];

const GUEST_CHECKOUT_ADDRESS_PREFIXES = {
  BILLING: "billing",
  DELIVERY: "delivery",
  REGISTRATION: "registration",
};

const THREESIXTY_ROUTER_MIME_TYPES = {
  fallback: "application/octet-stream",
  css: "text/css; charset=UTF-8",
  glb: "model/gltf-binary",
  hdr: "application/octet-stream",
  html: "text/html",
  ico: "vnd.microsoft.icon",
  jpg: "image/jpeg",
  js: "application/javascript",
  json: "application/json",
  md: "text/markdown",
  png: "image/png",
  wasm: "application/wasm",
};

module.exports = {
  IMPORT_SOURCE_TYPES,
  COUNTRIES,
  COUNTRY_CODES,
  LOCALES,
  DAYJS_LOCALES_WEEKDAY_TRANSLATIONS,
  ALL_KEY,
  BRANDS,
  BUSINESS_ENVIRONMENTS,
  BUSINESS_ENVIRONMENTS_API_LAYER_MAPPING,
  NODE_ENVIRONMENTS,
  AVAILABLE_JOURNEY,
  UPDATE_SOURCE,
  DEAL_ORIGIN,
  MOP_CULTURE_TO_LOCALE,
  DEV_COOKIE_DOMAIN,
  CUSTOMIZE_TYPES,
  MOP_CUSTOMIZE_TYPES,
  MOP_GOVERNMENT_CONTRIBUTION_CODES,
  MOP_FINANCIAL_TYPE,
  CTA_SOL_TYPES,
  CTA_TD_TYPES,
  JOURNEY_TYPE,
  DEFAULT_JOURNEY_TYPE,
  FINANCE_JOURNEYS,
  FINANCE_BANK_STATUS,
  FINANCE_FILE_STATUS,
  CACHE_NAMESPACES,
  KOA_SESSION_REDIS_PREFIX,
  KOA_SESSION_COOKIE_ID,
  KOA_SESSION_CSRF_COOKIE_ID,
  DEAL_TYPE,
  CTA_SOL_TYPES_ORDER,
  CLIENT_COUNTRY_HEADER_KEY,
  CLIENT_LANGUAGE_HEADER_KEY,
  CLIENT_BRAND_HEADER_KEY,
  TEST_DRIVE_TYPE_ID,
  KOA_BANNER_COOKIE_ID,
  KOA_LANGUAGE_COOKIE_ID,
  LAST_STEP_CODES,
  MOP_ORDER_STATUS_CODES,
  MOP_MAIL_STATUS_CODES,
  O2C_MAIL_PROVIDER,
  O2C_MAIL_TEMPLATE_NAMES,
  O2C_MAIL_CTX,
  DEAL_STATES,
  FINANCE_PRESCORE_GREEN,
  TEST_DRIVE_HOME_LABEL,
  TEST_DRIVE_HOME_MAIL_NAME,
  BUSINESS_MODELS,
  BUSINESS_MODELS_TARGET_KEYS,
  PERSONAL_DETAIL_PROPERTY,
  DELIVERY_METHOD,
  TEST_DRIVE_TYPE_KEY,
  TEST_DRIVE_TYPES,
  TEST_DRIVE_POINT_STORE_TYPE,
  QUERY_ACTION_TYPES,
  PAYMENT_STATUS,
  PAYMENT_CALLBACK_STATUS,
  COLLAPSIBLE_ANIM_TIMER,
  DEAL_TYPES_STATUSES,
  ORDER_STATUS,
  PROGRESS_BAR_STAGES,
  REFERER_TYPES,
  REQUEST_ERROR_CODES,
  UPDATE_STEPS_ERROR_CODES,
  DEAL_STEP_TIMELINE,
  STEP_INDEXES,
  MY_DEAL_STEP_INDEXES,
  BRANDID_RESPONSES,
  BRANDID_REGISTRATION_TYPES,
  BRANDID_PARAMS,
  CIVILITY_TYPES,
  VEHICLE_REGISTRATION_METHOD,
  PRECONFIGURATION_CAR_IMAGES,
  PRECONFIGURATION_CAR_DEFAULT_IMAGE,
  ACCESSORY_IMAGES,
  ACCESSORY_DEFAULT_IMAGE,
  MOBILE_APP_TYPES,
  REFERRER_TYPES,
  OFFLINE_PAYMENT,
  OFFLINE_PAYMENT_UK,
  BANK_TRANSFER_PAYMENT,
  RESERVATION_STATUSES,
  MOP_CONSTANTS,
  RESERVATION_STATUS_ORDER,
  TEST_DRIVE_STATUS,
  MTOC_COLOR_MAPPING,
  MOP_DELIVERY_TYPE,
  DELIVERY_TYPE,
  DELIVERY_TYPE_TEXT,
  EMPTY_DELIVERY_METHOD,
  PAYMENT_AUTHORISED,
  MAIL_STATUSES,
  PARKOPOLY_FIELDS,
  PAYMENT_DESCRIPTION_TIMEOUT,
  EPAYMENT_OPERATIONS,
  EPAYMENT_PROCESSING_STATES,
  EPAYMENT_RESPONSE_TO_NOTIFICATION,
  EPAYMENT_HISTORY_MESSAGES,
  FINANCE_RESULT,
  UNAVAILABLE_ERRORS,
  AVAILABLE_FILES,
  FILES_BLACKLIST,
  CSV_FILE_WHITELIST,
  ALLOWED_ADMIN_REFERERS,
  SFTP_AVAILABLE_FOLDERS,
  SECRET_QUERY_PARAMETER,
  GTM_TYPES,
  IS_CLIENT,
  IS_SERVER,
  DATE_FORMATS,
  USER_FIELDS_NAMES,
  USER_FIELDS,
  FG_DELIVERY_METHODS_PREFIX,
  GOVERNMENT_CONTRIBUTION,
  MOUNTING_TYPES,
  KOA_PARTIAL_SESSION_REDIS_PREFIX,
  KOA_USER_PROFILE_SESSION_REDIS_PREFIX,
  KOA_USER_PROFILE_SESSION_REDIS_TTL,
  ONE_DAY_IN_SECONDS,
  KOA_PARTIAL_SESSION_REDIS_TTL,
  PDF_DOCUMENT_TYPES,
  DAYJS_LOCALES_MAP,
  LP_DEMOOZ_URL,
  BANK_PROVIDERS,
  DEFAULT_TITLE,
  CAR_GALLERY_360_ICON,
  PROMO_CODE_JOURNEY_TYPES,
  PROMO_CODE_DISCOUNT_TYPES,
  PROMO_CODE_STATUS,
  SESSION_STORAGE_PREFIX,
  SESSION_STORAGE_KEYS,
  CSV_FILE_ERROR_CODES,
  MAX_OPPORTUNITY_AGE,
  MYFILES_DOCUMENT_STATUS,
  MYFILES_DOCUMENT_TYPES,
  MYFILES_DOCUMENT_TYPE_TRANSLATION,
  MYFILES_CATEGORY_NAMES,
  MYFILES_CATEGORY_NAME_TRANSLATION,
  MYFILES_ALLOWED_FILE_MIME_TYPES,
  MYFILES_LOCAL_UPLOAD_PREFIX,
  MYFILES_MAX_FILE_SIZE,
  MYFILES_HINT_TYPES,
  NEW_CLUSTER_ENVS,
  EMAIL_REGEX,
  CUSTOMER_AT_STATUS_CODES_CUSTOMER_ACTIVATED,
  CUSTOMER_AT_STATUS_CODES_CUSTOMER_FOUND,
  CUSTOMER_AT_CONSENT_FIELDS,
  GUEST_CHECKOUT_CONSENTS,
  GUEST_CHECKOUT_ADDRESS_PREFIXES,
  THREESIXTY_ROUTER_MIME_TYPES,
};
