import { produce, current } from "immer";
import rfdc from "rfdc";

import { useCarSelector } from "@redux/reducers/appConfig";
import { priceAlgorithm } from "@lib/priceAlgorithm";

import { useAppConfigSelector } from "@redux/reducers/appConfig";
import { useDealSelector } from "@redux/reducers/deal";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { CUSTOMIZE_TYPES } from "@shared/constants";
import { getLastCtaFromCtaActionsHistory } from "@lib/getLastCtaFromCtaActionsHistory";

const clone = rfdc();

export const useFormattedDeal = () => {
  const reduxDeal = useDealSelector();
  const appConfig = useAppConfigSelector();
  const { technicalCharacteristics } = useCarSelector();

  return deal => {
    if (deal.extendedVehicleInfo) {
      return fromOpencartDealToLocalDeal(deal, reduxDeal, appConfig);
    }

    return produce(deal, draft => {
      draft.carConfiguration.technicalCharacteristics = technicalCharacteristics;
    });
  };
};

const handleFinanceSimulationPriceType = (
  draft,
  deal,
  priceType,
  fallbackValue
) => {
  if (deal.financeSimulation[priceType]) {
    draft.financeSimulation[priceType] = deal.financeSimulation[priceType];
  } else {
    // Backwards compatibility
    draft.financeSimulation[priceType].cash = fallbackValue;
  }
};

export const fromOpencartDealToLocalDeal = (deal, reduxDeal, appConfig) => {
  const clonedDeal = clone(deal);

  return produce(reduxDeal, draft => {
    draft.currentDeal = clonedDeal;
    draft.deliveryDealer = clonedDeal.deliveryDealer || {};
    draft.businessModel = clonedDeal.businessModel;
    draft.isScrappageSelected = clonedDeal.isScrappageSelected ?? false;

    if (clonedDeal.loaPriceBreakdown) {
      draft.loaPriceBreakdown = clonedDeal.loaPriceBreakdown;
    }

    if (clonedDeal.lldPriceBreakdown) {
      draft.lldPriceBreakdown = clonedDeal.lldPriceBreakdown;
    }

    // Just for backward compatibility
    if (clonedDeal.vehicleSimulation && !clonedDeal.lldPriceBreakdown) {
      delete draft.lldPriceBreakdown;

      draft.vehicleSimulation = clonedDeal.vehicleSimulation;
    }

    if (clonedDeal.vacPriceBreakdown) {
      draft.vacPriceBreakdown = clonedDeal.vacPriceBreakdown;
    }

    if (clonedDeal.scfPriceBreakdown) {
      draft.scfPriceBreakdown = clonedDeal.scfPriceBreakdown;
    }

    const carConfigurationBase = priceAlgorithm.getPrice(
      deal.extendedVehicleInfo.preconfiguration.vehicle?.netPrice ??
        clonedDeal.extendedVehicleInfo.netPrice,

      appConfig.configuration.app.solJourney.taxPercentage
    );

    draft.financeSimulation.journey = clonedDeal.financeSimulation.paymentType;

    const defaultPrice = priceAlgorithm.getDefaultPrice(
      current(draft),
      appConfig
    );

    draft.carConfiguration = produce(carConfigurationBase, draft => {
      draft.preconfiguration = produce(
        clonedDeal.extendedVehicleInfo.preconfiguration,
        preconfigurationDraft => {
          const fallbackVehicle = {
            language: clonedDeal.language,
            lcdvCode: clonedDeal.extendedVehicleInfo.idVersion,
            label: clonedDeal.extendedVehicleInfo.modelLabel,
            netPrice: clonedDeal.extendedVehicleInfo.netPrice,
          };

          preconfigurationDraft.vehicle =
            clonedDeal.extendedVehicleInfo.preconfiguration.vehicle ??
            fallbackVehicle;
        }
      );
      draft.promo = appConfig.configuration.promoAmount;
      draft.priceAfterPromo = defaultPrice.netPrice;

      draft.accessory = clonedDeal.extendedVehicleInfo.options.filter(
        ({ type }) => type === CUSTOMIZE_TYPES.ACCESSORY
      );

      draft.service = clonedDeal.extendedVehicleInfo.services;

      draft.options = clonedDeal.extendedVehicleInfo.options.filter(
        ({ type }) =>
          type !== CUSTOMIZE_TYPES.SERVICE && type !== CUSTOMIZE_TYPES.ACCESSORY
      );

      draft.combinedOptions =
        clonedDeal.extendedVehicleInfo?.combinedOptions?.filter(
          ({ type }) => type === CUSTOMIZE_TYPES.PACK
        ) || [];
    });

    draft.isEcoBonusEnabled = clonedDeal.isEcoBonusEnabled;

    draft.configuration = clonedDeal.configuration || appConfig.configuration;

    draft.isSelectionConfirmed = true;
    draft.status = clonedDeal.status;
    draft.isSavedByUser = clonedDeal.isSavedByUser;
    draft.salesman = clonedDeal.salesman;
    draft.isPriceLocked =
      clonedDeal.isPriceLocked ??
      !clonedDeal.isFinanceChangeAllowedInOfferState;

    draft.userProfile = produce(clonedDeal.extendedCustomerInfo, draft => {
      draft.userFirstName = clonedDeal.extendedCustomerInfo.name;
      draft.userLastName = clonedDeal.extendedCustomerInfo.surname;

      delete draft.name;
      delete draft.surname;
    });

    draft.financeSimulation.promoCode = clonedDeal.financeSimulation?.promoCode;

    if (getIsCashJourneySelected(clonedDeal.financeSimulation.paymentType)) {
      handleFinanceSimulationPriceType(
        draft,
        clonedDeal,
        "defaultPrice",
        priceAlgorithm.getPrice(
          clonedDeal.financeSimulation.netTotalPricePreconfiguration ||
            clonedDeal.financeSimulation.defaultPrice.cash.netPrice,
          appConfig.configuration.app.solJourney.taxPercentage
        )
      );

      handleFinanceSimulationPriceType(
        draft,
        clonedDeal,
        "vehiclePrice",
        priceAlgorithm.getPrice(
          0,
          appConfig.configuration.app.solJourney.taxPercentage
        )
      );

      handleFinanceSimulationPriceType(
        draft,
        clonedDeal,
        "totalPrice",
        priceAlgorithm.getPrice(
          0,
          appConfig.configuration.app.solJourney.taxPercentage
        )
      );

      if (clonedDeal.financeSimulation.deliveryPrice) {
        draft.financeSimulation.deliveryPrice =
          clonedDeal.financeSimulation.deliveryPrice;
      } else {
        // Backwards compatibility, not that its not .deliveryPrice.cash, but just .deliveryPrice
        draft.financeSimulation.deliveryPrice = priceAlgorithm.getPrice(
          clonedDeal.deliveryMethod.netPrice ?? 0,
          appConfig.configuration.app.solJourney.taxPercentage
        );
      }
    }

    draft.financeSimulation.financeWidgetParameters =
      clonedDeal.financeSimulation.financeWidgetParameters;
    draft.financeSimulation.vehicleRegistration =
      clonedDeal.financeSimulation.vehicleRegistration?.type ??
      clonedDeal.financeSimulation.vehicleRegistration;
    draft.financeSimulation.totalPrice =
      clonedDeal.financeSimulation.totalPrice;
    draft.financeSimulation.defaultPrice =
      clonedDeal.financeSimulation.defaultPrice;
    draft.financeSimulation.vehiclePrice =
      clonedDeal.financeSimulation.vehiclePrice;
    draft.offerCreated = clonedDeal.offerCreatedAt;
    draft.clonedDealExpired = clonedDeal.dealExpired;
    draft.gitId = clonedDeal.gitId;

    const { displayBlocks, legalMention } = clonedDeal.financeSimulation;

    draft.financeSimulation.displayBlocks = displayBlocks;
    draft.financeSimulation.legalMention = legalMention;

    // Control for backwards compatibility
    if (clonedDeal.ctaActionsHistory) {
      draft.lastCta = getLastCtaFromCtaActionsHistory(
        clonedDeal.ctaActionsHistory
      );
    }

    delete draft.userProfile.postCode;
  });
};
