import { CTA_SOL_TYPES } from "@shared/constants";

export const getLastCtaFromCtaActionsHistory = ctaActionsHistory => {
  // Ignore CTA's which are not relevant for the frontend
  const filteredCtaActionsHistory = ctaActionsHistory.filter(
    cta =>
      ![
        CTA_SOL_TYPES.REFRESH_OFFER_DATA,
        CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
      ].includes(cta.name)
  );

  if (!filteredCtaActionsHistory.length) {
    return null;
  }

  return filteredCtaActionsHistory.slice(-1)[0].name;
};
