import React, { useState, forwardRef } from "react";
import { node, func, any } from "prop-types";
import { useTranslation } from "@lib/i18n";

import getDebug from "debug";

import Button from "./Button";
import { getIsProductionMode } from "@lib/getIsProductionMode";

import { toast } from "@lib/reactToastify";

const debug = getDebug("app:components:RequestButton");

const RequestButton = forwardRef(
  (
    {
      children,
      triggerRequestCallback,
      onRequestPending,
      onRequestSuccess,
      onRequestError,
      onClick,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const isProduction = getIsProductionMode();

    const [isPending, setIsPending] = useState(false);

    if (onClick) {
      throw new Error("Request button doesn't accept 'onClick' prop.");
    }

    const onClickEvent = async () => {
      setIsPending(true);

      if (onRequestPending) {
        onRequestPending();
      }

      try {
        const requestResult = await triggerRequestCallback();

        if (requestResult === false) {
          setIsPending(false);

          return;
        }

        if (onRequestSuccess) {
          await onRequestSuccess(requestResult);
        }

        setIsPending(false);
      } catch (error) {
        setIsPending(false);

        if (!isProduction) {
          debug(`❌ RequestButton Promise failed\n\n`, error);
        }

        if (!onRequestError) {
          toast.error(
            t("notify.apiDefaultError"),
            {
              toastId: "api-error",
            },
            "Missing Function onRequestError"
          );

          return;
        }

        onRequestError(error);
      }
    };

    return (
      <Button ref={ref} isLoading={isPending} onClick={onClickEvent} {...props}>
        {children}
      </Button>
    );
  }
);

RequestButton.displayName = "RequestButton";

RequestButton.propTypes = {
  children: node.isRequired,
  triggerRequestCallback: func.isRequired,
  onRequestPending: func,
  onRequestSuccess: func,
  onRequestError: func,
  onClick: any,
};

export default RequestButton;
