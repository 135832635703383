import React, { useCallback, useState } from "react";
import styled from "styled-components";

import Button from "@components/shared/Button";

import TextField from "@components/shared/TextField";
import Typography from "@components/shared/Typography";
import CloseIcon from "@components/shared/Icons/Close";

import { IS_CLIENT } from "@shared/constants";
import { getIsProductionMode } from "@lib/getIsProductionMode";
import { font, fontSize, fontWeight } from "@components/shared/utils";
import { useTranslation } from "@lib/i18n";

const DEV_TOOLBAR_ID = "dev-toolbar";

const isProduction = getIsProductionMode();

if (IS_CLIENT && !isProduction) {
  window.showDeveloperToolbar = () => {
    document.getElementById(DEV_TOOLBAR_ID).style.display = "flex";
  };
  window.hideDeveloperToolbar = () => {
    document.getElementById(DEV_TOOLBAR_ID).style.display = "none";
  };
}

const DeveloperToolbarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background-color: #cecece;
  border-top: 2px solid #a7a7a7;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const DeveloperToolbarTitle = styled(Typography)`
  position: relative;
  font-weight: ${fontWeight("bold")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  text-transform: uppercase;
  user-select: none;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  margin-top: 0;
`;

const DeveloperToolbar = () => {
  const [errorMessage, setErrorMessage] = useState("SentryTestError");

  const { t } = useTranslation();

  const closeDeveloperToolbar = () => {
    if (IS_CLIENT) {
      document.getElementById(DEV_TOOLBAR_ID).style.display = "none";
    }
  };

  const throwError = useCallback(() => {
    throw new Error(errorMessage);
  }, [errorMessage]);

  return (
    <DeveloperToolbarContainer id={DEV_TOOLBAR_ID} style={{ display: "none" }}>
      <DeveloperToolbarTitle>Developer toolbar</DeveloperToolbarTitle>
      <StyledCloseIcon
        data-id="developer-toolbar-close-icon"
        aria-label={t("basket.back")}
        width="1.2rem"
        height="1.2rem"
        onClick={closeDeveloperToolbar}
      />
      <StyledTextField
        label={"Error message:"}
        onChange={e => setErrorMessage(e.target.value)}
        value={errorMessage}
        labelStyle={{ marginTop: 0 }}
      />
      <Button onClick={throwError}>Throw error (test Sentry)</Button>
    </DeveloperToolbarContainer>
  );
};

export default DeveloperToolbar;
