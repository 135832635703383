import React, { forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import { string, bool, element, arrayOf, oneOfType } from "prop-types";
import { color, prop, tabletLarge } from "./utils";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${prop("color")};
  color: ${color("white")};
  transition: background-color 0.3s ease-in-out;
  padding: ${({ isWholeWidth }) => (isWholeWidth ? "0" : "0.5rem 1rem")};

  ${tabletLarge`
    padding: 0 2rem;
  `}
`;

const Wrapper = styled.div`
  max-width: ${props => props.width};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = forwardRef(
  (
    { className, children, width, color, dataId, isWholeWidth, ...props },
    ref
  ) => {
    const theme = useTheme();

    const finalWidth = width ?? theme.contentWidth;
    const finalColor = color ?? theme.colors.amiOrange;

    return (
      <Container
        className={className}
        color={finalColor}
        data-id={dataId ?? ""}
        isWholeWidth={isWholeWidth}
        ref={ref}
        {...props}
      >
        <Wrapper width={finalWidth}>{children}</Wrapper>
      </Container>
    );
  }
);

ContentWrapper.displayName = "ContentWrapper";

ContentWrapper.propTypes = {
  width: string,
  color: string,
  isWholeWidth: bool,
  className: string,
  dataId: string,
  children: oneOfType([arrayOf(element), element]),
};

export default ContentWrapper;
