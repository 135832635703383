import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

export const BRAND_NAME = "citroen";

export const VEHICLE_MODEL_BODY = "1CO2Q1";
export const VEHICLE_MODEL_BODY_LABEL = "AMI";
export const VEHICLE_ENGINE_TYPE = "BEV";
export const VEHICLE_GEARBOX = "automatic";

export const SITE_TYPE_LEVEL_1 = "cpp";
export const SITE_TYPE_LEVEL_2 = "selling online";
export const SITE_TYPE_LEVEL_TEST_DRIVE_2 = "showroom";

export const SITE_OWNER = "central";
export const SITE_TARGET_B2C = "B2C";
export const SITE_TARGET_B2B = "B2B";
export const SITE_FAMILY = "new cars";

export const EVENT_TYPES = {
  UPDATE_VIRTUAL_PATH: "updatevirtualpath",
  UA_EVENT: "uaevent",
};

export const EVENT_CATEGORY_CONTENT = "Content";
export const EVENT_CATEGORY_CONTENT_MAP = "Content::Map";
export const EVENT_CATEGORY_STEP = "Step";
export const EVENT_CATEGORY_HEADER = "Header";
export const EVENT_CATEGORY_FOOTER = "Footer";
export const EVENT_CATEGORY_ECOMMERCE = "Ecommerce";

export const EVENT_ACTION_MORE_INFO = "MoreInfos";
export const EVENT_ACTION_LESS_INFO = "LessInfos";
export const EVENT_ACTION_SELECT = "Select";
export const EVENT_ACTION_DISPLAY = "Display";
export const EVENT_ACTION_CLOSE = "Close";
export const EVENT_ACTION_REDIRECTION_INTERNAL = "Redirection::Internal";
export const EVENT_ACTION_REDIRECTION_EXTERNAL = "Redirection::External";
export const EVENT_ACTION_PURCHASE = "purchase";
export const EVENT_ACTION_DETAIL = "detail";
export const EVENT_CHECKOUT = "checkout";
export const EVENT_CHECKOUT_ACTION = "Checkout";
export const EVENT_CHECKOUT_OPTION = "checkoutOption";
export const EVENT_CHECKOUT_OPTION_ACTION = "Checkout option";
export const EVENT_ADD_TO_CART = "addToCart";
export const EVENT_ADD_TO_CART_ACTION = "Add to Cart";

export const PAGE_CATEGORY_CONFIGURATOR = "cart page";
export const PAGE_CATEGORY_CHECKOUT = "checkout page";
export const PAGE_CATEGORY_TRANSACTION = "transaction page";
export const PAGE_CATEGORY_FORM = "form page";
export const PAGE_CATEGORY_LEAD = "lead page";
export const FORMS_NAME_TESTDRIVE = "test drive";
export const FORMS_LEADTYPE_HOTLEAD = "hot lead";
export const FORMS_LEADTYPE_COLDLEAD = "cold lead";
export const ACTION_DETAIL = "detail";
export const ACTION_PURCHASE = "purchase";
export const MAIN_STEP_DATE = "date";
export const MAIN_STEP_SHOP_DETAILS = "shop details";
export const MAIN_STEP_PERSONAL = "personal details";
export const MAIN_STEP_VERIFICATION = "verification";
export const MAIN_STEP_CONFIRMATION = "confirmation";
export const MAIN_STEP_CONFIGURATION = "configuration";

export const SHARE_DOCUMENTS = { CANCEL: "annuler", SEND: "envoyer" };

export const ORDER_CONSENT = {
  FINANCE_JOURNEY_GUARANTEE: "financeGuarantee",
  CASH_JOURNEY_CONDITIONS: "cashCondition",
  CASH_JOURNEY_SERVICE: "cashService",
  GENERAL_CONSENT: "consent",
  FINANCE_AUTHORIZATION: "financeAuthorization",
};

export const GTM_TESTDRIVE_TYPE = {
  MAISON: "maisoncitroen",
  ENSTORE: "centre-dessai",
};

export const STEPS = {
  BASKET: "basket",
  DELIVERY: "delivery",
  FINANCING: "financing",
  PERSONAL_DETAILS: "personal details",
  REGISTRATION: "registration",
};

export const PAGES = {
  HOME: "/homepage",
  BASKET: "/basket",
  ORDER_SUMMARY: "/ordersummary",
};

export const NEW_CARS = {
  B2B: "B2B new car",
  B2C: "B2C new car",
};

export const MOBILE = "mobile";
export const TABLET = "tablet";
export const DESKTOP = "desktop";

export const DEFAULT_EVENT_DATA_LAYER_OBJECT = {
  event: EVENT_TYPES.UA_EVENT,
};

export const BASIC_UPDATE_URL_DATA_LAYER_OBJECT = {
  event: EVENT_TYPES.UPDATE_VIRTUAL_PATH,
  brand: BRAND_NAME,
  country: COUNTRY.toLowerCase(),
  siteTypeLevel1: SITE_TYPE_LEVEL_1,
  siteTypeLevel2: SITE_TYPE_LEVEL_2,
  siteOwner: SITE_OWNER,
  siteTarget: SITE_TARGET_B2C,
  siteFamily: SITE_FAMILY,
  pageCategory: "",
};

export const VEHICLE_MODEL_UPDATE_URL_DATA_LAYER_OBJECT = {
  ...BASIC_UPDATE_URL_DATA_LAYER_OBJECT,
  vehicleModelBodystyle: VEHICLE_MODEL_BODY,
  vehicleModelBodystyleLabel: VEHICLE_MODEL_BODY_LABEL,
};

export const DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT = {
  ...VEHICLE_MODEL_UPDATE_URL_DATA_LAYER_OBJECT,
  vehicleEngineType: VEHICLE_ENGINE_TYPE,
  vehicleGearboxLabelEN: VEHICLE_GEARBOX,
};

export const FINANCE_STATUS_TYPES = {
  FINAL_APPROVAL: "bankapproval",
  TEMPORARY_APPROVAL: "temporaryapproval",
  PENDING: "pendingapproval",
};
