window.TextEncoder = function TextEncoder() {};

window.TextEncoder.prototype.encode = function(s) {
  const e = new Uint8Array(s.length);
  const length = s.length;

  for (let i = 0; i < length; i += 1) {
    e[i] = s.charCodeAt(i);
  }

  return e;
};

window.TextDecoder = function TextDecoder() {};

window.TextDecoder.prototype.decode = function(arr) {
  let d = "";
  const length = arr.length;

  for (let i = 0; i < length; i += 1) {
    d += String.fromCharCode(arr[i]);
  }

  return d;
};
