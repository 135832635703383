const DefaultPriceAlgorithm = require("./DefaultPriceAlgorithm");
const PriceAlgorithmItaly = require("./PriceAlgorithmItaly");

const { COUNTRIES } = require("../constants");

const getPriceAlgorithm = country => {
  switch (country) {
    case COUNTRIES.IT:
      return new PriceAlgorithmItaly({
        roundingPrecision: 2,
      });

    default:
      return new DefaultPriceAlgorithm({
        roundingPrecision: 2,
      });
  }
};

module.exports = getPriceAlgorithm;
