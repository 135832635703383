import produce from "immer";
import { useSelector } from "react-redux";

import { DEAL_ACTION_TYPES } from "../actions/dealActions";
import { initialDealState, initialUserProfile } from "../initialDealState";

const deal = produce((state = initialDealState, { type, data }) => {
  switch (type) {
    case DEAL_ACTION_TYPES.PATCH_USER_PROFILE:
      state.userProfile = { ...state.userProfile, ...data };
      return state;

    case DEAL_ACTION_TYPES.SET_DELIVERY_DATA:
      state.userProfile.deliveryData = data;
      return state;

    case DEAL_ACTION_TYPES.SET_ACCESSORY_INCREASE:
      state.financeAccessoriesIncrease = data;
      return state;

    case DEAL_ACTION_TYPES.SET_FINANCE_DELIVERY_PRICES:
      state.financeDeliveryPrices = data;
      return state;

    case DEAL_ACTION_TYPES.SET_DELIVERY_CONTACT_DATA:
      state.userProfile.deliveryContact = data;
      return state;

    case DEAL_ACTION_TYPES.UPDATE_DELIVERY_CONTACT_DATA:
      state.userProfile.deliveryContact = data;
      return state;

    case DEAL_ACTION_TYPES.SET_DELIVERY_DEALER:
      state.deliveryDealer = data;
      return state;

    case DEAL_ACTION_TYPES.SET_CURRENT_DEAL:
      state.currentDeal = data;
      return state;

    case DEAL_ACTION_TYPES.SET_JOURNEY:
      state.financeSimulation.journey = data;
      return state;

    case DEAL_ACTION_TYPES.UPDATE_CAR_CONFIGURATION:
      state.carConfiguration = data;

      break;

    case DEAL_ACTION_TYPES.PATCH_CAR_CONFIGURATION:
      state.carConfiguration = {
        ...state.carConfiguration,
        ...data,
      };

      break;

    case DEAL_ACTION_TYPES.SET_VEHICLE_REGISTRATION:
      state.financeSimulation.vehicleRegistration = data;
      return state;

    case DEAL_ACTION_TYPES.UPDATE_CAR_PRECONFIGURATION:
      state.carConfiguration.preconfiguration = data;
      state.isSavedByUser = false;
      break;

    case DEAL_ACTION_TYPES.SET_DEAL_SAVED_BY_USER:
      state.isSavedByUser = data;
      return state;

    case DEAL_ACTION_TYPES.SET_CURRENT_CTA:
      state.lastCta = data;
      break;

    case DEAL_ACTION_TYPES.SET_FINANCE_WIDGET_PARAMETERS:
      state.financeSimulation.financeWidgetParameters = data;
      break;

    case DEAL_ACTION_TYPES.SET_BANK_PROVIDER:
      state.financeSimulation.bankProvider = data;
      break;

    case DEAL_ACTION_TYPES.UPDATE_PRECONFIGURATION_LEAD_TIMES:
      state.carConfiguration.preconfiguration.leadTimes = data;
      break;

    case DEAL_ACTION_TYPES.SET_SALESMAN:
      state.salesman = data;
      break;

    case DEAL_ACTION_TYPES.SET_BUSINESS_MODEL:
      state.businessModel = data;
      break;

    case DEAL_ACTION_TYPES.SET_GIT_ID:
      state.gitId = data;
      break;

    case DEAL_ACTION_TYPES.UPDATE_GIT_ID:
      state.gitId = data;
      break;

    case DEAL_ACTION_TYPES.SET_IS_SELECTION_CONFIRMED:
      state.isSelectionConfirmed = data;
      break;

    case DEAL_ACTION_TYPES.SET_IS_FINANCE_CHANGE_ALLOWED_IN_OFFER_STATE:
      state.isPriceLocked = data;
      break;

    case DEAL_ACTION_TYPES.SET_CAR_PRICE:
      state.carConfiguration.netPrice = data.netPrice;
      state.carConfiguration.basePrice = data.basePrice;
      state.carConfiguration.tax = data.tax;
      state.carConfiguration.promo = data.promo;
      state.carConfiguration.priceAfterPromo = data.priceAfterPromo;
      break;

    case DEAL_ACTION_TYPES.LOGOUT_USER:
      state.userProfile = initialUserProfile;
      break;

    case DEAL_ACTION_TYPES.SET_DEAL: {
      return data;
    }

    case DEAL_ACTION_TYPES.RESET_DEAL: {
      const userProfile = {
        ...state.userProfile,
        deliveryData: { type: null },
      };

      return {
        ...initialDealState,
        businessModel: state.businessModel,
        carConfiguration: state.carConfiguration,
        financeSimulation: state.financeSimulation,
        isScrappageSelected: state.isScrappageSelected,
        isPriceLocked: state.isPriceLocked,
        currentDeal: data ? null : { token: state.currentDeal.token ?? null },
        salesman: state.salesman,
        userProfile,
      };
    }

    case DEAL_ACTION_TYPES.SET_CASH_PRICES:
      state.financeSimulation.totalPrice.cash = data.totalCashPrice;
      state.financeSimulation.defaultPrice.cash = data.defaultCashPrice;
      state.financeSimulation.vehiclePrice.cash = data.vehicleCashPrice;
      break;

    case DEAL_ACTION_TYPES.UPDATE_DELIVERY_PRICE:
      state.financeSimulation.deliveryPrice = data;
      break;

    case DEAL_ACTION_TYPES.SET_CAR_PRECONFIGURATION_FROM_URL:
      state.carConfiguration.preconfiguration = data.preconfiguration;
      state.carConfiguration.accessory = data.accessories;
      state.carConfiguration.service = data.services;
      state.carConfiguration.options = data.options;
      state.carConfiguration.combinedOptions = data.combinedOptions;
      state.financeSimulation.journey = data.journey;

      break;

    case DEAL_ACTION_TYPES.SET_SCRAPPAGE:
      state.isScrappageSelected = data;
      break;

    case DEAL_ACTION_TYPES.SET_LOA_PRICE_BREAKDOWN:
      state.loaPriceBreakdown = data;
      break;

    case DEAL_ACTION_TYPES.SET_LLD_PRICE_BREAKDOWN:
      state.lldPriceBreakdown = data;
      break;

    case DEAL_ACTION_TYPES.SET_VAC_PRICE_BREAKDOWN:
      state.vacPriceBreakdown = data;
      break;

    case DEAL_ACTION_TYPES.SET_SCF_PRICE_BREAKDOWN:
      state.scfPriceBreakdown = data;
      break;

    case DEAL_ACTION_TYPES.SET_VEHICLES_QUANTITY:
      state.quantity = data;
      break;

    case DEAL_ACTION_TYPES.SET_PROMO_CODE:
      state.financeSimulation.promoCode = data;
      break;

    case DEAL_ACTION_TYPES.SET_PROMO_CODE_INVALID:
      state.financeSimulation.promoCode.valid = false;
      break;

    case DEAL_ACTION_TYPES.REMOVE_PROMO_CODE:
      state.financeSimulation.promoCode = false;
      break;

    default:
      return state;
  }
});

// Selectors
export const useDealerSelector = () =>
  useSelector(store => store.deal.deliveryDealer);

export const useDealSelector = () => useSelector(store => store.deal);
export const useCurrentDealSelector = () =>
  useSelector(store => store.deal.currentDeal);

export const useCurrentDealStateSelector = () =>
  useSelector(store => store.deal.currentDeal?.dealState);

export const useCurrentDealBankRedirectUrl = () =>
  useSelector(
    store => store.deal.currentDeal?.financeSimulation?.bankRedirectUrl
  );

export const useUserProfileSelector = () =>
  useSelector(store => store.deal.userProfile);

export const useCarConfigurationSelector = () =>
  useSelector(store => store.deal.carConfiguration);

export const useActiveCarPreconfigurationSelector = () =>
  useSelector(store => store.deal.carConfiguration.preconfiguration);

export const useActiveAccessorySelector = () =>
  useSelector(store => store.deal.carConfiguration.accessory);

export const useTokenSelector = () =>
  useSelector(store => store.deal.currentDeal?.token ?? null);

export const useFinanceSimulationSelector = () =>
  useSelector(store => store.deal.financeSimulation);

export const useFinanceWidgetParametersSelector = () =>
  useSelector(store => store.deal.financeSimulation.financeWidgetParameters);

export const useCarConfigurationCashPrice = () => {
  useSelector(store => store.deal.financeSimulation.netTotalPrice);
};

export const useSalesmanSelector = () =>
  useSelector(store => store.deal.salesman);

export const useGitIdSelector = () => useSelector(store => store.deal.gitId);

export const useIsDealPriceLockedSelector = () =>
  useSelector(store => store.deal.currentDeal?.isPriceLocked);

export const useIsSavedByUserSelector = () =>
  useSelector(store => store.deal.isSavedByUser);

export const useExternalOrderIDSelector = () =>
  useSelector(store => store.deal.currentDeal?.externalOrderId ?? null);

export const useDealDeliveryTypeSelector = () =>
  useSelector(({ deal }) => deal.userProfile.deliveryData?.type);

export const useDealDeliveryMethodSelector = () =>
  useSelector(({ deal }) => deal.userProfile.deliveryData);

export const useDeliveryPriceSelector = () =>
  useSelector(({ deal }) => deal.financeSimulation.deliveryPrice);

export const useFinanceAccessoriesIncreaseSelector = () =>
  useSelector(({ deal }) => deal.financeAccessoriesIncrease);

export const useFinanceDeliveryPricesSelector = () =>
  useSelector(({ deal }) => deal.financeDeliveryPrices || null);

export const useDefaultPriceSelector = () =>
  useSelector(({ deal }) => deal.financeSimulation.defaultPrice);

export const useVehiclePriceSelector = () =>
  useSelector(({ deal }) => deal.financeSimulation.vehiclePrice);

export const useTotalPriceSelector = () =>
  useSelector(({ deal }) => deal.financeSimulation.totalPrice);

export const useLastCtaSelector = () => useSelector(({ deal }) => deal.lastCta);

export const useCurrentDealLanguageSelector = () =>
  useSelector(({ deal }) => deal.currentDeal?.language);

export const useCurrentDealConfigurationSelector = () =>
  useSelector(({ deal }) => deal.currentDeal?.configuration);

export const useIsRefreshingDealSelector = () =>
  useSelector(({ deal }) => deal.isRefreshingDeal);

export const useSelectedJourneySelector = () =>
  useSelector(({ deal }) => deal.financeSimulation.journey);

export const useBusinessModelSelector = () =>
  useSelector(({ deal }) => deal.businessModel);

export const useDeliveryContactSelector = () =>
  useSelector(({ deal }) => deal.userProfile.deliveryContact);

export const useIsScrappageSelectedSelector = () =>
  useSelector(({ deal }) => deal.isScrappageSelected);

export const useLOAPriceBreakdownSelector = () =>
  useSelector(({ deal }) => deal.loaPriceBreakdown);

export const useLLDPriceBreakdownSelector = () =>
  useSelector(({ deal }) => deal.lldPriceBreakdown);

export const useVACPriceBreakdownSelector = () =>
  useSelector(({ deal }) => deal.vacPriceBreakdown);

export const useSCFPriceBreakdownSelector = () =>
  useSelector(({ deal }) => deal.scfPriceBreakdown);

export const useDealVehicleSimulationData = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.teaser.data ?? {}
  );

export const useFinanceDataTotalPriceSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.teaser.data
        .regularPayment.amountInclTax ?? null
  );

export const useLLDAccessoriesIncreaseSelector = () =>
  useSelector(
    ({ deal }) => deal.lldPriceBreakdown?.accessoryIncrease?.results ?? null
  );

export const useVACAccessoriesIncreaseSelector = () =>
  useSelector(
    ({ deal }) => deal.vacPriceBreakdown?.accessoryIncrease?.results ?? null
  );

export const useFinanceDataFirstRentalSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.teaser.data.firstRental
        ?.amountInclTax ?? null
  );

export const useVACDataDepositSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.vacPriceBreakdown?.vehicleSimulation?.result.teaser.data.deposit ??
      null
  );

export const useVACDataDurationSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.vacPriceBreakdown?.vehicleSimulation?.result.teaser.data.duration
  );

export const useFinanceDataDurationSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.teaser.data
        .regularPayment.duration
  );

export const useFinanceLegalMentionSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.globalText
        .legalMention ?? []
  );

export const useFinanceDisplayBlocksSelector = () =>
  useSelector(
    ({ deal }) =>
      deal.lldPriceBreakdown?.vehicleSimulation?.result.displayBlocks ?? []
  );

export const useVehicleQuantitySelector = () =>
  useSelector(({ deal }) => deal.quantity);

export const usePrimaryColorSelector = () =>
  useSelector(
    ({ deal }) => "#" + deal.carConfiguration.preconfiguration.colorHexa
  );

export default deal;
