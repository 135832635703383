import produce from "immer";
import i18next from "i18next";

import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";
import { buildPageName, getTestDriveType } from "../core/utils";

import {
  TESTDRIVE_EVENT_TYPES,
  TESTDRIVE_UPDATE_URL_TYPES,
} from "@gtm/events/testdrive";

import {
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  EVENT_ACTION_DISPLAY,
  EVENT_ACTION_REDIRECTION_INTERNAL,
  EVENT_CATEGORY_CONTENT,
  EVENT_CATEGORY_CONTENT_MAP,
  VEHICLE_MODEL_UPDATE_URL_DATA_LAYER_OBJECT,
  PAGE_CATEGORY_FORM,
  PAGE_CATEGORY_LEAD,
  FORMS_NAME_TESTDRIVE,
  MAIN_STEP_DATE,
  MAIN_STEP_SHOP_DETAILS,
  MAIN_STEP_PERSONAL,
  MAIN_STEP_VERIFICATION,
  FORMS_LEADTYPE_HOTLEAD,
  FORMS_LEADTYPE_COLDLEAD,
  MAIN_STEP_CONFIRMATION,
  SITE_TYPE_LEVEL_TEST_DRIVE_2,
} from "@gtm/constants";

export const testdriveReducerEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case TESTDRIVE_EVENT_TYPES.TESTDRIVE_LINK:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = "Anchor";
        draft.eventLabel = i18next.t("testDrive.typesLink");
        break;

      case TESTDRIVE_EVENT_TYPES.GEOLOCALISATION:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = "Search::Geolocalisation";
        draft.eventLabel = "Icon::Geolocalisation";
        break;

      case TESTDRIVE_EVENT_TYPES.DISPLAY_FILTER:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::Filter`;
        draft.eventLabel = "Icon::Filter";
        break;

      case TESTDRIVE_EVENT_TYPES.FILTER_MAP:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = "Filter::Type";
        draft.eventLabel = data;
        break;

      case TESTDRIVE_EVENT_TYPES.MORE_RESULTS:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = `${EVENT_ACTION_DISPLAY}::MoreResults`;
        draft.eventLabel = i18next.t("testDrive.showRemaining");
        break;

      case TESTDRIVE_EVENT_TYPES.MORE_INFO:
        draft.eventCategory = EVENT_CATEGORY_CONTENT_MAP;
        draft.eventAction = data.action;
        draft.eventLabel = data.label;
        break;

      case TESTDRIVE_EVENT_TYPES.FINAL_STEP_REDIRECT:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_INTERNAL}::AllCars`;
        draft.eventLabel = i18next.t("testDrive.merciSection.buttonText");
        break;

      default:
        return null;
    }
  });

export const testdriveReducerUpdateUrl = ({ type, data }) =>
  produce(VEHICLE_MODEL_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_MAP:
        draft.virtualPageURL = "/test-drive/location-map";
        draft.pageCategory = "dealer location page";
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: false,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SEARCH_RESULTS:
        draft.virtualPageURL = "/test-drive/search-results";
        draft.pageCategory = "search results page";
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });
        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DESSAI_CLICKED:
        draft.virtualPageURL = "/test-drive/centre-dessai/results-page";
        draft.pageCategory = "search results page";
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });
        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_FNAC_CLICKED:
        draft.virtualPageURL = "/test-drive/showroom-fnac-darty/results-page";
        draft.pageCategory = "search results page";
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });
        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_FNAC_DARTY:
        draft.virtualPageURL = "/test-drive/showroom-fnac-darty";
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.formsLeadType = FORMS_LEADTYPE_COLDLEAD;
        draft.formsPostalCode = data.postalCode;
        draft.formsName = FORMS_NAME_TESTDRIVE;
        draft.mainStepIndicator = "1";
        draft.mainStepName = MAIN_STEP_SHOP_DETAILS;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DATE:
        draft.virtualPageURL = `/test-drive/${getTestDriveType(
          data.type
        )}/date`;
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.formsName = FORMS_NAME_TESTDRIVE;
        draft.mainStepIndicator = "1";
        draft.mainStepName = MAIN_STEP_DATE;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DETAILS:
        draft.virtualPageURL = `/test-drive/${getTestDriveType(
          data.type
        )}/personal-details`;
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.formsName = FORMS_NAME_TESTDRIVE;
        draft.mainStepIndicator = "2";
        draft.mainStepName = MAIN_STEP_PERSONAL;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_DETAILS_VERIFICATION:
        draft.virtualPageURL = `/test-drive/${getTestDriveType(
          data.type
        )}/verification`;
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.formsName = FORMS_NAME_TESTDRIVE;
        draft.mainStepIndicator = "3";
        draft.mainStepName = MAIN_STEP_VERIFICATION;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
          showVehicleModel: true,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_CONFIRMATION:
        draft.virtualPageURL = `/test-drive/${getTestDriveType(
          data.type
        )}/confirmation`;
        draft.pageCategory = PAGE_CATEGORY_LEAD;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.formsLeadID = data.gitId;
        draft.formsLeadType = FORMS_LEADTYPE_HOTLEAD;
        draft.formsPostalCode = data.postalCode;
        draft.formsName = FORMS_NAME_TESTDRIVE;
        draft.mainStepIndicator = "4";
        draft.mainStepName = MAIN_STEP_CONFIRMATION;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SELF_SERVICE:
        draft.virtualPageURL = "/test-drive/self-service/results-page";
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          siteTypeLevel2: SITE_TYPE_LEVEL_TEST_DRIVE_2,
          pageTitle: data.pageTitle,
        });
        break;

      case TESTDRIVE_UPDATE_URL_TYPES.TESTDRIVE_SELF_SERVICE_REDIRECT:
        draft.virtualPageURL = "/test-drive/self-service/redirection-f2m";
        draft.pageCategory = PAGE_CATEGORY_FORM;
        draft.siteTypeLevel2 = SITE_TYPE_LEVEL_TEST_DRIVE_2;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });
