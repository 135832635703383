export const REQUESTS_ACTION_TYPES = {
  POST_DEAL_SUCCESS: "POST_DEAL_SUCCESS",
  POST_DEAL_PENDING: "POST_DEAL_PENDING",
  POST_DEAL_FAILURE: "POST_DEAL_FAILURE",
  SESSION_UPDATE_PENDING: "SESSION_UPDATE_PENDING",
  SESSION_UPDATE_FAILURE: "SESSION_UPDATE_FAILURE",
  SESSION_UPDATE_SUCCESS: "SESSION_UPDATE_SUCCESS",
  SET_IS_LOA_PRICE_BREAK_REQUEST_PENDING:
    "SET_IS_LOA_PRICE_BREAK_REQUEST_PENDING",
  SET_IS_LOA_PRICE_BREAK_REQUEST_ERROR: "SET_IS_LOA_PRICE_BREAK_REQUEST_ERROR",
  SET_IS_LOA_PRICE_BREAK_REQUEST_SUCCESS:
    "SET_IS_LOA_PRICE_BREAK_REQUEST_SUCCESS",
  SET_IS_LLD_PRICE_BREAK_REQUEST_PENDING:
    "SET_IS_LLD_PRICE_BREAK_REQUEST_PENDING",
  SET_IS_LLD_PRICE_BREAK_REQUEST_ERROR: "SET_IS_LLD_PRICE_BREAK_REQUEST_ERROR",
  SET_IS_LLD_PRICE_BREAK_REQUEST_SUCCESS:
    "SET_IS_LLD_PRICE_BREAK_REQUEST_SUCCESS",
  SET_IS_VAC_PRICE_BREAK_REQUEST_PENDING:
    "SET_IS_VAC_PRICE_BREAK_REQUEST_PENDING",
  SET_IS_VAC_PRICE_BREAK_REQUEST_ERROR: "SET_IS_VAC_PRICE_BREAK_REQUEST_ERROR",
  SET_IS_VAC_PRICE_BREAK_REQUEST_SUCCESS:
    "SET_IS_VAC_PRICE_BREAK_REQUEST_SUCCESS",
  SET_IS_SCF_PRICE_BREAK_REQUEST_PENDING:
    "SET_IS_SCF_PRICE_BREAK_REQUEST_PENDING",
  SET_IS_SCF_PRICE_BREAK_REQUEST_ERROR: "SET_IS_SCF_PRICE_BREAK_REQUEST_ERROR",
  SET_IS_SCF_PRICE_BREAK_REQUEST_SUCCESS:
    "SET_IS_SCF_PRICE_BREAK_REQUEST_SUCCESS",
};

export const setUpdateStepsRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.POST_DEAL_SUCCESS,
});

export const setUpdateStepsRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.POST_DEAL_PENDING,
});

export const setUpdateStepsRequestFailureAction = () => ({
  type: REQUESTS_ACTION_TYPES.POST_DEAL_FAILURE,
});

export const setSessionPatchRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.SESSION_UPDATE_PENDING,
});

export const setSessionPatchRequestFailureAction = () => ({
  type: REQUESTS_ACTION_TYPES.SESSION_UPDATE_FAILURE,
});

export const setSessionPatchRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.SESSION_UPDATE_SUCCESS,
});

export const setIsLOAPriceBreakRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_PENDING,
});

export const setIsLOAPriceBreakRequestErrorAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_ERROR,
});

export const setIsLOAPriceBreakRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LOA_PRICE_BREAK_REQUEST_SUCCESS,
});

export const setIsVACPriceBreakRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_PENDING,
});

export const setIsVACPriceBreakRequestErrorAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_ERROR,
});

export const setIsVACPriceBreakRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_VAC_PRICE_BREAK_REQUEST_SUCCESS,
});

export const setIsLLDPriceBreakRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_PENDING,
});

export const setIsLLDPriceBreakRequestErrorAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_ERROR,
});

export const setIsLLDPriceBreakRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_LLD_PRICE_BREAK_REQUEST_SUCCESS,
});

export const setIsSCFSimulationRequestPendingAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_PENDING,
});

export const setIsSCFSimulationRequestErrorAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_ERROR,
});

export const setIsSCFSimulationRequestSuccessAction = () => ({
  type: REQUESTS_ACTION_TYPES.SET_IS_SCF_PRICE_BREAK_REQUEST_SUCCESS,
});
