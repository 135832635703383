import React from "react";

import BaseIcon from "../BaseIcon";

const LoadingCircle = ({ ...props }) => {
  return (
    <BaseIcon viewBox="0 0 40 40" {...props}>
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </BaseIcon>
  );
};

export default LoadingCircle;
