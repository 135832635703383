import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { createGlobalStyle } from "styled-components";
import { bool, func, node, string, shape, any } from "prop-types";
import { getConditionallyRequiredProp } from "@lib/getConditionallyRequiredProp";
import { useKeyPress } from "react-use";

import { useIsInitialRender } from "@hooks/useIsInitialRender";
import { color, tablet } from "./utils";
import CloseIcon from "./Icons/Close";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { useTranslation } from "@lib/i18n";
import { useRouter } from "next/router";

// Disable scrolling on body in smaller devices when modal is open
const GlobalScrollPrevent = createGlobalStyle`
  body, body > div {
    ${({ prevent }) => prevent && "overflow-y: hidden;"}
    ${tablet`
      overflow: unset;
    `}
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ModalOverlay = styled.div`
  background-color: ${({ overlayColor }) => color(overlayColor)};
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const ModalContent = styled.div`
  background: ${color("white")};
  border: 1px solid ${color("grey")};
  height: 100%;
  position: fixed;
  padding: 1rem;
  width: 100%;
  z-index: 1000;
  transform: translateZ(0);
  overflow-y: auto;

  ${tablet`
    border-radius: 5px;
    height: auto;
    max-height: 100%;
    max-width: ${({ contentMaxWidth }) => contentMaxWidth};
    width: 80%;

  `}
`;

const WrapperCloseIcon = styled.button`
  width: 16px;
  height: 16px;
  border: none;
  background-color: unset;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 1rem;
`;

const Modal = ({
  ariaLabel,
  children,
  className,
  contentMaxWidth = "40rem",
  disableBackdropClick = false,
  disableEscapePress = false,
  firstElement,
  isOpen,
  lastElement,
  onClose,
  overlayColor = "semiTransparentWhite",
  preventGoBack = false,
  withCloseIcon,
  ...props
}) => {
  const isInitialRender = useIsInitialRender();
  const [isEscapePress] = useKeyPress("Escape");
  const primaryColor = usePrimaryColor();
  const closeIconRef = useRef(null);
  const router = useRouter();
  const { t } = useTranslation();
  const controlKeys = {
    TAB: 9,
  };

  // if CloseIcon is used - it will be the first element for tab navigation
  if (withCloseIcon) {
    firstElement = closeIconRef;
  }
  // if the last element is not set - the first element will be the last for tab navigation
  if (!lastElement) {
    lastElement = firstElement;
  }

  useEffect(() => {
    router.beforePopState(() => {
      if (isOpen && preventGoBack) {
        onClose();
        window.history.pushState(null, document.title, window.location.href);
        return false;
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [isOpen, preventGoBack, router]);

  useEffect(() => {
    if (isOpen) {
      if (firstElement && firstElement.current) firstElement.current.focus();
      document.onkeydown = event => {
        if (event.keyCode === controlKeys.TAB && firstElement && lastElement) {
          if (event.target.tagName === "BODY") {
            // if focus has been lost - set it to firstElement again
            event.preventDefault();
            firstElement.current.focus();
          } else if (event.target.className === "ps__thumb-y") {
            // if scrollbar is focused and TAB is pressed - go to the last element if possible
            if (lastElement.current && !lastElement.current.disabled) {
              lastElement.current.focus();
            } else {
              firstElement.current.focus();
            }
            event.preventDefault();
          } else if (event.target === firstElement.current && event.shiftKey) {
            event.preventDefault();
            lastElement.current.focus();
          } else if (event.target === lastElement.current && !event.shiftKey) {
            event.preventDefault();
            firstElement.current.focus();
          }
        }
      };
    } else {
      document.onkeydown = null;
    }
    return () => (document.onkeydown = null);
  }, [isOpen, closeIconRef.current]);

  useEffect(() => {
    if (!disableEscapePress && isEscapePress && isOpen) {
      onClose();
    }
  }, [disableEscapePress, isEscapePress]);

  const onBackdropClick = () => {
    if (disableBackdropClick) {
      return;
    }

    onClose();
  };

  // Modals are attached directly to the document using
  // portals, the document object is not available on the
  // server, so they can't be rendered during SSR
  if (isInitialRender) {
    return null;
  }

  return createPortal(
    <Wrapper isOpen={isOpen} className={className}>
      <GlobalScrollPrevent prevent={isOpen} />
      <ModalOverlay overlayColor={overlayColor} onClick={onBackdropClick} />
      <ModalContent
        aria-label={ariaLabel}
        aria-modal="true"
        className={className}
        contentMaxWidth={contentMaxWidth}
        {...props}
        role="dialog"
      >
        {withCloseIcon && (
          <WrapperCloseIcon
            aria-label={t("basket.back")}
            ref={closeIconRef}
            onClick={onClose}
          >
            <CloseIcon color={primaryColor} width="16px" height="16px" />
          </WrapperCloseIcon>
        )}
        {children}
      </ModalContent>
    </Wrapper>,
    document.body
  );
};

Modal.propTypes = {
  ariaLabel: string.isRequired,
  children: node.isRequired,
  className: string,
  contentMaxWidth: string,
  disableBackdropClick: bool,
  disableEscapePress: bool,
  firstElement: shape({ current: any }),
  isOpen: bool.isRequired,
  lastElement: shape({ current: any }),
  onClose: getConditionallyRequiredProp(func, props => props.withCloseIcon),
  overlayColor: string,
  preventGoBack: bool,
  withCloseIcon: bool,
};

export default Modal;
