import { useTranslation } from "@lib/i18n";

import api from "@api";
import { useExternalConfigurationPathname } from "@hooks/useExternalConfigurationPathname";
import { toast } from "@lib/reactToastify";

export const useResetClientState = () => {
  const { t } = useTranslation();
  const configurationPathname = useExternalConfigurationPathname();

  return async () => {
    try {
      await api.purgeCurrentSession();

      window.location.href = configurationPathname;
    } catch (error) {
      toast.error(
        t("notify.apiDefaultError"),
        {
          toastId: "brandid-logout-session-error",
        },
        error
      );
    }
  };
};
