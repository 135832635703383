import produce from "immer";
import i18next from "i18next";

import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";
import { buildPageName, getProducts } from "../core/utils";

import {
  ORDER_EVENT_TYPES,
  ORDER_UPDATE_URL_TYPES,
  ORDER_ECOMMERCE_EVENT_TYPES,
} from "@gtm/events/order";

import {
  DEFAULT_EVENT_DATA_LAYER_OBJECT,
  DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT,
  EVENT_CATEGORY_CONTENT,
  EVENT_ACTION_REDIRECTION_EXTERNAL,
  PAGE_CATEGORY_TRANSACTION,
  MAIN_STEP_CONFIRMATION,
  FORMS_LEADTYPE_HOTLEAD,
  EVENT_CATEGORY_ECOMMERCE,
  EVENT_CHECKOUT,
  EVENT_CHECKOUT_ACTION,
  EVENT_ACTION_PURCHASE,
  SITE_TARGET_B2C,
  SITE_TARGET_B2B,
} from "@gtm/constants";

export const orderReducerEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case ORDER_EVENT_TYPES.CHECK_CONSENT:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = "ConsentBox:Check";
        draft.eventLabel = data;
        break;

      case ORDER_EVENT_TYPES.UNCHECK_CONSENT:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = "ConsentBox:Uncheck";
        draft.eventLabel = data;
        break;

      case ORDER_EVENT_TYPES.REDIRECT_TO_PAYMENT:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_EXTERNAL}::${data.action}`;
        draft.eventLabel = data.label;
        break;

      case ORDER_EVENT_TYPES.REDIRECT_TO_CITROEN_LIFESTYLE:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = `${EVENT_ACTION_REDIRECTION_EXTERNAL}::CitroenLifestyle`;
        draft.eventLabel = i18next.t("orderComplete.discover.buttonText");
        break;

      case ORDER_EVENT_TYPES.PLAY_VIDEO:
        draft.eventCategory = EVENT_CATEGORY_CONTENT;
        draft.eventAction = "Play::Video";
        draft.eventLabel = "orderFinancementVideo";
        break;

        case ORDER_EVENT_TYPES.ORDER_TRACKING_CLICK:
          draft.eventCategory = EVENT_CATEGORY_CONTENT;
          draft.eventAction = "OrderTrackingButton:Click";
          draft.eventLabel = "Order Tracking";
          break;

      default:
        return null;
    }
  });

export const orderReducerUpdateUrl = ({ type, data }) =>
  produce(DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case ORDER_UPDATE_URL_TYPES.ORDER_CONFIRMATION:
        draft.virtualPageURL = "/buy/order-confirmation";
        draft.pageCategory = PAGE_CATEGORY_TRANSACTION;
        draft.mainStepIndicator = "10";
        draft.mainStepName = MAIN_STEP_CONFIRMATION;
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.formsLeadID = data.gitId;
        draft.formsLeadType = FORMS_LEADTYPE_HOTLEAD;
        draft.formsPostalCode = data.postalCode;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });

        if (data.isSSRRendered) {
          delete draft.event;
        }

        break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });

export const orderEcommerceEvents = ({ type, data }) =>
  produce(DEFAULT_EVENT_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case ORDER_ECOMMERCE_EVENT_TYPES.CHECKOUT_ORDER_REVIEW:
        draft.event = EVENT_CHECKOUT;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_ACTION;
        draft.ecommerce = {
          checkout: {
            actionField: {
              step: 3,
              option: "Order review",
            },
            products: getProducts(
              data.price,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      case ORDER_ECOMMERCE_EVENT_TYPES.CHECKOUT_PAYMENT_SUCCESS:
        draft.event = EVENT_CHECKOUT;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_CHECKOUT_ACTION;
        draft.ecommerce = {
          checkout: {
            actionField: {
              step: 4,
              option: "Payment success",
            },
            products: getProducts(
              data.price,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      case ORDER_ECOMMERCE_EVENT_TYPES.ECOMMERCE_PURCHASE:
        draft.event = EVENT_ACTION_PURCHASE;
        draft.eventCategory = EVENT_CATEGORY_ECOMMERCE;
        draft.eventAction = EVENT_ACTION_PURCHASE;
        draft.ecommerce = {
          purchase: {
            actionField: {
              id: data.transactionId,
              affiliation: data.affiliation,
              revenue: data.totalPrice,
            },
            products: getProducts(
              data.totalPrice,
              data.variant,
              true,
              data.isB2BSelected
            ),
          },
        };
        break;

      default:
        return null;
    }
  });
