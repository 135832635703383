// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import { IS_SERVER } from "@shared/constants";

const Sentry = require("@sentry/node");
const SentryIntegrations = require("@sentry/integrations");

module.exports = ({
  release,
  sentryDsn,
  isEnabled,
  releaseWebUrl,
  businessEnv,
}) => {
  const sentryOptions = {
    dsn: sentryDsn,
    release,
    maxBreadcrumbs: 7,
    attachStacktrace: isEnabled,
    environment: businessEnv,
  };

  // When we're developing locally
  if (!isEnabled) {
    // Don't actually send the errors to Sentry
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];

    // Actually, don't initialize sentry at all
    return {
      Sentry,
      captureException: err => err,
    };
  }

  Sentry.init(sentryOptions);

  Sentry.configureScope(scope => {
    scope.setExtra("releaseWebUrl", releaseWebUrl);
    scope.setExtra("businessEnv", businessEnv);
  });

  return {
    Sentry,
    captureException: (err, ctx) => {
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra("statusCode", err.statusCode);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          if (IS_SERVER) {
            scope.setTag("ssr", true);
            scope.setExtra("url", req.url);
            scope.setExtra("method", req.method);
            scope.setExtra("headers", req.headers);
            scope.setExtra("params", req.params);
            scope.setExtra("query", req.query);

            // On server-side we take session cookie directly from request
            // TODO: Consider if user should really be a sessionId
            if (req.sessionId) {
              scope.setUser({ id: req.sessionId });
            }
          } else {
            scope.setTag("ssr", false);
            scope.setExtra("query", query);
            scope.setExtra("pathname", pathname);

            // TODO: Figure out how to handle this on FE, since sessionId
            // TODO: cannot be used because its inaccessible by javascript
            // const sessionId = Cookie.get('sid');
            // if (sessionId) {
            //   scope.setUser({ id: sessionId })
            // }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key])
            );
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};
