import React, { useState } from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import Button from "@components/shared/Button";
import ShareIcon from "@components/shared/Icons/ShareIcon";
import Typography from "@components/shared/Typography";
import FooterSection from "./FooterSection/FooterSection";
import SaveProgress from "./SaveProgress";
import SellerInfoModal from "./SellerInfoModal";
import ShareInfoModal from "./ShareInfoModal";

import gtm from "@gtm/core";
import { color, fontSize, tabletLarge, fontWeight } from "../shared/utils";
import { openDocument } from "@lib/openDocument";
import { COUNTRIES, PDF_DOCUMENT_TYPES } from "@shared/constants";
import { useGetAssetPath } from "hooks/useGetAssetPath";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  useSolJourneySelector,
  useIsLOAJourneyEnabledSelector,
  useIsLLDJourneyEnabledSelector,
  useIsVACJourneyEnabledSelector,
  useIsUIv2Selector,
} from "@redux/reducers/appConfig";

import {
  displayCookiesEvent,
  displayTermsOfServiceEvent,
  displayTermsOfSalesEvent,
  displayServiceConditionsEvent,
  displayLeasingTermsOfSalesEvent,
  displaySellerInformationEvent,
  displayShareInformationEvent,
  displayAccessibilityEvent,
  displayTermsOfAssistance,
  displayTermsOfWarranty,
} from "@gtm/events/shared";
import { getIsCountry } from "lib/getIsCountry";
import FinancialPanel from "./FinancialPanel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${color("white")};
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  background: ${color("black1")};
  padding: 2rem;
  flex-wrap: wrap;

  ${tabletLarge`
    flex-direction: row;
    min-height: 8rem;
    padding: 1rem;
  `}
`;

const StyledLegalTypography = styled(Typography)`
  color: ${color("white")};
  font-size: ${fontSize("sm")};
  position: relative;
  display: block;
`;

const StyledTypography = styled(Typography)`
  color: ${color("white")};
  font-size: ${fontSize("sm")};
  position: relative;
  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${color("white")};
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    ${tabletLarge`
      margin-bottom: 0;
    `}
  }
`;

const StyledTypographyWithTabNavigation = withTabNavigation(StyledTypography);

const StyledShareButton = styled(Button)`
  color: ${color("black")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("xs")};
  min-width: 8rem;
  padding: 0.5rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSharedIcon = styled(ShareIcon)`
  margin-left: 0.5rem;
`;

const Footer = ({ withSave, saveProgressSource }) => {
  const { t, i18n } = useTranslation();
  const [isSellerInfoOpen, setIsSellerInfoOpen] = useState(false);
  const [isShareInfoOpen, setIsShareInfoOpen] = useState(false);
  const getAssetPath = useGetAssetPath(i18n.language);
  const primaryColor = usePrimaryColor();

  const { accessibilityDeclaration } = useSolJourneySelector();
  const isLOAJourneyEnabled = useIsLOAJourneyEnabledSelector();
  const isLLDJourneyEnabled = useIsLLDJourneyEnabledSelector();
  const isVACJourneyEnabled = useIsVACJourneyEnabledSelector();
  const isUIv2 = useIsUIv2Selector();

  const handleCookieClick = () => {
    gtm.fire(displayCookiesEvent());

    const MODAL_ELEMENTS_SELECTOR = "#_psaihm_overlay, #_psaihm_main_div";
    const DISPLAY_CLASS_NAME = "_psaihm_css_collapse";

    document.querySelectorAll(MODAL_ELEMENTS_SELECTOR).forEach(element => {
      element.className = DISPLAY_CLASS_NAME;
      element.style.display = "block";
      // If it's a cookie DIV container, then overwrite transform property.
      if (element.id === "_psaihm_main_div") {
        element.style.transform = "translate(-50%, -50%)";
      }
    });
  };

  const openSellerInformation = () => {
    setIsSellerInfoOpen(true);
    gtm.fire(displaySellerInformationEvent());
  };

  const openShareInfoModal = () => {
    setIsShareInfoOpen(true);
    gtm.fire(displayShareInformationEvent());
  };

  return (
    <Container data-id="page-footer">
      {isUIv2 && getIsCountry(COUNTRIES.GB) && <FinancialPanel />}
      {withSave && <SaveProgress updateSource={saveProgressSource} />}
      <FooterSection />
      <SellerInfoModal
        isOpen={isSellerInfoOpen}
        closeModal={() => setIsSellerInfoOpen(false)}
      />
      <BottomBar>
        <StyledTypographyWithTabNavigation
          onClick={() => {
            gtm.fire(displayTermsOfServiceEvent());
            if (getIsCountry(COUNTRIES.GB)) {
              window.open(t("footer.termsOfServiceLink"), "_blank");
            } else {
              openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_SERVICE));
            }
          }}
        >
          {t("footer.termsOfService")}
        </StyledTypographyWithTabNavigation>
        <StyledTypographyWithTabNavigation
          onClick={() => {
            gtm.fire(displayTermsOfSalesEvent());
            openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_SALES_CASH));
          }}
        >
          {t("footer.termsOfSales")}
        </StyledTypographyWithTabNavigation>
        <StyledTypographyWithTabNavigation
          onClick={() => {
            gtm.fire(displayServiceConditionsEvent());
            openDocument(getAssetPath(PDF_DOCUMENT_TYPES.AMI_CARE_SERVICE));
          }}
        >
          {t("footer.serviceConditions")}
        </StyledTypographyWithTabNavigation>
        {isLLDJourneyEnabled && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              gtm.fire(displayLeasingTermsOfSalesEvent());
              openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_SALES_LLD));
            }}
          >
            {t("footer.deliveryGuaranteeConditions")}
          </StyledTypographyWithTabNavigation>
        )}
        {isLOAJourneyEnabled && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_SALES_LOA));
            }}
          >
            {t("footer.loaConditions")}
          </StyledTypographyWithTabNavigation>
        )}
        {isVACJourneyEnabled && t("footer.vacConditions") && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_SALES_VAC));
            }}
          >
            {t("footer.vacConditions")}
          </StyledTypographyWithTabNavigation>
        )}
        {t("footer.makeComplaints.link") &&
          t("footer.makeComplaints.heading") && (
            <StyledTypographyWithTabNavigation
              onClick={() =>
                window.open(t("footer.makeComplaints.link"), "_blank")
              }
            >
              {t("footer.makeComplaints.heading")}
            </StyledTypographyWithTabNavigation>
          )}
        <StyledTypographyWithTabNavigation onClick={openSellerInformation}>
          {t("footer.sellerInformation")}
        </StyledTypographyWithTabNavigation>
        {accessibilityDeclaration.isRequired && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              gtm.fire(displayAccessibilityEvent());
              getIsCountry(COUNTRIES.GB)
                ? window.open(t("footer.accessibilityLink"), "_blank")
                : openDocument(
                    getAssetPath(PDF_DOCUMENT_TYPES.ACCESSIBILITY_DECLARATION)
                  );
            }}
          >
            {t("footer.accessibility")}
          </StyledTypographyWithTabNavigation>
        )}
        {t("footer.termsOfAssistance") &&
          t("footer.termsOfAssistance").length > 0 && (
            <StyledTypographyWithTabNavigation
              onClick={() => {
                gtm.fire(displayTermsOfAssistance());
                openDocument(
                  getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_ASSISTANCE)
                );
              }}
            >
              {t("footer.termsOfAssistance")}
            </StyledTypographyWithTabNavigation>
          )}
        {t("footer.termsOfWarranty") && t("footer.termsOfWarranty").length > 0 && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              gtm.fire(displayTermsOfWarranty());
              openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TERMS_OF_WARRANTY));
            }}
          >
            {t("footer.termsOfWarranty")}
          </StyledTypographyWithTabNavigation>
        )}
        <StyledTypographyWithTabNavigation
          onClick={handleCookieClick}
          data-id="footer-cookies-preferences"
        >
          {t("cookies.footerTitle")}
        </StyledTypographyWithTabNavigation>
        {getIsCountry(COUNTRIES.GB) && (
          <StyledTypographyWithTabNavigation
            onClick={() => {
              window.open(t("footer.privacyPolicyLink"), "_blank");
            }}
          >
            {t("footer.privacyPolicy")}
          </StyledTypographyWithTabNavigation>
        )}

        <StyledShareButton withBorder onClick={openShareInfoModal}>
          {t("footer.share")}
          <StyledSharedIcon
            primaryColor={primaryColor}
            data-id="footer-share-icon"
            width="24px"
            height="24px"
          />
        </StyledShareButton>
        <ShareInfoModal
          isOpen={isShareInfoOpen}
          closeModal={() => setIsShareInfoOpen(false)}
        />
        {t("footer.legalMention") && (
          <StyledLegalTypography>
            {t("footer.legalMention")}
          </StyledLegalTypography>
        )}
      </BottomBar>
    </Container>
  );
};

Footer.propTypes = {
  withSave: bool,
  saveProgressSource: string,
};

export default React.memo(Footer);
