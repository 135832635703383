import React, { forwardRef } from "react";
import styled from "styled-components";
import { node, bool, string, func } from "prop-types";

import LoadingCircle from "../shared/Icons/LoadingCircle";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { color, font, fontSize, fontWeight } from "./utils";

const StyledButton = styled.button`
  align-items: center;
  background: ${({ inverted, primaryColor }) =>
    inverted ? primaryColor : color("white")};
  border: solid 2px
    ${({ withBorder }) => (withBorder ? color("grey") : color("white"))};
  border-radius: 32px;
  color: ${({ inverted, primaryColor }) =>
    inverted ? color("white") : primaryColor};
  cursor: pointer;
  display: flex !important;
  font-family: ${font("citroen")};
  font-size: ${({ textSize }) => fontSize(textSize ?? "default")};
  font-weight: ${fontWeight("bold")};
  justify-content: center;
  margin: 0.5rem 0;
  min-width: 12.5rem;
  padding: 1.25rem 2rem;
  position: relative;
  text-transform: lowercase;
  transition: background-color 0.2s ease-in-out;

  &::first-letter {
    text-transform: uppercase;
  }

  &:disabled {
    border-color: ${color("grey")};
    color: ${color("grey")};
    cursor: default;
  }

  &:enabled:hover {
    border-color: ${({ withBorder, primaryColor }) =>
      withBorder ? primaryColor : color("white")};

    background: ${({ inverted, theme, primaryColor }) =>
      inverted ? theme.colors.white : primaryColor};

    color: ${({ inverted, theme, primaryColor }) =>
      inverted ? primaryColor : theme.colors.white}};
  }
`;

const StyledLoadingCircle = styled(LoadingCircle)`
  color: ${({ inverted, primaryColor }) =>
    inverted ? color("white") : primaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = forwardRef(
  (
    { children, disabled, isLoading = false, customColor, inverted, ...props },
    ref
  ) => {
    const primaryColor = customColor ?? usePrimaryColor();

    return (
      <StyledButton
        ref={ref}
        primaryColor={primaryColor}
        disabled={disabled || isLoading}
        inverted={inverted}
        {...props}
      >
        {children}
        {isLoading && (
          <StyledLoadingCircle
            width="48px"
            height="48px"
            primaryColor={primaryColor}
            inverted={inverted}
          />
        )}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  customColor: string,
  disabled: bool,
  children: node.isRequired,
  inverted: bool,
  isLoading: bool,
  withBorder: bool,
  onClick: func,
};

export default Button;
