import reducers from "@gtm/reducers";
import {
  isObject,
  updateDynamicYieldRecommendationContext,
} from "@gtm/core/utils";
import { getIsProductionMode } from "@lib/getIsProductionMode";

export const fire = event => {
  const isProduction = getIsProductionMode();

  if (!window.dataLayer) {
    throw new Error(
      `'window.dataLayer' must be initialized before calling 'gtm.fire' method`
    );
  }

  if (!isObject(event)) {
    throw new Error(
      `GTM event must be a plain object, but instead got:\n` +
        JSON.stringify(event, null, 4)
    );
  }

  if (event.type === undefined) {
    throw new Error("GTM event object may not have undefined 'type' property!");
  }

  const values = reducers(event);

  values.forEach(value => {
    if (
      !isProduction &&
      window.dataLayer.length === 0 &&
      (!value.brand || !value.country || !value.language)
    ) {
      console.warn(
        `First GTM event must include values "brand", "country" and "language" instead got '${(value.brand,
        value.country,
        value.language)}'`
      );
    }

    window.dataLayer.push(value);
  });

  updateDynamicYieldRecommendationContext(event);
};

export default {
  fire,
};
