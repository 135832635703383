import React, { useRef } from "react";
import useOnScreen from "@hooks/accessibility/useOnScreen";

export function withTabNavigation(
  Component,
  role = "button",
  alwaysOnScreen = false
) {
  const controlKeys = {
    ENTER: 13,
    SPACE: 32,
  };
  const hocComponent = props => {
    let tabNavigationOptions = {};
    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (onScreen || alwaysOnScreen) {
      tabNavigationOptions = {
        tabIndex: 0,
        role: role,
        onKeyDown: event => {
          if (
            event.keyCode === controlKeys.SPACE ||
            event.keyCode === controlKeys.ENTER
          ) {
            // preventDefault to prevent spacing current page content
            event.preventDefault();
            event.target.click();
          }
        },
      };
    }
    return <Component {...props} {...tabNavigationOptions} ref={ref} />;
  };

  return hocComponent;
}
