import produce from "immer";
import { useSelector } from "react-redux";

import { ERRORS_ACTION_TYPES } from "../actions/errorsActions";

const errors = produce((state = initialState, { data, type }) => {
  switch (type) {
    case ERRORS_ACTION_TYPES.SET_BASKET_ERRORS:
      state.basketErrorCodes = data;
      break;

    case ERRORS_ACTION_TYPES.REMOVE_BASKET_ERROR:
      state.basketErrorCodes = state.basketErrorCodes.filter(
        errorCode => errorCode !== data
      );

      break;

    case ERRORS_ACTION_TYPES.SET_FINANCE_WIDGET_ERRORS:
      state.financeWidgetErrorCodes = data;
      break;

    case ERRORS_ACTION_TYPES.SET_UNAVAILABLE_ITEMS:
      state.unavailableItems = data;
      break;

    default:
      return state;
  }
});

const initialState = {
  basketErrorCodes: [],
  financeWidgetErrorCodes: [],
  unavailableItems: [],
};

export const useBasketErrorCodesSelector = () =>
  useSelector(({ errors }) => errors.basketErrorCodes);

export const useFinanceWidgetErrorsSelector = () =>
  useSelector(({ errors }) => errors.financeWidgetErrorCodes);

export const useUnavailableItemsSelector = () =>
  useSelector(({ errors }) => errors.unavailableItems);

export default errors;
