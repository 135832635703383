import getConfig from "next/config";
import { i18n } from "@lib/i18n";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";

const { publicRuntimeConfig } = getConfig();

export const openDocument = documentName => {
  const country = publicRuntimeConfig.COUNTRY;
  const languageCode = getLanguageCode(i18n.language);

  if (!documentName) {
    throw new Error(`Invalid documentName '${documentName}'`);
  }

  window.open(
    getStaticAssetURL(`/docs/${country}/${languageCode}/${documentName}`)
  );
};
