const {
  getSolJourneyConfigForJourney,
} = require("./getSolJourneyConfigForJourney");

const { VEHICLE_REGISTRATION_METHOD } = require("../../constants");

const getSelectedRegistrationConfiguration = (
  registrationType,
  journey,
  businessModel,
  configuration
) => {
  const { vehicleRegistration } = getSolJourneyConfigForJourney(
    journey,
    businessModel,
    configuration
  );

  switch (registrationType) {
    case VEHICLE_REGISTRATION_METHOD.MYSELF:
      return vehicleRegistration.selfRegistration;

    case VEHICLE_REGISTRATION_METHOD.CITROEN:
      return vehicleRegistration.citroenRegistration;

    default:
      return null;
  }
};

module.exports = { getSelectedRegistrationConfiguration };
