const {
  PRECONFIGURATION_CAR_IMAGES,
  PRECONFIGURATION_CAR_DEFAULT_IMAGE,
} = require("../../constants");

const getCarImagePath = preconfigurationId => {
  const carImage =
    Object.values(PRECONFIGURATION_CAR_IMAGES).find(value =>
      value.includes(preconfigurationId)
    ) ?? PRECONFIGURATION_CAR_DEFAULT_IMAGE;

  return `/images/preconfigs/${preconfigurationId}/${carImage}.png`;
};

module.exports = { getCarImagePath };
