import { useState, useEffect } from "react";
import { useTheme } from "styled-components";

import { useIsInitialRender } from "./useIsInitialRender";

const minMediaQuery = (name, theme) => {
  return `(min-width: ${theme.breakPoints[name]})`;
};

const maxMediaQuery = (name, theme) => {
  /**
   * Usage of regex substitute calc() function which might cause bad evaluation
   * in window.matchMedia function below in some browsers.
   */
  return `(max-width: ${theme.breakPoints[name].match(/\d+/)[0] - 0.1}px)`;
};

const isDesktop = theme => {
  return window.matchMedia(minMediaQuery("desktop", theme)).matches;
};

const isTablet = theme => {
  return (
    window.matchMedia(minMediaQuery("tablet", theme)).matches &&
    window.matchMedia(maxMediaQuery("desktop", theme)).matches
  );
};

const isMobile = theme => {
  return window.matchMedia(maxMediaQuery("tablet", theme)).matches;
};

export const useDetectDevice = () => {
  const theme = useTheme();
  const isInitialRender = useIsInitialRender();

  const setValues = () => ({
    isDesktop: isDesktop(theme),
    isTablet: isTablet(theme),
    isMobile: isMobile(theme),
  });

  /**
   * We have to render the desktop site on initial render because SSR and
   * initial client render need to match.
   */
  const [detectedDevices, setDetectedDevices] = useState({
    isDesktop: true,
    isTablet: false,
    isMobile: false,
  });

  const recomputeDevice = () => setDetectedDevices(setValues());

  useEffect(() => recomputeDevice(), [isInitialRender]);

  useEffect(() => {
    window.addEventListener("resize", recomputeDevice);

    return () => window.removeEventListener("resize", recomputeDevice);
  }, []);

  return detectedDevices;
};
