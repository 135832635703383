import React from "react";
import styled from "styled-components";

import AmiB2CLogoMobile from "@components/shared/Icons/AmiB2CLogoMobile";
import AmiB2CLogoDesktop from "@components/shared/Icons/AmiB2CLogoDesktop";
import AmiB2BLogoMobile from "@components/shared/Icons/AmiB2BLogoMobile";
import AmiB2BLogoDesktop from "@components/shared/Icons/AmiB2BLogoDesktop";

import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { tablet, smallMobile } from "@components/shared/utils";

const DesktopContainer = styled.div`
  display: none;

  ${tablet`
    display: flex;
  `}
`;

const MobileContainer = styled.div`
  display: flex;

  ${tablet`
    display: none;
  `}
`;

const Container = styled.div`
  width: 4rem;
  cursor: pointer;

  ${smallMobile`
    width: 6rem;
  `}

  ${tablet`
    align-items: flex-start;
    font-size: 0.6rem;
    flex-direction: column;
    position: relative;
    width: 13rem;
  `}

  svg {
    width: 100%;
  }
`;

const Logo = ({ ...props }) => {
  const isB2BSelected = useIsB2BSelected();

  return (
    <Container {...props}>
      <MobileContainer>
        {isB2BSelected ? (
          <AmiB2BLogoMobile size="lg" />
        ) : (
          <AmiB2CLogoMobile size="lg" />
        )}
      </MobileContainer>
      <DesktopContainer>
        {isB2BSelected ? (
          <AmiB2BLogoDesktop size="lg" />
        ) : (
          <AmiB2CLogoDesktop size="lg" />
        )}
      </DesktopContainer>
    </Container>
  );
};

export default Logo;
