import React from "react";

import BaseIcon from "../BaseIcon";

const ShoppingCartIcon = props => (
  <BaseIcon viewBox="0 0 32 27" fill="none" {...props}>
    <path
      d="M0 0.75H5.732L9.272 17.419H27.142M10.453 3.10601H30.514L28.322 12.716H12.645M14.119 22.751C14.119 24.1941 12.9491 25.364 11.506 25.364C10.0629 25.364 8.893 24.1941 8.893 22.751C8.893 21.3079 10.0629 20.138 11.506 20.138C12.9491 20.138 14.119 21.3079 14.119 22.751ZM27.143 22.751C27.143 24.1941 25.9731 25.364 24.53 25.364C23.0869 25.364 21.917 24.1941 21.917 22.751C21.917 21.3079 23.0869 20.138 24.53 20.138C25.9731 20.138 27.143 21.3079 27.143 22.751Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </BaseIcon>
);

export default ShoppingCartIcon;
