import produce from "immer";
import { useSelector } from "react-redux";

import { AUTH_ACTION_TYPES } from "../actions/authActions";

const deal = produce((state = initialState, { data, type }) => {
  switch (type) {
    case AUTH_ACTION_TYPES.SET_IS_TRUSTED:
      state.isTrusted = data;
      break;
    case AUTH_ACTION_TYPES.SET_IS_GUEST:
      state.isGuest = data;
      break;
    default:
      return state;
  }
});

// Initial state
const initialState = {
  isTrusted: false,
  isGuest: false,
};

// Selectors
export const useIsUserTrustedSelector = () =>
  useSelector(({ auth }) => auth.isTrusted);

export default deal;
