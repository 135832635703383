import { useRouter } from "next/router";

import { priceAlgorithm } from "@lib/priceAlgorithm";
import { useAppConfigSelector } from "@redux/reducers/appConfig";
import { getIsDeliveryPriceIncluded } from "@lib/getIsDeliveryPriceIncluded";
import { getIsRegistrationDiscountApplied } from "@shared/v2/lib/getIsRegistrationDiscountApplied";
import { getSelectedRegistrationConfiguration } from "@shared/v2/lib/getSelectedRegistrationConfiguration";
import { getPreselectedDelivery } from "@shared/v2/lib/getPreselectedDelivery";

import { useLastCtaSelector, useDealSelector } from "@redux/reducers/deal";

// TODO: How to handle possibly undefined data ???
export const useCurrentFinancingData = customDealObject => {
  const appConfig = useAppConfigSelector();
  const localDeal = useDealSelector();
  const deal = customDealObject ?? localDeal;

  const lastCta = useLastCtaSelector();
  const router = useRouter();

  const isDeliveryPreselected = !!getPreselectedDelivery(
    appConfig,
    deal.financeSimulation.journey,
    deal.businessModel
  );

  const isDeliveryIncluded = getIsDeliveryPriceIncluded(
    router.pathname,
    lastCta,
    isDeliveryPreselected
  );

  const isRegistrationDiscountApplied = getIsRegistrationDiscountApplied(
    router.pathname,
    lastCta
  );

  return {
    financingCashData: getFinancingCashData(
      deal,
      appConfig,
      isDeliveryIncluded,
      isRegistrationDiscountApplied
    ),
    financingLLDData: getFinancingLLDData(deal, isDeliveryIncluded),
    financingLOAData: getFinancingLOAData(deal, isDeliveryIncluded),
    financingVACData: getFinancingVACData(deal, isDeliveryIncluded),
    financingSCFData: getFinancingSCFData(deal, isDeliveryIncluded),
  };
};

const getFinancingCashData = (
  deal,
  appConfig,
  isDeliveryIncluded,
  isRegistrationDiscountApplied
) => {
  const { carConfiguration, financeSimulation } = deal;

  const packPrice = priceAlgorithm.getPackPrice(deal, appConfig);
  const registrationConfiguration = getSelectedRegistrationConfiguration(
    deal.financeSimulation.vehicleRegistration,
    deal.financeSimulation.journey,
    deal.businessModel,
    appConfig.configuration
  );

  return {
    preconfigurationLabel: carConfiguration.preconfiguration.label,
    deliveryPrice: financeSimulation.deliveryPrice.netPrice,
    options: carConfiguration.options,
    accessories: carConfiguration.accessory,
    services: carConfiguration.service,
    basePrice: financeSimulation.defaultPrice.cash.netPrice,

    totalPrice: isDeliveryIncluded
      ? financeSimulation.totalPrice.cash.netPrice
      : financeSimulation.vehiclePrice.cash.netPrice,

    totalPriceWithoutTax: isDeliveryIncluded
      ? financeSimulation.totalPrice.cash.basePrice
      : financeSimulation.vehiclePrice.cash.basePrice,

    registrationDiscountAmount:
      isRegistrationDiscountApplied && registrationConfiguration?.includeInPrice
        ? registrationConfiguration?.discountAmount
        : null,

    packPrice,
    isDeliveryIncluded,
  };
};

const getFinancingLLDData = (deal, isDeliveryIncluded) => {
  const {
    lldPriceBreakdown,
    financeSimulation: { financeWidgetParameters },
  } = deal;

  return {
    totalPrice:
      lldPriceBreakdown?.vehicleSimulation?.result.teaser.data?.regularPayment
        ?.amountInclTax,
    deposit:
      lldPriceBreakdown?.vehicleSimulation?.result.teaser.data.firstRental
        ?.amountInclTax ?? null,
    duration: lldPriceBreakdown?.vehicleSimulation?.result.teaser.data.duration,
    annualMileage:
      lldPriceBreakdown?.vehicleSimulation?.result.teaser.data.annualMileage,
    areServicesIncluded: Boolean(financeWidgetParameters?.services?.length),
    legalMention:
      lldPriceBreakdown?.vehicleSimulation?.result.globalText.legalMention,
    displayBlocks: lldPriceBreakdown?.vehicleSimulation?.result.displayBlocks,
    financialProductLabel:
      lldPriceBreakdown?.vehicleSimulation?.result.globalText
        .financialProductLabel.text,
    isDeliveryIncluded,
  };
};

const getFinancingLOAData = (deal, isDeliveryIncluded) => {
  const {
    loaPriceBreakdown,
    financeSimulation: { financeWidgetParameters },
  } = deal;

  return {
    deposit:
      loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.firstRental
        ?.amountInclTax ?? null,
    duration: loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.duration,
    annualMileage:
      loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.annualMileage,
    areServicesIncluded: Boolean(financeWidgetParameters?.services?.length),
    legalMention:
      loaPriceBreakdown?.vehicleSimulation?.result.globalText.legalMention,
    displayBlocks: loaPriceBreakdown?.vehicleSimulation?.result.displayBlocks,
    financialProductLabel:
      loaPriceBreakdown?.vehicleSimulation?.result.globalText
        .financialProductLabel.text,
    isDeliveryIncluded,
  };
};

const getFinancingVACData = (deal, isDeliveryIncluded) => {
  const {
    vacPriceBreakdown,
    financeSimulation: { financeWidgetParameters },
  } = deal;

  return {
    deposit:
      vacPriceBreakdown?.vehicleSimulation?.result.teaser.data.firstRental
        ?.amountInclTax ?? null,
    duration: vacPriceBreakdown?.vehicleSimulation?.result.teaser.data.duration,
    annualMileage:
      vacPriceBreakdown?.vehicleSimulation?.result.teaser.data.annualMileage,
    areServicesIncluded: Boolean(financeWidgetParameters?.services?.length),
    legalMention:
      vacPriceBreakdown?.vehicleSimulation?.result.globalText.legalMention,
    displayBlocks: vacPriceBreakdown?.vehicleSimulation?.result.displayBlocks,
    financialProductLabel:
      vacPriceBreakdown?.vehicleSimulation?.result.globalText
        .financialProductLabel.text,
    isDeliveryIncluded,
  };
};

const getFinancingSCFData = (deal, isDeliveryIncluded) => {
  const { scfPriceBreakdown } = deal;

  return {
    deposit:
      scfPriceBreakdown?.vehicleSimulation?.result.teaser.data.deposit ?? null,
    duration: scfPriceBreakdown?.vehicleSimulation?.result.teaser.data.duration,
    annualMileage:
      scfPriceBreakdown?.vehicleSimulation?.result.teaser.data.annualMileage,
    areServicesIncluded: false, // look above, assuming it's false since no FW here
    legalMention:
      scfPriceBreakdown?.vehicleSimulation?.result.globalText.legalMention,
    displayBlocks: scfPriceBreakdown?.vehicleSimulation?.result.displayBlocks,
    financialProductLabel:
      scfPriceBreakdown?.vehicleSimulation?.result.globalText
        .financialProductLabel.text,
    isDeliveryIncluded,
  };
};
