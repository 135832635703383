import { scrollToRef } from "@lib/scrollToRef";

export const FIXED_ELEMENTS_ATTRIBUTE_NAME = "data-fixed-on-top";

const getTopOffset = (additionalOffset = 0) => {
  const fixedElements = document.querySelectorAll(
    `[${FIXED_ELEMENTS_ATTRIBUTE_NAME}=true]`
  );

  const totalOffsetHeight = Array.from(fixedElements).reduce(
    (totalOffset, currentElement) => totalOffset + currentElement.offsetHeight,
    0
  );

  return totalOffsetHeight + additionalOffset;
};

const useScrollToView = ({ additionalOffset } = {}) => {
  return ref => scrollToRef(ref, getTopOffset(additionalOffset));
};

export { useScrollToView };
