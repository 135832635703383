import produce from "immer";
import { useSelector } from "react-redux";

import { TEST_DRIVE_ACTION_TYPES } from "@redux/actions/testDriveActions";

const deal = produce((state = initialTestDriveState, { data, type }) => {
  switch (type) {
    case TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_POINT:
      state.point = data;
      break;

    case TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_ADDRESS:
      state.address = data;
      break;

    case TEST_DRIVE_ACTION_TYPES.SET_TEST_DRIVE_SLOT:
      state.slot = data;
      break;

    default:
      return state;
  }
});

const initialTestDriveState = {
  point: null,
  address: null,
  slot: null,
};

export const useTestDrivePointSelector = () =>
  useSelector(({ testDrive }) => testDrive.point);

export const useTestDriveAddressSelector = () =>
  useSelector(({ testDrive }) => testDrive.address);

export const useTestDriveSlotSelector = () =>
  useSelector(({ testDrive }) => testDrive.slot);

export default deal;
