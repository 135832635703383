import React from "react";
import styled from "styled-components";
import { string, bool, node, func } from "prop-types";

import CheckboxIcon from "./Icons/Checkmark";

import { color } from "./utils";
import { usePrimaryColor } from "hooks/usePrimaryColor";

const StyledLabel = styled.label`
  position: relative;
  align-items: center;
  display: flex;
  color: ${color("black")};
  cursor: ${({ disabled }) => !disabled && "pointer"};
  user-select: none;
  min-width: fit-content;

  color: ${({ disabled, theme }) =>
    disabled && `${theme.colors.grey} !important`};

  & svg {
    transition: 0.1s linear box-shadow;
  }

  &:hover svg,
  &:focus,
  &:focus-within svg {
    box-shadow: 0px 0px 6px 3px
      ${({ currentColor }) => currentColor ?? color("brightOrange")};
  }
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const StyledCheckboxIcon = styled(CheckboxIcon)`
  background: transparent;
  border: 1px solid currentColor;
  border-radius: 2px;
  margin-right: 1rem;
`;

const Checkbox = ({
  label,
  disabled,
  className,
  onChange,
  checked,
  ...props
}) => {
  const currentColor = usePrimaryColor();
  return (
    <StyledLabel
      className={className}
      currentColor={currentColor}
      disabled={disabled}
    >
      <StyledInput
        type="checkbox"
        tabIndex={0}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
      <StyledCheckboxIcon checked={checked} />
      <div>{label}</div>
    </StyledLabel>
  );
};

Checkbox.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  label: node,
  className: string,
  disabled: bool,
};

export default Checkbox;
