import { produce } from "immer";
import getConfig from "next/config";

import { getCarImagePath } from "@shared/v2/lib/getCarImagePath";

const { publicRuntimeConfig } = getConfig();
const { FRONTEND_URL, ROOT_URL_PREFIX } = publicRuntimeConfig;

export const extendPreconfigurationWithImage = preconfiguration =>
  produce(preconfiguration, draft => {
    draft.image = `${FRONTEND_URL}${ROOT_URL_PREFIX}${getCarImagePath(
      preconfiguration.id
    )}`;
  });
