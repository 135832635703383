import { useTranslation } from "@lib/i18n";
import { useConfigurationSelector } from "@redux/reducers/appConfig";
import { generateConfigurationUrl } from "@shared/helper/generateConfigurationURL";

import {
  useCarConfigurationSelector,
  useSelectedJourneySelector,
  useBusinessModelSelector,
  useIsScrappageSelectedSelector,
} from "@redux/reducers/deal";

export const useConfigurationPathname = () => {
  const { i18n } = useTranslation();
  const appConfiguration = useConfigurationSelector();
  const selectedJourney = useSelectedJourneySelector();
  const selectedBusinessModel = useBusinessModelSelector();
  const isScrappageSelected = useIsScrappageSelectedSelector();

  const {
    preconfiguration: selectedPreconfiguration,
    accessory: selectedAccessories,
    service: selectedServices,
  } = useCarConfigurationSelector();

  return generateConfigurationUrl({
    selectedLanguage: i18n.language,

    selectedPreconfiguration,
    selectedAccessories,
    selectedServices,
    selectedJourney,
    selectedBusinessModel,
    isScrappageSelected,
    appConfiguration,
  });
};
