import { ROUTES } from "@shared/routing/routes";

const TYPES = "types";
const DESCRIPTIONS = "descriptions";

const getPageName = pathname => {
  switch (pathname) {
    case ROUTES.BASKET:
      return "seo.pageNames.basket";

    case ROUTES.MY_ACCOUNT_INDEX:
      return "seo.pageNames.myAccount";

    case ROUTES.MY_ACCOUNT_DETAILS:
      return "seo.pageNames.myDeals";

    case ROUTES.MY_ACCOUNT_ORDERS:
      return "seo.pageNames.myOrders";

    case ROUTES.OFFER_SUMMARY:
      return "seo.pageNames.summary";

    default:
      return;
  }
};

const getTestDriveTypeDescription = (pathname, targetKey) => {
  switch (pathname) {
    case ROUTES.TEST_DRIVE_HOME:
      return `seo.testDrive.${targetKey}.homeDeliveryTestDrive`;

    case ROUTES.TEST_DRIVE_DETAIL_FNAC_DARTY:
      return `seo.testDrive.${targetKey}.fnacDarty`;

    case ROUTES.TEST_DRIVE_DETAIL_MEDIAMARKT:
      return `seo.testDrive.${targetKey}.mediaMarkt`;

    case ROUTES.TEST_DRIVE_DETAIL_ENSTORE:
      return `seo.testDrive.${targetKey}.popUpStore`;

    case ROUTES.TEST_DRIVE_DETAIL_LA_MAISON:
      return `seo.testDrive.${targetKey}.maisonCitroen`;

    case ROUTES.TEST_DRIVE_DETAIL_LOCATION:
      return `seo.testDrive.${targetKey}.f2m`;

    default:
      return;
  }
};

export const getMetaData = (
  t,
  pathname,
  preconfigurationName,
  isGetPageNameByPathname,
  isPageNotFound
) => {
  const isTestDrive = pathname.includes(ROUTES.TEST_DRIVE_INDEX);

  if (isPageNotFound) {
    return { title: t("seo.404.title"), description: t("seo.404.description") };
  }

  if (pathname === ROUTES.TEST_DRIVE_INDEX) {
    return {
      title: t("seo.mainPageTestDrive.title"),
      description: t("seo.mainPageTestDrive.description"),
    };
  }

  if (preconfigurationName) {
    return {
      title: t("seo.mainPage.title", {
        namePreconfiguration: preconfigurationName,
      }),
      description: t("seo.mainPage.description", {
        namePreconfiguration: preconfigurationName,
      }),
    };
  }

  if (isGetPageNameByPathname) {
    if (isTestDrive) {
      return {
        title: t("seo.otherPagesTestDrive.title", {
          testDriveType: t(getTestDriveTypeDescription(pathname, TYPES)),
        }),
        description: t("seo.otherPagesTestDrive.description", {
          testDriveDescription: t(
            getTestDriveTypeDescription(pathname, DESCRIPTIONS)
          ),
        }),
      };
    }

    const pageName = t(getPageName(pathname));

    return {
      title: t("seo.otherPages.title", {
        nameOfPage: pageName,
      }),
      description: t("seo.otherPages.description", {
        nameOfPage: pageName,
      }),
    };
  }

  if (isTestDrive) {
    return {
      title: t("seo.noPageNameTestDrive.title"),
      description: t("seo.noPageNameTestDrive.description"),
    };
  }

  return {
    title: t("seo.noPageName.title"),
    description: t("seo.noPageName.description"),
  };
};
