const theme = {
  colors: {
    white: "#ffffff",
    black: "#000000",
    black1: "#333333",
    blue: "#4766ff",
    lightBlack: "#2b2b29",
    darkGrey: "#454444",
    grey: "#d3d3d3",
    grey1: "rgba(100,100,100)",
    green: "#20a720",
    lightGrey: "#F4F5F7",
    brightOrange: "#FFA500",
    darkNavy: "#203354",
    amiOrange: "#EB3300",
    amiParisianis: "#A5BAC9",
    amiBlue: "#1FAFEF",
    amiKhaki: "#A09857",
    amiGrey: "#c4c2c2",
    amiPink: "#FC168D",
    transparent: "rgba(0, 0, 0, 0)",
    myDetailsGrey: "#f2f2f2",
    smallTextGrey: "#797979",
    transparentWhite: "rgba(255,255,255,0.2)",
    semiTransparentWhite: "rgba(255,255,255,0.5)",
    transparentOrange: "rgba(235,81,31,0.6)",
    transparentBlack: "rgba(0, 0, 0, 0.5)",
    transparentRed: "rgba(255,0,0,0.1)",
    errorRed: "#ff0000",
    threeSixtyLoaderBlue: "#617582",
    citroenBlue: "#20AFF0",
    testDriveDefault: "#A5BAC9",
    testDriveAccent: "#EB3300",
    myFilesBackdrop: "rgba(36, 36, 36, 0.8)",
    myFilesSubBackdrop: "rgba(0, 0, 0, 0.3)",
    myFilesBorderGrey: "#c9c6c5",
    myFilesGreen: "#018210",
    myFilesLightGrey: "#f1f0f0",
    myFilesNotificationBG: "rgba(218, 41, 28, 0.1)",
    myFilesNotificationFG: "#da291c",
    myFilesRed: "#cc1400",
    myFilesSeparator: "#a19a99",
    myFilesTextGrey: "#393b38",
    myFilesUploadBG: "#e4e2e2",
  },
  breakPoints: {
    smallMobile: "375px",
    largeMobile: "475px",
    tablet: "640px",
    tabletLarge: "768px",
    desktop: "1024px",
    wideScreen: "1600px",
  },
  fonts: {
    ubuntu: `'CitroenType', 'Verdana', sans-serif`,
    citroen: `'CitroenType', 'Verdana', sans-serif`,
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    bolder: 800,
  },
  fontSizes: {
    xs: ".65rem",
    sm: ".875rem",
    default: "1rem",
    md: "1.125rem",
    lg: "1.5rem",
    xl: "1.9rem",
    xxl: "2.5rem",
    xxxl: "3.5rem",
    giant: "3.75rem",
  },
  downloadButtonSizes: {
    sm: "10rem",
    md: "15rem",
    lg: "20rem",
    xl: "25rem",
  },
  lineHeights: {
    larger: "1.5",
  },
  contentWidth: "1200px",
};

export default theme;
