import React from "react";
import { string, func, arrayOf, shape, bool } from "prop-types";
import styled from "styled-components";

import Typography from "@components/shared/Typography";
import { withTabNavigation } from "@hoc/withTabNavigation";

import { font, fontWeight, fontSize } from "../shared/utils";

const StyledList = styled.ul`
  display: flex;
  justify-items: start;
  list-style: none;
  margin: 0;
`;

const StyledItem = styled.li`
  display: inline-block;
  padding: 0.25rem 1rem 1px 1rem;
  margin-left: 0.5rem;
  background-color: ${({ isSelected, theme }) =>
    isSelected && theme.colors.grey1};
`;

const StyledTypography = withTabNavigation(styled(Typography)`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.white : theme.colors.grey};
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: ${fontSize("sm")};
  text-transform: uppercase;
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? "2px solid " + theme.colors.white : ""};
`);

const ItemSelector = ({ items, value, onClickItem, isDisabled }) => {
  const handleChange = selectedItemValue => {
    if (isDisabled) {
      return;
    }

    onClickItem?.(selectedItemValue);
  };

  return (
    <StyledList>
      {items.map(item => (
        <StyledItem
          key={item.value}
          isDisabled={isDisabled}
          isSelected={value === item.value}
          onClick={() => handleChange(item.value)}
        >
          <StyledTypography
            isDisabled={isDisabled}
            isSelected={value === item.value}
            onClick={() => handleChange(item.value)}
          >
            {item.title}
          </StyledTypography>
        </StyledItem>
      ))}
    </StyledList>
  );
};

ItemSelector.propTypes = {
  items: arrayOf(
    shape({
      value: string,
      title: string,
    })
  ).isRequired,
  value: string,
  onClickItem: func,
  isDisabled: bool,
};

export default ItemSelector;
