import produce from "immer";
import getConfig from "next/config";
import { useSelector } from "react-redux";

import { CONFIG_ACTION_TYPES } from "../actions/configActions";

import {
  useBusinessModelSelector,
  useSelectedJourneySelector,
} from "@redux/reducers/deal";
import { getEcoBonus } from "@shared/v2/lib/getEcoBonus";
import { getMovesProgram } from "@shared/v2/lib/getMovesProgram";
import {
  BUSINESS_MODELS,
  FINANCE_JOURNEYS,
  JOURNEY_TYPE,
  USER_FIELDS_NAMES,
} from "@shared/constants";
import { getIsB2BSelected } from "@shared/v2/lib/getIsB2BSelected";
import { isConfigurationAvailable } from "@shared/helper/isConfigurationAvailable";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

const appConfig = produce((state = initialConfigState, { type, data }) => {
  switch (type) {
    case CONFIG_ACTION_TYPES.SET_APP_DATA:
      state.car = data.car;
      state.deliveryMethods = data.deliveryMethods;
      state.configuration = data.configuration;
      state.upsellItems = data.upsellItems;
      state.assets = data.assets;
      state.routes = data.routes;
      break;

    case CONFIG_ACTION_TYPES.SET_REFERRER:
      state.referrer = data;
      break;

    case CONFIG_ACTION_TYPES.SET_TEST_DRIVE_TYPES:
      state.testDriveTypes = data;
      break;

    default:
      return state;
  }
});

const initialCarState = {
  options: [],
  combinedOptions: undefined,
  technicalCharacteristics: [],
  services: [],
  preconfigurations: [],
};

const initialDeliveryMethodState = {
  country: COUNTRY,
  id: "",
  street: "",
  streetNumber: "",
  postcode: "",
  city: "",
  county: "",
  addressCountry: "",
  phone: "",
  geoLatitude: "",
  geoLongitude: "",
  type: "",
  label: "",
};

const initialConfigurationState = {
  dealer: {},
  externalLinks: {},
  feature: {},
  localization: {},
  banner: {},
  referrer: "",
};

export const initialConfigState = {
  configuration: initialConfigurationState,
  car: initialCarState,
  deliveryMethods: [initialDeliveryMethodState],
  testDriveTypes: [],
  isAppVersionError: false,
  assets: [],
};

export const useAppConfigSelector = () =>
  useSelector(({ appConfig }) => appConfig);

export const useCarSelector = () => useSelector(store => store.appConfig.car);

export const useDeliveryMethodsSelector = () =>
  useSelector(store => store.appConfig.deliveryMethods);

export const usePreconfigurationsSelector = () =>
  useSelector(({ appConfig, deal }) =>
    appConfig.car.preconfigurations.filter(
      isConfigurationAvailable(getIsB2BSelected(deal.businessModel))
    )
  );

export const useConfigurationSelector = () =>
  useSelector(store => store.appConfig.configuration);

export const useExternalLinksSelector = () =>
  useSelector(store => store.appConfig.configuration?.externalLinks);

export const useLanguageSettingsSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.localisation);

export const useReferrerSelector = () =>
  useSelector(({ appConfig }) => appConfig.referrer);

export const useTestDriveTypesSelector = () =>
  useSelector(({ appConfig }) => appConfig.testDriveTypes);

export const useUpsellItemsSelector = () =>
  useSelector(({ appConfig }) => appConfig.upsellItems);

export const useBannerSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.banner ?? {});

export const useIsJourneySwitchEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney?.journeySwitch?.enabled ?? false
  );

export const useIsUIv2Selector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.uiv2 || false);

export const useIsReversedDeliveryFieldsOrderSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney
        .reverseDeliveryAddressFieldsOrder || false
  );

export const useShowEmissionStickerSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney?.showEmissionSticker ?? false
  );

export const useDaysBeforeTestDriveSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.testDrive.daysBeforeTD
  );

export const useTestDriveLinkOnHomePageSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.testDrive.linkOnHomePage
  );

export const useTestDriveLinkOnHomePagePathSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.testDrive.linkOnHomePagePath
  );

export const useIsContactPhoneNumberEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney?.contactPhoneNumberHeader
        ?.enabled ?? false
  );

export const useIsLOAJourneyEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.loaJourney?.enabled
  );

export const useIsLLDJourneyEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.lldJourney?.enabled ?? false
  );

export const useIsVACJourneyEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.vacJourney?.enabled ?? false
  );

export const useIsTelephoneFieldEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.telephoneField?.enabled ?? false
  );

export const useTaxPercentageSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.taxPercentage
  );

export const useCashDepositAmountSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.cashJourney.depositAmount
  );

export const useB2BCashDepositAmountSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.b2b.cashJourney.depositAmount
  );

export const useB2BDepositAmountSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.b2b.cashJourney.depositAmount
  );

export const useRegexPatternsSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.core.regexPatterns
  );

export const usePersonalInformationRegexPatternsSelector = () =>
  useSelector(({ appConfig }) => {
    const { regexPatterns } = appConfig.configuration.app.core;

    return {
      [USER_FIELDS_NAMES.PERSONAL_INFORMATION.USER_FIRST_NAME]:
        regexPatterns.userFirstName,
      [USER_FIELDS_NAMES.PERSONAL_INFORMATION.USER_LAST_NAME]:
        regexPatterns.userLastName,
      [USER_FIELDS_NAMES.PERSONAL_INFORMATION.PHONE]: regexPatterns.phone,
      [USER_FIELDS_NAMES.PERSONAL_INFORMATION.TIN]: regexPatterns.tin,
    };
  });

export const useCompanyInformationRegexPatternsSelector = () =>
  useSelector(({ appConfig }) => {
    const { regexPatterns } = appConfig.configuration.app.core;

    return {
      [USER_FIELDS_NAMES.COMPANY_INFORMATION.COMPANY_NAME]:
        regexPatterns.companyName,
      [USER_FIELDS_NAMES.COMPANY_INFORMATION.SIRET_NUMBER]:
        regexPatterns.siretNumber,
      [USER_FIELDS_NAMES.COMPANY_INFORMATION.TVA_NUMBER]:
        regexPatterns.TVANumber,
    };
  });

export const useAddressRegexPatternsSelector = () =>
  useSelector(({ appConfig }) => {
    const { regexPatterns } = appConfig.configuration.app.core;

    return {
      [USER_FIELDS_NAMES.ADDRESS.PRIMARY_ADDRESS]: regexPatterns.address,
      [USER_FIELDS_NAMES.ADDRESS.ADDITIONAL_ADDRESS]:
        regexPatterns.additionalAddress,
      [USER_FIELDS_NAMES.ADDRESS.CITY]: regexPatterns.city,
      [USER_FIELDS_NAMES.ADDRESS.POST_CODE]: regexPatterns.postCode,
      [USER_FIELDS_NAMES.ADDRESS.COUNTRY]: regexPatterns.country,
      [USER_FIELDS_NAMES.ADDRESS.PROVINCE]: regexPatterns.province,
    };
  });

export const usePhoneRegexPatternSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.core.regexPatterns.phone
  );

export const useAssetsSettingsSelector = () =>
  useSelector(({ appConfig }) => appConfig.assets);

export const useDateFormatSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.app.core.dateFormat);

export const useIsB2BJourneySwitchEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.b2b.journeySwitch.enabled
  );

export const useBrandCodeSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.brandCode);

export const useCurrencySettingsSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.currency
  );

export const useNumberFormatSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.app.core.numberFormat);

export const useSolJourneySelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.app.solJourney);

export const useFinanceWidgetConfigurationSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.financeJourney.financeWidget
  );

export const useIsBusinessModelSwitcherEnabledSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.businessModelSwitcher.enabled
  );

export const useB2BReceivingContactSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.b2b.receivingContact);

export const useMyAccountSettingsSelector = () =>
  useSelector(({ appConfig }) => appConfig.configuration.app.myAccount);

export const useIsParkopolyEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.discoveryJourney.parkopoly.enabled
  );

export const useGoogleMapsWidgetSettingsSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.widgets.googleMaps
  );

export const useBrandIdSettingsSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.integrations.brandId
  );

export const useDynamicYieldSettingsSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.integrations.dynamicYield
  );

export const useLLDConfigSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.lldJourney
  );

export const useLOAConfigSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.loaJourney
  );

export const useCashConfigSelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.cashJourney
  );

export const useCashOutstandingBalanceSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.cashJourney.outstandingBalance
  );

export const useB2BCashOutstandingBalanceSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.cashJourney.outstandingBalance
  );

export const useDefaultJourneySelector = () =>
  useSelector(
    ({ appConfig }) => appConfig.configuration.app.solJourney.defaultJourney
  );

export const useRoutesSelector = () =>
  useSelector(({ appConfig }) => appConfig.routes);

export const useConfirmationPopinSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.confirmationPopin?.enabled ?? false
  );

export const useLeadTimePopinSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.leadTimePopin.enabled ?? false
  );

export const useBankSummarySelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.solJourney.cashJourney.bankSummary?.enabled ??
      true
  );

export const journeyTypeToJourneyName = type => {
  switch (type) {
    case "cash":
      return "cashJourney";
    case "vac":
      return "vacJourney";
    case "lld":
      return "lldJourney";
    case "loa":
      return "loaJourney";
    case "scf":
      return "scfJourney";
    case "finance":
      return "finance";
    default:
      return null;
  }
};

// Returns ecoBonus but looks at the journey if it should be enabled or disabled
export const useEcoBonusSelector = () =>
  useSelector(({ appConfig }) => {
    const journeyType = useSelectedJourneySelector();
    const businessModel = useBusinessModelSelector();

    return getEcoBonus(journeyType, appConfig, businessModel);
  });

export const useMovesProgramSelector = () =>
  useSelector(({ appConfig }) => {
    const journeyType = useSelectedJourneySelector();
    const businessModel = useBusinessModelSelector();

    return getMovesProgram(journeyType, appConfig, businessModel);
  });

export const useDiscountStripeSelector = () =>
  useSelector(({ appConfig }) => {
    const journeyType = useSelectedJourneySelector();
    const businessModel = useBusinessModelSelector();

    const discountStripe =
      appConfig.configuration.app.solJourney.discountStripe;

    const configPath =
      businessModel === "b2b"
        ? appConfig.configuration.app.solJourney.b2b
        : appConfig.configuration.app.solJourney;

    if (!journeyType) return discountStripe;

    const journey = configPath[journeyTypeToJourneyName(journeyType)];

    if (!journey) return discountStripe;

    return {
      ...discountStripe,
      enabled:
        journey.discountStripe?.enabled === undefined
          ? discountStripe.enabled
          : journey.discountStripe.enabled,
    };
  });

export const useIsMyFilesEnabledSelector = businessModel =>
  useSelector(({ appConfig }) => {
    const myFilesConf = appConfig.configuration.app.myFiles;

    if (businessModel === BUSINESS_MODELS.B2B) {
      return myFilesConf?.b2b?.enabled ?? false;
    } else if (businessModel === BUSINESS_MODELS.B2C) {
      return myFilesConf?.b2c?.enabled ?? false;
    }

    return false;
  });

export const useIsB2BCashPromoCodesEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app?.promoCodes?.b2b?.cashJourney?.enabled ??
      false
  );

export const useIsB2BFinancePromoCodesEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app?.promoCodes?.b2b?.financeJourney?.enabled ??
      false
  );

export const useIsB2CCashPromoCodesEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app?.promoCodes?.b2c?.cashJourney?.enabled ??
      false
  );

export const useIsB2CFinancePromoCodesEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app?.promoCodes?.b2c?.financeJourney?.enabled ??
      false
  );

export const useIsPromoCodesEnabledSelector = (journey, businessModel) =>
  useSelector(({ appConfig }) => {
    const promoCodesConf = appConfig.configuration.app.promoCodes;

    if (journey === JOURNEY_TYPE.CASH) {
      if (businessModel === BUSINESS_MODELS.B2B)
        return promoCodesConf?.b2b?.cashJourney?.enabled ?? false;
      if (businessModel === BUSINESS_MODELS.B2C)
        return promoCodesConf?.b2c?.cashJourney?.enabled ?? false;
    }

    if (FINANCE_JOURNEYS.includes(journey)) {
      if (businessModel === BUSINESS_MODELS.B2B)
        return promoCodesConf?.b2b?.financeJourney?.enabled ?? false;
      if (businessModel === BUSINESS_MODELS.B2C)
        return promoCodesConf?.b2c?.financeJourney?.enabled ?? false;
    }

    return false;
  });

export const useIsOrderTrackingEnabledSelector = businessModel =>
  useSelector(({ appConfig }) => {
    if (businessModel === BUSINESS_MODELS.B2C) {
      return appConfig.configuration.app.orderTracking?.b2c?.enabled ?? false;
    }

    if (businessModel === BUSINESS_MODELS.B2B) {
      return appConfig.configuration.app.orderTracking?.b2b?.enabled ?? false;
    }

    return false;
  });

export const useOrderTrackingWidgetUrl = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.orderTracking?.widgetUrl ?? ""
  );

export const useIsGuestCheckoutEnabledSelector = () =>
  useSelector(
    ({ appConfig }) =>
      appConfig.configuration.app.guestCheckout?.enabled ?? false
  );

export const useIsAccessoriesDefaultOpenSelector = () =>
  useSelector(({ appConfig }) => {
    return (
      appConfig.configuration.app.customization?.accessories?.defaultOpen ??
      false
    );
  });

export default appConfig;
