import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getIsLOAJourneySelected } from "@shared/v2/lib/getIsLOAJourneySelected";
import { getIsLLDJourneySelected } from "@shared/v2/lib/getIsLLDJourneySelected";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";
import { getEcoBonus } from "@shared/v2/lib/getEcoBonus";

export const getIsEcoBonusEligible = (
  configuration,
  selectedFinanceDuration,
  journeyType,
  businessModel
) => {
  const ecoBonus = getEcoBonus(journeyType, { configuration }, businessModel);

  if (!ecoBonus.enabled || getIsVACJourneySelected(journeyType)) {
    return false;
  }

  if (
    getIsCashJourneySelected(journeyType) ||
    getIsLOAJourneySelected(journeyType)
  ) {
    return true;
  }

  return getIsLLDJourneySelected(journeyType);
};
