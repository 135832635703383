import styled from "styled-components";
import { desktop, smallMobile } from "../shared/utils";

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyCenter }) =>
    justifyCenter ? "center" : "space-between"};
  align-items: center;
  margin: 1rem 0;

  ${desktop`
    & button:not(:last-of-type) {
      margin-right: 1.5rem
    }
  `}

  ${smallMobile`
     flex-direction: row;
   `}
`;

export default ButtonGroup;
