const { getIsLLDJourneySelected } = require("./getIsLLDJourneySelected");
const { getIsLOAJourneySelected } = require("./getIsLOAJourneySelected");
const { getIsVACJourneySelected } = require("./getIsVACJourneySelected");
const { getIsSCFJourneySelected } = require("./getIsSCFJourneySelected");

const getIsLeasingJourneySelected = journey =>
  getIsLLDJourneySelected(journey) ||
  getIsLOAJourneySelected(journey) ||
  getIsVACJourneySelected(journey) ||
  getIsSCFJourneySelected(journey);

module.exports = { getIsLeasingJourneySelected };
