import React, { useMemo } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";

import {
  useSelectedJourneySelector,
  useVehicleQuantitySelector,
} from "@redux/reducers/deal";

import { useCurrentFinancingData } from "@hooks/financing/useCurrentFinancingData";

import Popin from "@components/shared/Popin";
import FinanceDisplayBlocks, {
  getDisplayBlocksVariant,
} from "@components/Financing/FinanceDisplayBlocks";
import Typography from "@components/shared/Typography";
import { fontSize } from "@components/shared/utils";

import { JOURNEY_TYPE } from "@shared/constants";
import { getParsedLegalMention } from "@shared/v2/lib/getParsedLegalMention";

const LegalMentionParagraph = styled(Typography)`
  font-size: ${fontSize("sm")};
`;

const FinanceTerms = ({ isOpen, onClose }) => {
  const { financingVACData } = useCurrentFinancingData();
  const journey = useSelectedJourneySelector();
  const quantity = useVehicleQuantitySelector();

  const parsedLegalMentions = useMemo(() => {
    return getParsedLegalMention(financingVACData.legalMention);
  }, [financingVACData.legalMention]);

  if (journey !== JOURNEY_TYPE.VAC || !isOpen) return null;

  return (
    <Popin
      withCloseIcon
      isOpen={isOpen}
      onClose={onClose}
      title={financingVACData.financialProductLabel}
    >
      <FinanceDisplayBlocks
        displayBlocks={financingVACData.displayBlocks}
        variant={getDisplayBlocksVariant(journey)}
        vehicleQuantity={quantity}
      />
      {parsedLegalMentions.map((paragraph, index) => (
        <LegalMentionParagraph key={index}>{paragraph}</LegalMentionParagraph>
      ))}
    </Popin>
  );
};

FinanceTerms.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default FinanceTerms;
