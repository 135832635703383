import React, { useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useTranslation } from "@lib/i18n";
import { useDispatch } from "react-redux";
import { bool, string } from "prop-types";

import Typography from "../shared/Typography";
import RequestButton from "../shared/RequestButton";
import gtm from "@gtm/core";

import { setIsDealSavedByUserAction } from "@redux/actions/dealActions";
import { postDealAction } from "@redux/actions/dealActions";
import { CTA_SOL_TYPES, DEAL_TYPE } from "@shared/constants";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { color, fontSize, fontWeight } from "../shared/utils";
import { saveProgressEvent } from "@gtm/events/shared";
import { getGTMPathnameStep } from "@gtm/core/utils";
import { useRoutingUtils } from "@hooks/useRoutingUtils";
import { ROUTES } from "@shared/routing/routes";

import { useDealSelector } from "@redux/reducers/deal";
import { withTabNavigation } from "@hoc/withTabNavigation";

const Container = styled.div`
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: ${color("black")};
  background: ${color("lightGrey")};
  text-align: center;
`;

const WrapperLink = withTabNavigation(styled.div``);

const SummaryRedirectLink = styled.a`
  font-size: 1.1rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
  padding: 0.5rem;

  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000000;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover::before {
    transform: scaleX(1);
  }
`;

const StyledRequestButton = styled(RequestButton)`
  color: ${color("black")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
`;

const SaveProgress = ({ updateSource }) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { lastCta, isSavedByUser } = useDealSelector();
  const isUserTrusted = useIsUserTrustedSelector();
  const gtmStep = getGTMPathnameStep(router.pathname, lastCta);
  const routingUtils = useRoutingUtils();

  const [isSaving, setIsSaving] = useState(false);

  const triggerRequest = async () => {
    setIsSaving(true);
    dispatch(setIsDealSavedByUserAction(true));

    if (!isUserTrusted) {
      gtm.fire(saveProgressEvent(gtmStep));
      router.push(
        ROUTES.MY_ACCOUNT_LOGIN,
        routingUtils.getLocalizedPathname(
          ROUTES.MY_ACCOUNT_LOGIN,
          null,
          i18n.language
        )
      );

      return false;
    }

    await dispatch(
      postDealAction(
        CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
        DEAL_TYPE.DEAL,
        null,
        true,
        updateSource
      )
    );

    setIsSaving(false);
  };

  const isDealSavedByUser = isSavedByUser && isUserTrusted && !isSaving;

  return (
    <Container>
      {isDealSavedByUser ? (
        <>
          <Typography size="sm">{t("footer.saveConfirmation")}</Typography>
          <WrapperLink
            onClick={() =>
              router.push(
                ROUTES.MY_ACCOUNT_DEALS,
                routingUtils.getLocalizedPathname(
                  ROUTES.MY_ACCOUNT_DEALS,
                  null,
                  i18n.language
                )
              )
            }
          >
            <SummaryRedirectLink>
              {t("footer.viewSavedProgress")}
            </SummaryRedirectLink>
          </WrapperLink>
        </>
      ) : (
        <>
          <Typography size="sm">{t("footer.saveCarCTA")}</Typography>
          <StyledRequestButton
            withBorder
            isLoading={isSaving}
            triggerRequestCallback={triggerRequest}
            onRequestSuccess={() => gtm.fire(saveProgressEvent(gtmStep))}
            onRequestError={console.error}
          >
            {t("footer.saveCarButton")}
          </StyledRequestButton>
        </>
      )}
    </Container>
  );
};

SaveProgress.propTypes = {
  isUserLoggedIn: bool,
  updateSource: string,
};

export default SaveProgress;
