import React, { useMemo } from "react";
import styled from "styled-components";
import { arrayOf, object, oneOf, bool, number } from "prop-types";
import getConfig from "next/config";
import { useRouter } from "next/router";
import api from "@api";

import Typography from "@components/shared/Typography";
import { color } from "@components/shared/utils";

import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import { useGetFormattedNumber } from "@hooks/useGetFormattedNumber";
import getPriceAlgorithm from "@shared/PriceAlgorithm/getPriceAlgorithm";
import { dealStatusAllowsPromoCodeChanges } from "@shared/helper/dealStatusAllowsPromoCodeChanges";
import { isIndexOrConfigPage } from "@shared/helper/isIndexOrConfigPage";

const { publicRuntimeConfig } = getConfig();
const { COUNTRY } = publicRuntimeConfig;

import {
  COUNTRIES,
  CTA_SOL_TYPES,
  DEAL_TYPE,
  JOURNEY_TYPE,
  SESSION_STORAGE_KEYS,
} from "@shared/constants";
import { getIsCountry } from "lib/getIsCountry";
import { dealType } from "types";
import { useDispatch } from "react-redux";
import {
  calculatePricesAction,
  postDealAction,
  removePromoCodeAction,
} from "redux/actions/dealActions";
import CloseIcon from "components/shared/Icons/Close";
import { useTranslation } from "@lib/i18n";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { sessionStorageClient } from "utils/sessionStorageClient";

export const DISPLAY_BLOCKS_VARIANT = {
  COMPACT: "COMPACT",
  WORDY: "WORDY",
  DEFAULT: "DEFAULT",
};

export const getDisplayBlocksVariant = journey => {
  switch (journey) {
    case JOURNEY_TYPE.LOA:
      return DISPLAY_BLOCKS_VARIANT.COMPACT;

    case JOURNEY_TYPE.VAC:
      return DISPLAY_BLOCKS_VARIANT.WORDY;

    case JOURNEY_TYPE.LLD:
    default:
      return DISPLAY_BLOCKS_VARIANT.DEFAULT;
  }
};

const Container = styled.div`
  ${({ variant }) => {
    switch (variant) {
      case DISPLAY_BLOCKS_VARIANT.COMPACT:
        return `
          ${DisplayBlockContainer} {
            padding: 1rem 0;
          }
        `;

      case DISPLAY_BLOCKS_VARIANT.DEFAULT:
      default:
        return `
          ${DisplayBlockContainer} {
            padding: 1rem 0;
          }
        `;
    }
  }}
`;

const DisplayBlockContainer = styled.div`
  border-bottom: 1px solid ${color("grey")};
  display: block;

  &:last-child {
    border-bottom: 0;
  }
`;

const DisplayBlock = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const BlockItemLabelTypography = styled(Typography)`
  padding-left: ${({ isIndented }) => isIndented && "1rem"};
  padding-top: 0.1rem;
  ${({ variant, isIndented }) => {
    switch (variant) {
      case DISPLAY_BLOCKS_VARIANT.WORDY:
        return `
          font-size: 0.8rem;
        `;

      case DISPLAY_BLOCKS_VARIANT.COMPACT:
        return `
          &:before {
            content: "";
            display: ${isIndented ? "inline-block" : "none"};
            width: 0.8rem;
            height: 0.3rem;
            border-left: 2px solid currentColor;
            border-bottom: 2px solid currentColor;
            transform: translate(0,-50%) rotate(-45deg);
            margin-right: 0.8rem;
          }
        `;

      case DISPLAY_BLOCKS_VARIANT.DEFAULT:
      default:
        return "";
    }
  }};
`;

const BlockItemValueTypography = styled(Typography)`
  ${({ variant }) =>
    variant === DISPLAY_BLOCKS_VARIANT.WORDY &&
    `
      font-size: 0.8rem;
      white-space: nowrap;
  `}
`;

const SPromoCodeRemoveButton = styled.button`
  appearance: none;
  background: 0;
  border: 0;
  cursor: pointer;
  display: inline-grid;
  margin-left: 0.625rem;
  height: 1.28125rem;
  place-items: center;
  width: 1.28125rem;

  &:hover {
    color: ${color("errorRed")};
  }
`;

const FinanceDisplayBlocks = ({
  deal,
  displayBlocks,
  variant,
  isReadOnly,
  isQuantityVisible,
  vehicleQuantity,
}) => {
  const dispatch = useDispatch();
  const priceAlgorithm = getPriceAlgorithm(COUNTRY);
  const getFormattedPrice = useGetFormattedPrice();
  const getFormattedNumber = useGetFormattedNumber();
  const isGB = getIsCountry(COUNTRIES.GB);
  const isPT = getIsCountry(COUNTRIES.PT);
  const isUserTrusted = useIsUserTrustedSelector();
  const { t } = useTranslation();
  const router = useRouter();

  const parsedFinanceDisplayBlocks = useMemo(() => {
    if (!displayBlocks) {
      return [];
    }

    const sortByOrder = (a, b) => (a.order > b.order ? 1 : -1);

    return [...displayBlocks]
      .sort(sortByOrder)
      .map(block => [...block.displayLines].sort(sortByOrder));
  });

  const { financeSimulation = { promoCode: undefined }, status } = deal ?? {};

  const { promoCode } = financeSimulation;

  const removePromoCode = async () => {
    if (!isUserTrusted) {
      api.removePromoCodeFromSession();
    }

    dispatch(removePromoCodeAction());
    dispatch(calculatePricesAction());

    sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);

    if (deal?.currentDeal?.token) {
      await dispatch(
        postDealAction(
          CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
          DEAL_TYPE.DEAL,
          null,
          true
        )
      );
    }
  };

  return (
    <Container variant={variant}>
      {!isIndexOrConfigPage(router.pathname) && promoCode?.valid && (
        <DisplayBlockContainer>
          <DisplayBlock>
            <BlockItemLabelTypography variant={variant}>
              {promoCode.label}
              {!isReadOnly && dealStatusAllowsPromoCodeChanges(status) && (
                <SPromoCodeRemoveButton
                  onClick={removePromoCode}
                  title={t("promoCodes.remove")}
                >
                  <CloseIcon size="sm" />
                </SPromoCodeRemoveButton>
              )}
            </BlockItemLabelTypography>
            <BlockItemValueTypography
              data-id={`financefinancing-price-promoCode`}
              variant={variant}
            >
              {getFormattedPrice(promoCode.discountAmount * -1)}
            </BlockItemValueTypography>
          </DisplayBlock>
        </DisplayBlockContainer>
      )}

      {parsedFinanceDisplayBlocks.map((block, blockIndex) => (
        <DisplayBlockContainer key={blockIndex}>
          {block.map((blockItem, blockItemIndex) => (
            <DisplayBlock key={blockItemIndex}>
              <BlockItemLabelTypography
                isIndented={blockItem.styleType?.indent}
                variant={variant}
              >
                {blockItem.label}
              </BlockItemLabelTypography>
              {blockItem.displayValue && (
                <BlockItemValueTypography
                  data-id={`financefinancing-price-${blockItemIndex}`}
                  variant={variant}
                >
                  {/* Magic index values used to avoid formatting */}
                  {/* of non-currency values, needs refactoring */}
                  {isQuantityVisible && ![0, 3].includes(blockIndex)
                    ? getFormattedPrice(
                        priceAlgorithm.times(
                          Number(blockItem.value),
                          vehicleQuantity
                        )
                      )
                    : blockIndex === 0 &&
                      blockItemIndex === 1 &&
                      blockItem.value &&
                      !isGB && //APP-25320: A corner case for IT, when this clause is true, yet the 'blockItem' object only has 'displayItemValue' property and the 'value' property is undefined
                      !isPT
                    ? getFormattedNumber(blockItem.value * vehicleQuantity)
                    : blockItem.displayValue}
                </BlockItemValueTypography>
              )}
            </DisplayBlock>
          ))}
        </DisplayBlockContainer>
      ))}
    </Container>
  );
};

FinanceDisplayBlocks.propTypes = {
  displayBlocks: arrayOf(object).isRequired,
  variant: oneOf(Object.values(DISPLAY_BLOCKS_VARIANT)).isRequired,
  isReadOnly: bool,
  isQuantityVisible: bool,
  vehicleQuantity: number,
  deal: dealType,
};

export default FinanceDisplayBlocks;
