export default (...reducers) => event =>
  reducers
    // Get value from a reducer function
    .map(reducer => reducer(event))
    // Filter only truthy values (reducers return 'null' if they
    // don't match any event type)
    .filter(Boolean)
    // Reducers are using 'immer' which returns freezed object,
    // which messes up GTM, so we need to unfreeze it
    .map(value => ({ ...value }));
