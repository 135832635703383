const { bool } = require("prop-types");

const isConfigurationAvailable = isB2BSelected => ({ status }) => {
  if (typeof status === "boolean") {
    return status;
  } else if (Array.isArray(status) && status.length === 4) {
    const [
      b2cCashActive,
      b2cFinanceActive,
      b2bCashActive,
      b2bFinanceActive,
    ] = status;
    return isB2BSelected
      ? b2bCashActive || b2bFinanceActive
      : b2cCashActive || b2cFinanceActive;
  }

  return false;
};

isConfigurationAvailable.propTypes = {
  isB2BSelected: bool,
};

module.exports = { isConfigurationAvailable };
