export const SHARED_EVENT_TYPES = {
  AMI_LOGO_CLICK: "AMI_LOGO_CLICK",
  ACCOUNT_LOGO_CLICK: "ACCOUNT_LOGO_CLICK",
  BASKET_LOGO_CLICK: "BASKET_LOGO_CLICK",
  FAQ_SECTION_DISPLAY: "FAQ_SECTION_DISPLAY",
  TERMS_SALES_DISPLAY: "TERMS_SALES_DISPLAY",
  TERMS_OF_ASSISTANCE_DISPLAY: "TERMS_OF_ASSISTANCE_DISPLAY",
  TERMS_OF_WARRANTY_DISPLAY: "TERMS_OF_WARRANTY_DISPLAY",
  SERVICE_CONDITIONS_DISPLAY: "SERVICE_CONDITIONS_DISPLAY",
  LEASING_TERMS_OF_SALES_DISPLAY: "LEASING_TERMS_OF_SALES_DISPLAY",
  TERMS_SERVICE_DISPLAY: "TERMS_SERVICE_DISPLAY",
  SELLER_INFORMATION_DISPLAY: "SELLER_INFORMATION_DISPLAY",
  ACCESSIBILITY_CONDITIONS_DISPLAY: "ACCESSIBILITY_CONDITIONS_DISPLAY",
  SHARE_INFORMATION_DISPLAY: "SHARE_INFORMATION_DISPLAY",
  COOKIES_DISPLAY: "COOKIES_DISPLAY",
  CHANGE_DELIVERY: "CHANGE_DELIVERY",
  CHANGE_FUNDING: "CHANGE_FUNDING",
  OPEN_FINANCE_WIDGET: "OPEN_FINANCE_WIDGET",
  SAVE_PROGRESS: "SAVE_PROGRESS",
  REVERT_PROGRESS: "REVERT_PROGRESS",
  DOWNLOAD_APP: "DOWNLOAD_APP",
  DISPLAY_MORE_INFO: "DISPLAY_MORE_INFO",
  SWITCH_BUSINESS_MODEL: "SWITCH_BUSINESS_MODEL",
  SHARE_DOCUMENTS: "SHARE_DOCUMENTS",
  REDIRECT_INSURANCE: "REDIRECT_INSURANCE",
  REDIRECT_UPSELL: "REDIRECT_UPSELL",
};

export const amiLogoClickEvent = data => ({
  type: SHARED_EVENT_TYPES.AMI_LOGO_CLICK,
  data,
});

export const accountLogoClickEvent = () => ({
  type: SHARED_EVENT_TYPES.ACCOUNT_LOGO_CLICK,
});

export const basketLogoClickEvent = () => ({
  type: SHARED_EVENT_TYPES.BASKET_LOGO_CLICK,
});

export const faqSectionDisplayEvent = faqSectionLabel => ({
  type: SHARED_EVENT_TYPES.FAQ_SECTION_DISPLAY,
  data: faqSectionLabel,
});

export const displayTermsOfSalesEvent = () => ({
  type: SHARED_EVENT_TYPES.TERMS_SALES_DISPLAY,
});

export const displayTermsOfAssistance = () => ({
  type: SHARED_EVENT_TYPES.TERMS_OF_ASSISTANCE_DISPLAY,
});

export const displayTermsOfWarranty = () => ({
  type: SHARED_EVENT_TYPES.TERMS_OF_WARRANTY_DISPLAY,
});

export const displayTermsOfServiceEvent = () => ({
  type: SHARED_EVENT_TYPES.TERMS_SERVICE_DISPLAY,
});

export const displayServiceConditionsEvent = () => ({
  type: SHARED_EVENT_TYPES.SERVICE_CONDITIONS_DISPLAY,
});

export const displayLeasingTermsOfSalesEvent = () => ({
  type: SHARED_EVENT_TYPES.LEASING_TERMS_OF_SALES_DISPLAY,
});

export const displayAccessibilityEvent = () => ({
  type: SHARED_EVENT_TYPES.ACCESSIBILITY_CONDITIONS_DISPLAY,
});

export const displaySellerInformationEvent = () => ({
  type: SHARED_EVENT_TYPES.SELLER_INFORMATION_DISPLAY,
});

export const displayShareInformationEvent = () => ({
  type: SHARED_EVENT_TYPES.SHARE_INFORMATION_DISPLAY,
});

export const displayCookiesEvent = () => ({
  type: SHARED_EVENT_TYPES.COOKIES_DISPLAY,
});

export const changeDeliveryTypeEvent = data => ({
  type: SHARED_EVENT_TYPES.CHANGE_DELIVERY,
  data,
});

export const changeFundingEvent = data => ({
  type: SHARED_EVENT_TYPES.CHANGE_FUNDING,
  data,
});

export const openFinanceWidgetEvent = (label, route) => ({
  type: SHARED_EVENT_TYPES.OPEN_FINANCE_WIDGET,
  data: {
    label,
    route,
  },
});

export const saveProgressEvent = step => ({
  type: SHARED_EVENT_TYPES.SAVE_PROGRESS,
  data: step,
});

export const revertProgressEvent = label => ({
  type: SHARED_EVENT_TYPES.REVERT_PROGRESS,
  data: label,
});

export const downloadAppEvent = data => ({
  type: SHARED_EVENT_TYPES.DOWNLOAD_APP,
  data,
});

export const redirectInsuranceEvent = data => ({
  type: SHARED_EVENT_TYPES.REDIRECT_INSURANCE,
  data,
});

export const redirectUpsellEvent = data => ({
  type: SHARED_EVENT_TYPES.REDIRECT_UPSELL,
  data,
});

export const displayMoreInfoEvent = data => ({
  type: SHARED_EVENT_TYPES.DISPLAY_MORE_INFO,
  data,
});

export const switchBusinessModelEvent = data => ({
  type: SHARED_EVENT_TYPES.SWITCH_BUSINESS_MODEL,
  data,
});

export const shareDocumentsModalEvent = data => ({
  type: SHARED_EVENT_TYPES.SHARE_DOCUMENTS,
  data,
});
