import { getSolJourneyConfigForJourney } from "./getSolJourneyConfigForJourney";
const { VEHICLE_REGISTRATION_METHOD } = require("../../constants");

const VEHICLE_REGISTRATION_MAP = {
  selfRegistration: VEHICLE_REGISTRATION_METHOD.MYSELF,
  citroenRegistration: VEHICLE_REGISTRATION_METHOD.CITROEN,
};

const getEnabledVehicleRegistrationMethods = (
  journey,
  businessModel,
  configuration
) => {
  const {
    vehicleRegistration: vehicleRegistrationConfig,
  } = getSolJourneyConfigForJourney(journey, businessModel, configuration);

  const enabledRegistrationOptions = Object.entries(
    vehicleRegistrationConfig
  ).filter(([, option]) => option.enabled);

  return enabledRegistrationOptions.map(
    ([optionKey]) => VEHICLE_REGISTRATION_MAP[optionKey]
  );
};

module.exports = { getEnabledVehicleRegistrationMethods };
