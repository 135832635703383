import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { node } from "prop-types";

import useHandleDealRelatedTriggers from "@hooks/app/useHandleDealRelatedTriggers";
import { useRemoveQueryParameters } from "@hooks/useRemoveQueryParameters";
import { useIsLeasingJourneySelected } from "@hooks/useIsLeasingJourneySelected";
import { useGetIsTargetBusinessModelFinanceEnabled } from "@hooks/useGetIsTargetBusinessModelFinanceEnabled";
import {
  useDealSelector,
  useActiveCarPreconfigurationSelector,
} from "@redux/reducers/deal";
import { updateJourneyAction } from "@redux/actions/dealActions";
import {
  postDealAction,
  updateAvailableJourneyAction,
} from "@redux/actions/dealActions";
import { initGoogleMapsWidget } from "@shared/v2/widgets/googleMapsWidget";
import api from "@api";

import {
  JOURNEY_TYPE,
  CTA_SOL_TYPES,
  DEAL_TYPE,
  DEAL_STATES,
} from "@shared/constants";

const AppWrapper = ({ children, store }) => {
  const { appConfig } = store.getState();
  const deal = useDealSelector();
  const dispatch = useDispatch();

  initGoogleMapsWidget({
    apiKey: appConfig.configuration.app.widgets.googleMaps.apiKey,
  });

  const removeQueryParameters = useRemoveQueryParameters();
  const getIsTargetBusinessModelFinanceEnabled = useGetIsTargetBusinessModelFinanceEnabled();
  const isLeasingJourneySelected = useIsLeasingJourneySelected();
  const preconfiguration = useActiveCarPreconfigurationSelector();

  useHandleDealRelatedTriggers();

  useEffect(() => {
    removeQueryParameters([
      "salesman",
      "sessionId",
      "state",
      "isUserUpdateSuccess",
    ]);
  }, []);

  useEffect(() => {
    if (deal.dealState === DEAL_STATES.DEAL && deal.isSavedByUser) {
      dispatch(
        postDealAction(
          CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
          DEAL_TYPE.DEAL,
          null
        )
      );
    }
  }, []);

  const selectedJourney = deal.financeSimulation.journey;
  const selectedBusinessModel = deal.businessModel;

  // TODO How to prevent this ???
  useEffect(() => {
    if (
      isLeasingJourneySelected &&
      !getIsTargetBusinessModelFinanceEnabled(selectedBusinessModel)
    ) {
      dispatch(updateJourneyAction(JOURNEY_TYPE.CASH));

      api.sendSlackNotification(deal);
    }
  }, [selectedJourney, selectedBusinessModel]);

  useEffect(() => {
    dispatch(updateAvailableJourneyAction(preconfiguration));
  }, [preconfiguration]);

  return children;
};

AppWrapper.propTypes = {
  children: node.isRequired,
};

export default AppWrapper;
