import { useCallback, useState, useLayoutEffect } from "react";

/**
 * @param { element } elementRef
 * @param { object } options
 * @param {number} options.tolerance
 *  - allow gap, when content is present without scrolling exactly to bottom (bigger borders/paddings)
 * @param { boolean } options.disabled
 * @returns { boolean }
 */
const useIsScrolledToBottom = (node, { tolerance = 5, disabled = false }) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = useCallback(() => {
    if (!node || disabled) return;

    const diff = node.scrollHeight - node.scrollTop - node.clientHeight;

    if (diff < tolerance) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  }, [node, setIsScrolledToBottom]);

  useLayoutEffect(() => {
    if (disabled || !node) return;

    handleScroll();

    node.onscroll = handleScroll;

    return () => {
      node.onscroll = () => {};
    };
  }, [node, disabled]);

  return isScrolledToBottom;
};

export { useIsScrolledToBottom };
