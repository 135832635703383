import React, { useState, useEffect } from "react";
import { node, string, bool } from "prop-types";
import Head from "next/head";
import dynamic from "next/dynamic";
import styled from "styled-components";
import cookie from "cookie";

import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";
import Banner from "@components/Banner/Banner";
import PageTransition from "@components/PageTransition/PageTransition";
import ErrorBoundary from "@components/shared/ErrorBoundary";
import DeveloperToolbar from "@components/DeveloperToolbar/DeveloperToolbar";
import { desktop, prop } from "@components/shared/utils";

import { useHeaderBackgroundColor } from "@hooks/useHeaderBackgroundColor";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { useMetaData } from "../../seo/useMetaData";
import { KOA_BANNER_COOKIE_ID } from "@shared/constants";
import { useBannerSelector } from "@redux/reducers/appConfig";
import { useCurrentDealStateSelector } from "@redux/reducers/deal";
import { getIsProductionMode } from "@lib/getIsProductionMode";

const FinancialWidget = dynamic(
  () => import("../FinancialWidget/FinancialWidget"),
  { ssr: false }
);

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  flex: ${({ isFullHeight }) => (isFullHeight ? "1" : "1 0 auto")};
  display: ${({ isFullHeight }) => isFullHeight && "flex"};
  width: 100%;

  ${desktop`
    margin-top: 7.5rem;
  `}
`;

const StyledHeader = styled(Header)`
  background-color: ${prop("backgroundColor")};

  ${desktop`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  `}
`;

const Layout = ({
  children,
  withSave,
  saveProgressSource,
  animateOpacityOnly,
  noAnimation,
  isPageNotFound,
  isFullHeight,
  namePreconfiguration,
  isGetPageNameByPathname,
  dealState,
}) => {
  const currentDealState = useCurrentDealStateSelector();
  dealState = dealState ?? currentDealState;

  const isProduction = getIsProductionMode();

  const headerBackgroundColor = useHeaderBackgroundColor(isPageNotFound);
  const banner = useBannerSelector();

  const [isBannerHidden, setIsBannerHidden] = useState(true);

  const bannerExists = Boolean(banner.id);

  const { title, description } = useMetaData(
    namePreconfiguration,
    isGetPageNameByPathname,
    isPageNotFound
  );

  const getCookieName = () => `${KOA_BANNER_COOKIE_ID}.${banner.id}`;

  const onBannerClose = () => {
    const cookieName = getCookieName();

    document.cookie = cookie.serialize(cookieName, null, {
      maxAge: banner.ttl,
    });

    setIsBannerHidden(true);
  };

  useEffect(() => {
    if (!bannerExists) {
      return;
    }

    const cookieName = getCookieName();

    setIsBannerHidden(document.cookie.includes(cookieName));
  }, []);

  return (
    <ErrorBoundary>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#333" />
        <link rel="icon" href={getStaticAssetURL("/favicon.ico")} />
      </Head>
      <PageWrapper>
        <header>
          <StyledHeader
            dealState={dealState}
            backgroundColor={headerBackgroundColor}
          />
        </header>
        {bannerExists && !isBannerHidden && (
          <Banner text={banner.text} onClose={onBannerClose} />
        )}
        <PageTransition
          animateOpacityOnly={animateOpacityOnly}
          noAnimation={noAnimation}
          isFullHeight={isFullHeight}
        >
          <main>
            <ContentWrapper isFullHeight={isFullHeight}>
              {children}
            </ContentWrapper>
          </main>
        </PageTransition>
        <FinancialWidget />
        <footer>
          <Footer withSave={withSave} saveProgressSource={saveProgressSource} />
          {!isProduction && <DeveloperToolbar />}
        </footer>
      </PageWrapper>
    </ErrorBoundary>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  namePreconfiguration: string,
  isGetPageNameByPathname: bool,
  withSave: bool,
  saveProgressSource: string,
  animateOpacityOnly: bool,
  noAnimation: bool,
  isPageNotFound: bool,
  isFullHeight: bool,
  dealState: string,
};

export default Layout;
