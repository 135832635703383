const getConfig = require("next/config").default;

const { publicRuntimeConfig } = getConfig();
const { ROOT_URL_PREFIX } = publicRuntimeConfig;

const getIsB2BUrl = (url, b2bPrefix) => {
  return url.includes(`${ROOT_URL_PREFIX}/${b2bPrefix}`);
};

module.exports = { getIsB2BUrl };
