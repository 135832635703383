import React, { useState, useEffect } from "react";
import styled from "styled-components";
import getDebug from "debug";

import Modal from "@components/shared/Modal";
import { useCurrentDealSelector } from "@redux/reducers/deal";
import { useAppConfigSelector } from "@redux/reducers/appConfig";
import api from "@api";

const debug = getDebug("app:development:MOPInspectorModal");

const StyledModal = styled(Modal)`
  margin-top: 10vh;
  max-width: initial;
  max-height: 80vh;
`;

const MOPInspectorModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mopContent, setMopContent] = useState(null);

  const currentDeal = useCurrentDealSelector();
  const appConfig = useAppConfigSelector();

  useEffect(() => {
    window.__displayCurrentMopDetail = async () => {
      if (!currentDeal.token) {
        debug(
          `❗️ Couldn't fetch current MOP ID because there is not any current deal token in Redux!`
        );

        return;
      }

      debug(`⏳ Fetching MOP ID for deal '${currentDeal.token}'`);

      const { data: deal } = await api.getDeal(currentDeal.token);

      debug(`✅ Successfully fetched MOP ID '${deal.externalId}'`);

      debug(`⏳ Fetching content of MOP '${deal.externalId}'`);

      const { data: mop } = await api.getMopRecord(
        appConfig.configuration.brandCode,
        appConfig.configuration.country,
        deal.externalId
      );

      debug(`✅ Successfully fetched content of MOP '${deal.externalId}'`);

      setMopContent(mop);
      setIsModalOpen(true);
    };
  }, [currentDeal?.token]);

  return (
    <StyledModal
      isOpen={isModalOpen}
      ariaLabel={"MOPInspectorModal"}
      onClose={() => setIsModalOpen(false)}
    >
      <pre>{JSON.stringify(mopContent, null, 4)}</pre>
    </StyledModal>
  );
};

export default MOPInspectorModal;
