import React, { useState, useEffect, useRef } from "react";
import { string, bool, func, array } from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import Typography from "../../shared/Typography";
import ArrowDownIcon from "../../shared/Icons/ArrowDown";
import { useTranslation, Trans, i18n } from "@lib/i18n";
import { withTabNavigation } from "@hoc/withTabNavigation";

import { color, font, fontSize, lineHeight } from "../../shared/utils";
import { scrollInFullView } from "@lib/scrollToRef";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import QuestionList from "./QuestionList";

const SectionWrapper = styled.div`
  padding: 0 2rem;
  background: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.white : theme.colors.black1};
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.black1 : theme.colors.white};
  padding-bottom: ${({ isOpen }) => isOpen && "1rem"};
  margin: 0.5rem 0;
`;

const CollapsedContent = withTabNavigation(styled.div`
  max-width: ${({ theme }) => theme.contentWidth};
  padding: 1.5rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ isOpen, isLast, theme }) =>
    isOpen || isLast ? "none" : `2px solid ${theme.colors.lightBlack}`};
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.black : theme.colors.white};
  transition: 0.1s linear color;

  &:hover {
    color: ${color("grey")};
  }
`);

const StyledCollapsedContent = styled(CollapsedContent)`
  padding: 1rem 1.5rem;
  border: 1px solid ${color("grey")};
  color: ${color("black")};
  background: transparent;
  transition: 0.1s linear background;

  &:hover {
    color: ${color("black")};
    background: ${color("lightGrey")};
  }
`;

const ItemWrapper = styled.div`
  color: ${color("black")};
  margin-bottom: 1rem;
`;

const Topic = styled(motion.div)`
  max-width: ${({ theme }) => theme.contentWidth};
  margin: 0 auto;
  overflow: hidden;
`;

const StyledArrowRotatableIcon = styled(ArrowDownIcon)`
  transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
  transition: transform 0.3s linear;
  margin-right: 0.3rem;
  color: ${({ switchable, theme }) =>
    !switchable ? theme.colors.grey : "currentColor"};
`;

const StyledTypography = styled(Typography)`
  line-height: ${lineHeight("larger")};
  font-size: ${fontSize("md")};
  font-family: ${font("citroen")};
  color: ${color("black")};
`;

const CollapsedContentTitle = styled(Typography)`
  text-transform: uppercase;
  font-size: ${fontSize("md")};
  color: currentColor;
  font-family: ${font("citroen")};
`;

const CollapsedContentSubTitle = styled(Typography)`
  color: currentColor;
  text-transform: uppercase;
  font-family: ${font("citroen")};
`;

const FAQItem = ({ content, isOpen, label, onClick, isLast, description }) => {
  const { t } = useTranslation();
  const answerRef = useRef(null);
  const [isSelected, setIsSelected] = useState("");

  const handleSelection = label =>
    setIsSelected(isSelected === label ? "" : label);

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      if (isOpen) {
        scrollInFullView(answerRef);
      }
    }, 100);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setIsSelected("");
    }
  }, [isOpen]);

  return (
    <SectionWrapper isOpen={isOpen} isLast={isLast}>
      <CollapsedContent
        isOpen={isOpen}
        onClick={() => onClick(label)}
        isLast={isLast}
      >
        <CollapsedContentTitle isOpen={isOpen}>
          <Trans data-id={`section-${label}`} i18nKey={label} />
        </CollapsedContentTitle>
        <StyledArrowRotatableIcon
          isOpen={isOpen}
          switchable
          data-id={`question-${label}-icon-${isOpen ? "close" : "open"}`}
          url={`url(${getStaticAssetURL("/icons/arrow-down.svg")})`}
          width="12px"
          height="12px"
        />
      </CollapsedContent>
      <AnimatePresence>
        {isOpen && (
          <Topic
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: "auto",
                display: "block",
              },
              collapsed: {
                opacity: 0,
                height: 0,
                transitionEnd: { display: "none" },
                transition: { duration: 0.3, type: "tween" },
              },
            }}
            ref={answerRef}
          >
            <StyledTypography
              data-id={`section-description-${label}`}
              margin="0 0 1rem 0"
            >
              <Trans i18nKey={description} />
            </StyledTypography>
            {content
              .filter(
                section =>
                  i18n.exists(`footer.faq.sections.${section}.title`) &&
                  t(`footer.faq.sections.${section}.title`)
              )
              .map((section, sectionIndex) => {
                const title = t(`footer.faq.sections.${section}.title`);
                const questions = t(
                  `footer.faq.sections.${section}.questions`,
                  {
                    returnObjects: true,
                  }
                );

                return (
                  <ItemWrapper key={sectionIndex}>
                    <StyledCollapsedContent
                      onClick={() => handleSelection(title)}
                    >
                      <CollapsedContentSubTitle>
                        <Trans i18nKey={title} />
                      </CollapsedContentSubTitle>
                      <StyledArrowRotatableIcon
                        isOpen={isSelected === title}
                        switchable={false}
                        data-id={`question-${title}-icon-${
                          isSelected ? "close" : "open"
                        }`}
                        url={`url(${getStaticAssetURL(
                          "/icons/arrow-down.svg"
                        )})`}
                        size="sm"
                      />
                    </StyledCollapsedContent>
                    <AnimatePresence>
                      {isSelected === title && (
                        <Topic
                          key={sectionIndex}
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: {
                              opacity: 1,
                              height: "auto",
                              display: "block",
                            },
                            collapsed: {
                              opacity: 0,
                              height: 0,
                              transitionEnd: { display: "none" },
                              transition: { duration: 0.3, type: "tween" },
                            },
                          }}
                        >
                          <QuestionList
                            questions={questions}
                            title={title}
                            section={section}
                          />
                        </Topic>
                      )}
                    </AnimatePresence>
                  </ItemWrapper>
                );
              })}
          </Topic>
        )}
      </AnimatePresence>
    </SectionWrapper>
  );
};

FAQItem.propTypes = {
  isOpen: bool,
  label: string.isRequired,
  onClick: func.isRequired,
  isLast: bool,
  content: array,
  description: string,
};

export default FAQItem;
