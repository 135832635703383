export const MYACCOUNT_EVENT_TYPES = {
  SHOW_MORE_INFO: "SHOW_MORE_INFO",
  SHOW_LESS_INFO: "SHOW_LESS_INFO",
  DEAL_CONTINUE: "DEAL_CONTINUE",
};

export const MYACCOUNT_UPDATE_URL_TYPES = {
  EMPTY_BASKET: "EMPTY_BASKET",
  MYACCOUNT_DASHBOARD: "MYACCOUNT_DASHBOARD",
  MYACCOUNT_MYORDER: "MYACCOUNT_MYORDER",
  MYACCOUNT_SAVED_DEALS: "MYACCOUNT_SAVED_DEALS",
  MYACCOUNT_PERSONAL_DETAIL: "MYACCOUNT_PERSONAL_DETAIL",
  MYACCOUNT_O2C: "MYACCOUNT_O2C",
  MYACCOUNT_F2M: "MYACCOUNT_F2M",
  MY_ACCOUNT_LOGIN: "MY_ACCOUNT_LOGIN",
};

export const showMoreInfoEvent = label => ({
  type: MYACCOUNT_EVENT_TYPES.SHOW_MORE_INFO,
  data: label,
});

export const showLessInfoEvent = label => ({
  type: MYACCOUNT_EVENT_TYPES.SHOW_LESS_INFO,
  data: label,
});

export const myDealContinueEvent = () => ({
  type: MYACCOUNT_EVENT_TYPES.DEAL_CONTINUE,
});

export const emptyBasketUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.EMPTY_BASKET,
  data,
});

export const myAccountDashboardUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_DASHBOARD,
  data,
});

export const myAccountMyOrderUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_MYORDER,
  data,
});

export const myAccountSavedDealsUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_SAVED_DEALS,
  data,
});

export const myAccountPersonalDetailsUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_PERSONAL_DETAIL,
  data,
});

export const myAccountO2CUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_O2C,
  data,
});

export const myAccountF2MUpdateUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MYACCOUNT_F2M,
  data,
});

export const updateMyAccountLoginVirtualUrlEvent = data => ({
  type: MYACCOUNT_UPDATE_URL_TYPES.MY_ACCOUNT_LOGIN,
  data,
});
