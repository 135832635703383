import getConfig from "next/config";

import { useConfigurationPathname } from "@hooks/useConfigurationPathname";

const { publicRuntimeConfig } = getConfig();

const { ROOT_URL_PREFIX, FRONTEND_URL } = publicRuntimeConfig;

export const useExternalConfigurationPathname = () => {
  const configurationPathname = useConfigurationPathname();

  return FRONTEND_URL + ROOT_URL_PREFIX + configurationPathname;
};
