const { REQUEST_ERROR_CODES } = require("../../constants");

class ApplicationError extends Error {
  constructor(
    message,
    status = 500,
    axiosError = null,
    axiosErrorHandlersConfig = {},
    errorDetails
  ) {
    super(message);

    this.status = status;
    this.details = errorDetails;

    if (axiosError?.isAxiosError) {
      this.handleAxiosError(axiosError, axiosErrorHandlersConfig);
    }
  }

  handleAxiosError(error, errorHandlersConfig) {
    const isResponseCodeInConfig = Object.keys(errorHandlersConfig).includes(
      String(error.response.status)
    );

    if (!isResponseCodeInConfig) {
      return;
    }

    const { status, message } = errorHandlersConfig[error.response.status];

    this.status = status;
    this.message = message;
  }

  getResponseBody() {
    if (this.details) {
      return {
        errorCode: REQUEST_ERROR_CODES.FAILED_TO_RUN_IMPORTS_FOR_COUNTRY,
        details: this.details,
      };
    }

    if (!Object.values(REQUEST_ERROR_CODES).includes(this.message)) {
      return { errorCode: REQUEST_ERROR_CODES.UNKNOWN_ERROR };
    }

    return { errorCode: this.message };
  }
}

module.exports = ApplicationError;
